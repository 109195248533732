import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
    containerBounds: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        maxHeight: '87.8vh',
        maxWidth: '100%',
        '::-webkit-scrollbar': {
            WebkitAppearance: 'none',
            width: 8
        },
        '::-webkit-scrollbar-thumb': {
            backgroundColor: '#ffc501',
            borderRadius: 8,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#ffc501',
            borderImage: 'initial'
        },
        '::-webkit-scrollbar-track': {
            backgroundColor: '#ffc5010d',
            borderRadius: 8,
            borderColor: "#ffc501",
            borderWidth: 0.5
        },
        '@media (min-height: 760px) and (max-height: 860px)': {
            maxHeight: '89.6vh'
        },
        '@media (min-height: 861px) and (max-height: 960px)': {
            maxHeight: '90.8vh'
        },
        '@media (min-height: 961px) and (max-height: 1060px)': {
            maxHeight: '91.8vh'
        },
        '@media (min-height: 1061px) and (max-height: 1160px)': {
            maxHeight: '92.3vh'
        },
        '@media (min-height: 1161px) and (max-height: 1260px)': {
            maxHeight: '93vh'
        },
        '@media (min-height: 1261px) and (max-height: 1360px)': {
            maxHeight: '93.4vh'
        },
        '@media (min-height: 1361px) and (max-height: 1460px)': {
            maxHeight: '93.8vh'
        },
        '@media (min-height: 1461px) and (max-height: 1560px)': {
            maxHeight: '94.3vh'
        },
        '@media (min-height: 1561px) and (max-height: 1660px)': {
            maxHeight: '94.7vh'
        },
        '@media (min-height: 1661px) and (max-height: 1760px)': {
            maxHeight: '94.9vh'
        },
        '@media (min-height: 1761px) and (max-height: 1860px)': {
            maxHeight: '95.2vh'
        },
        // '@media (min-height: 825px)': {
        //     maxHeight: '88.8vh'
        // }, 
        // '@media (max-height: 785px)': {
        //     maxHeight: '86.8vh'
        // }, 
        // '@media (max-height: 725px)': {
        //     maxHeight: '85.8vh'
        // }, 
        // '@media (max-height: 650px)': {
        //     maxHeight: '82.8vh'
        // }, 
        // '@media (max-height: 540px)': {
        //     maxHeight: '79.8vh'
        // }
    },
    containerBoundsTut: {
        overflowX: 'hidden',
        // maxHeight: '87.8vh'
    },
    mqContainerBoundsTut: {
        "@media (max-width: 991px)": {
            maxWidth: "unset"
        },
        "@media (max-width: 784px)": {
            marginTop: 30
        }, 
        "@media (max-width: 425px)": {
            marginTop: 105
        }
    },
    tutorialArrow1: {
        position: 'absolute',
        left: 130,
        zIndex: 1,
        top: 249,
        width: 540,
        "@media (max-width: 1199px)": {
            left: 117,
            top: 230,
            width: 440
        }, 
        "@media (max-width: 964px)": {
            transform: "rotate(5deg)", 
            left: 85
        }, 
        "@media (max-width: 899px)": {
            width: 363, 
            transform: "rotate(7deg)", 
            left: 85
        }, 
        "@media (max-width: 784px)": {
            top: -38, 
            left: 245, 
            transform: "rotate(-57deg)", 
            width: 325
        }
    },
    tutorialGameBack: {
        backgroundColor: "#3a3934",
        padding: "3% 0% 0.5% 0%",
        borderRadius: 400,
        "@media (min-width: 992px) and (max-width: 1199px)": {
            padding: "3% 0% 0% 0%"
        }
    },
    tutorialPrompt: {
        display: "flex",
        alignItems: "center"
    },
    tutorialPromptHash: {
        color: "#FFC700",
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 35,
        letterSpacing: 1.84,
        lineHeight: "41px"
    },
    tutorialPromptMoney: {
        fontSize: 63,
        fontFamily: 'SF Pro Text Heavy',
        letterSpacing: 3.32,
        color: "#FFC700"
    },
    tutorialPromptWin: {
        fontSize: 63,
        fontFamily: 'SF Pro Text Heavy',
        letterSpacing: 3.32,
        color: "FFC700",
        lineHeight: "0px"
    },
    mqtutorialGameBack: {
        '@media (min-width: 900px) and (max-width: 991px)': {
            width: 500,
            height: 510,
            margin: 'auto'
        }, 
        '@media (max-width: 899px)': {
            width: 440, 
            height: 466
        }, 
        '@media (max-width: 784px)': {
            margin: 'auto', 
            height: 475
        }, 
        '@media (min-width: 426px) and (max-width: 475px)': {
            width: 395, 
            height: 425
        }, 
        '@media (max-width: 425px)': {
            width: 315, 
            height: 372
        }, 
        '@media (max-width: 345px)': {
            width: 300
        }
    }, 
    mqtutorialPrompt: {
        position: 'absolute', 
        bottom: '30%', 
        left: 10, 
        '@media (max-width: 784px)': {
            left: 55, 
            bottom: 70
        }, 
        '@media (max-width: 559px)': {
            textAlign: 'center', 
            left: '20%'
        }, 
        '@media (max-width: 515px)': {
            left: '18%'
        }, 
        '@media (max-width: 480px)': {
            left: '16%'
        }, 
        '@media (max-width: 450px)': {
            left: '14%'
        }, 
        '@media (max-width: 425px)': {
            left: '12%'
        }, 
        '@media (max-width: 415px)': {
            left: '10%'
        },
        '@media (max-width: 400px)': {
            left: '12%'
        }, 
        '@media (max-width: 380px)': {
            left: '10%'
        }, 
        '@media (max-width: 360px)': {
            left: '8%'
        }, 
        '@media (max-width: 330px)': {
            left: '6%'
        }, 
        '@media (max-width: 315px)': {
            left: '4%'
        }
    }, 
    mqtutorialPromptWin: {
        '@media (max-width: 820px)': {
            fontSize: 60
        }, 
        '@media (max-width: 400px)': {
            fontSize: 55
        },
        '@media (max-width: 350px)': {
            fontSize: 52
        }
    }, 
    mqtutorialPromptMoney: {
        '@media (max-width: 820px)': {
            fontSize: 60
        }, 
        '@media (max-width: 400px)': {
            fontSize: 55
        }, 
        '@media (max-width: 350px)': {
            fontSize: 52
        }
    }
});