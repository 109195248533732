import { minigameActionTypes, pending, rejected, fulfilled } from '../constants/ActionConstants';

export default function reducer(state = {
    toggleUi: true,
    cannonShotsUpdated : false,
    cannonballBalance: 0,
    addingMoreShots : false
}, action) {
    switch (action.type) {
        case pending(minigameActionTypes.UPDATE_CANNON_SHOTS):
            return {
                ...state,
                transactPayment: false,
                message: "Updating Cannonball Balance"
            }
        case rejected(minigameActionTypes.UPDATE_CANNON_SHOTS):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(minigameActionTypes.UPDATE_CANNON_SHOTS): 
        return {
            ...state, 
            cannonShotsUpdated: true,
            user: action.payload
        }
        case pending(minigameActionTypes.GET_CANNON_BALANCE):
            return {
                ...state,
                message: "Getting Cannonball Balance"
            }
        case rejected(minigameActionTypes.GET_CANNON_BALANCE):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(minigameActionTypes.GET_CANNON_BALANCE): 
        return {
            ...state, 
            cannonballBalance: action.payload.cannonBallBalance
        }
        case minigameActionTypes.TOGGLE_UI: 
            return {
                ...state, 
                toggleUi: !state.toggleUi
            }
        case fulfilled(minigameActionTypes.ADD_MORE_SHOTS):
            return {
                ...state,
                addingMoreShots: true
            }
        case fulfilled(minigameActionTypes.CLOSE_MORE_SHOTS):
            return {
                ...state,
                addingMoreShots: false
            }
        default:
            return state;
    }
};
