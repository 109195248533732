import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
    accountInfoBox: {
        textAlign: 'right',
    },
    countDown: {
        fontFamily: "SF Pro Text Heavy", 
        background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text", 
        WebkitTextFillColor: "transparent",
        marginTop: -110
    },
    countDownTime: {
        fontSize: 200
    },
    gameView: {
        height: '100vh',
        width: '100%',
        position: 'relative'
    },
    gameViewAccountInfo: {
        position: 'absolute', 
        right: '3%'
    },
    gameViewAccountValueText: {
        background: "linear-gradient(to right, #646461, rgb(127, 126, 121))",
        WebkitBackgroundClip: 'text', 
        WebkitTextFillColor: 'transparent',
        fontFamily: 'SF Pro Text Heavy', 
        letterSpacing: -0.47, 
        fontSize: 70,
        lineHeight: 1.2, 
        '@media (max-width: 1050px)': {
            fontSize: 60
        }, 
        '@media (max-width: 899px)': {
            fontSize: 52
        }, 
        '@media (max-width: 650px)': {
            fontSize: 46
        }, 
        '@media (max-width: 570px)': {
            fontSize: 40
        }, 
        '@media (max-width: 450px)': {
            fontSize: 36
        }, 
        '@media (max-width: 400px)': {
            fontSize: 32
        }
    },
    gameViewBackButton: {
        marginLeft: '5%', 
        zIndex: 1, 
    },
    gameViewBackButtonTut: {
        marginLeft: '5%', 
        zIndex: 1, 
        visibility: 'hidden'
    },
    gameViewGameId: {
        color: "#8A8A8F", 
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 11, 
        letterSpacing: -0.07
    },
    gameViewGameInfo: {
        marginLeft: '3%',
    },
    gameViewInfoRow: {
        paddingTop: '2%',
        position: 'relative'
    },
    gameViewMyAccountText: {
        background: "linear-gradient(to right, #646461, rgb(127, 126, 121))",
        WebkitBackgroundClip: 'text', 
        WebkitTextFillColor: 'transparent',
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 25, 
        letterSpacing: -0.17, 
        '@media (max-width: 550px)': {
            fontSize: 22
        }, 
        '@media (max-width: 355px)': {
            fontSize: 20
        }
    },
    gameViewPrizeValueText: {
        background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text", 
        WebkitTextFillColor: "transparent",
        fontSize: 70, 
        letterSpacing: -0.47, 
        fontFamily: 'SF Pro Text Heavy',
        lineHeight: 1.2, 
        '@media (max-width: 1050px)': {
            fontSize: 60
        }, 
        '@media (max-width: 899px)': {
            fontSize: 52
        }, 
        '@media (max-width: 650px)': {
            fontSize: 46
        }, 
        '@media (max-width: 550px)': {
            fontSize: 40
        }, 
        '@media (max-width: 450px)': {
            fontSize: 36
        }, 
        '@media (max-width: 400px)': {
            fontSize: 32
        }
    },
    gameViewPrizeValueTextTut: {
        background: "linear-gradient(to right, #3c935b, #468f60)",
        WebkitBackgroundClip: "text", 
        WebkitTextFillColor: "transparent",
        fontSize: 70, 
        letterSpacing: -0.47, 
        fontFamily: 'SF Pro Text Heavy',
        lineHeight: 1.2, 
        '@media (max-width: 1050px)': {
            fontSize: 60
        }, 
        '@media (max-width: 899px)': {
            fontSize: 52
        }, 
        '@media (max-width: 650px)': {
            fontSize: 46
        }, 
        '@media (max-width: 550px)': {
            fontSize: 40
        }, 
        '@media (max-width: 450px)': {
            fontSize: 36
        }, 
        '@media (max-width: 400px)': {
            fontSize: 32
        }
    },
    gameViewTitle: {
        color: 'rgb(127, 126, 121)',
        fontFamily: 'SF Pro Text Heavy', 
        letterSpacing: 3.68,
        fontSize: 70, 
        '@media (max-width: 1050px)': {
            fontSize: 60
        }, 
        '@media (max-width: 899px)': {
            fontSize: 52
        }, 
        '@media (max-width: 700px)': {
            marginLeft: 40
        },
        '@media (max-width: 650px)': {
            fontSize: 46
        }, 
        '@media (max-width: 575px)': {
            marginLeft: 60
        },
        '@media (max-width: 550px)': {
            fontSize: 40
        }, 
        '@media (max-width: 450px)': {
            fontSize: 36
        }, 
        '@media (max-width: 400px)': {
            fontSize: 32
        }
    },
    gameViewTitleRow: {
        width: '100vw', 
        position: 'absolute', 
        top: '2.5%', 
        display: 'flex', 
        justifyContent: 'center',
        zIndex: -1, 
        '@media (max-width: 775px)': {
            top: '0%'
        }, 
        '@media (max-width: 499px)': {
            top: '5%'
        }, 
        '@media (max-width: 390px)': {
            top: '6.5%'
        }
    },
    gameViewTopBar: {
        paddingTop: '2.5%', 
        // '@media (max-width: 725px)': {
        //     paddingTop: '7.5%'
        // },
    },
    innerNum: {
        backgroundColor: "#3A3934",
        borderRadius: 70,
        width: "100%", 
        height: "100%",
        fontSize: 60, 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center"
    },
    noSelect: {
        WebkitTouchCallout: 'none', /* iOS Safari */
        WebkitUserSelect: 'none', /* Safari */
        KhtmlUserSelect: 'none', /* Konqueror HTML */
        MozUserSelect: 'none', /* Old versions of Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */
        userSelect: 'none' /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    }, 
    particleEffect: {
        position: "absolute", 
        zIndex: -1, 
        // USE BELOW FOR 'particles.gif'
        // bottom: "-20%"
        // USE BELOW FOR 'particlesLonger.gif'
        bottom: "-20%"
    },
    playForText: {
        color: '#fce010', 
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 20, 
        letterSpacing: -0.17
    },
    playForTextHide: {
        color: '#fce010', 
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 20, 
        letterSpacing: -0.17,
        visibility: 'hidden'
    },
    playForTextTut: {
        color: '#3c935b', 
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 20, 
        letterSpacing: -0.17
    },
    plndrHomeButton: {
        margin: '0 auto',
        background: "none",
        color: "#FFCC00",
        border: "2px solid #FFCC00",
        // background: 'linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)',
        // color: "#3A3933",
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        // border: "none",
        borderRadius: 8,
        height: 38,
        width: 161,
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "0 0 0 0.15rem #50504a"
        }, 
        marginTop: -18, 
        marginBottom: 53, 
        '@media (max-width: 390px)': {
            marginTop: -12
        }
    },
    showWinnerButton: {
        textDecoration: 'none',
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        width: 300,
        height: 100,
        background: "linear-gradient(270deg, #FFE300 0%, #FFC500 100%)",
        color: "#3A3933",
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 22, 
        letterSpacing: 0.79,
        textAlign: "center",
        border: "none", 
        borderRadius: 8,
        margin: "0 auto", 
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "0 0 0 0.15rem #50504a"
        } 
    },
    takenImg: {
        width: "100%", 
        height: "100%", 
        borderRadius: 70
    },
    tutReturnButton: {
        background: 'linear-gradient(270deg, #FFCC00 0%, #FF9500 100%)',
        height: 113, 
        width: 415, 
        fontSize: 28,
        color: "#3A3933",
        fontFamily: 'SF Pro Text Heavy',
        letterSpacing: 1,
        textAlign: "center",
        border: "none",
        borderRadius: 16,
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "0 0 0 0.15rem #50504a"
        }, 
        '@media (max-width: 775px)': {
            height: 110, 
            width: 410
        },
        '@media (max-width: 645px)': {
            height: 100, 
            width: 395
        },
        '@media (max-width: 499px)': {
            height: 95, 
            width: 335, 
            fontSize: 27
        }, 
        '@media (max-width: 420px)': {
            height: 90, 
            width: 310, 
            fontSize: 26
        }, 
        '@media (max-width: 380px)': {
            width: 290, 
            fontSize: 25
        }
    }, 
    tutReturnButtonBox: {
        textAlign: 'center',
        '@media (max-width: 899px)': {
            marginTop: 10
        }, 
        '@media (max-width: 599px)': {
            marginTop: 20
        }
    },
    winnersCircle: {
        background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
        height: 320, 
        width: 320, 
        boxShadow: "0 40px 160px 20px rgba(0,0,0,0.16), 0 20px 40px 0 rgba(0,0,0,0.16)", 
        marginTop: -110,
        borderRadius: "10rem", 
        padding: 35,
        margin: "0 auto"
    }, 
    winnersCircleTut: {
        height: 320, 
        width: 320, 
        marginTop: -110,
        borderRadius: "10rem", 
        padding: 35,
        margin: "0 auto", 
        '@media (max-width: 899px)': {
            height: 295, 
            width: 295
        }, 
        '@media (max-width: 799px)': {
            height: 280, 
            width: 280
        },
        '@media (max-width: 599px)': {
            height: 260, 
            width: 260
        }, 
    }, 
    winnersCircleText: {
        textAlign: 'center',
        background: "linear-gradient(to right, rgb(239, 186, 0), rgb(255, 222, 1)) text !important",
        fontFamily: "SF Pro Text Heavy", 
        fontSize: 45, 
        letterSpacing: 2.37, 
    },
    winnerImg: {
        width: "100%", 
        height: "100%", 
        borderRadius: "10rem"
    },
    wheelNodeDisplay: {
        display: 'inline-block', 
        position: 'relative', 
        top: 22,
        fontFamily: 'SF Pro Text Heavy',
        background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text", 
        WebkitTextFillColor: "transparent",
        border: 'none'
    },
    wheelNodeDisplayTest: {
        fontFamily: 'SF Pro Text Heavy', 
        // background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text", 
        // border: 'none',
        background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
        padding: 8,
        borderRadius: '8rem',
        width: 128, 
        height: 128
    }, 
    wheelNodeDisplayTaken: {
        fontFamily: 'SF Pro Text Heavy', 
        // background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text", 
        WebkitTextFillColor: "transparent",
        // border: 'none',
        background: "none",
        padding: 0,
        borderRadius: '8rem',
        width: 128, 
        height: 128
    },
    nameDisplay: {
        fontSize: 'small',
        textAlign: 'center'
    }, 
    mqgameViewAccountInfo: {
        minWidth: 500
    }, 
    mqgameViewBackButtonImg: {
        '@media (max-width: 725px)': {
            
        }, 
        '@media (max-width: 525px)': {
            width: 105
        }
    },
    mqgameViewTitleLong: {
        '@media (max-width: 1150px)': {
            fontSize: 60
        },  
        '@media (max-width: 1050px)': {
            fontSize: 56
        }, 
        '@media (max-width: 950px)': {
            fontSize: 52
        }, 
        '@media (max-width: 910px)': {
            fontSize: 48
        }, 
        '@media (max-width: 860px)': {
            fontSize: 42, 
            marginLeft: 40
        },
        '@media (max-width: 775px)': {
            marginLeft: 40 
        }, 
        '@media (max-width: 699px)': {
            fontSize: 36
        }, 
        '@media (max-width: 625px)': {
            fontSize: 32
        }
    },
    mqinnerNum: {
        '@media (max-width: 330px)': {
            fontSize: 50
        }
    },
    mqinnerNumHundreds: {
        fontSize: 50, 
        '@media (max-width: 675px)': {
            fontSize: 46
        },
        '@media (max-width: 630px)': {
            fontSize: 44
        }, 
        '@media (max-width: 450px)': {
            fontSize: 38
        }, 
        '@media (max-width: 330px)': {
            fontSize: 32
        }
    },  
    mqinnerNumThousands: {
        fontSize: 40, 
        '@media (max-width: 675px)': {
            fontSize: 38
        },
        '@media (max-width: 630px)': {
            fontSize: 35
        }, 
        '@media (max-width: 450px)': {
            fontSize: 30
        }, 
        '@media (max-width: 330px)': {
            fontSize: 25
        }
    },
    mqwheelNodeDisplay: {
        '@media (max-width: 675px)': {
            width: 120, 
            height: 120
        }, 
        '@media (max-width: 630px)': {
            width: 112, 
            height: 112
        }, 
        '@media (max-width: 450px)': {
            width: 100, 
            height: 100
        }, 
        '@media (max-width: 330px)': {
            width: 85, 
            height: 85
        }
    }, 
    mqgameViewTitleRowMock: {
        '@media (max-width: 499px)': {
            top: '2.5%'
        }
    }, 
    mqgameFillMock: {
        '@media (max-width: 575px)': {
            marginLeft: 35
        },
        '@media (max-width: 499px)': {
            top: '0%'
        }, 
        '@media (max-width: 355px)': {
            fontSize: 30
        }, 
        '@media (max-width: 338px)': {
            fontSize: 28
        }
    }, 
    mqgameFillRowMock: {
        '@media (max-width: 499px)': {
            top: '3%'
        }
    }, 
    mqgameViewTitleLongMock: { 
        '@media (max-width: 575px)': {
            marginLeft: 35
        },
        '@media (max-width: 499px)': {
            top: '0%'
        }, 
        '@media (max-width: 450px)': {
            fontSize: 28
        }, 
        '@media (max-width: 385px)': {
            textAlign: 'center'
        }
    }, 
    mqgameFillRowMockLong: {
        '@media (max-width: 385px)': {
            top: '0.5%'
        }
    }, 
    mqcountDownTime: {
        '@media (max-width: 399px)': {
            fontSize: 180
        }
    }, 
    mqshowWinnerButton: {
        '@media (max-width: 350px)': {
            width: 275
        }
    }, 
    mqwinnersCircleTextMock: {
        '@media (max-width: 999px)': {
            fontSize: 42
        }, 
        '@media (max-width: 775px)': {
            fontSize: 40
        }, 
        '@media (max-width: 649px)': {
            fontSize: 38
        }, 
        '@media (max-width: 549px)': {
            fontSize: 34
        }, 
        '@media (max-width: 499px)': {
            marginLeft: 80, 
            marginRight: 80
        }, 
        '@media (max-width: 360px)': {
            marginLeft: 40, 
            marginRight: 40 
        }
    }
}); 