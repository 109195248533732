import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
    adsToHomeButton: {
        position: 'relative',
        top: '20%',
        left: '20%',
        background: 'linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)',
        color: "#3A3933",
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        border: "none",
        borderRadius: 8,
        height: 45,
        width: 250,
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "0 0 0 0.15rem #50504a"
        }
    },
    achievementIcon: {
        width: 30,
        position: 'absolute',
        top: '-10%',
        right: '-32%'
    },
    achievementIconFirst: {
        width: 45,
        position: 'absolute',
        top: '-10%',
        right: '-29%'
    },
    adPlayButton: {
        width: 35, 
        height: 35
    },
    adPlayButtonBox: {
        position: 'absolute',
        width: '100%',
        left: '0',
        ':hover': {
            cursor: 'pointer'
        }
    },
    adPlayButtonContainer: {
        display: 'flex', 
       position: 'relative', 
        alignItems: 'center',
    },
    adPlaylistHeader: {
        textAlign: 'center',
        width: '100%',
        paddingTop: '4%', 
        paddingBottom: '5%',
        color: "#666666", 
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 18,
        letterSpacing: -0.48
        
    },
    adPlayTime: {
        color: '#A1A1A1', 
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 13, 
        letterSpacing: 0.68
    },
    adQuestionVariantText: {
        color: '#A1A1A1', 
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 13, 
        letterSpacing: 0.68
    },
    adStopButton: {
        width: 35,
        height: 35
    },
    buttonBoxAB: {
        marginTop: -180,
        marginBottom: -200,
        width: "100%",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center"
    },
    buttonAB: {
        width: "60%",
        height: 48,
        background: "linear-gradient(270deg, #FFE300 0%, #FFC500 100%)",
        color: "#3A3933",
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        border: "none",
        borderRadius: 8,
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "0 0 0 0.15rem #50504a"
        }, 
        '@media (max-height: 600px)': {
            width: '55%', 
            height: 40
        }
    },
    cannonShotValue: {
        position: 'absolute',
        top: '30%',
        width: '36%', 
        right: '31%',
        textAlign: 'center', 
        fontSize: 27,
        letterSpacing: -0.65,
        color: "#FFE400",
        fontFamily: 'SF Pro Text Heavy'
    },
    closeSideMenuButton: {
        position: 'absolute', 
        top: 9, 
        left: 9, 
        border: '2px solid', 
        borderRadius: 8, 
        padding: 5,
        zIndex: 1, 
        ':hover': {
            cursor: 'pointer'
        }
    },
    adsProgressBar: {
        position: 'absolute',
        height: 9,
        
        background: 'linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)'

    },
    adsProgressBase: {
        background: 'linear-gradient(270deg, #666666 0%, #3A3933 100%)',
        width: '100%',
        height: 9,
        position: 'relative'
    },
    adsProgressRow: {
        marginTop: 5
    },
    currentUserBar: {
        position: 'relative',
        paddingTop: 5,
        paddingBottom: 5
    },
    currentUserBarFirst: {
        position: 'relative',
        paddingTop: 20,
        paddingBottom: 5
    },
    playlistControlSection: {
        position: "absolute", 
        bottom: 0,
        height: '11%'
    },
    earnedAB: {
        color: '#FAC51C',
        fontFamily: 'SF Pro Text Light',
        fontSize: 11,
        marginTop: 6,
        marginBottom: -8

    },
    fadeBox: {
        position: 'absolute',
        bottom: 0,
        height: '3%',
        width: '100%',
        background: 'linear-gradient(#3a393400,#3a3934)'
    },
    fadeBoxTop: {
        zIndex: 1,
        position: 'absolute',
        top: 0,
        height: 20,
        width: '100%',
        background: 'linear-gradient(#3a3934,#3a393400)'
    },
    fixedBoxContainer: {
        position: "relative",
        height: "82vh",
        width: 350,
        // width: '24.3vw', 	
        backgroundColor: "#3A3934",
        borderRadius: 8,
        boxShadow: "0 0 6px rgba(0,0,0,0.16), 0 6px 6px 0 rgba(0,0,0,0.16)"
    },
    adThumbnail: {
        maxHeight: 48,
        maxWidth: 64,
        height: 'auto', 
        width: 'auto',
        borderRadius: 8,
        margin: "0 auto"
    },
    leaderboardPhotoFirst: {
        width: 77,
        borderRadius: 50,
        margin: "0 auto"
    },
    adThumbnailContainer: {
        position: 'relative',
        margin: '0 auto'
    },
    adsboardBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        width: '91%',
        marginTop: -14
    },
    adsboardBoxHidden: {
        visibility: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative'
    },
    adsboardGraphic: {
        width: "62.5%",
    },
    adsboardGraphicSafari: {
        height: 122
    }, 
    earnCashText: {
        position: 'absolute',
        bottom: '6%',
        color: '#3A3934',
        backgroundColor: '#FFCC00',
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 18,
        letterSpacing: -0.48,
        textAlign: 'center',
        width: '49%',
        height: '28%',
        borderRadius: 8,
        boxShadow: '0 4px 16px 2px rgba(0,0,0,0.16)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    leaderBox: {
        marginLeft: 40
    },
    adsList: {
        height: '55%',
        position: 'relative'
    },
    adsListContainer: {
        display: "none",
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
        MsOverflowStyle: 'none',
        '::-webkit-scrollbar': {
            width: 0,
            height: 0
        }
    },
    myUser: {
        backgroundColor: "#3a3933",
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid'
    },
    noSelect: {
        WebkitTouchCallout: 'none', /* iOS Safari */
        WebkitUserSelect: 'none', /* Safari */
        KhtmlUserSelect: 'none', /* Konqueror HTML */
        MozUserSelect: 'none', /* Old versions of Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */
        userSelect: 'none' /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    },
    numberContainer: {
        position: "relative"
    },
    photoContainer: {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        marginRight: -5,
        marginLeft: 5
    },
    photoContainerFirst: {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        marginRight: -5,
        marginLeft: -10
    },
    rankingContainer: {
        display: "flex",
        alignContent: "center",
        alignItems: "center"
    },
    shotsGraphic: {
        width: 122
    },
    shotsGraphicContainer: {
        position: 'absolute',
        right: 0,
        bottom: -10
    },
    timerDays: {

    },
    timerGrid: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
    },
    timerGroup: {

    },
    timerNumber: {
        textAlign: 'center',
        color: "#FAC51C",
        fontSize: 19,
        letterSpacing: 1,
        fontFamily: 'SF Pro Text Heavy'
    },
    tvBox: {
        height: '55%',
        position: 'relative',
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    }, 
    tvBoxImg: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    tvBoxText: {
        textAlign: 'center', 
        background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text", 
        WebkitTextFillColor: "transparent",
        fontFamily: "SF Pro Text Heavy", 
        fontSize: 20, 
        letterSpacing: -0.48, 
        lineHeight: "24px"
    },
    valueEarnedRow: {
        marginTop: -16,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center'
    },
    timerText: {
        textAlign: 'center',
        color: "#FAC51C",
        fontSize: 11,
        fontFamily: 'SF Pro Text Light'
    },
    adInfoName: {
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 19,
        letterSpacing: 1,
        background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        marginTop: 1
    },
    userCurrentPointsLBFirst: {
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 35,
        letterSpacing: 1.84,
        background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        marginTop: 1
    },
    adInfoValue: {
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 13,
        letterSpacing: 0.68,
        background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        marginTop: -3
    },
    adInfoContainer: {
        position: 'relative'
    },
    userInfoContainerFirst: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    userLeaderboardRanking: {
        margin: "0 auto",
        background: "linear-gradient(to right, #fac91f, #ffde01)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 19,
        letterSpacing: 1,
    },
    valueProgress: {
        width: '100%',
        textAlign: 'center',
        color: '#FAC51C',
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 18,
        letterSpacing: 1,
        marginTop: 5
    }, 
    // mqtvBox: {
    //     "@media (max-height: 630px)": {
    //         height: '44.7%', 
    //         marginTop: 15
    //     }, 
    //     "@media (max-height: 565px)": {
    //         height: "42%", 
    //         marginTop: 26
    //     }, 
    //     '@media (max-height: 525px)': {
    //         height: '40%', 
    //         marginTop: 35
    //     }
    // }, 
    mqAdsToHomeButton: {
        '@media (max-height: 800px)': {
            zIndex: 10
        }, 
        '@media (max-height: 600px)': {
            top: '30%', 
            left: '25%', 
            height: 40, 
            width: 230
        }
    },
    mqtvBoxContainer: {
        '@media (min-height: 655px) and (max-height: 680px)': {
            marginTop: 23
        }, 
        '@media (max-height: 654px)': {
            marginTop: 34
        }, 
        '@media (max-height: 585px)': {
            marginTop: 52
        }
    },
    mqtvBoxImg: {
        '@media (max-height: 725px)': {
            width: 200
        }, 
        '@media (max-height: 654px)': {
            width: 170
        }, 
        '@media (max-height: 560px)': {
            width: 150
        }
    }
});