/*
GameActions.js
These are actions that return information assocaited with Main Games
*/

import { gameActionTypes, menuActionTypes, pending_function, rejected_function, fulfilled_function, serverEnvironment, loginActionTypes, rejected } from '../constants/ActionConstants';
import axios from 'axios';
import swal from 'sweetalert';

export var axiosConfig = {
    headers: { 'token': '', 'refUrl': document.location.host }
};

export function clearCurrentGame() {
    const { CLEAR_CURRENT_GAME, FINALIZE_SLOT } = gameActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(CLEAR_CURRENT_GAME));
        dispatch(pending_function(FINALIZE_SLOT));
    }
}

export function gamesRefreshed() {
    const { GAMES_REFRESHED } = gameActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(GAMES_REFRESHED));
    }
}

export function leaderboardRefreshed() {
    const { LEADERBOARD_REFRESHED } = gameActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(LEADERBOARD_REFRESHED));
    }
}

export function enterGameView(gameId) {
    const { ENTER_GAME_VIEW } = gameActionTypes;


    return function (dispatch) {
        dispatch(fulfilled_function(ENTER_GAME_VIEW, parseInt(gameId)));
    }

}

export function finalizeSlot(token, payload) {
    const { FINALIZE_SLOT } = gameActionTypes;
    const { UPDATE_USER } = gameActionTypes;
    const { LOGOUT } = loginActionTypes;

    return function (dispatch) {
        dispatch(pending_function(FINALIZE_SLOT));
        axiosConfig.headers.token = token;

        axios
            .put(serverEnvironment.apiHost + '/game/finalizeSlot', payload, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(FINALIZE_SLOT, res.data.game));
                dispatch(fulfilled_function(UPDATE_USER, res.data.user));
                // if (res.data.game.filled) {
                    // if (res.data.game.winnerId === res.data.user.id) {
                    //     // alert("Congratulations!  You won $" + String(res.data.game.prizeValue.toFixed(2)));
                    //     swal("Congratulations!", "You won $" + String(res.data.game.prizeValue.toFixed(2)) + "!", "success");
                    // }
                    // else {
                    //     // alert("Game over.  Better luck next time!");
                    //     swal("Game Over", "Unfortunately you didn't win. Try your luck again!", "info");
                    // }
                // }
                console.log(res); 
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT, err));
                }
                dispatch(rejected_function(FINALIZE_SLOT, err));
                swal("Error on finalizeSlot", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    };
}

export function getGames(token) {
    const { GET_GAMES } = gameActionTypes;

    return function (dispatch) {
        dispatch(pending_function(GET_GAMES));
        var payload = {

        };

        if (token) {
            axiosConfig.headers.token = token;
            axios
                .post(serverEnvironment.apiHost + '/game/active', payload, axiosConfig)
                .then((res) => {
                    let gameList = res.data;
                    // console.log(gameList);
                    dispatch(fulfilled_function(GET_GAMES, gameList));
                })
                .catch(err => {
                    dispatch(rejected_function(GET_GAMES, err));
                    swal("Error on getGames", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
                });
        }
        else {
            axios
                .post(serverEnvironment.apiHost + '/game/activeNologin', payload, axiosConfig)
                .then((res) => {
                    let gameList = res.data;
                    // console.log("getGames", gameList);
                    dispatch(fulfilled_function(GET_GAMES, gameList));
                })
                .catch(err => {
                    dispatch(rejected_function(GET_GAMES, err));
                    swal("Error on getGames", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
                });
        }
    }
}

export function getLeaderboard(token) {
    const { GET_LEADERBOARD } = gameActionTypes;

    return function (dispatch) {
        dispatch(pending_function(GET_LEADERBOARD));
        axiosConfig.headers.token = token;
        axios
            .get(serverEnvironment.apiHost + '/leaderboard')
            .then((res) => {
                let leaderboard = res.data;
                // console.log(leaderboard); 
                dispatch(fulfilled_function(GET_LEADERBOARD, leaderboard, axiosConfig));
            })
            .catch(err => {
                dispatch(rejected_function(GET_LEADERBOARD, err));
                swal("Error on getLeaderboard", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    }
}

export function requestSlot(token, payload) {
    const { REQUEST_SLOT } = gameActionTypes;
    const { LOGOUT } = loginActionTypes;

    return function (dispatch) {
        dispatch(pending_function(REQUEST_SLOT));

        // console.log(payload); 
        axiosConfig.headers.token = token;

        axios
            .post(serverEnvironment.apiHost + '/game/requestSlot', payload, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(REQUEST_SLOT, { data: res, slot: payload.slot }));
                // console.log(res); 
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT, err));
                }
                else if (err.response.status === 409) {
                    dispatch(rejected_function(REQUEST_SLOT, err));
                    swal("Slot Reserved", "Another user is currently watching ads to enter into this slot. Please try again in 5 minutes if the user hasn't already claimed it.");
                }
                else {
                    dispatch(rejected_function(REQUEST_SLOT, err));
                    swal("Error on requestSlot", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
                }
            });
    };
}

export function transactValue(token, payload) {
    const { TRANSACT_VALUE } = gameActionTypes;
    const { UPDATE_USER } = gameActionTypes;
    const { LOGOUT } = loginActionTypes;

    return function (dispatch) {
        dispatch(pending_function(TRANSACT_VALUE));
        axiosConfig.headers.token = token;

        axios
            .put(serverEnvironment.apiHost + '/game/transact', payload, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(TRANSACT_VALUE, res));
            })
            .then(() => {
                axios.get(serverEnvironment.apiHost + '/user/byId/' + payload.userID, axiosConfig)
                    .then((res) => {
                        dispatch(fulfilled_function(UPDATE_USER, res.data));
                    });
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT, err));
                }
                dispatch(rejected_function(TRANSACT_VALUE, err));
                swal("Error on transactValue", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    };
}

export function unrequestSlot(token, gameId, userId) {
    const { UNREQUEST_SLOT } = gameActionTypes;
    const { LOGOUT } = loginActionTypes;

    return function (dispatch) {
        dispatch(pending_function(UNREQUEST_SLOT));
        axiosConfig.headers.token = token;

        axios
            .delete(serverEnvironment.apiHost + '/game/unrequest/' + gameId + '/' + userId, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(UNREQUEST_SLOT, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT, err));
                }
                dispatch(rejected_function(UNREQUEST_SLOT, err));
                swal("Error on unrequestSlot", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    };
}

export function getGameHistory(token, userId) {
    const { GET_GAME_HISTORY } = gameActionTypes;
    const { LOGOUT } = loginActionTypes;

    return function (dispatch) {
        dispatch(pending_function(GET_GAME_HISTORY));
        axiosConfig.headers.token = token;

        axios
            .get(serverEnvironment.apiHost + '/game/history/' + String(userId), axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_GAME_HISTORY, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT, err));
                }
                dispatch(rejected_function(GET_GAME_HISTORY, err));
                swal("Error on getGameHistory", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    };
}

export function getUnreadCount(token) {
    const { GOT_ALERT } = menuActionTypes;
    const { LOGOUT } = loginActionTypes;

    return function (dispatch) {
        dispatch(pending_function(GOT_ALERT));
        axiosConfig.headers.token = token;

        axios
            .get(serverEnvironment.apiHost + '/notification/getUnreadCount', axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GOT_ALERT, res.data.id));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT, err));
                }
                dispatch(rejected_function(GOT_ALERT, err));
                // swal("Error on getUnreadCount", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    };
}

export function markAlertsRead(token) {
    const { CLEAR_ALERT } = menuActionTypes;
    const { LOGOUT } = loginActionTypes;

    return function (dispatch) {
        dispatch(pending_function(CLEAR_ALERT));
        axiosConfig.headers.token = token;

        axios
            .put(serverEnvironment.apiHost + '/notification/markRead', {}, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(CLEAR_ALERT));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT, err));
                }
                dispatch(rejected_function(CLEAR_ALERT, err));
                swal("Error on markAlertsRead", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    };
}

export function addUserToLeaderboard(token, userID) {
    const { ADD_USER_TO_LEADERBOARD } = gameActionTypes;
    const { GET_LEADERBOARD } = gameActionTypes;
    const { LOGOUT } = loginActionTypes;

    return function (dispatch) {
        dispatch(pending_function(ADD_USER_TO_LEADERBOARD));
        axiosConfig.headers.token = token;
        axios
            .post(serverEnvironment.apiHost + '/leaderboard/' + String(userID), {}, axiosConfig)
            .then((res) => {
                let leaderboard = res.data;
                dispatch(fulfilled_function(ADD_USER_TO_LEADERBOARD, res.data));
                dispatch(fulfilled_function(GET_LEADERBOARD, leaderboard, axiosConfig));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT, err));
                }
                dispatch(rejected_function(ADD_USER_TO_LEADERBOARD, err));
                swal("Error on Adding user to the leaderboard", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    };
}