import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Modal, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { sleep, serverEnvironment } from '../../constants/ActionConstants.js';
import { closeAddCredits } from '../../actions/MenuActions';
import { makePayment, sendClientNonce, addPaypalPayment } from '../../actions/PaymentActions';
import { Redirect } from 'react-router';
import { css } from 'aphrodite';
import { styles } from './AddCreditsStyles';
import { braintreewebdropin } from 'braintree-web-drop-in';
import { braintreeweb } from 'braintree-web';
import { braintree } from 'braintree';
import { paypalcheckout } from 'braintree-web/paypal-checkout';
import swal from 'sweetalert';
import MediaQuery from 'react-responsive';
import './AddCredits.css'


class AddCredits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymethodCard: true,
            transactionValue: "10",
            cannonshotvalue: "1"
        };
        this.input = React.createRef();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onClickCloseAddCredits = this.onClickCloseAddCredits.bind(this);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this.onClickClosePaymentConfirm = this.onClickClosePaymentConfirm.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            //this.props.closeAddCredits();
        }
    }

    forceUpdateHandler() {
        this.forceUpdate();
    };

    handleLoad() {
        this.setState({ loaded: true });
    }

    componentDidMount() {
        if (!this.state.loaded) {
            this.createPaypalClient();
            // this.createCreditCardBraintreeClient();
            if (this.props.addingmoreshots)
                this.setState({ transactionValue: "1" });
        }
        document.addEventListener('load', this.handleLoad);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('load', this.handleLoad)
        this.onClickCloseAddCredits();
    }

    onClickCloseAddCredits() {
        this.props.closeAddCredits();
    }

    onClickClosePaymentConfirm() {
        sleep(500).then(() => {
            //alert("wait");
            if (this.props.addingmoreshots)
                document.location = "/minigame";
            else
                document.location = "/";
        })
    }

    makePaymentCall(event) {
        event.preventDefault();
        //var chargeAmount = Number(document.getElementById("chargeAmount").innerHTML.replace(/[^0-9\.]+/g, ""));
        var chargeAmount = parseInt(document.getElementById("chargeAmount").value);
        if ((chargeAmount >= 5) || ((this.props.addingmoreshots) && (chargeAmount == 1))) {
            // var paymethod = document.getElementById('paymethod').value;
            // if (paymethod === "PAYPAL") {
            //     this.sendPaypalData(chargeAmount);
            // }
            // else {
            //     this.makePaymentEntry(chargeAmount);
            // }
            this.sendPaypalData(chargeAmount);
        }
        else {
            alert("Please enter an amount to add credits");
        }
    }
    sendPaypalData(chargeAmount) {
        // console.log("In Paypal Entry");
        var responseText = "";
        var methodType = document.getElementById('paymethod').value;
        //var chargeAmount = Number(document.getElementById("chargeAmount").innerHTML.replace(/[^0-9\.]+/g,""));
        if (document.getElementById('paypalprocessorResponseText').value === "COMPLETED") {
            responseText = "Approved"
        }
        else {
            responseText = document.getElementById('paypalprocessorResponseText').value
        }
        var payload = {
            userId: this.props.currUser.id,
            customerId: this.props.currUser.id,
            transactionStatus: document.getElementById('paypalprocessorResponseText').value,
            transactionPaymentMethodType: methodType,
            amount: chargeAmount,
            deviceData: document.getElementById('deviceData').value,
            transactionDate: document.getElementById('paypaltransactionDate').value,
            processorAuthorizationCode: document.getElementById('paypalprocessorAuthorizationCode').value,
            processorResponseText: responseText
        }

        if (this.props.addingmoreshots) {
            payload.addMoreShots = true;
        }
        // console.log("payload before API", payload);
        //if (payload.clientNonce) {
        this.props.addPaypalPayment(this.props.logintoken, payload);
        //}
    }

    makePaymentEntry(chargeAmount) {
        // console.log("In payment Entry");
        //var chargeAmount = Number(document.getElementById("chargeAmount").innerHTML.replace(/[^0-9\.]+/g,""));
        var payload = {
            userId: this.props.currUser.id,
            amount: chargeAmount,
            clientNonce: document.getElementById("hiddenNonce").value,
            deviceData: document.getElementById("deviceData").value
        };
        if (this.props.addingmoreshots) {
            payload.addMoreShots = true;
        }
        if (payload.clientNonce) {
            this.props.makePayment(this.props.logintoken, payload);
        }
        // console.log('payment is called with: ' + JSON.stringify(payload));
    }

    createCreditCardBraintreeClient() {
        // console.log("Creating creditcard braintree client...");
        var braintreewebdropin = require("braintree-web-drop-in");
        var braintreeweb = require("braintree-web");
        braintreewebdropin.create({
            //authorization: 'sandbox_249nbbmy_7ctbvsygh9rmvxtm',
            authorization: this.props.clienttoken,
            container: '#dropin-container',
            vaultManager: true,
            card: {
                cardholderName: false,
                overrides: {
                    fields: {
                        number: {
                            placeholder: 'Card Number',
                            formatInput: true // Turn off automatic formatting
                        }
                    },
                    // styles: {
                    //     input: {
                    //         'font-size': '18px' // Change the font size for all inputs
                    //     },
                    //     ':focus': {
                    //         color: 'red' // Change the focus color to red for all inputs
                    //     },
                    //     '.braintree-sheet__text': {
                    //         'font-family': 'monospace !important'
                    //     }
                    // },
                }
            }
        }, function (clientErr, dropinInstance) {
            if (clientErr) {
                console.error('Error creating Braintree Credit card client:', clientErr);
                return;
            }
            // //Collect Devicedata
            // braintreewebdropin.dataCollector.({
            //     client: dropinInstance,
            //     paypal: true
            //   }, function (err, dataCollectorInstance) {
            //     if (err) {
            //         console.error('Error collectingg Braintree devicedata:', err);
            //       return;
            //     }
            //     document.getElementById('deviceData').value = dataCollectorInstance.deviceData;
            //     console.log("device data", dataCollectorInstance.deviceData);
            //   });
            dropinInstance.on('noPaymentMethodRequestable', function () {
                document.getElementById('submit-button').setAttribute('disabled', true);
            });
            if (dropinInstance.isPaymentMethodRequestable()) {
                // This will be true if you generated the client token
                // with a customer ID and there is a saved payment method
                // available to tokenize with that customer.
                document.getElementById('submit-button').removeAttribute('disabled');
            }
            dropinInstance.on('paymentMethodRequestable', function (event) {
                document.getElementById('paymethod').value = event.type;
                // console.log(event.paymentMethodIsSelected); // true if a customer has selected a payment method when paymentMethodRequestable fires
                if (!event.paymentMethodIsSelected) {
                    document.getElementById('submit-button').setAttribute('disabled', true);
                }
                if ((event.paymentMethodIsSelected) || (dropinInstance.isPaymentMethodRequestable)) {
                    document.getElementById('submit-button').removeAttribute('disabled');
                }
            });

            document.getElementById('submit-button').addEventListener('mousedown', function (event) {
                event.preventDefault();
                dropinInstance.requestPaymentMethod(function (err, payload) {
                    document.getElementById('hiddenNonce').value = payload.nonce;
                    // console.log(document.getElementById('hiddenNonce').value);
                });
            });
        });
    }

    createPaypalBraintreeClient() {
        // console.log("Creating Paypal braintree client...");
        var braintreepaypal = require("braintree-web");
        braintreepaypal.client.create({
            authorization: this.props.clienttoken
        }, function (clientErr, clientInstance) {
            if (clientErr) {
                console.error('Error creating Braintree Paypal client:', clientErr);
                return;
            }
            braintreepaypal.dataCollector.create({
                client: clientInstance,
                paypal: true
            }, function (err, dataCollectorInstance) {
                if (err) {
                    console.error('Error collectingg Braintree devicedata:', err);
                    return;
                }
                document.getElementById('deviceData').value = dataCollectorInstance.deviceData;
            });
            braintreepaypal.paypalCheckout.create({
                client: clientInstance
            }, function (paypalCheckoutErr, paypalCheckoutInstance) {
                paypalCheckoutInstance.loadPayPalSDK({
                    intent: 'capture'
                }, function () {
                    window.paypal.Buttons({
                        fundingSource: window.paypal.FUNDING.PAYPAL,
                        commit: true,
                        createOrder: function (data, actions) {
                            document.getElementById('carddiv').style.display = "none";
                            swal({
                                text: "Paypal Payment is being processed...",
                                icon: "info"
                            });
                            return actions.order.create({
                                flow: 'checkout',
                                purchase_units: [{
                                    amount: {
                                        // value: Number(document.getElementById("chargeAmount").innerHTML.replace(/[^0-9\.]+/g, "")),
                                        value: Number(document.getElementById("chargeAmount").value)
                                    }
                                }]
                            });
                        },
                        onApprove: function (data, actions) {
                            return actions.order.capture().then(function (details) {
                                // console.log(details);
                                var payload = {
                                    transactionStatus: details.status,
                                    transactionPaymentMethodType: "PAYPAL",
                                    amount: details.purchase_units[0].amount.value,
                                    transactionDate: details.create_time,
                                    processorAuthorizationCode: details.id,
                                    processorResponseText: details.status
                                    //deviceData: document.getElementById("deviceData").value
                                };
                                document.getElementById('paymethod').value = "PAYPAL";
                                document.getElementById('paypaltransactionDate').value = details.create_time;
                                document.getElementById('paypalprocessorAuthorizationCode').value = details.id;
                                document.getElementById('paypalprocessorResponseText').value = details.status;
                                document.getElementById('paypalbtn').click();
                                swal({
                                    text: "Paypal Payment Successful",
                                    icon: "info"
                                });
                                // document.querySelector('#paypal-button-container').innerText = 'Payment Complete!';
                                // document.getElementById('submit-button').style.visibility = "hidden";
                            });
                        },

                        onCancel: function (data) {
                            //Send to Confirmation page for cancel confirmation
                            //document.querySelector('#paypal-button-container').innerText = 'Payment Cancelled';
                            swal({
                                text: "Paypal Payment Cancelled!",
                                icon: "warning"
                            });
                            // console.log('PayPal payment cancelled', JSON.stringify(data, 0, 2));
                        },

                        onError: function (err) {
                            //Send to Confirmation page for Payment not made
                            //   if (details.error === 'INSTRUMENT_DECLINED') {
                            //     return actions.restart();
                            //   }
                            //document.querySelector('#paypal-button-container').innerText = 'Payment Error';
                            //console.error('PayPal error', err);
                            swal({
                                text: "Paypal Payment Error, Try Again!",
                                icon: "error"
                            });
                        }
                    }).render('#paypal-button-container');
                });
            });
        });
    }
    createPaypalClient() {
        var FUNDING_SOURCES = [
            window.paypal.FUNDING.PAYPAL,
            window.paypal.FUNDING.PAYLATER,
            window.paypal.FUNDING.VENMO,
            window.paypal.FUNDING.CARD
        ];

        // Loop over each funding source / payment method
        FUNDING_SOURCES.forEach(function (fundingSource) {

            let methodType = ""

            switch (fundingSource) {
                case window.paypal.FUNDING.PAYPAL:
                    methodType = "PAYPAL"
                    break;
                case window.paypal.FUNDING.CARD:
                    methodType = "CARD PAYMENT"
                    break;
                case window.paypal.FUNDING.VENMO: 
                    methodType = "VENMO"
                    break;
                case window.paypal.FUNDING.PAYLATER:
                    methodType = "PAYPAL PAY LATER"
                    break;
            }

            var obj = {
                fundingSource: fundingSource,
                createOrder: function (data, actions) {
                    return actions.order.create({
                        flow: 'checkout',
                        purchase_units: [{
                            amount: {
                                value: Number(document.getElementById("chargeAmount").value)
                            }
                        }]
                    });
                },
                onApprove: function (data, actions) {
                    return actions.order.capture().then(function (details) {
                        console.log(details);
                        console.log(data);
                        var payload = {
                            transactionStatus: details.status,
                            transactionPaymentMethodType: methodType,
                            amount: details.purchase_units[0].amount.value,
                            transactionDate: details.create_time,
                            processorAuthorizationCode: details.id,
                            processorResponseText: details.status
                            //deviceData: document.getElementById("deviceData").value
                        };
                        document.getElementById('paymethod').value = methodType;
                        document.getElementById('paypaltransactionDate').value = details.create_time;
                        document.getElementById('paypalprocessorAuthorizationCode').value = details.id;
                        document.getElementById('paypalprocessorResponseText').value = details.status;
                        document.getElementById('paypalbtn').click();
                        swal({
                            text: "Paypal Payment Successful",
                            icon: "info"
                        });
                        // document.querySelector('#paypal-button-container').innerText = 'Payment Complete!';
                        // document.getElementById('submit-button').style.visibility = "hidden";
                    });
                },

                onCancel: function (data) {
                    //Send to Confirmation page for cancel confirmation
                    //document.querySelector('#paypal-button-container').innerText = 'Payment Cancelled';
                    swal({
                        text: "Payment Cancelled!",
                        icon: "warning"
                    });
                    // console.log('PayPal payment cancelled', JSON.stringify(data, 0, 2));
                },

                onError: function (err) {
                    //Send to Confirmation page for Payment not made
                    //   if (details.error === 'INSTRUMENT_DECLINED') {
                    //     return actions.restart();
                    //   }
                    //document.querySelector('#paypal-button-container').innerText = 'Payment Error';
                    console.error('PayPal error', err);
                    swal({
                        text: "Paypal Payment Error, Try Again!",
                        icon: "error"
                    });
                }
            }

            if (fundingSource === window.paypal.FUNDING.CARD) {
                obj.style = { color: "black" }
            }
            else if (fundingSource === window.paypal.FUNDING.PAYLATER || fundingSource === window.paypal.FUNDING.PAYPAL) {
                obj.style = { color: "gold" }
            }

            // Initialize the buttons
            var button = window.paypal.Buttons(obj);

            // Check if the button is eligible
            if (button.isEligible()) {
                // Render the standalone button for that funding source
                button.render('#paypal-button-container')
            }
        });
        // console.log("Creating Paypal braintree client...");
        // window.paypal.Buttons({
        //     fundingSource: window.paypal.FUNDING.PAYPAL,
        //     //fundingSource: window.paypal.FUNDING.CREDIT,
        //     //commit: true,
        //     createOrder: function (data, actions) {
        //         document.getElementById('carddiv').style.display = "none";
        //         swal({
        //             text: "Paypal Payment is being processed...",
        //             icon: "info"
        //         });
        //         return actions.order.create({
        //             flow: 'checkout',
        //             purchase_units: [{
        //                 amount: {
        //                     // value: Number(document.getElementById("chargeAmount").innerHTML.replace(/[^0-9\.]+/g, "")),
        //                     value: Number(document.getElementById("chargeAmount").value)
        //                 }
        //             }]
        //         });
        //     },
        //     onApprove: function (data, actions) {
        //         return actions.order.capture().then(function (details) {
        //             console.log(details);
        //             console.log(data);
        //             var payload = {
        //                 transactionStatus: details.status,
        //                 transactionPaymentMethodType: "PAYPAL",
        //                 amount: details.purchase_units[0].amount.value,
        //                 transactionDate: details.create_time,
        //                 processorAuthorizationCode: details.id,
        //                 processorResponseText: details.status
        //                 //deviceData: document.getElementById("deviceData").value
        //             };
        //             document.getElementById('paymethod').value = "PAYPAL";
        //             document.getElementById('paypaltransactionDate').value = details.create_time;
        //             document.getElementById('paypalprocessorAuthorizationCode').value = details.id;
        //             document.getElementById('paypalprocessorResponseText').value = details.status;
        //             document.getElementById('paypalbtn').click();
        //             swal({
        //                 text: "Paypal Payment Successful",
        //                 icon: "info"
        //             });
        //             // document.querySelector('#paypal-button-container').innerText = 'Payment Complete!';
        //             // document.getElementById('submit-button').style.visibility = "hidden";
        //         });
        //     },

        //     onCancel: function (data) {
        //         //Send to Confirmation page for cancel confirmation
        //         //document.querySelector('#paypal-button-container').innerText = 'Payment Cancelled';
        //         swal({
        //             text: "Paypal Payment Cancelled!",
        //             icon: "warning"
        //         });
        //         // console.log('PayPal payment cancelled', JSON.stringify(data, 0, 2));
        //     },

        //     onError: function (err) {
        //         //Send to Confirmation page for Payment not made
        //         //   if (details.error === 'INSTRUMENT_DECLINED') {
        //         //     return actions.restart();
        //         //   }
        //         //document.querySelector('#paypal-button-container').innerText = 'Payment Error';
        //         //console.error('PayPal error', err);
        //         swal({
        //             text: "Paypal Payment Error, Try Again!",
        //             icon: "error"
        //         });
        //     }
        // }).render('#paypal-button-container');
    }
    increaseAmount(e) {
        //var chargeamt = Number(document.getElementById("chargeAmount").innerHTML.replace(/[^0-9\.]+/g, ""));
        //var chargeamt = parseInt(document.getElementById("chargeAmount").value);
        //var newnum = chargeamt + 1;
        //document.getElementById("chargeAmount").innerText = "$" + newnum + ".00";
        var newnum = parseInt(document.getElementById("chargeAmount").value);
        newnum += 1;
        this.setState({ transactionValue: newnum });
    }
    decreaseAmount(e) {
        //var chargeamt = Number(document.getElementById("chargeAmount").innerHTML.replace(/[^0-9\.]+/g, ""));
        //var chargeamt = parseInt(document.getElementById("chargeAmount").value);
        var newnum = parseInt(document.getElementById("chargeAmount").value);
        newnum -= 1;
        if (newnum >= 5) {
            this.setState({ transactionValue: newnum });
        }
        else {
            swal({
                text: "Minimum credit is $5.00",
                icon: "info"
            });
        }
    }

    updateTransactValue(e) {
        //var chargeamtdouble = parseInt(document.getElementById("chargeAmount").value);
        this.setState({ transactionValue: parseInt(e.target.value) ? parseInt(e.target.value) : '' });
        //document.getElementById("chargeAmount").innerText = newnum;
        //this.setState({ transactionValue: newnum + ".00" });
    }
    render() {
        return (
            this.props.transactpayment && this.props.addingcredits ?
                (
                    <div className={css(styles.bootstrapBasic, styles.paymentconfirmbody)} ref={this.setWrapperRef}>
                        <Row className={css(styles.menuRowExit)}>
                            <Col className={css(styles.closeMenuButton, styles.mqcloseMenuButton)}><img className={css(styles.addFundsIcon, styles.menuRowOption)} src={require('../../images/exitalt.svg')} onClick={() => this.onClickClosePaymentConfirm()} /></Col>
                        </Row>
                        <div>
                            <Row className={css(styles.menuRowAddCredits)}>
                                <Col md={6} className={css(styles.addCreditsTitleRow, styles.addCreditsTitle)}>Payment Confirmation</Col>
                            </Row>
                            <Row className={css(styles.menuRow, styles.menuRowOptionPaymentValue)}>
                                <Col className={css(styles.confirmAmountTitlefonts)}>
                                    <span>${this.props.paymenttransactionamount}.00 </span>
                                    <div className={css(styles.paidText)}>{this.props.paymentstatus}</div>
                                </Col>
                            </Row>
                        </div>
                        <Row className={css(styles.menuRowConfirmTitle)}>
                            <Col md={10} className={css(styles.menuOptionCol, styles.paymentconfirmSmallFonts)} >
                                <span>Transaction ID: </span>
                            </Col>
                        </Row>
                        <Row className={css(styles.menuRowConfirm)}>
                            <Col md={10} className={css(styles.menuOptionCol, styles.paymentconfirmSmallDarkFonts)} >
                                <span>{this.props.paymenttransactionid}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <hr className={css(styles.confirmationDivider)}></hr>
                            </Col>
                            <Col md={1}>
                            </Col>
                        </Row>
                        <Row className={css(styles.menuRowConfirmTitle)}>
                            <Col md={10} className={css(styles.menuOptionCol, styles.paymentconfirmSmallFonts)} >
                                <span> Merchant Name</span>
                            </Col>
                        </Row>
                        <Row className={css(styles.menuRowConfirm)}>
                            <Col md={10} className={css(styles.menuOptionCol, styles.paymentconfirmSmallDarkFonts)} >
                                <span> Plndr Mobile, Inc. </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <hr className={css(styles.confirmationDivider)}></hr>
                            </Col>
                            <Col md={1}>
                            </Col>
                        </Row>
                        <Row className={css(styles.menuRowConfirmTitle)}>
                            <Col md={10} className={css(styles.menuOptionCol, styles.paymentconfirmSmallFonts)} >
                                <span> Email Receipt Sent To: </span>
                            </Col>
                        </Row>
                        <Row className={css(styles.menuRowConfirm)}>

                            <Col md={10} className={css(styles.menuOptionCol, styles.paymentconfirmSmallDarkFonts)} >
                                <span> {this.props.currUser.email} </span>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <hr className={css(styles.confirmationDivider)}></hr>
                            </Col>
                            <Col md={1}>
                            </Col>
                        </Row>
                        <Row className={css(styles.menuRowOptionPayRow)}>
                            <Col>
                                <div className={css(styles.dropInBtnContainer)}>
                                    <button className={css(styles.dropinbtn, styles.addcreditButton)} id="confirm-button" onClick={() => this.onClickClosePaymentConfirm()}>Back to Plndr</button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )
                :
                (
                    !this.props.clienttoken ?
                        (

                            <div className={css(styles.bootstrapBasic, styles.addcreditsbody)} ref={this.setWrapperRef}>
                                <Row className={css(styles.menuRow, styles.menuRowOption)}>
                                    <Col md={6} className={css(styles.addCreditsTitleRow, styles.addCreditsTitle)}>Loading Payment Options</Col>
                                </Row>
                            </div>

                        ) :
                        (
                            <div className={css(styles.bootstrapBasic, styles.addcreditsbody)} ref={this.setWrapperRef}>
                                <Row className={css(styles.menuRowExit)}>
                                    <Col className={css(styles.closeMenuButton, styles.mqcloseMenuButton)}><img className={css(styles.addFundsIcon, styles.menuRowOption)} src={require('../../images/exitalt.svg')} onClick={() => this.onClickCloseAddCredits()} /></Col>
                                </Row>
                                {this.props.addingmoreshots ?
                                    (<div>
                                        <Row className={css(styles.menuRowAddCredits)}>
                                            <Col md={6} className={css(styles.addCreditsTitleRow, styles.addCreditsTitle)}>Add 5 Cannon Shots</Col>
                                        </Row>
                                        <Row className={css(styles.menuRow, styles.menuRowOptionPaymentValue)}>
                                            <Col md={3}></Col>
                                            <Col md={6} className={css(styles.amountTitlefontsRow)}>
                                                &nbsp;&nbsp;&nbsp;
                                            <span>$</span>
                                                <input className={css(styles.dollarAmount, styles.amountTitlefonts)} type="number" placeholder="1" value={this.state.cannonshotvalue} id="chargeAmount" required min="1"></input>
                                            </Col>
                                            <Col md={3}></Col>
                                        </Row>
                                    </div>)
                                    :
                                    (<div className={css(styles.noSelect)}>
                                        <Row className={css(styles.menuRowAddCredits)}>
                                            <Col md={6} className={css(styles.addCreditsTitleRow, styles.addCreditsTitle)}>Add Credits</Col>
                                        </Row>
                                        <Row className={css(styles.menuRow, styles.menuRowOptionPaymentValue)}>
                                            {/* <Col md={1}></Col>
                                            <Col md={2} className={css(styles.minussignamount)}> <span className={css(styles.minusSign)} id="minus" onClick={() => this.decreaseAmount()}> - </span> </Col>
                                            <Col md={6} className={css(styles.amountTitlefonts)}> <span id="chargeAmount">$20.00</span></Col>
                                            <Col md={2} className={css(styles.plussignamount)}> <span className={css(styles.plusSign)}id="plus" onClick={() => this.increaseAmount()}> + </span></Col>
                                            <Col md={1}></Col> */}
                                            <MediaQuery minWidth={992}>
                                                <Col md={1}></Col>
                                                <Col md={2} className={css(styles.minusSignContain)}><div className={css(styles.circle)} onClick={(e) => this.decreaseAmount(e)}><span className={css(styles.minussign)} id="minus"> - </span></div> </Col>
                                                <Col md={6} className={css(styles.amountTitlefontsRow)}>
                                                    &nbsp;&nbsp;&nbsp;
                                                <span>$</span>
                                                    <input className={css(styles.dollarAmount, styles.amountTitlefonts)} type="number" value={this.state.transactionValue} id="chargeAmount" required min="5" maxLength="6" onChange={(e) => this.updateTransactValue(e)}></input>
                                                    {/* <span>.00</span> */}
                                                </Col>
                                                <Col md={2} className={css(styles.minusSignContain)}><div className={css(styles.circle)} onClick={(e) => this.increaseAmount(e)}><span className={css(styles.plussign)} id="plus"> + </span></div></Col>
                                                <Col md={1}></Col>
                                            </MediaQuery>
                                            <MediaQuery maxWidth={991}>
                                                <Col md={3} sm={3} xs={3} className={css(styles.minusSignContain)}><div className={css(styles.circle, styles.mqcircle)} onClick={(e) => this.decreaseAmount(e)}><span className={css(styles.minussign)} id="minus"> - </span></div> </Col>
                                                <Col md={6} sm={6} xs={6} className={css(styles.amountTitlefontsRow, styles.mqamountTitlefontsRow)}>
                                                    <span>$</span>
                                                    <input className={css(styles.dollarAmount, styles.amountTitlefonts, styles.mqdollarAmount)} type="number" value={this.state.transactionValue} id="chargeAmount" required min="1" maxLength="6" onChange={(e) => this.updateTransactValue(e)}></input>
                                                    {/* <span>.00</span> */}
                                                </Col>
                                                <Col md={3} sm={3} xs={3} className={css(styles.minusSignContain)}><div className={css(styles.circle, styles.mqcircle)} onClick={(e) => this.increaseAmount(e)}><span className={css(styles.plussign)} id="plus"> + </span></div></Col>
                                            </MediaQuery>
                                        </Row>
                                    </div>)}
                                <Row>
                                    <Col md={2} sm={2} xs={2}></Col>
                                    <Col md={8} sm={8} xs={8} style={{ paddingRight: 0, paddingLeft: 0 }}><hr className={css(styles.menuDivider)}></hr></Col>
                                    <Col md={2} sm={2} xs={2}></Col>
                                </Row>
                                <input id="paymethod" type="hidden"></input>
                                <div id="paypaldiv">
                                    <input id="paypalbtn" type="hidden" onClick={(event) => this.makePaymentCall(event)}></input>
                                    <Row className={css(styles.menuRowPayPal)}>
                                        <Col md={2} sm={2} xs={2} ></Col>
                                        <Col md={8} sm={8} xs={8}>
                                            <div id="paypal-button-container" className={css(styles.paypalButton)}>
                                                <div id="paypal-button">
                                                    <input id="deviceData" type="hidden"></input>
                                                    <input id="paypaltransactionDate" type="hidden"></input>
                                                    <input id="paypalprocessorAuthorizationCode" type="hidden"></input>
                                                    <input id="paypalprocessorResponseText" type="hidden"></input>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={2} sm={2} xs={2}></Col>
                                    </Row>
                                </div>
                                {/* <div id="carddiv">
                                    <Row className={css(styles.menuRowPaymentMethod)}>
                                        <Col>
                                            <script src="https://js.braintreegateway.com/web/dropin/1.22.1/js/dropin.min.js"></script>
                                            <div className={css(styles.dropindiv)}>
                                                <div id="dropin-wrapper">
                                                    <div id="dropin-container"></div>
                                                    <input id="hiddenNonce" type="hidden"></input>
                                                    <input id="deviceData" type="hidden"></input>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className={css(styles.menuRowOptionPayRow)}>
                                        <Col>
                                            <div className={css(styles.dropInBtnContainer)}>
                                                <button className={css(styles.dropinbtn, styles.addcreditButton, styles.mqaddCreditButton)} id="submit-button" onClick={(event) => this.makePaymentCall(event)}>{`Pay $${this.state.transactionValue}`}</button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div> */}
                            </div>
                        )
                )
        );
    }
}


function mapStateToProps(state) {
    return {
        currUser: state.loginReducer.user,
        logintoken: state.loginReducer.loginToken,
        clienttoken: state.paymentReducer.clientToken,
        addingcredits: state.noPersistReducer.addingCredits,
        addingmoreshots: state.minigameReducer.addingMoreShots,
        transactpayment: state.paymentReducer.transactPayment,
        paymenttransactionid: state.paymentReducer.paymentTransactionID,
        paymenttransactionamount: state.paymentReducer.paymentTransactionAmount,
        paymentstatus: state.paymentReducer.paymentStatus
    }
}

export default connect(mapStateToProps, {
    closeAddCredits,
    //getClientToken,
    makePayment,
    addPaypalPayment
}
)(AddCredits);
