/*
MinigameActions.js
These are actions that return information associated with Mini Games
*/

import { gameActionTypes, minigameActionTypes, pending_function, rejected_function, fulfilled_function, serverEnvironment, loginActionTypes, rejected } from '../constants/ActionConstants';
import axios from 'axios';
import swal from 'sweetalert';

export var axiosConfig = {
    headers: { 'token': '', 'refUrl': document.location.host }
};


export function enterGameView(gameId) {
    const { ENTER_GAME_VIEW } = gameActionTypes;
    

    return function (dispatch) {
        dispatch(fulfilled_function(ENTER_GAME_VIEW, parseInt(gameId)));
    }
     
}

export function addMoreShots() {
    const { ADD_MORE_SHOTS } = minigameActionTypes;
    
    return function (dispatch) {
        dispatch(fulfilled_function(ADD_MORE_SHOTS));
    }
     
}

export function closeMoreShots() {
    const { CLOSE_MORE_SHOTS } = minigameActionTypes;
    
    return function (dispatch) {
        dispatch(fulfilled_function(CLOSE_MORE_SHOTS));
    }
     
}

export function getCannonballBalance(token, userid) {
    const { GET_CANNON_BALANCE } = minigameActionTypes; 
    const { LOGOUT } = loginActionTypes; 
    return function (dispatch) {
        dispatch(pending_function(GET_CANNON_BALANCE)); 
        axiosConfig.headers.token = token;

        axios
           .get(serverEnvironment.apiHost + '/user/byId/' + userid, axiosConfig)
           .then((res) => {
                    dispatch(fulfilled_function(GET_CANNON_BALANCE, res.data)); 
           })
           .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT, err)); 
                }
                dispatch(rejected_function(GET_CANNON_BALANCE, err)); 
                swal("Error on getCannonballBalance", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    };

}

export function updateCannonballBalance(token, payload){
    const { UPDATE_CANNON_SHOTS } = minigameActionTypes; 
    const { GET_LEADERBOARD } = gameActionTypes; 
    const { UPDATE_USER } = gameActionTypes; 
    const { LOGOUT } = loginActionTypes; 
    return function (dispatch) {
        dispatch(pending_function(UPDATE_CANNON_SHOTS)); 
        axiosConfig.headers.token = token;
        axios
            .put(serverEnvironment.apiHost + '/game/transact', payload, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(UPDATE_CANNON_SHOTS, res));  
            }) 
            .then(() => {
                axios.get(serverEnvironment.apiHost + '/leaderboard')
                .then((res) => {
                    let leaderboard = res.data; 
                   dispatch(fulfilled_function(GET_LEADERBOARD, leaderboard, axiosConfig)); 
                })
            })
            .then(() => {
                axios.get(serverEnvironment.apiHost + '/user/byId/' + payload.userID, axiosConfig)
                .then((res) => {
                    dispatch(fulfilled_function(UPDATE_USER, res.data)); 
                });
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT, err)); 
                }
                dispatch(rejected_function(UPDATE_CANNON_SHOTS, err)); 
                swal("Error on updateCannonballBalance", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    };
}