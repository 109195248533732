import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import FacebookLogin from 'react-facebook-login';
import GameHistoryModal from '../Modals/GameHistoryModal';
import PaymentHistoryModal from '../Modals/PaymentHistoryModal';
import PayoutHistoryModal from '../Modals/PayoutHistoryModal';
import EditProfileModal from '../Modals/EditProfileModal'
import Modal from 'react-bootstrap/Modal';
import Wallet from '../Wallet/Wallet';
import { connect } from 'react-redux';
import { closeUserMenu, openAddCredits, closeAddCredits, openPayout, closePayout, openWallet, closeWallet, clearAlert, showEditProfile, showGameHistory, showPaymentHistory, showPayoutHistory, showWallet, showReferCode } from '../../actions/MenuActions';
import { facebookLogin, logout } from '../../actions/LoginActions';
import { getGameHistory, markAlertsRead } from '../../actions/GameActions';
import { getPaymentHistory } from '../../actions/PaymentActions';
import { getPayoutHistory } from '../../actions/PayoutActions';
import { css } from 'aphrodite';
import { styles } from './UserMenuStyles.js';
import MediaQuery from 'react-responsive';

class UserMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedin: false,
            showGameHistoryModal: false,
            showPaymentHistoryModal: false,
            showPayoutHistoryModal: false,
            showMyWallet: false,
            showEditProfile: false,
            showAddCredits: false,
            showWithdrawing: false
        }
        /*
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '2526173294131373',
                cookie: true,
                xfbml: true,
                version: 'v6.0'
            });

            window.FB.AppEvents.logPageView();

        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
        */
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onClickLogout = this.onClickLogout.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.onClickShowGameHistory = this.onClickShowGameHistory.bind(this);
        this.onClickShowPaymentHistory = this.onClickShowPaymentHistory.bind(this);
        this.onClickShowPayoutHistory = this.onClickShowPayoutHistory.bind(this);
        this.onClickOpenAddCredits = this.onClickOpenAddCredits.bind(this);
        this.onClickCloseAddCredits = this.onClickCloseAddCredits.bind(this);
        this.onClickOpenPayout = this.onClickOpenPayout.bind(this);
        this.onClickClosePayout = this.onClickClosePayout.bind(this);
        this.onClickCloseWallet = this.onClickCloseWallet.bind(this);
        this.onClickShowWallet = this.onClickShowWallet.bind(this);
        this.editUserProfile = this.editUserProfile.bind(this);
        this.onClickOpenReferCode = this.onClickOpenReferCode.bind(this); 
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    editUserProfile() {
        // this.setState({ showEditProfile: true });
        this.props.showEditProfile();
        this.props.closeUserMenu();
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.closeUserMenu();
        }
    }

    onClickLogout() {
        //    if (this.props.logintoken) {
        this.props.logout(this.props.logintoken);
        //   }
    }

    onClickOpenAddCredits() {
        this.props.openAddCredits();
        this.setState({ showAddCredits: true, showWithdrawing: false, showMyWallet: false, showGameHistoryModal: false, showPaymentHistoryModal: false, showPayoutHistoryModal: false, });

    }
    onClickCloseAddCredits() {
        this.setState({
            showAddCredits: false
        });
        this.props.closeAddCredits();
    }

    onClickOpenReferCode() {
        this.props.showReferCode(); 
    }

    onClickOpenPayout() {
        this.props.openPayout();
        this.setState({ showWithdrawing: true, showAddCredits: false, showMyWallet: false, showGameHistoryModal: false, showPaymentHistoryModal: false, showPayoutHistoryModal: false, });
    }
    onClickClosePayout() {
        this.setState({
            showWithdrawing: false
        });
        this.props.closePayout();
    }

    onClickCloseWallet() {
        this.setState({
            showMyWallet: false
        });
        this.props.closeWallet();
    }

    onClickShowGameHistory() {
        this.props.getGameHistory(this.props.logintoken, this.props.currUser.id);
        // this.setState({ showGameHistoryModal: true, showPaymentHistoryModal: false, showPayoutHistoryModal: false, showMyWallet: false, showWithdrawing: false, showAddCredits: false, });
        this.props.showGameHistory();
        this.props.closeUserMenu();
    }
    onClickShowPaymentHistory() {
        this.props.getPaymentHistory(this.props.logintoken, this.props.currUser.id);
        // this.setState({ showGameHistoryModal: false, showPaymentHistoryModal: true, showPayoutHistoryModal: false, showMyWallet: false, showWithdrawing: false, showAddCredits: false, });
        this.props.showPaymentHistory();
        this.props.closeUserMenu();
    }

    onClickShowPayoutHistory() {
        this.props.getPayoutHistory(this.props.logintoken, this.props.currUser.id);
        // this.setState({ showGameHistoryModal: false, showPaymentHistoryModal: false, showPayoutHistoryModal: true, showMyWallet: false, showWithdrawing: false, showAddCredits: false, });
        this.props.showPayoutHistory();
        this.props.closeUserMenu();
    }
    onClickShowWallet() {
        this.props.openWallet();
        this.props.showWallet();
        this.props.closeUserMenu();
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    onHideModal() {
        this.setState({ showGameHistoryModal: false });
        this.setState({ showPaymentHistoryModal: false });
        this.setState({ showPayoutHistoryModal: false });
        this.setState({ showMyWallet: false });
        this.setState({ showEditProfile: false });
        this.props.markAlertsRead(this.props.logintoken);
        this.setState({ showAddCredits: false });
        this.setState({ showWithdrawing: false });
    }


    render() {
        return (
            <>
                <div className={css(styles.menuContainer, styles.mqmenuContainer)} ref={this.setWrapperRef}>
                    <Row onClick={this.editUserProfile} className={css(styles.menuRow, styles.menuUserRow, styles.menuUserRowUserNameCol)}>
                        {/* FOR THIS FEATURE TO MATCH THE UI - WE NEED TO STORE THE USER'S FIRST AND LAST IN TWO SEPARATE FIELDS */}
                        <MediaQuery minWidth={1001}>
                            <Col md={3} sm={3} xs={3}><img onClick={this.editUserProfile} className={css(styles.menuProfilePhoto)} src={this.props.currUser.userProfileURL} /></Col>
                            <Col md={9} sm={9} xs={9} className={css(styles.menuUserNameCol)}>
                                <div>
                                    <span className={css(styles.menuMobileMyProfile, styles.mqmenuMobileMyProfile)}>My Profile:</span>
                                    <div className={css(styles.menuUserName, styles.mqmenuUserName)}>{(this.props.currUser ? this.props.currUser.name : this.props.staticUser.userName)}</div>
                                </div>
                            </Col>
                        </MediaQuery>
                        <MediaQuery maxWidth={1000}>
                            <Col md={4} sm={4} xs={4}><img onClick={this.editUserProfile} className={css(styles.menuProfilePhoto)} src={this.props.currUser.userProfileURL} /></Col>
                            <Col md={8} sm={8} xs={8} className={css(styles.menuUserNameCol)}>
                                <div>
                                    <span className={css(styles.menuMobileMyProfile,  styles.mqmenuMobileMyProfile)}>My Profile:</span>
                                    <div className={css(styles.menuUserName, styles.mqmenuUserName)}>{(this.props.currUser ? this.props.currUser.name : this.props.staticUser.userName)}</div>
                                </div>
                            </Col>
                        </MediaQuery>
                    </Row>
                    <Row className={css(styles.menuRow, styles.menuRowOption)}>
                        <Col md={2} sm={2} xs={2} className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/addFunds.svg')} /></Col>
                        <Col md={10} sm={10} xs={10} className={css(styles.menuOptionCol)} onClick={this.onClickOpenAddCredits}>Add Credits</Col>
                    </Row>
                    {1 === 0 &&
                        <Row className={css(styles.menuRow, styles.menuRowOption)}>
                            <Col md={2} sm={2} xs={2} className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/videoButton.svg')} /></Col>
                            <Col md={10} sm={10} xs={10} className={css(styles.menuOptionCol)}>Watch Ads to Earn Game Credits</Col>
                        </Row>
                    }

                    <Row className={css(styles.menuRow, styles.menuRowOption)}>
                        <Col md={2} sm={2} xs={2} className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/withdrawFunds.svg')} /></Col>
                        <Col md={10} sm={10} xs={10} className={css(styles.menuOptionCol)} onClick={this.onClickOpenPayout}>Withdraw Funds</Col>
                    </Row>
                    <Row className={css(styles.menuRow, styles.menuRowOption)}>
                        <Col md={2} sm={2} xs={2} className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/myWallet.svg')} /></Col>
                        <Col md={10} sm={10} xs={10} className={css(styles.menuOptionCol)} onClick={this.onClickShowWallet}>My Wallet</Col>
                    </Row>
                    <hr className={css(styles.menuDivider)}></hr>
                    <Row className={css(styles.menuRow, styles.menuRowOption)} onClick={this.onClickShowPaymentHistory}>
                        <Col md={2} sm={2} xs={2} className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/paymentHistory.svg')} /></Col>
                        <Col md={10} sm={10} xs={10} className={css(styles.menuOptionCol)}>Payment History</Col>
                    </Row>
                    <Row className={css(styles.menuRow, styles.menuRowOption)} onClick={this.onClickShowPayoutHistory}>
                        <Col md={2} sm={2} xs={2} className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/withdrawalHistory.svg')} /></Col>
                        <Col md={10} sm={10} xs={10} className={css(styles.menuOptionCol)}>Withdrawal History</Col>
                    </Row>
                    <Row className={css(styles.menuRow, styles.menuRowOption)} onClick={this.onClickShowGameHistory}>
                        <Col md={2} sm={2} xs={2} className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/gameHistory.svg')} /></Col>
                        <Col md={10} sm={10} xs={10} className={css(styles.menuOptionCol)}>Game History</Col>
                    </Row>
                    <hr className={css(styles.menuDivider)}></hr>
                    <a href="mailto:kyle@plndrmobile.com?subject=Help Requested" target="_blank" className={css(styles.supportMenuLink)}>
                        <Row className={css(styles.menuRow, styles.menuRowOption)}>
                            <Col md={2} sm={2} xs={2} className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/support.svg')} /></Col>
                            <Col md={10} sm={10} xs={10} className={css(styles.menuOptionCol)}>Support</Col>
                        </Row>
                    </a>
                    {/*
                <Row className={css(styles.menuRow, styles.menuRowOption)}>
                    <Col md={2} className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/moreOptions.svg')} /></Col>
                    <Col md={10} className={css(styles.menuOptionCol)}>More Options</Col>
                </Row>
                    */}
                <Row className={css(styles.menuRow, styles.menuRowOption)} onClick={this.onClickOpenReferCode}>
                    <Col md={2} sm={2} xs={2}  className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/share.svg')} /></Col>
                    <Col md={10} sm={10} xs={10} className={css(styles.menuOptionCol)}>Refer a Friend</Col>
                </Row>
    
                    <Row className={css(styles.menuRow, styles.menuRowOption)} onClick={this.onClickLogout}>
                        <Col md={2} sm={2} xs={2} className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/logOut.svg')} /></Col>
                        <Col md={10} sm={10} xs={10} className={css(styles.menuOptionCol)}>Log Out</Col>
                    </Row>
                    <MediaQuery minWidth={850}>
                        <Row className={css(styles.menuRowSocials)}>
                            <Col className={css(styles.menuSocialBox)}>
                                <Row>
                                    <Col md={4} sm={4} xs={4}>
                                        <a href={'https://www.facebook.com/Plndr-win-for-real-102060521340653'} target="_blank"><img className={css(styles.menuSocialIcon)} src={require('../../images/socialFacebook.svg')} /></a>
                                    </Col>
                                    <Col md={4} sm={4} xs={4}>
                                        <a href={'https://www.instagram.com/plndr_app?igsh=MXhibWR1bHI1ZG5nMw%3D&ut-m_source=qr'} target="_blank"><img className={css(styles.menuSocialIcon)} src={require('../../images/socialInstagram.svg')} /></a>
                                    </Col>
                                    <Col md={4} sm={4} xs={4}>
                                        <a href={'https://twitter.com'} target="_blank"><img className={css(styles.menuSocialIcon)} src={require('../../images/socialTwitter.svg')} /></a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col></Col>
                        </Row>
                    </MediaQuery>
                    <MediaQuery minWidth={451} maxWidth={849}>
                        <Row className={css(styles.menuRowSocials)}>
                            <Col md={8} sm={8} xs={8} className={css(styles.menuSocialBox)}>
                                <Row>
                                    <Col md={4} sm={4} xs={4}>
                                        <a href={'https://www.facebook.com/Plndr-win-for-real-102060521340653'} target="_blank"><img className={css(styles.menuSocialIcon)} src={require('../../images/socialFacebook.svg')} /></a>
                                    </Col>
                                    <Col md={4} sm={4} xs={4}>
                                        <a href={'https://instagram.com'} target="_blank"><img className={css(styles.menuSocialIcon)} src={require('../../images/socialInstagram.svg')} /></a>
                                    </Col>
                                    <Col md={4} sm={4} xs={4}>
                                        <a href={'https://twitter.com'} target="_blank"><img className={css(styles.menuSocialIcon)} src={require('../../images/socialTwitter.svg')} /></a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} sm={4} xs={4}></Col>
                        </Row>
                    </MediaQuery>
                    <MediaQuery maxWidth={450}>
                        <Row className={css(styles.menuRowSocials)}>
                            <Col md={10} sm={10} xs={10} className={css(styles.menuSocialBox)}>
                                <Row>
                                    <Col md={4} sm={4} xs={4}>
                                        <a href={'https://www.facebook.com/Plndr-win-for-real-102060521340653'} target="_blank"><img className={css(styles.menuSocialIcon)} src={require('../../images/socialFacebook.svg')} /></a>
                                    </Col>
                                    <Col md={4} sm={4} xs={4}>
                                        <a href={'https://instagram.com'} target="_blank"><img className={css(styles.menuSocialIcon)} src={require('../../images/socialInstagram.svg')} /></a>
                                    </Col>
                                    <Col md={4} sm={4} xs={4}>
                                        <a href={'https://twitter.com'} target="_blank"><img className={css(styles.menuSocialIcon)} src={require('../../images/socialTwitter.svg')} /></a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2} sm={2} xs={2}></Col>
                        </Row>
                    </MediaQuery>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        isloggedin: state.loginReducer.isLoggedIn,
        logintoken: state.loginReducer.loginToken,
        currUser: state.loginReducer.user,
        clienttoken: state.paymentReducer.clientToken,
        addingcredits: state.noPersistReducer.addingCredits,
        dowithdrawing: state.noPersistReducer.doWithdrawing,
        mywallet: state.noPersistReducer.myWallet,
    }
}

export default connect(mapStateToProps, {
    closeUserMenu,
    facebookLogin,
    logout,
    getGameHistory,
    getPaymentHistory,
    getPayoutHistory,
    openAddCredits,
    closeAddCredits,
    openPayout,
    closePayout,
    openWallet,
    closeWallet,
    clearAlert,
    markAlertsRead,
    showEditProfile,
    showGameHistory,
    showPaymentHistory,
    showPayoutHistory,
    showWallet, 
    showReferCode
})(UserMenu);