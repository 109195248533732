import { menuActionTypes, pending_function, rejected_function, fulfilled_function } from '../constants/ActionConstants';
import axios from 'axios'; 


export function openUserMenu() {
    const { OPEN_USER_MENU } = menuActionTypes; 

    return function (dispatch) {
        dispatch(fulfilled_function(OPEN_USER_MENU)); 
    }
}

export function closeUserMenu() {
    const { CLOSE_USER_MENU } = menuActionTypes; 

    return function(dispatch) {
        dispatch(fulfilled_function(CLOSE_USER_MENU)); 
    }
}

export function gameEntryClose() {
    const { GAME_ENTRY_CLOSE } = menuActionTypes; 
    
    return function (dispatch) {
        dispatch(fulfilled_function(GAME_ENTRY_CLOSE)); 
    }
}

export function gameEntryMenu() { 
    const { GAME_ENTRY_MENU } = menuActionTypes; 

    return function (dispatch) {
        dispatch(fulfilled_function(GAME_ENTRY_MENU)); 
    }
}

export function playAdContent() {
    const { PLAY_AD_CONTENT } = menuActionTypes;

    return function(dispatch) {
        dispatch(fulfilled_function(PLAY_AD_CONTENT)); 
    }
}

export function playAllContent() {
    const { PLAY_ALL_CONTENT } = menuActionTypes; 

    return function(dispatch) {
        dispatch(fulfilled_function(PLAY_ALL_CONTENT)); 
    }
}

export function stopAdContent() {
    const { STOP_AD_CONTENT } = menuActionTypes;

    return function(dispatch) {
        dispatch(fulfilled_function(STOP_AD_CONTENT));
    }
}

export function stopAllContent() {
    const { STOP_ALL_CONTENT } = menuActionTypes;

    return function(dispatch) {
        dispatch(fulfilled_function(STOP_ALL_CONTENT)); 
    }
}
export function openAddCredits() {
    const { OPEN_ADD_CREDITS } = menuActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(OPEN_ADD_CREDITS));
    }
}

export function closeAddCredits() {
    const { CLOSE_ADD_CREDITS } = menuActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(CLOSE_ADD_CREDITS));
    }
}
export function openPayout() {
    const { OPEN_PAYOUT } = menuActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(OPEN_PAYOUT));
    }
}

export function closePayout() {
    const { CLOSE_PAYOUT } = menuActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(CLOSE_PAYOUT));
    }
}

export function openWallet() {
    const { OPEN_WALLET } = menuActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(OPEN_WALLET));
    }
}

export function closeWallet() {
    const { CLOSE_WALLET } = menuActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(CLOSE_WALLET));
    }
}

export function gotAlert(count) {
    const { GOT_ALERT } = menuActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(GOT_ALERT, count+1));
    }
}

export function clearAlert() {
    const { CLEAR_ALERT } = menuActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(CLEAR_ALERT));
    }
}

export function showEditProfile() {
    const { SHOW_EDIT_PROFILE } = menuActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(SHOW_EDIT_PROFILE)); 
    }
}

export function showGameHistory() {
    const { SHOW_GAME_HISTORY } = menuActionTypes; 

    return function (dispatch) {
        dispatch(fulfilled_function(SHOW_GAME_HISTORY)); 
    }
}

export function showPaymentHistory() {
    const { SHOW_PAYMENT_HISTORY } = menuActionTypes; 

    return function (dispatch) {
        dispatch(fulfilled_function(SHOW_PAYMENT_HISTORY)); 
    }
}

export function showPayoutHistory() {
    const { SHOW_PAYOUT_HISTORY } = menuActionTypes; 

    return function (dispatch) {
        dispatch(fulfilled_function(SHOW_PAYOUT_HISTORY)); 
    }
}

export function showWallet() {
    const { SHOW_WALLET } = menuActionTypes; 

    return function (dispatch) {
        dispatch(fulfilled_function(SHOW_WALLET)); 
    }
}

export function closeMenuModals() {
    const { CLOSE_MENU_MODALS } = menuActionTypes; 

    return function (dispatch) {
        dispatch(fulfilled_function(CLOSE_MENU_MODALS)); 
    }
}

export function showSelectAvatar() {
    const { SHOW_SELECT_AVATAR } = menuActionTypes; 
    
    return function (dispatch) {
        dispatch(fulfilled_function(SHOW_SELECT_AVATAR));
    }
}

export function closeSelectAvatar() {
    const { CLOSE_SELECT_AVATAR } = menuActionTypes; 

    return function (dispatch) {
        dispatch(fulfilled_function(CLOSE_SELECT_AVATAR));
    }
}

export function showCropImage() {
    const { SHOW_CROP_IMAGE } = menuActionTypes; 

    return function (dispatch) {
        dispatch(fulfilled_function(SHOW_CROP_IMAGE)); 
    }
}

export function closeCropImage() {
    const { CLOSE_CROP_IMAGE } = menuActionTypes; 

    return function (dispatch) {
        dispatch(fulfilled_function(CLOSE_CROP_IMAGE)); 
    }
}

export function openSideMenus() {
    const { OPEN_SIDE_MENUS } = menuActionTypes; 

    return function (dispatch) {
        dispatch(fulfilled_function(OPEN_SIDE_MENUS)); 
    }
}

export function closeSideMenus() {
    const { CLOSE_SIDE_MENUS } = menuActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(CLOSE_SIDE_MENUS));
    }
}

export function showReferCode() {
    const { SHOW_REFER_CODE } = menuActionTypes; 

    return function (dispatch) {
        dispatch(fulfilled_function(SHOW_REFER_CODE)); 
    }
}

export function closeReferCode() {
    const { CLOSE_REFER_CODE } = menuActionTypes; 

    return function (dispatch) {
        dispatch(fulfilled_function(CLOSE_REFER_CODE)); 
    }
}
