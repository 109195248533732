import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { styles } from './ModalStyles.js';
import { css } from 'aphrodite';
import { closeReferCode } from '../../actions/MenuActions';
import MediaQuery from "react-responsive"; 


class ReferCode extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef(); 
        this.state = {
            codeCopied: false
        };
        this.copyText = this.copyText.bind(this); 
    }

    copyText() {
        let inputElement = this.inputRef.current; 
        inputElement.select(); 
        document.execCommand('copy'); 
        this.setState({codeCopied: true}); 
    }

    render() {

        return (
            <>
                <Col className={css(styles.avatarSelectionTitle)}>Sign Up a Friend and Receive a Free Entry!</Col>
                <br />
                <Row>
                    <div className={css(styles.referCodeLinkText)}>
                        <span>Share this link: </span><br />
                        <input className={css(styles.referCodeInput)} type="text" id="referralLink" name="referralLink" value={window.location.origin + '/signup/' + this.props.currUser.referralCode} readOnly ref={this.inputRef} ></input>
                        &nbsp;
                        <MediaQuery maxWidth={390}>
                            <br/>
                            <br/>
                        </MediaQuery>
                        <button className={css(styles.referCodeCopyBtn)} onClick={this.copyText}>{this.state.codeCopied ? 'Copied!' : 'Copy'}</button>
                    </div>
                </Row>
                <br /><br />
                {/* <Row>
                <hr className={css(styles.referCodeDivider)} /><span style={{ fontFamily: 'SF Pro Text Semibold' }}> Or </span> <hr className={css(styles.referCodeDivider)} />
                </Row>
                <Row>
                    <div className={css(styles.referCodeLinkText)}>
                        <span>Email Link to Friend: </span><br />
                        <input type="text" id="referralLink" name="referralLink" value={this.props.currUser.referralCode}></input>
                        <button>Copy</button>
                    </div>
                </Row> */}
                <Row className={css(styles.avatarButtonsRow)}>
                    <Col>
                        <Button onClick={this.props.closeReferCode} className={css(styles.profileModalButton)}>CLOSE</Button>
                    </Col>
                </Row>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        currUser: state.loginReducer.user
    }
}

export default connect(mapStateToProps, {
    closeReferCode,
})(ReferCode);