//THIS IS A BLACKLISTED REDUCER - i.e. The values contained within will not be persisted. 
//Keep this in mind if you want any values to be persisted

import { interestsActionTypes, menuActionTypes, gameActionTypes, loginActionTypes, payoutActionTypes, walletActionTypes, pending, rejected, fulfilled } from '../constants/ActionConstants';

export default function reducer(state = {
    //Is the userMenu open? 
    userMenu: false,
    //Should the specific ad/content play?
    playAdContent: false,
    //Global Flag for ad/content playing (used to control DOM Rendering) 
    contentPlaying: false,
    //Dicatates how many rows of interests are displayed to the user
    toDoRowRender: 0,
    generalRowRender: 0,
    gameEntryMenu: false,
    transactSuccess: false,
    addingCredits: false,
    doWithdrawing: false,
    myWallet: false,
    alertCount: 0,
    gameHistoryModal: false,
    paymentHistoryModal: false,
    payoutHistoryModal: false,
    editProfile: false,
    selectAvatar: false, 
    cropImage: false, 
    tutProcess: false, 
    sideMenu: false, 
    referCode: false
}, action) {
    switch (action.type) {

        case fulfilled(gameActionTypes.CLEAR_CURRENT_GAME):
            return {
                ...state,
                transactSuccess: false
            }
        case fulfilled(gameActionTypes.TRANSACT_VALUE):
            return {
                ...state,
                transactSuccess: true
            }
        case fulfilled(interestsActionTypes.SHOW_MORE_INTERESTS_TODO):
            return {
                ...state,
                toDoRowRender: state.toDoRowRender + 1
            }
        case fulfilled(interestsActionTypes.SHOW_MORE_INTERESTS_GENERAL):
            return {
                ...state,
                generalRowRender: state.generalRowRender + 1
            }
        case fulfilled(interestsActionTypes.HIDE_INTERESTS_TODO):
            return {
                ...state,
                toDoRowRender: 0
            }
        case fulfilled(interestsActionTypes.HIDE_INTERESTS_GENERAL):
            return {
                ...state,
                generalRowRender: 0
            }
        case fulfilled(menuActionTypes.OPEN_USER_MENU):
            return {
                ...state,
                userMenu: true
            }
        case fulfilled(menuActionTypes.CLOSE_USER_MENU):
            return {
                ...state,
                userMenu: false
            }
        case fulfilled(menuActionTypes.PLAY_AD_CONTENT):
            return {
                ...state,
                playAdContent: true,
                contentPlaying: true
            }
        case fulfilled(menuActionTypes.STOP_AD_CONTENT):
            return {
                ...state,
                playAdContent: false,
                contentPlaying: false
            }
        case fulfilled(menuActionTypes.STOP_ALL_CONTENT):
            return {
                ...state,
                contentPlaying: false
            }
        case fulfilled(menuActionTypes.PLAY_ALL_CONTENT):
            return {
                ...state,
                contentPlaying: true
            }
        case fulfilled(menuActionTypes.GAME_ENTRY_MENU):
            return {
                ...state,
                gameEntryMenu: true
            }
        case fulfilled(menuActionTypes.GAME_ENTRY_CLOSE):
            return {
                ...state,
                gameEntryMenu: false
            }
        case fulfilled(menuActionTypes.OPEN_ADD_CREDITS):
            return {
                ...state,
                addingCredits: true
            }
        case fulfilled(menuActionTypes.CLOSE_ADD_CREDITS):
            return {
                ...state,
                addingCredits: false
            }
        case fulfilled(menuActionTypes.OPEN_PAYOUT):
            return {
                ...state,
                doWithdrawing: true
            }
        case fulfilled(menuActionTypes.CLOSE_PAYOUT):
            return {
                ...state,
                doWithdrawing: false
            }
        case fulfilled(menuActionTypes.OPEN_WALLET):
            return {
                ...state,
                myWallet: true
            }
        case fulfilled(menuActionTypes.CLOSE_WALLET):
            return {
                ...state,
                myWallet: false
            }
        case fulfilled(menuActionTypes.GOT_ALERT):
            var ac = action.payload;
            return {
                ...state,
                alertCount: ac
            }
        case fulfilled(menuActionTypes.CLEAR_ALERT):
            return {
                ...state,
                alertCount: 0
            }
        case fulfilled(menuActionTypes.SHOW_EDIT_PROFILE):
            return {
                ...state,
                editProfile: true,
                gameHistoryModal: false,
                paymentHistoryModal: false,
                payoutHistoryModal: false,
                myWallet: false,
            }
        case fulfilled(menuActionTypes.SHOW_GAME_HISTORY):
            return {
                ...state,
                gameHistoryModal: true,
                editProfile: false,
                paymentHistoryModal: false,
                payoutHistoryModal: false,
                myWallet: false,
            }
        case fulfilled(menuActionTypes.SHOW_PAYMENT_HISTORY):
            return {
                ...state,
                paymentHistoryModal: true,
                payoutHistoryModal: false,
                gameHistoryModal: false,
                editProfile: false,
                myWallet: false,
            }
        case fulfilled(menuActionTypes.SHOW_PAYOUT_HISTORY):
            return {
                ...state,
                payoutHistoryModal: true,
                paymentHistoryModal: false,
                gameHistoryModal: false,
                editProfile: false,
                myWallet: false,
            }
        case fulfilled(menuActionTypes.SHOW_WALLET):
            return {
                ...state,
                myWallet: true,
                payoutHistoryModal: false,
                paymentHistoryModal: false,
                gameHistoryModal: false,
                editProfile: false
            }
        case fulfilled(menuActionTypes.CLOSE_MENU_MODALS):
            return {
                ...state,
                gameHistoryModal: false,
                paymentHistoryModal: false,
                payoutHistoryModal: false,
                myWallet: false,
                editProfile: false, 
                referCode: false
            }
        case fulfilled(menuActionTypes.SHOW_SELECT_AVATAR):
            return {
                ...state,
                selectAvatar: true
            }
        case fulfilled(menuActionTypes.CLOSE_SELECT_AVATAR):
            return {
                ...state,
                selectAvatar: false
            }
        case fulfilled(menuActionTypes.SHOW_CROP_IMAGE): 
            return { 
                ...state, 
                cropImage: true
            }
        case fulfilled(menuActionTypes.CLOSE_CROP_IMAGE): 
            return {
                ...state, 
                cropImage: false
            }
        case fulfilled(loginActionTypes.CLEAR_ERR):
            return {
                ...state, 
                tutProcess: true
            }
        case fulfilled(menuActionTypes.OPEN_SIDE_MENUS):
            return {
                ...state, 
                sideMenu: true
            }
        case fulfilled(menuActionTypes.CLOSE_SIDE_MENUS):
            return {
                ...state,
                sideMenu: false
            }
        case fulfilled(menuActionTypes.SHOW_REFER_CODE): 
            return {
                ...state, 
                referCode: true
            }
        case fulfilled(menuActionTypes.CLOSE_REFER_CODE):
            return {
                ...state, 
                referCode: false
            }
        default:
            return state;
    }
};