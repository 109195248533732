import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import FacebookLogin from 'react-facebook-login';
import GameHistoryModal from '../Modals/GameHistoryModal';
import PaymentHistoryModal from '../Modals/PaymentHistoryModal';
import PayoutHistoryModal from '../Modals/PayoutHistoryModal';
import EditProfileModal from '../Modals/EditProfileModal'
import Modal from 'react-bootstrap/Modal';
import Wallet from '../Wallet/Wallet';
import { connect } from 'react-redux';
import { closeUserMenu, openAddCredits, closeAddCredits, openPayout, closePayout, openWallet, closeWallet, clearAlert, showEditProfile, showGameHistory, showPaymentHistory, showPayoutHistory, showWallet, closeSideMenus } from '../../actions/MenuActions';
import { facebookLogin, logout } from '../../actions/LoginActions';
import { getGameHistory, markAlertsRead } from '../../actions/GameActions';
import { getPaymentHistory } from '../../actions/PaymentActions';
import { getPayoutHistory } from '../../actions/PayoutActions';
import { css } from 'aphrodite';
import { styles } from './UserMenuStyles.js';
import { ArrowBarLeft } from 'react-bootstrap-icons';
import MediaQuery from 'react-responsive';

class SubMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedin: false,
            showGameHistoryModal: false,
            showPaymentHistoryModal: false,
            showPayoutHistoryModal: false,
            showMyWallet: false,
            showEditProfile: false,
            showAddCredits: false,
            showWithdrawing: false
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onClickLogout = this.onClickLogout.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.onClickShowGameHistory = this.onClickShowGameHistory.bind(this);
        this.onClickShowPaymentHistory = this.onClickShowPaymentHistory.bind(this);
        this.onClickShowPayoutHistory = this.onClickShowPayoutHistory.bind(this);
        this.onClickOpenAddCredits = this.onClickOpenAddCredits.bind(this);
        this.onClickCloseAddCredits = this.onClickCloseAddCredits.bind(this);
        this.onClickOpenPayout = this.onClickOpenPayout.bind(this);
        this.onClickClosePayout = this.onClickClosePayout.bind(this);
        this.onClickCloseWallet = this.onClickCloseWallet.bind(this);
        this.onClickShowWallet = this.onClickShowWallet.bind(this);
        this.editUserProfile = this.editUserProfile.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    editUserProfile() {
        // this.setState({ showEditProfile: true });
        this.props.showEditProfile();
        this.props.closeUserMenu();
        this.props.closeSideMenus();
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.closeSideMenus();
        }
    }

    onClickLogout() {
        //    if (this.props.logintoken) {
        this.props.logout(this.props.logintoken);
        //   }
    }

    onClickOpenAddCredits() {
        this.props.openAddCredits();
        this.setState({ showAddCredits: true, showWithdrawing: false, showMyWallet: false, showGameHistoryModal: false, showPaymentHistoryModal: false, showPayoutHistoryModal: false, });

    }
    onClickCloseAddCredits() {
        this.setState({
            showAddCredits: false
        });
        this.props.closeAddCredits();
    }

    onClickOpenPayout() {
        this.props.openPayout();
        this.setState({ showWithdrawing: true, showAddCredits: false, showMyWallet: false, showGameHistoryModal: false, showPaymentHistoryModal: false, showPayoutHistoryModal: false, });
    }
    onClickClosePayout() {
        this.setState({
            showWithdrawing: false
        });
        this.props.closePayout();
    }

    onClickCloseWallet() {
        this.setState({
            showMyWallet: false
        });
        this.props.closeWallet();
    }

    onClickShowGameHistory() {
        this.props.getGameHistory(this.props.logintoken, this.props.currUser.id);
        // this.setState({ showGameHistoryModal: true, showPaymentHistoryModal: false, showPayoutHistoryModal: false, showMyWallet: false, showWithdrawing: false, showAddCredits: false, });
        this.props.showGameHistory();
        this.props.closeUserMenu();
        this.props.closeSideMenus();
    }
    onClickShowPaymentHistory() {
        this.props.getPaymentHistory(this.props.logintoken, this.props.currUser.id);
        // this.setState({ showGameHistoryModal: false, showPaymentHistoryModal: true, showPayoutHistoryModal: false, showMyWallet: false, showWithdrawing: false, showAddCredits: false, });
        this.props.showPaymentHistory();
        this.props.closeUserMenu();
        this.props.closeSideMenus();
    }

    onClickShowPayoutHistory() {
        this.props.getPayoutHistory(this.props.logintoken, this.props.currUser.id);
        // this.setState({ showGameHistoryModal: false, showPaymentHistoryModal: false, showPayoutHistoryModal: true, showMyWallet: false, showWithdrawing: false, showAddCredits: false, });
        this.props.showPayoutHistory();
        this.props.closeUserMenu();
        this.props.closeSideMenus();
    }
    onClickShowWallet() {
        this.props.openWallet();
        this.props.showWallet();
        this.props.closeUserMenu();
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    onHideModal() {
        this.setState({ showGameHistoryModal: false });
        this.setState({ showPaymentHistoryModal: false });
        this.setState({ showPayoutHistoryModal: false });
        this.setState({ showMyWallet: false });
        this.setState({ showEditProfile: false });
        this.props.markAlertsRead(this.props.logintoken);
        this.setState({ showAddCredits: false });
        this.setState({ showWithdrawing: false });
    }


    render() {
        return (
            <>
                <div ref={this.setWrapperRef}>
                    <MediaQuery maxWidth={1019}>
                        <ArrowBarLeft className={css(styles.closeSideMenuButton)} color={"#ffc400"} size={49} onClick={this.props.closeSideMenus} />
                    </MediaQuery>
                    <MediaQuery minWidth={1020}>
                        <Row className={css(styles.menuRow, styles.menuRowOption)}>
                        </Row>
                    </MediaQuery>

                    <Row className={css(styles.menuRow, styles.menuRowOption)}>
                    </Row>
                    <Row onClick={this.props.selection !== "profile" ? this.editUserProfile : undefined} className={css(styles.menuRow, styles.menuRowOption, styles.noSelect, this.props.selection === "profile" && styles.menuRowSubSelect)}>
                        {/* FOR THIS FEATURE TO MATCH THE UI - WE NEED TO STORE THE USER'S FIRST AND LAST IN TWO SEPARATE FIELDS */}
                        <Col md={2} sm={2} xs={2} className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/profileIcon.svg')} /></Col>
                        <Col md={10} sm={10} xs={10} className={css(styles.menuOptionCol)}>Personal Information</Col>
                    </Row>
                    <hr className={css(styles.menuSubDivider)}></hr>
                    <Row onClick={this.onClickShowWallet} className={css(styles.menuRow, styles.menuRowOption, styles.noSelect)}>
                        <Col md={2} sm={2} xs={2} className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/myWallet.svg')} /></Col>
                        <Col md={10} sm={10} xs={10} className={css(styles.menuOptionCol)}>My Wallet</Col>
                    </Row>

                    <Row className={css(styles.menuRow, styles.menuRowOption, styles.noSelect, this.props.selection === "paymenthistory" && styles.menuRowSubSelect)} onClick={this.props.selection !== "paymenthistory" && this.onClickShowPaymentHistory}>
                        <Col md={2} sm={2} xs={2} className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/paymentHistory.svg')} /></Col>
                        <Col md={10} sm={10} xs={10} className={css(styles.menuOptionCol)}>Payment History</Col>
                    </Row>
                    <Row className={css(styles.menuRow, styles.menuRowOption, styles.noSelect, this.props.selection === "payouthistory" && styles.menuRowSubSelect)} onClick={this.props.selection !== "payouthistory" && this.onClickShowPayoutHistory}>
                        <Col md={2} sm={2} xs={2} className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/withdrawalHistory.svg')} /></Col>
                        <Col md={10} sm={10} xs={10} className={css(styles.menuOptionCol)}>Withdrawal History</Col>
                    </Row>
                    <Row className={css(styles.menuRow, styles.menuRowOption, styles.noSelect, this.props.selection === "gamehistory" && styles.menuRowSubSelect)} onClick={this.props.selection !== "gamehistory" && this.onClickShowGameHistory}>
                        <Col md={2} sm={2} xs={2} className={css(styles.menuIconCol)}><img className={css(styles.addFundsIcon)} src={require('../../images/gameHistory.svg')} /></Col>
                        <Col md={10} sm={10} xs={10} className={css(styles.menuOptionCol)}>Game History</Col>
                    </Row>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        isloggedin: state.loginReducer.isLoggedIn,
        logintoken: state.loginReducer.loginToken,
        currUser: state.loginReducer.user,
        clienttoken: state.paymentReducer.clientToken,
        addingcredits: state.noPersistReducer.addingCredits,
        dowithdrawing: state.noPersistReducer.doWithdrawing,
        mywallet: state.noPersistReducer.myWallet,
    }
}

export default connect(mapStateToProps, {
    closeUserMenu,
    facebookLogin,
    logout,
    getGameHistory,
    getPaymentHistory,
    getPayoutHistory,
    openAddCredits,
    closeAddCredits,
    openPayout,
    closePayout,
    openWallet,
    closeWallet,
    clearAlert,
    markAlertsRead,
    showEditProfile,
    showGameHistory,
    showPaymentHistory,
    showPayoutHistory,
    showWallet,
    closeSideMenus
})(SubMenu);