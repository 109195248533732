import React from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    Button,
    Col
} from "react-bootstrap";

import { css as cssAphro } from 'aphrodite';
import { styles } from './GameStyles.js';
import styled, { css, keyframes } from 'styled-components';
import MediaQuery from 'react-responsive';

export default function Game(props) {
    const dash = keyframes`
        from {
            stroke-dashoffset: 792
        }
        to {
            stroke-dashoffset: 792 - (792 * (${props.game.fillStatusUsers}/${props.game.maxUsers}));
        }
    `
    const dashAnimation =
        css`
            ${dash} 1.5s linear forwards;
        `

    const Circle = styled.circle`
        width: 100%;
        height: 100%; 
        fill: none; 
        stroke-width: 15; 
        transform: translate(3.5%, 3.5%);
        stroke-dasharray: 792;
        stroke-dashoffset: calc(792 - (792 * (${props.game.fillStatusUsers}/${props.game.maxUsers})));
        animation: ${dashAnimation};
    `
    const dashalt1 = keyframes`
        from {
            stroke-dashoffset: 675
        }
        to {
            stroke-dashoffset: 675 - (675 * (${props.game.fillStatusUsers}/${props.game.maxUsers}));
        }
    `
    const dashAnimationalt1 =
        css`
            ${dashalt1} 1.5s linear forwards;
        `

    const Circlealt1 = styled.circle`
        width: 100%;
        height: 100%; 
        fill: none; 
        stroke-width: 15; 
        transform: translate(3.5%, 3.5%);
        stroke-dasharray: 675;
        stroke-dashoffset: calc(675 - (675 * (${props.game.fillStatusUsers}/${props.game.maxUsers})));
        animation: ${dashAnimationalt1};
    `

    const dashalt2 = keyframes`
        from {
            stroke-dashoffset: 585
        }
        to {
            stroke-dashoffset: 585 - (585 * (${props.game.fillStatusUsers}/${props.game.maxUsers}));
        }
    `
    const dashAnimationalt2 =
        css`
            ${dashalt2} 1.5s linear forwards;
        `

    const Circlealt2 = styled.circle`
        width: 100%;
        height: 100%; 
        fill: none; 
        stroke-width: 15; 
        transform: translate(3.5%, 3.5%);
        stroke-dasharray: 585;
        stroke-dashoffset: calc(585 - (585 * (${props.game.fillStatusUsers}/${props.game.maxUsers})));
        animation: ${dashAnimationalt2};
    `
    const dashalt3 = keyframes`
    from {
        stroke-dashoffset: 515
    }
    to {
        stroke-dashoffset: 515 - (515 * (${props.game.fillStatusUsers}/${props.game.maxUsers}));
    }
`
const dashAnimationalt3 =
    css`
        ${dashalt3} 1.5s linear forwards;
    `

const Circlealt3 = styled.circle`
    width: 100%;
    height: 100%; 
    fill: none; 
    stroke-width: 15; 
    transform: translate(3.5%, 3.5%);
    stroke-dasharray: 515;
    stroke-dashoffset: calc(515 - (515 * (${props.game.fillStatusUsers}/${props.game.maxUsers})));
    animation: ${dashAnimationalt3};
`

    var slot = 0;
    if (props.game.userEntered) {
        for (var i = 0; i < props.game.players.length; i++) {
            if (props.userId) {
                if (props.game.players[i].player.id === props.userId) {
                    slot = props.game.players[i].slot;
                    break;
                }
            }
            else {
                slot = props.game.players[i].slot;
            }

        }
    }

    return (
        <>
            {props.tutorial ?
                <Card className={cssAphro(styles.gameCard, styles.noSelect)}>
                    {/* <img className={cssAphro(styles.gameFire)} src={require('../../images/gameFire.svg')} /> */}
                    <div className={cssAphro(styles.gameBodyWrapperTut, styles.mqgameBodyWrapperTut)}>
                        <img className={cssAphro(styles.gameFire, styles.mqgameFire)} src={require('../../images/gameFire.svg')} alt='' />
                        <img className={cssAphro(styles.gameBodyBase, styles.mqgameBodyBase)} src={require('../../images/coinBase.svg')} alt='' />
                        <Card.Body className={cssAphro(styles.gameBodyshadow)}>
                            <img className={cssAphro(styles.gameHashMarks, styles.gameHashMarksTut, styles.mqgameHashMarksTut)} src={require('../../images/coinHashMarks.svg')} onClick={() => props.tutGame()} alt='' />
                            <div className={cssAphro(styles.prizeTextContainer, styles.mqprizeTextContainer)}>
                                <div className={cssAphro(styles.prizeValue, styles.mqprizeValue)}>{`$${props.game.prizeValue}.00`}</div>
                                <div className={cssAphro(styles.prizeText, styles.mqprizeText)}>PRIZE</div>
                                {//PUT CANNONBALL COUNT COMPONENT HERE
                                }
                            </div>
                            <svg className={cssAphro(styles.progressBorderSVG, styles.mqprogressBorderSVG)}>
                                <defs>
                                    <linearGradient id="linearGold" x1="0%" y1="0%" x2="0%" y2="100%">
                                        <stop offset="0%" stopColor={"#FFE500"} />
                                        <stop offset="100%" stopColor={"#FAC51C"} />
                                    </linearGradient>
                                    <linearGradient id="linearGreen" x1="0%" y1="0%" x2="0%" y2="100%">
                                        <stop offset="0%" stopColor={"#2fbe60"} />
                                        <stop offset="100%" stopColor={"#31C163"} />
                                    </linearGradient>
                                    <linearGradient id="linearWhite" x1="0%" y1="0%" x2="0%" y2="100%">
                                        <stop offset="0%" stopColor={"#008040"} />
                                        <stop offset="100%" stopColor={"#008040"} />
                                    </linearGradient>
                                    <linearGradient id="linearGray" x1="0%" y1="0%" x2="0%" y2="100%">
                                        <stop offset="0%" stopColor={"#e6e600"} />
                                        <stop offset="100%" stopColor={"#e6e600"} />
                                    </linearGradient>
                                </defs>
                                {!props.game.filled ?
                                    <>
                                        <MediaQuery minWidth={992}>
                                            <Circle stroke={props.game.userEntered ? "url(#linearGreen)" : "url(#linearGold)"} cx="128" cy="130" r="128"></Circle>
                                        </MediaQuery>
                                        <MediaQuery minWidth={476} maxWidth={991}>
                                            <Circlealt1 stroke={props.game.userEntered ? "url(#linearGreen)" : "url(#linearGold)"} cx="128" cy="130" r="107"></Circlealt1>
                                        </MediaQuery>
                                        <MediaQuery minWidth={426} maxWidth={475}>
                                            <Circlealt2 stroke={props.game.userEntered ? "url(#linearGreen)" : "url(#linearGold)"} cx="128" cy="130" r="95"></Circlealt2>
                                        </MediaQuery>
                                        <MediaQuery maxWidth={425}>
                                            <Circlealt3 stroke={props.game.userEntered ? "url(#linearGreen)" : "url(#linearGold)"} cx="128" cy="130" r="84"></Circlealt3>
                                        </MediaQuery>
                                    </>
                                    : <div />}
                                {props.game.filled ? <Circle stroke={props.game.userEntered ? "url(#linearWhite)" : "url(#linearGray)"} cx="128" cy="130" r="128"></Circle> : <div />}
                            </svg>
                            <svg className={cssAphro(props.game.fillStatusUsers < 10 && styles.enteredPlayersSVG, (10 <= props.game.fillStatusUsers && props.game.fillStatusUsers < 100) && [styles.enteredPlayersSVG2, styles.mqenteredPlayersSVG2], (100 <= props.game.fillStatusUsers && props.game.fillStatusUsers < 1000) && styles.enteredPlayersSVG3, (1000 <= props.game.fillStatusUsers && props.game.fillStatusUsers < 10000) && styles.enteredPlayersSVG4)} viewBox="0 0 220 220">
                                <path id="curveText" d="M0,110a110,110 0 1,0 220,0a110,110 0 1,0 -220,0" fill="transparent" />
                                <text className={cssAphro(props.game.userEntered ? styles.enteredPlayersTextEntered : [styles.enteredPlayersText, styles.mqenteredPlayersTextTut])} width="220">
                                    <textPath alignmentBaseline={"bottom"} xlinkHref="#curveText">
                                        {props.game.fillStatusUsers} PLAYERS IN
                        </textPath>
                                </text>
                            </svg>
                            {/* <div className={cssAphro(props.game.userEntered ? styles.enteredValueTextEntered : styles.enteredValueText)}>{`$${props.game.fillStatusFiat} / $${props.game.prizeValue}`}</div> */}
                        </Card.Body>

                    </div>
                    <Card.Footer className={cssAphro(styles.gameFooter, styles.mqgameFooter)}>
                        {props.game.userEntered ?
                            (
                                <>
                                    <div className={cssAphro(styles.enteredGameSection)}><span><span className={cssAphro(styles.youreIn)}>{props.game.filled ? "GAME OVER!" : "YOU'RE IN!"}</span><br /><span className={cssAphro(styles.enteredSlotNumber)}>SLOT {slot}</span></span></div>
                                    <div className={cssAphro(styles.buyInTextHidden)}><span className={cssAphro(styles.buyInValue)}>FREE</span> BUY IN</div>
                                </>
                            )
                            :
                            (
                                <>
                                    <Button onClick={() => props.tutGame()} disabled={props.game.userEntered} className={cssAphro(styles.joinGameButtonTut, styles.mqjoinGameButtonTut)}>{props.game.userEntered ? "ENTERED" : "JOIN GAME"}</Button><br />
                                    <div className={cssAphro(styles.buyInText, styles.mqbuyInTextTut)}><span className={cssAphro(styles.buyInValue)}>FREE</span> BUY IN</div>
                                </>
                            )
                        }
                        {props.game.userEntered ?
                            (
                                // <div className={cssAphro((Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? styles.remainingSlotsOpaqueEntered : styles.remainingSlotsEntered)}>{(props.game.maxUsers - props.game.fillStatusUsers)}{(Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? ' Slots Remaining' : ' Slots Left!'}</div>
                                !props.game.filled ? <div className={cssAphro((Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? styles.remainingSlotsEntered : styles.remainingSlotsEntered)}>{(props.game.maxUsers - props.game.fillStatusUsers)}{(Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? ' Slots Remaining' : props.game.maxUsers - props.game.fillStatusUsers <= 1 ? ' Slot Left!' : ' Slots Left!'}</div> : <div />
                            )
                            :
                            (
                                // <div className={cssAphro((Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? styles.remainingSlotsOpaque : styles.remainingSlots)}>{(props.game.maxUsers - props.game.fillStatusUsers)}{(Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? ' Slots Remaining' : ' Slots Left!'}</div>
                                !props.game.filled ? <div className={cssAphro((Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? [styles.remainingSlotsTut, styles.mqremainingSlotsTut] : [styles.remainingSlotsTut, styles.mqremainingSlotsTut])}>{(props.game.maxUsers - props.game.fillStatusUsers)}{(Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? ' Slots Remaining' : props.game.maxUsers - props.game.fillStatusUsers <= 1 ? ' Slot Left!' : ' Slots Left!'}</div> : <div />

                            )
                        }
                    </Card.Footer>
                </Card>
                :
                <>
                    <MediaQuery minWidth={1256}>
                        <Col md={4}>
                            <Card className={cssAphro(styles.gameCard, styles.noSelect, styles.mqgameCard)}>
                                <div className={cssAphro(styles.gameBodyWrapper)}>
                                    <img className={cssAphro(styles.gameBodyBase)} src={require('../../images/coinBase.svg')} alt='' />
                                    <Card.Body className={cssAphro(styles.gameBodyshadow)}>
                                        {props.game.userEntered ?
                                            <img className={cssAphro(styles.gameHashMarks)} src={require('../../images/coinHashMarks.svg')} alt='' />
                                            :
                                            <Link to={`/game/${props.game.gameid}`}>
                                                <img className={cssAphro(styles.gameHashMarks)} src={require('../../images/coinHashMarks.svg')} alt='' />
                                            </Link>
                                        }

                                        <div className={cssAphro(styles.prizeTextContainer)}>
                                            <div className={cssAphro(props.game.userEntered ? styles.prizeValueEntered : styles.prizeValue)}>{`$${props.game.prizeValue}`}</div>
                                            <div className={cssAphro(props.game.userEntered ? styles.prizeTextEntered : styles.prizeText)}>PRIZE</div>
                                            {//PUT CANNONBALL COUNT COMPONENT HERE
                                            }
                                        </div>
                                        <svg className={cssAphro(styles.progressBorderSVG)}>
                                            <defs>
                                                <linearGradient id="linearGold" x1="0%" y1="0%" x2="0%" y2="100%">
                                                    <stop offset="0%" stopColor={"#FFE500"} />
                                                    <stop offset="100%" stopColor={"#FAC51C"} />
                                                </linearGradient>
                                                <linearGradient id="linearGreen" x1="0%" y1="0%" x2="0%" y2="100%">
                                                    <stop offset="0%" stopColor={"#2fbe60"} />
                                                    <stop offset="100%" stopColor={"#31C163"} />
                                                </linearGradient>
                                                <linearGradient id="linearWhite" x1="0%" y1="0%" x2="0%" y2="100%">
                                                    <stop offset="0%" stopColor={"#008040"} />
                                                    <stop offset="100%" stopColor={"#008040"} />
                                                </linearGradient>
                                                <linearGradient id="linearGray" x1="0%" y1="0%" x2="0%" y2="100%">
                                                    <stop offset="0%" stopColor={"#e6e600"} />
                                                    <stop offset="100%" stopColor={"#e6e600"} />
                                                </linearGradient>
                                            </defs>
                                            {!props.game.filled ? <Circle stroke={props.game.userEntered ? "url(#linearGreen)" : "url(#linearGold)"} cx="128" cy="130" r="128"></Circle> : <div />}
                                            {props.game.filled ? <Circle stroke={props.game.userEntered ? "url(#linearWhite)" : "url(#linearGray)"} cx="128" cy="130" r="128"></Circle> : <div />}
                                        </svg>
                                        <svg className={cssAphro(props.game.fillStatusUsers < 10 && styles.enteredPlayersSVG, (10 <= props.game.fillStatusUsers && props.game.fillStatusUsers < 100) && styles.enteredPlayersSVG2, (100 <= props.game.fillStatusUsers && props.game.fillStatusUsers < 1000) && styles.enteredPlayersSVG3, (1000 <= props.game.fillStatusUsers && props.game.fillStatusUsers < 10000) && styles.enteredPlayersSVG4)} viewBox="0 0 220 220">
                                            <path id="curveText" d="M0,110a110,110 0 1,0 220,0a110,110 0 1,0 -220,0" fill="transparent" />
                                            <text className={cssAphro(props.game.userEntered ? styles.enteredPlayersTextEntered : styles.enteredPlayersText)} width="220">
                                                <textPath alignmentBaseline={"bottom"} xlinkHref="#curveText">
                                                    {props.game.fillStatusUsers} PLAYERS IN
                                </textPath>
                                            </text>
                                        </svg>
                                        {/* <div className={cssAphro(props.game.userEntered ? styles.enteredValueTextEntered : styles.enteredValueText)}>{`$${props.game.fillStatusFiat} / $${props.game.prizeValue}`}</div> */}
                                    </Card.Body>

                                </div>
                                <Card.Footer className={cssAphro(styles.gameFooter)}>
                                    {props.game.userEntered ?
                                        (
                                            <>
                                                <div className={cssAphro(styles.enteredGameSection)}><span><span className={cssAphro(styles.youreIn)}>{props.game.filled ? "GAME OVER!" : "YOU'RE IN!"}</span><br /><span className={cssAphro(styles.enteredSlotNumber)}>SLOT {slot}</span></span></div>
                                                <div className={cssAphro(styles.buyInTextHidden)}><span className={cssAphro(styles.buyInValue)}>${props.game.entryValue.toFixed(2)}</span> BUY IN</div>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <Link to={`/game/${props.game.gameid}`}><Button disabled={props.game.userEntered} className={cssAphro(styles.joinGameButton)}>{props.game.userEntered ? "ENTERED" : "JOIN GAME"}</Button></Link><br />
                                                <div className={cssAphro(styles.buyInText)}><span className={cssAphro(styles.buyInValue)}>${props.game.entryValue.toFixed(2)}</span> BUY IN</div>
                                            </>
                                        )
                                    }
                                    {props.game.userEntered ?
                                        (
                                            // <div className={cssAphro((Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? styles.remainingSlotsOpaqueEntered : styles.remainingSlotsEntered)}>{(props.game.maxUsers - props.game.fillStatusUsers)}{(Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? ' Slots Remaining' : ' Slots Left!'}</div>
                                            !props.game.filled ? <div className={cssAphro((Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? styles.remainingSlotsEntered : styles.remainingSlotsEntered)}>{(props.game.maxUsers - props.game.fillStatusUsers)}{(Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? ' Slots Remaining' : props.game.maxUsers - props.game.fillStatusUsers <= 1 ? ' Slot Left!' : ' Slots Left!'}</div> : <div />
                                        )
                                        :
                                        (
                                            // <div className={cssAphro((Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? styles.remainingSlotsOpaque : styles.remainingSlots)}>{(props.game.maxUsers - props.game.fillStatusUsers)}{(Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? ' Slots Remaining' : ' Slots Left!'}</div>
                                            !props.game.filled ? <div className={cssAphro((Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? styles.remainingSlots : styles.remainingSlots)}>{(props.game.maxUsers - props.game.fillStatusUsers)}{(Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? ' Slots Remaining' : ' Slots Left!'}</div> : <div />

                                        )
                                    }
                                </Card.Footer>
                            </Card>
                        </Col>
                    </MediaQuery>
                    <MediaQuery maxWidth={1255}>
                        <Col md={6}>
                            <Card className={cssAphro(styles.gameCard, styles.noSelect, styles.mqgameCard)}>
                                <div className={cssAphro(styles.gameBodyWrapper)}>
                                    <img className={cssAphro(styles.gameBodyBase)} src={require('../../images/coinBase.svg')} alt='' />
                                    <Card.Body className={cssAphro(styles.gameBodyshadow)}>
                                        {props.game.userEntered ?
                                            <img className={cssAphro(styles.gameHashMarks)} src={require('../../images/coinHashMarks.svg')} alt='' />
                                            :
                                            <Link to={`/game/${props.game.gameid}`}>
                                                <img className={cssAphro(styles.gameHashMarks)} src={require('../../images/coinHashMarks.svg')} alt='' />
                                            </Link>
                                        }

                                        <div className={cssAphro(styles.prizeTextContainer)}>
                                            <div className={cssAphro(props.game.userEntered ? styles.prizeValueEntered : styles.prizeValue)}>{`$${props.game.prizeValue}`}</div>
                                            <div className={cssAphro(props.game.userEntered ? styles.prizeTextEntered : styles.prizeText)}>PRIZE</div>
                                            {//PUT CANNONBALL COUNT COMPONENT HERE
                                            }
                                        </div>
                                        <svg className={cssAphro(styles.progressBorderSVG)}>
                                            <defs>
                                                <linearGradient id="linearGold" x1="0%" y1="0%" x2="0%" y2="100%">
                                                    <stop offset="0%" stopColor={"#FFE500"} />
                                                    <stop offset="100%" stopColor={"#FAC51C"} />
                                                </linearGradient>
                                                <linearGradient id="linearGreen" x1="0%" y1="0%" x2="0%" y2="100%">
                                                    <stop offset="0%" stopColor={"#2fbe60"} />
                                                    <stop offset="100%" stopColor={"#31C163"} />
                                                </linearGradient>
                                                <linearGradient id="linearWhite" x1="0%" y1="0%" x2="0%" y2="100%">
                                                    <stop offset="0%" stopColor={"#008040"} />
                                                    <stop offset="100%" stopColor={"#008040"} />
                                                </linearGradient>
                                                <linearGradient id="linearGray" x1="0%" y1="0%" x2="0%" y2="100%">
                                                    <stop offset="0%" stopColor={"#e6e600"} />
                                                    <stop offset="100%" stopColor={"#e6e600"} />
                                                </linearGradient>
                                            </defs>
                                            {!props.game.filled ? <Circle stroke={props.game.userEntered ? "url(#linearGreen)" : "url(#linearGold)"} cx="128" cy="130" r="128"></Circle> : <div />}
                                            {props.game.filled ? <Circle stroke={props.game.userEntered ? "url(#linearWhite)" : "url(#linearGray)"} cx="128" cy="130" r="128"></Circle> : <div />}
                                        </svg>
                                        <svg className={cssAphro(props.game.fillStatusUsers < 10 && styles.enteredPlayersSVG, (10 <= props.game.fillStatusUsers && props.game.fillStatusUsers < 100) && styles.enteredPlayersSVG2, (100 <= props.game.fillStatusUsers && props.game.fillStatusUsers < 1000) && styles.enteredPlayersSVG3, (1000 <= props.game.fillStatusUsers && props.game.fillStatusUsers < 10000) && styles.enteredPlayersSVG4)} viewBox="0 0 220 220">
                                            <path id="curveText" d="M0,110a110,110 0 1,0 220,0a110,110 0 1,0 -220,0" fill="transparent" />
                                            <text className={cssAphro(props.game.userEntered ? styles.enteredPlayersTextEntered : styles.enteredPlayersText)} width="220">
                                                <textPath alignmentBaseline={"bottom"} xlinkHref="#curveText">
                                                    {props.game.fillStatusUsers} PLAYERS IN
                                            </textPath>
                                            </text>
                                        </svg>
                                        {/* <div className={cssAphro(props.game.userEntered ? styles.enteredValueTextEntered : styles.enteredValueText)}>{`$${props.game.fillStatusFiat} / $${props.game.prizeValue}`}</div> */}
                                    </Card.Body>

                                </div>
                                <Card.Footer className={cssAphro(styles.gameFooter)}>
                                    {props.game.userEntered ?
                                        (
                                            <>
                                                <div className={cssAphro(styles.enteredGameSection)}><span><span className={cssAphro(styles.youreIn)}>{props.game.filled ? "GAME OVER!" : "YOU'RE IN!"}</span><br /><span className={cssAphro(styles.enteredSlotNumber)}>SLOT {slot}</span></span></div>
                                                <div className={cssAphro(styles.buyInTextHidden)}><span className={cssAphro(styles.buyInValue)}>${props.game.entryValue.toFixed(2)}</span> BUY IN</div>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <Link to={`/game/${props.game.gameid}`}><Button disabled={props.game.userEntered} className={cssAphro(styles.joinGameButton)}>{props.game.userEntered ? "ENTERED" : "JOIN GAME"}</Button></Link><br />
                                                <div className={cssAphro(styles.buyInText)}><span className={cssAphro(styles.buyInValue)}>${props.game.entryValue.toFixed(2)}</span> BUY IN</div>
                                            </>
                                        )
                                    }
                                    {props.game.userEntered ?
                                        (
                                            // <div className={cssAphro((Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? styles.remainingSlotsOpaqueEntered : styles.remainingSlotsEntered)}>{(props.game.maxUsers - props.game.fillStatusUsers)}{(Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? ' Slots Remaining' : ' Slots Left!'}</div>
                                            !props.game.filled ? <div className={cssAphro((Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? styles.remainingSlotsEntered : styles.remainingSlotsEntered)}>{(props.game.maxUsers - props.game.fillStatusUsers)}{(Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? ' Slots Remaining' : props.game.maxUsers - props.game.fillStatusUsers <= 1 ? ' Slot Left!' : ' Slots Left!'}</div> : <div />
                                        )
                                        :
                                        (
                                            // <div className={cssAphro((Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? styles.remainingSlotsOpaque : styles.remainingSlots)}>{(props.game.maxUsers - props.game.fillStatusUsers)}{(Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? ' Slots Remaining' : ' Slots Left!'}</div>
                                            !props.game.filled ? <div className={cssAphro((Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? styles.remainingSlots : styles.remainingSlots)}>{(props.game.maxUsers - props.game.fillStatusUsers)}{(Number(props.game.fillStatusUsers / props.game.maxUsers) < 0.95) && (props.game.maxUsers - props.game.fillStatusUsers >= 5) ? ' Slots Remaining' : ' Slots Left!'}</div> : <div />

                                        )
                                    }
                                </Card.Footer>
                            </Card>
                        </Col>
                    </MediaQuery>
                </>
            }
        </>
    );
}