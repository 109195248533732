import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import PlayerWheel from '../PlayerWheel';
import Login from '../Login';
import { connect } from 'react-redux';
import { clearCurrentGame, enterGameView } from '../../actions/GameActions';
import { sleep } from '../../constants/ActionConstants';
import { css } from 'aphrodite';
import { styles } from './GameViewStyles.js';
import { tutorialPlayers } from '../../JSON/tutorialPlayers.js';

class GameViewMock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timer: false,
            time: 5,
            countdownComplete: false,
        };
        this.countDownWinner = this.countDownWinner.bind(this);
        this.decrement = this.decrement.bind(this);
        this.setTutWinner = this.setTutWinner.bind(this);
    }

    countDownWinner() {
        this.setState({ timer: true });
        setInterval(this.decrement, 1000);
    }

    decrement() {
        if (this.state.time <= 1) {
            this.setState({ countdownComplete: true, timer: false });
        }
        else {
            this.setState({ time: this.state.time - 1 });
        }
    }

    setTutWinner() {
        this.setState({ wheelWinner: true })
    }

    mapImages(image) {
        switch (image) {
            case '../../images/tutorialPhotos/nrd-Y9oI7oLSd_c-unsplash.jpg':
                return require('../../images/tutorialPhotos/nrd-Y9oI7oLSd_c-unsplash.jpg');
            case '../../images/tutorialPhotos/freestocks-9UVmlIb0wJU-unsplash.jpg':
                return require('../../images/tutorialPhotos/freestocks-9UVmlIb0wJU-unsplash.jpg');
            case '../../images/tutorialPhotos/brooks-leibee-27QcqVqgVg4-unsplash.jpg':
                return require('../../images/tutorialPhotos/brooks-leibee-27QcqVqgVg4-unsplash.jpg');
            case '../../images/tutorialPhotos/zachary-nelson-98Elr-LIvD8-unsplash.jpg':
                return require('../../images/tutorialPhotos/zachary-nelson-98Elr-LIvD8-unsplash.jpg');
            case '../../images/tutorialPhotos/michael-dam-mEZ3PoFGs_k-unsplash.jpg':
                return require('../../images/tutorialPhotos/michael-dam-mEZ3PoFGs_k-unsplash.jpg');
            case '../../images/tutorialPhotos/dami-adebayo-k6aQzmIbR1s-unsplash.jpg':
                return require('../../images/tutorialPhotos/dami-adebayo-k6aQzmIbR1s-unsplash.jpg');
            case '../../images/tutorialPhotos/jose-alejandro-cuffia-k1LNP6dnyAE-unsplash.jpg':
                return require('../../images/tutorialPhotos/jose-alejandro-cuffia-k1LNP6dnyAE-unsplash.jpg');
            case '../../images/tutorialPhotos/dan-ROJFuWCsfmA-unsplash.jpg':
                return require('../../images/tutorialPhotos/dan-ROJFuWCsfmA-unsplash.jpg');
            case '../../images/tutorialPhotos/courtney-cook-Q1T4XGEXIgw-unsplash.jpg':
                return require('../../images/tutorialPhotos/courtney-cook-Q1T4XGEXIgw-unsplash.jpg');
            case '../../images/tutorialPhotos/ben-parker-OhKElOkQ3RE-unsplash.jpg':
                return require('../../images/tutorialPhotos/ben-parker-OhKElOkQ3RE-unsplash.jpg');
            case '../../images/tutorialPhotos/nicholas-green-nPz8akkUmDI-unsplash.jpg':
                return require('../../images/tutorialPhotos/nicholas-green-nPz8akkUmDI-unsplash.jpg');
            default:
                return require('../../images/addFunds.svg');
        }
    }

    mapWheelNodes(slots, players, winner) {
        let wheelNodes = [];
        let lastSlot = 0;

        for (let i = 1; i <= slots; i++) {
            var taken = null;
            for (var p = lastSlot; p < i && p < players.length; p++) {
                if (i === players[p].slot) {
                    taken = players[p].player;
                    lastSlot = p;
                }
            }

            // if (taken && winner === taken.name) {
            //     this.setState({ wheelWinner: taken });
            // }

            wheelNodes.push(
                // <div disabled={taken && taken.length} className={css(styles.wheelNodeDisplay)}>
                <div disabled={taken && taken.length} className={css(taken !== null && [styles.wheelNodeDisplayTaken, styles.mqwheelNodeDisplay], taken === null && [styles.wheelNodeDisplayTest, styles.mqwheelNodeDisplay])}>
                    {taken && (<img className={css(styles.takenImg)} src={taken.userProfileURL ? this.mapImages(taken.userProfileURL) : <em>taken</em>} width="65" />)}
                    {taken && winner === taken.name && <span className={css(styles.nameDisplay)}>{taken.name} won!</span>}
                    {!taken && (<div className={css(i < 100 && [styles.innerNum, styles.mqinnerNum], i >= 100 && i < 1000 && [styles.innerNum, styles.mqinnerNumHundreds], i >= 1000 && [styles.innerNum, styles.mqinnerNumThousands])}>{String(i)}</div>)}
                </div>
            )
        }
        return wheelNodes;
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        var winner = '';
   
        // for (var i = 0; i < this.props.gameHistory.length; i++) {
        //     if (String(this.props.gameHistory[i].gameID) === String(id)) {
        //         winner = this.props.gameHistory[i].winnerName;
        //         break;
        //     }
        // }

        sleep(200).then(() => {
            var wheelNodes = this.mapWheelNodes(12, tutorialPlayers, '');
            this.setState({ wheelNodes: wheelNodes });
        })
    }

    componentWillUnmount() {
        this.setState({ wheelNodes: null });
    }

    render() {
        return (
            <div className={css(styles.gameView, styles.noSelect)}>
                <Row className={css(styles.gameViewTopBar)}>
                    <Link to="/" className={css(styles.gameViewBackButtonTut)}><img src={require('../../images/backToHome.svg')} /></Link>
                </Row>
                <Row className={css(styles.gameViewInfoRow)}>
                    <Col md={2} className={css(styles.gameViewGameInfo)}>

                        <span className={css(styles.gameInfoBox)}>
                            <span className={css(this.state.countdownComplete ? styles.playForTextTut : styles.playForText)}>
                                {this.state.wheelWinner ? (this.state.countdownComplete ? "YOU JUST WON" : "WINNER GETS") : "PLAY FOR"}
                            </span><br />
                            <span className={css(this.state.countdownComplete ? styles.gameViewPrizeValueTextTut : styles.gameViewPrizeValueText)}>
                                $2.00
                                </span><br />
                            {!this.state.wheelWinner &&
                                <>
                                    <span className={css(styles.playForText)}>
                                        FOR FREE
                                    </span><br />
                                </>
                            }
                            <span className={css(styles.gameViewGameId)}>
                                GAME 762378
                                </span>
                        </span>

                    </Col>
                    <Col md={2} className={css(styles.gameViewAccountInfo, styles.mqgameViewAccountInfo)}>
                        <div className={css(styles.accountInfoBox)}>
                            <span className={css(styles.gameViewMyAccountText)}>MY ACCOUNT</span><br />
                            <span className={css(styles.gameViewAccountValueText)}>{this.props.currUser.balance >= 0 ? `$${this.props.currUser.balance.toFixed(2)}` : (this.state.countdownComplete ? "$2.00" : "$0.00")}</span>
                        </div>
                    </Col>
                </Row>

                {this.state.wheelWinner ?
                    (<>
                        {this.state.countdownComplete ?
                            (<Row className={css(styles.gameViewTitleRow, styles.mqgameFillRowMock)}>
                                <div className={css(styles.gameViewTitle, styles.mqgameFillMock)}>WINNER!</div>
                            </Row>
                            )
                            :
                            (
                                this.state.timer ?
                                    (
                                        <Row className={css(styles.gameViewTitleRow, styles.mqgameFillRowMock, styles.mqgameFillRowMockLong)}>
                                            <div className={css(styles.gameViewTitle, styles.mqgameViewTitleLong, styles.mqgameViewTitleLongMock)}>SELECTING WINNER</div>
                                        </Row>
                                    )
                                    :
                                    (
                                        <Row className={css(styles.gameViewTitleRow, styles.mqgameFillRowMock)}>
                                            <div className={css(styles.gameViewTitle, styles.mqgameFillMock)}>EVERYONE'S IN!</div>
                                        </Row>
                                    )
                            )
                        }
                        <div style={{ position: 'fixed', top: '50%', width: '100%' }}>
                            <div style={{ position: 'relative' }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {this.state.timer &&
                                        <div className={css(styles.countDown)}><span className={css(styles.countDownTime, styles.mqcountDownTime)}>{this.state.time}</span></div>
                                    }
                                    {!this.state.timer && !this.state.countdownComplete &&
                                        <Button onClick={this.countDownWinner} className={css(styles.showWinnerButton, styles.mqshowWinnerButton)}>SHOW ME<br />THE WINNER!</Button>
                                    }
                                    {!this.state.timer && this.state.countdownComplete &&
                                        <>
                                            <div style={{ marginTop: -170 }}>
                                                <div className={css(styles.winnersCircleTut)}><img className={css(styles.winnerImg)} src={require("../../images/noAvSelected.svg")} /></div>
                                                {/* <br /> */}

                                                <div className={css(styles.winnersCircleText, styles.mqwinnersCircleTextMock)}>{(`You just won $2!`).toUpperCase()}</div>
                                                <div className={css(styles.tutReturnButtonBox)}>
                                                    <Button className={css(styles.tutReturnButton)} onClick={this.props.tutReturnHome}>RETURN TO HOME </Button>
                                                </div>

                                            </div>
                                            <img className={css(styles.particleEffect)} src={require("../../images/particles.gif")} /><br />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                    )
                    :
                    (
                        <Row className={css(styles.gameViewTitleRow, styles.mqgameViewTitleRowMock)}>
                            <div className={css(styles.gameViewTitle)}>PICK A SLOT</div>
                        </Row>
                    )
                }


                {this.state.wheelNodes && !this.state.wheelWinner && (
                    <PlayerWheel tutorial setTutWinner={this.setTutWinner}>
                        {this.state.wheelNodes}
                    </PlayerWheel>
                )}
            </div>
        );

    }

}

function mapStateToProps(state) {
    return {
        currentgame: state.gameReducer.currentGame,
        currUser: state.loginReducer.user,
        token: state.loginReducer.loginToken,
        gameHistory: state.gameReducer.gameHistory
    }
}

export default withRouter(connect(mapStateToProps, {
    clearCurrentGame,
    enterGameView
}
)(GameViewMock));