import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
    addFundsButton: {
        position: 'absolute', 
        bottom: 5
    },
    addFundsBox: {
        display: "flex", 
        position: "relative", 
        justifyContent: 'center', 
        alignItems: 'center'
    }, 
    addFundsGraphic: {
        width: 168,
        marginBottom: 6
    },
    closeGameEntry: {
        fontFamily: "SF Pro Text Heavy",
        fontSize: 13,
        ':hover': {
            cursor: 'pointer'
        }
    },
    controlRow: {
        marginTop: 75, 
        "@media (max-width: 525px)": {
            marginTop: 36
        }, 
        "@media (max-width: 450px)": {
            marginTop: 22
        }
    },
    embla: {
        position: 'relative'
    },
    embla__viewport: {
        overflow: 'hidden'
    },
    embla__container: {
        display: 'flex',
        willChange: 'transform',
        marginLeft: '-1rem'
    },
    //ARCHIVE 
    // embla__slide: {
    //     flex: '0 0 auto', 
    //     width: 120, 
    //     heigth: 120, 
    //     position: 'relative', 
    //     paddingLeft: '1rem', 
    //     counterIncrement: 'embla'
    // }, 
    embla__slide: {
        position: "relative",
        minWidth: "11%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center", 
        '@media (max-width: 1400px)': {
            minWidth: "12%"
        }, 
        '@media (max-width: 1110px)': {
            minWidth: "14%"
        }, 
        '@media (max-width: 950px)': {
            minWidth: "16%"
        }, 
        '@media (max-width: 850px)': {
            minWidth: "17%"
        }, 
        '@media (max-width: 780px)': {
            minWidth: "20%"
        }, 
        '@media (max-width: 600px)': {
            minWidth: "22%"
        }, 
        '@media (max-width: 535px)': {
            minWidth: "25%"
        }, 
        '@media (max-width: 420px)': {
            minWidth: "27%"
        }, 
        '@media (max-width: 380px)': {
            minWidth: "30%"
        }, 
        '@media (max-width: 350px)': {
            minWidth: "33%"
        }
    },
    embla__slide__tenth: {
        position: "relative", 
        minWidth: "12%", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        '@media (max-width: 1110px)': {
            minWidth: "14%"
        }, 
        '@media (max-width: 950px)': {
            minWidth: "16%"
        }, 
        '@media (max-width: 850px)': {
            minWidth: "17%"
        }, 
        '@media (max-width: 780px)': {
            minWidth: "20%"
        }, 
        '@media (max-width: 600px)': {
            minWidth: "22%"
        }, 
        '@media (max-width: 535px)': {
            minWidth: "25%"
        }, 
        '@media (max-width: 420px)': {
            minWidth: "27%"
        }, 
        '@media (max-width: 380px)': {
            minWidth: "30%"
        }, 
        '@media (max-width: 350px)': {
            minWidth: "33%"
        }
    },
    embla__slide__eighth: {
        position: "relative", 
        minWidth: "15%", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        '@media (max-width: 900px)': {
            minWidth: "17%"
        }, 
        '@media (max-width: 780px)': {
            minWidth: "20%"
        }, 
        '@media (max-width: 600px)': {
            minWidth: "22%"
        }, 
        '@media (max-width: 535px)': {
            minWidth: "25%"
        }, 
        '@media (max-width: 420px)': {
            minWidth: "27%"
        }, 
        '@media (max-width: 380px)': {
            minWidth: "30%"
        }, 
        '@media (max-width: 350px)': {
            minWidth: "33%"
        }
    },
    embla__slide__sixth: {
        position: "relative", 
        minWidth: "22%", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        '@media (max-width: 570px)': {
            minWidth: "23%"
        }, 
        '@media (max-width: 500px)': {
            minWidth: "25%"
        }, 
        '@media (max-width: 420px)': {
            minWidth: "27%"
        }, 
        '@media (max-width: 380px)': {
            minWidth: "30%"
        }, 
        '@media (max-width: 350px)': {
            minWidth: "33%"
        }
    }, 
    embla__slide__fourth: {
        position: "relative", 
        minWidth: "35%", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center"
    },
    embla__slide__inner: {
        backgroundColor: 'rgb(40, 44, 52)',
        position: 'relative',
        borderRadius: '8rem',
        paddingBottom: '46%',
        fontSize: '2.2rem',
        display: 'flex',
        justifyContent: 'center'
    },
    gameEntryButton: {
        background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
        height: 42,
        width: 148,
        border: "none",
        borderRadius: 4,
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        color: "#3A3934",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "none"
        }
    },
    gameEntryButtonWide: {
        width: 162,
    },
    gameEntryBody: {
        paddingTop: 0,
        color: "#FFE400",
        fontFamily: 'SF Pro Text Semibold'
    },
    gameEntryHeader: {
        borderBottom: 'none',
        fontFamily: 'SF Pro Text Heavy',
        color: "#FFE400",
        letterSpacing: -0.17,
        textAlign: 'center'
    },
    gameEntrySection: {
        textAlign: 'center'
    },
    gameEntrySubtitle: {
        textAlign: 'center'
    },
    gameEntryTitle: {
        margin: '0 auto'
    },
    goBackButton: {
        position: "absolute", 
        bottom: 15
    },
    particleEffect: {
        position: "absolute", 
        zIndex: -1, 
        bottom: "-20%"
    },
    playerWheelBezel: {
        position: 'absolute', 
        top: "-73%",
        zIndex: -1, 
        width: "100%", 
        justifyContent: "center", 
        alignItems: "center", 
        display: "flex"
    },
    playerWheelControls: {
        position: 'fixed',
        bottom: 0,
        width: '100%'
    },
    playerWheelPointer: {
        position: "absolute",
        height: "75%"
    },
    pwControlCol: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 30
    },
    selectedWrapper: {
        background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
        padding: 6,
        borderRadius: '8rem'
    },
    selectionButtonRow: {
        display: "flex", 
        justifyContent: "center", 

    },
    slotButtonsRow: {
        position: "relative"
    },
    slotNextButton: {
        position: "absolute", 
        right: 55, 
        ":hover": {
            cursor: "pointer"
        }, 
        "@media (max-width: 565px)": {
            right: 30
        }, 
        "@media (max-width: 525px)": {
            width: 105
        }, 
        "@media (max-width: 400px)": {
            right: 20
        }, 
        "@media (max-width: 375px)": {
            width: 96
        }
    },
    slotPrevButton: {
        marginLeft: 35,
        ":hover": {
            cursor: "pointer"
        }, 
        "@media (max-width: 565px)": {
            marginLeft: 15
        }, 
        "@media (max-width: 525px)": {
            width: 105
        }, 
        "@media (max-width: 400px)": {
            marginLeft: 6
        }, 
        "@media (max-width: 375px)": {
            width: 96
        }
    },
    slotSelectionButton: {
        background: 'linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)',
        color: "#3A3933",
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        border: "none",
        borderRadius: 8,
        height: 60,
        width: 220,
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "0 0 0 0.15rem #50504a"
        },
        marginBottom: 10, 
        "@media (max-width: 565px)": {
            width: 165
        },
        "@media (max-width: 425px)": {
            width: 137
        }, 
        "@media (max-width: 355px)": {
            width: 125
        }
    },
    termsConditionsBox: {
        marginTop: 6,
        "@media (max-width: 525px)": {
            width: 200
        }
    },
    termsConditionsLink: {
        color: '#FFC400',
        fontFamily: 'SF Pro Text Medium',
        fontSize: 13,
        letterSpacing: 0.79,
        textDecoration: 'none'
    },
    tutEntryButton: {
        background: 'linear-gradient(270deg, #FFCC00 0%, #FF9500 100%)',
        height: 113, 
        width: 415, 
        fontSize: 28,
        color: "#3A3933",
        fontFamily: 'SF Pro Text Heavy',
        letterSpacing: 1,
        textAlign: "center",
        border: "none",
        borderRadius: 16,
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "0 0 0 0.15rem #50504a"
        },
        marginBottom: 10,
        marginTop: -38, 
        '@media (max-width: 1000px)': {
            width: 370
        }, 
        '@media (max-width: 875px)': {
            width: 350, 
            height: 103, 
            marginTop: -28
        }, 
        '@media (max-width: 775px)': {
            width: 320, 
            height: 93, 
            marginTop: -18
        }, 
        '@media (max-width: 700px)': {
            width: 295, 
            height: 83, 
            marginTop: -8, 
            fontSize: 26
        }, 
        '@media (max-width: 645px)': {
            width: 255, 
            fontSize: 25
        }, 
        '@media (max-width: 600px)': {
            width: 230, 
            fontSize: 23
        }, 
        '@media (max-width: 485px)': {
            width: 195, 
            fontSize: 20
        }, 
        '@media (max-width: 435px)': {
            width: 170
        }, 
        '@media (max-width: 415px)': {
            width: 150
        }, 
        '@media (max-width: 365px)': {
            width: 140
        }, 
        '@media (max-width: 345px)': {
            width: 128, 
            fontSize: 19
        }, 
        '@media (max-width: 330px)': {
            width: 118, 
            fontSize: 17
        }
    }, 
    watchAdsGraphic: {
        width: 180,
        marginBottom: -8
    },
    mqDraggableNodes: {
        '@media (max-width: 630px)': {
            marginTop: 9
        },
        '@media (max-width: 450px)': {
          marginTop: 18  
        }, 
        '@media (max-width: 330px)': {
            marginTop: 27
        }
    }, 
    mqWheelControl: {
        '@media (min-height: 780px)': {
            marginTop: 35
        }, 
        '@media (min-height: 850px)': {
            marginTop: 60
        }, 
        '@media (min-height: 900px)': {
            marginTop: 90
        }, 
        '@media (max-width: 525px)': {
            marginTop: 20
        }, 
        '@media (max-width: 450px)': {
            marginTop: 45
        }, 
        '@media (max-width: 330px)': {
            marginTop: 38
        }
    },
    mqWheelControlMock: {
        '@media (max-width: 600px)': {
            marginTop: 65
        }, 
        '@media (max-width: 499px)': {
            marginTop: 85
        }, 
        '@media (max-width: 425px)': {
            marginTop: 95
        }
    }, 
    mqcontrolRowMock: {
        '@media (max-width: 525px)': {
            marginTop: 75
        }
    }
}); 