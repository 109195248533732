import React from 'react';

import { Container, Row, Col, Button } from 'react-bootstrap';

import axios from 'axios';
import { data } from '../../JSON/games.js';
import { user } from '../../JSON/currentUser.js';
import { leaderboard } from '../../JSON/leaderboard.js';
import { adsQueue } from '../../JSON/adsQueue.js';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { playAdContent, playAllContent, stopAdContent, stopAllContent, closeSideMenus } from '../../actions/MenuActions';
import MediaQuery from 'react-responsive';
import { ArrowBarLeft } from 'react-bootstrap-icons';
import { transactValue, finalizeSlot } from '../../actions/GameActions';
import { serverEnvironment, sleep } from '../../constants/ActionConstants.js';
import { css } from 'aphrodite';
import { styles } from './AdsboardStyles.js';
import swal from 'sweetalert';


class Adsboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.onClickPlayAdContent = this.onClickPlayAdContent.bind(this);
        this.onClickStopAdContent = this.onClickStopAdContent.bind(this);
    }

    onClickPlayAdContent() {
        this.props.playAdContent();
    }

    onClickStopAdContent() {
        this.props.stopAdContent();
    }

    componentDidMount() {
        var numAdsRequired = parseFloat(this.props.currentGame.entryValue) / serverEnvironment.adCost;
        var balance = this.props.user.adsViewedBalance >= numAdsRequired ? numAdsRequired - 1 : this.props.user.adsViewedBalance; // always starts at one
        this.setState({ adBalance: balance, adCount: numAdsRequired });

        //PUT THIS BACK IN!!!!
        // if (!numAdsRequired) {
        //     sleep(200).then(() => {
        //         document.location = "/";
        //     })
        // }

        window.addEventListener('message', function (message) {
            if (message.data.type == "adblocker.detected") {
                this.props.stopAllContent();
                // alert("Ad Blocker Detected.  Please disable ad blockers or submit funds to play game");
                swal("Uh Oh. Looks like you have an Ad Blocker running", "Please disable any Ad Blockers to view this ad", "warning")
            }
            else if (message.data.type == "aip_rewardedgranted") {
                // Close the ad
                // this.props.stopAllContent();
                console.log("ads board detected aip_rewardedgranted event")
                var numAdsRequired = parseFloat(this.props.currentGame.entryValue) / serverEnvironment.adCost;
                var funds = 0;
                var slot = this.props.slot; // get slot # requested

                var balance = this.props.user.adsViewedBalance + 1;
                if (balance >= numAdsRequired) {

                    var player = [];
                    player.push(this.props.user);
                    player = player.slice();

                    var gameUser = {
                        gameid: this.props.currentGame.id,
                        id: this.props.user.id,
                        slot: slot,
                        isFinalized: true,
                        player: player[0]
                    }

                    // Credit the user with the ads watched
                    gameUser.player.adsViewedBalance = balance - numAdsRequired;

                    // Finalize the slot, if the user has enough ads
                    this.props.finalizeSlot(this.props.token, gameUser);

                    // navigate to the next page                    
                    sleep(1000).then(() => {
                        if (this.props.finalized) {
                            // alert("You are now entered!");
                            swal("Game Entry", "You are now entered!  You have been awarded " + String(this.props.currentGame.cannonsGiven ? this.props.currentGame.cannonsGiven : 5) + " cannon shots!", "success");
                            document.location = "/";
                        }
                    })
                }
                else {
                    var transaction = {
                        gameID: this.props.currentGame.id,
                        userID: this.props.user.id,
                        deltaFunds: funds,
                        deltaAds: 1,
                        deltaCannonBalls: 0,
                        deltaPoints: 0,
                        deltaGames: 0,
                        reserveSlot: slot,
                        isSlotFinalized: true
                    };

                    // Log this amount regardless
                    this.props.transactValue(this.props.token, transaction);

                    this.setState({ adBalance: balance, adCount: numAdsRequired });
                    // this.props.playAllContent();
                }
            }
        }.bind(this));
    }
    // mapAdsQueue(adsQueue) {
        /*
         * //NEED TO CHANGE THE REDUCER LOGIC SO THAT THE `playadcontent` VALUE IS TIED TO EACH SPECIFIC AD - FOR NOW KEEP AS-IS FOR UI SHELL DEV
        let adsList = adsQueue.map((ad, index) => {
            return (
                <Row className={css(styles.currentUserBar)}>

                    <Col className={css(styles.photoContainer)} md={3}>
                        <div className={css(styles.adThumbnailContainer)}>
                            <img src={(ad.adThumbnail)} className={css(styles.adThumbnail)} />
                        </div>
                    </Col>
                    <Col className={css(styles.adInfoContainer)} md={7}>
                        <div className={css(styles.adInfo)}>
                            <div className={css(styles.adInfoName)}>{ad.adName}</div>
                            <span className={css(styles.adInfoValue)}>{`+$${ad.userAdValue.toFixed(2)}`}</span>&nbsp;
                            {ad.videoAd ? (<span className={css(styles.adPlayTime)}>{` ${ad.adTime} Sec`}</span>) : (<span className={css(styles.adQuestionVariantText)}> Question</span>)}
                        </div>
                    </Col>
                    <Col className={css(styles.adPlayButtonContainer)} md={2}>
                        <div className={css(styles.adPlayButtonBox)}>
                            {!this.props.playadcontent ? <img className={css(styles.adPlayButton)} src={require('../../images/videoButton.svg')} onClick={this.onClickPlayAdContent} /> : <img className={css(styles.adStopButton)} src={require('../../images/stopButton.svg')} onClick={this.onClickStopAdContent}/>}
                        </div>
                    </Col>
                </Row>
               
            )
        }
        );
        return adsList;
        */
    //     return [];
    // }

    render() {
        var width = parseFloat(this.state.adBalance) / parseFloat(this.state.adCount);
        width = (width * 100).toFixed(0);

        var progress = { width: String(width) + '%' };

        return (
            <>
                <Container className={css(styles.adsboardGenInfo, styles.noSelect)}>
                    <MediaQuery maxWidth={1019}>
                        <ArrowBarLeft className={css(styles.closeSideMenuButton)} color={"#ffc400"} size={49} onClick={this.props.closeSideMenus} />
                    </MediaQuery>
                    <div className={css(styles.adsboardBox)}>
                        <img className={css(navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0 ? styles.adsboardGraphicSafari : styles.adsboardGraphic)} src={require('../../images/watchAdsIcon.png')} />
                        <div className={css(styles.earnCashText)}>WATCH ADS</div>
                    </div>
                    <div className={css(styles.adsboardBoxHidden)}>
                        <img className={css(navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0 ? styles.adsboardGraphicSafari : styles.adsboardGraphic)} src={require('../../images/watchAdsIcon.png')} />
                        <div className={css(styles.earnCashText)}>WATCH ADS</div>
                    </div>
                    <Row className={css(styles.valueEarnedRow)}>
                        <div className={css(styles.earnedAB)}>Watching</div>
                        <div className={css(styles.valueProgress)}>{`${this.state.adBalance + 1} of ${this.state.adCount}`}</div>
                    </Row>
                    <Row className={css(styles.adsProgressRow)}>
                        <div className={css(styles.adsProgressBase)}>
                            <div className={css(styles.adsProgressBar)} style={progress}></div>
                        </div>
                    </Row>
                    <Row>
                        <Link to="/"><Button className={css(styles.adsToHomeButton, styles.mqAdsToHomeButton)}>RETURN TO GAME LIST</Button></Link>
                    </Row>

                </Container>
                {/* <Container className={css(styles.adsList, styles.noSelect)}>
                    <Row className={css(styles.fadeBoxTop)}></Row> */}
                {/* UNCOMMENT ONCE THIS FEATURE IS ADDED BACK IN */}
                {/* <div className={css(styles.adsListContainer)}>
                        {this.mapAdsQueue(adsQueue.adsPlaylist)}
                    </div> */}
                {/* <Row className={css(styles.fadeBox)}></Row>
                </Container> */}
                <Container className={css(styles.tvBox, styles.noSelect, styles.mqtvBox)}>
                    <div className={css(styles.mqtvBoxContainer)}>
                        <div className={css(styles.noSelect, styles.tvBoxImg)}>
                            <img className={css(styles.mqtvBoxImg)} src={require("../../images/tv.svg")} />
                        </div>
                        <MediaQuery minHeight={606}><br /></MediaQuery>
                        <div className={css(styles.tvBoxText,)}>Watch all ads</div>
                        <div className={css(styles.tvBoxText)}>to earn entry</div>
                    </div>
                </Container>
                <Container className={css(styles.playlistControlSection, styles.noSelect)}>
                    <Row>
                        <div className={css(styles.buttonBoxAB)}>
                            {this.props.contentplaying ?
                                (<Button className={css(styles.buttonAB)} onClick={this.props.stopAllContent}>STOP ADS</Button>)
                                :
                                (<Button className={css(styles.buttonAB)} onClick={this.props.playAllContent}>PLAY ADS</Button>)
                            }
                        </div>
                    </Row>

                </Container>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        slot: state.gameReducer.slot,
        currentGame: state.gameReducer.currentGame,
        finalized: state.gameReducer.finalized,
        playadcontent: state.noPersistReducer.playAdContent,
        contentplaying: state.noPersistReducer.contentPlaying
    }
}

export default connect(mapStateToProps, {
    playAdContent,
    playAllContent,
    stopAdContent,
    stopAllContent,
    transactValue,
    finalizeSlot, 
    closeSideMenus
})(Adsboard);