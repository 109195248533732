import { blankGame, gameActionTypes, pending, rejected, fulfilled, serverEnvironment } from '../constants/ActionConstants';
import swal from 'sweetalert';

export default function reducer(state = {
    currentGame: blankGame,
    gamesList: [], 
    gameHistory: [],
    slot: 0,
    leaderboard: [],
    finalized: false,
    loadGames: true,
    loadLeaderboard: true,
    disableClick: false
}, action) {
    switch (action.type) {
        case fulfilled(gameActionTypes.CLEAR_ALL):
            return {
                ...state,
                currentGame: blankGame,
                gamesList: [],
                gameHistory: [],
                slot: 0,
                leaderboard: []
            }
        case fulfilled(gameActionTypes.CLEAR_CURRENT_GAME): 
            return {
                ...state, 
                currentGame: blankGame
            }
        case fulfilled(gameActionTypes.ENTER_GAME_VIEW):
            var currGame = blankGame; 
            for (var game = 0; game < state.gamesList.length; game++) {
                if (state.gamesList[game].id == action.payload) {
                    currGame = state.gamesList[game]; 
                    break;
                }
            }
            return {
                ...state, 
                message: `Entering Game ${action.payload}`,
                currentGame: currGame
            }
        case pending(gameActionTypes.FINALIZE_SLOT): 
            return { 
                ...state,
                finalized: false,
                disableClick: true,
                message: "Finalizing Slot"
            }
        case fulfilled(gameActionTypes.FINALIZE_SLOT):
            var games = state.gamesList;
            if (!action.payload.filled) {
                for (game = 0; game < state.gamesList.length; game++) {
                    if (games[game].id == action.payload.id) {
                        // games[game] = action.payload;
                        // games[game].userEntered = true;
                        action.payload.userEntered = true;
                        games.push(action.payload);
                        games.splice(game, 1); 
                        break;
                    }
                }
            }
            else {
                games = games.filter((function (e) {
                    return e.id !== action.payload.id;
                }));
            }
            return {
                ...state,
                gamesList : games,
                loadGames: true,
                finalized: true,
                disableClick: true, // they just entered successfully
                message: ""
            }
        case rejected(gameActionTypes.FINALIZE_SLOT):
            return {
                ...state,
                finalized: false,
                disableClick: false,
                message: action.payload.message
            }
        case fulfilled(gameActionTypes.GAMES_REFRESHED):
            return {
                ...state,
                loadGames: true,
                loadLeaderboard: true
            }
        case pending(gameActionTypes.GET_GAMES):
            return {
                ...state,
                loadGames: false,
                message: "Fetching all games"
            }
        case fulfilled(gameActionTypes.GET_GAMES):
            return {
                ...state,
                loadGames: true,
                gamesList: action.payload
            }
        case rejected(gameActionTypes.GET_GAMES): 
            return {
                ...state, 
                loadGames: false,
                message: action.payload.message
            }
        case pending(gameActionTypes.GET_GAME_HISTORY):
            return {
                ...state,
                message: "Fetching game history"
            }
        case fulfilled(gameActionTypes.GET_GAME_HISTORY):
            return {
                ...state,
                gameHistory: action.payload.data
            }
        case rejected(gameActionTypes.GET_GAME_HISTORY):
            return {
                ...state,
                message: action.payload.message
            }
        case pending(gameActionTypes.GET_LEADERBOARD): 
            return {
                ...state,
                loadLeaderboard: false,
                message: "Grabbing Leaderboard"
            }
        case fulfilled(gameActionTypes.GET_LEADERBOARD): 
            return {
                ...state, 
                loadLeaderboard: true,
                leaderboard: action.payload[0]
            }
        case rejected(gameActionTypes.GET_LEADERBOARD): 
            return {
                ...state, 
                loadLeaderboard: false,
                message: action.payload.message
            }
        case fulfilled(gameActionTypes.LEADERBOARD_REFRESHED):
            return {
                ...state,
                loadLeaderboard: false
            }
        case pending(gameActionTypes.REQUEST_SLOT): 
            return { 
                ...state, 
                disableClick: true,
                message: "Requesting Slot"
            }
        case fulfilled(gameActionTypes.REQUEST_SLOT):            
            return {
                ...state,
                currentGame: action.payload.data.data,
                slot: action.payload.slot,
                disableClick: false,
                message: ""
            }
        case rejected(gameActionTypes.REQUEST_SLOT): 
            if (action.payload.response && action.payload.response.status === 400) {
                // alert("This slot is already taken");  
                swal({
                    text: "This slot is already taken", 
                    icon: "warning"
                });
            }
            return {
                ...state, 
                slot: 0,
                disableClick: true, // they haven't requested the slot successfully
                message: action.payload.message
            }
        case pending(gameActionTypes.UNREQUEST_SLOT): 
            return {
                ...state, 
                disableClick: true,
                message: "Unrequesting Slot"
            }
        case fulfilled(gameActionTypes.UNREQUEST_SLOT):
            return {
                ...state,
                //currentGame: blankGame,
                slot: 0,
                disableClick: false,
                message: ""
            }
        case rejected(gameActionTypes.UNREQUEST_SLOT):
            return {
                ...state, 
                disableClick: false,  
                message: action.payload.message
            }
        case pending(gameActionTypes.ADD_USER_TO_LEADERBOARD): 
            return { 
                ...state,
                addingToLeaderboard: false,
                message: "Adding user to the leaderboard"
            }
        case fulfilled(gameActionTypes.ADD_USER_TO_LEADERBOARD):
            return {
                ...state,
                addingToLeaderboard: true,
                message: action.payload.message
            }
        case rejected(gameActionTypes.ADD_USER_TO_LEADERBOARD):
            return {
                ...state,
                addingToLeaderboard: false,
                message: action.payload.message
            }
        default:
            return state;
    }
};
