import { StyleSheet } from 'aphrodite';
import { findRenderedDOMComponentWithClass } from 'react-dom/test-utils';

export const styles = StyleSheet.create({
    landingOverlay: {
        zIndex: 10000000,
        backdropFilter: "blur(8px)",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        position: "fixed"
    },
    landingContentContainer: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        width: "100%"
    },
    landingContentBig: {
        color: "#FFC900",
        fontSize: "63px",
        fontFamily: "SF Pro Text Heavy", 
        letterSpacing: 3.32
       
    },
    landingContentSmall: {
        color: "#FFC900",
        fontSize: "35px",
        fontFamily: "SF Pro Text Heavy",
        letterSpacing: 1.84,
    },
    landingContentButton: {
        width: "268px",
        height: "75px",
        margin: "10px",
        background: "linear-gradient(270deg, #FFE300 0%, #FFC500 100%)",
        border: "none",
        borderRadius: 8,
        fontSize: "20px",
        letterSpacing: 0.79,
        fontFamily: "SF Pro Text Heavy",
        color: "#3A3933",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "none"
        }
    },
    landingFooterContainer: {
        position: "absolute",
        top: "90%",
        //left: "10%",
        // transform: "translate(-50%, -50%)",
        textAlign: "center",
        width: "100%"
    },
    landingFooterText: {
        fontSize: "20px",
        fontFamily: 'SF Pro Text Light'
    },
    landingFooterTextLink: {
        fontSize: 20, 
        fontFamily: 'SF Pro Text Semibold', 
        textDecorationColor: "#F9CA14", 
        color: "#F9CA14", 
        textDecoration: "underline"

    },
    landingAcceptButton: {
        width: "140px",
        height: "40px",
        margin: "10px",
        background: "linear-gradient(270deg, #FFE300 0%, #FFC500 100%)",
        border: "none",
        borderRadius: 4,
        fontSize: 20,
        letterSpacing: 0.79,
        fontFamily: "SF Pro Text Heavy",
        color: "#3A3933",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "none"
        }
    },
    mqlandingAcceptButton: {
        '@media (max-width: 1000px)': {
            width: 115, 
            fontSize: 18
        }, 
        '@media (max-width: 900px)': {
            width: 105,
            fontSize: 16
        }
    },
    mqLandingButtons: {
        marginTop: 40, 
        marginBottom: -70,
        '@media (max-width: 738px)': {
            marginTop: 40, 
            marginBottom: -70
        }
    },
    mqlandingContentBig: {
        '@media (max-width: 899px)': {
            fontSize: 50
        }, 
        '@media (max-width: 644px)': {
            fontSize: 42
        },
        '@media (max-width: 550px)': {
            fontSize: 32
        },
        '@media (max-width: 460px)': {
            fontSize: 30
        },
        '@media (max-width: 400px)': {
            fontSize: 28
        }
    },
    mqlandingContentSmall: {
        '@media (max-width: 899px)': {
            fontSize: 32
        }, 
        '@media (max-width: 550px)': {
            fontSize: 22
        }
    },
    mqlandingFooterContainer: {
        '@media (max-width: 785px)': {
            width: '90%', 
            left: '5%'
        }, 
        '@media (max-width: 443px)': {
            top: '85%'
        }
    }, 
    mqlandingFooterText: {
        '@media (max-width: 1000px)': {
            fontSize: 18
        }, 
        '@media (max-width: 900px)': {
            fontSize: 16
        }
    }
});