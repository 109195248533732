import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
    facebookLoginButton: {
        height: 42,
        borderRadius: 4,
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 15,
        letterSpacing: 0.79,
    },
    loginButton: {
        background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
        height: 32,
        border: "none",
        display: "flex",
        alignItems: "center",
        borderRadius: 4,
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        color: "#3A3934",
        left: '20px,',
        padding: "calc(.34435vw + 13.38843px) calc(.34435vw + 18.38843px)",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "none"
        },
        '@media (max-width: 425px)': {
            width: 195,
            justifyContent: 'center'
        }
    },
    loginButtonRow: {
        width: "100%",
        top: '25px'
        // display: 'flex', 
        // justifyContent: 'center'
    },
    loginButtonRowFB: {
        width: "100%",
        // display: 'flex', 
        justifyContent: 'center',
        marginLeft: 0,
        marginRight: 0,
        alignItems: 'center'
    },
    loginButtonRowPrimary: {
        width: "100%",
        top: '25px',
        justifyContent: 'center'
    },
    loginDivider: {
        width: '40%',
        borderTop: '1px solid #F9CA14',
        '@media (max-width: 991px)': {
            width: '30%'
        }
    },
    loginFormInput: {
        background: "none",
        border: "none",
        borderBottom: "1px solid #A1A1A1",
        borderRadius: 0,
        color: "#B6BCBF",
        fontSize: 17,
        letterSpacing: -0.41,
        "::placeholder": {
            color: "#B6BCBF"
        },
        ":-ms-input-placeholder": {
            color: "#B6BCBF"
        },
        "::-ms-input-placeholder": {
            color: "#B6BCBF"
        },
        ':-webkit-autofill': {
            transition: 'background-color 5000s ease-in-out 0s',
            WebkitTextFillColor: '#B6BCBF'
        },
        ':-webkit-autofill:hover': {
            transition: 'background-color 5000s ease-in-out 0s',
            WebkitTextFillColor: '#B6BCBF'
        },
        ':-webkit-autofill:focus': {
            transition: 'background-color 5000s ease-in-out 0s',
            WebkitTextFillColor: '#B6BCBF'
        },
        ':-webkit-autofill:active': {
            transition: 'background-color 5000s ease-in-out 0s',
            WebkitTextFillColor: '#B6BCBF'
        }
    },
    loginModalCloseButton: {
        position: 'absolute',
        right: '20px',
        backgroundColor: 'none',
        textDecoration: 'none'
    },
    loginModalForgotButton: {
        position: 'relative',
        textDecoration: 'underline',
        backgroundColor: 'transparent',
        border: 'none',
        ':hover': {
            cursor: 'pointer'
        },
        ':focus': {
            border: 'none',
            boxShadow: 'none'
        },
        color: '#FFC400',
        fontFamily: 'SF Pro Text Medium',
        marginTop: 16,
        marginBottom: -10,
    },
    loginPlndrLogo: {
        position: "absolute",
        right: 20,
        top: -8,
        '@media (max-width: 360px)': {
            width: 100,
            height: 96
        }
    },
    loginPopup: {
        zIndex: 10000000,
        backdropFilter: "blur(8px)",
    },
    loginPopupBackdrop: {
        zIndex: 10000000,
        backdropFilter: "blur(4px)"
    },
    loginTitle: {
        fontFamily: "SF Pro Text Heavy",
        color: "#FFCC00",
        letterSpacing: -0.84,
        fontSize: 35
    },
    ORTitle: {
        fontFamily: "SF Pro Text Heavy",
        color: "#FFCC00",
        position: 'relative',
        marginLeft: '25px',
        marginTop: '35px',
        letterSpacing: -0.84,
        fontSize: 20
    },
    modalBorder: {
        border: "none",
        padding: "1rem 1.5rem",
        position: "relative"
    },
    modalBorderLoginButtons: {
        border: "none",
        padding: "1rem 1.5rem",
        paddingTop: 0
    },
    mqCloseButton: {
        '@media (max-width: 425px)': {
            display: 'none'
        }
    },
    mqCloseButtonRow: {
        '@media (min-width: 426px)': {
            display: 'none'
        },
        '@media (max-width: 425px)': {
            marginTop: 12
        }
    },
    mqForgotButton: {
        '@media (max-width: 425px)': {
            justifyContent: 'center'
        }
    },
    mqforgotPasswordText: {
        '@media (max-width: 991px)': {
            fontSize: 26
        },
        '@media (max-width: 490px)': {
            fontSize: 20
        }
    }
});