import { StyleSheet } from 'aphrodite'; 

export const styles = StyleSheet.create({
    achievementIcon: {
        width: 30, 
        position: 'absolute', 
        top: '-10%',
        right: '-32%'
    },
    achievementIconFirst: {
        width: 45, 
        position: 'absolute', 
        top: '-10%',
        right: '-29%'
    },
    buttonBoxLB: {
        marginTop: 10, 
        marginBottom: 20,
        width: "100%", 
        display: "flex", 
        alignItems: "center", 
        alignContent: "center", 
        justifyContent: "center" 
    },
    buttonLB: {
        textDecoration: 'none',
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        width: "60%",
        height: 48,
        background: "linear-gradient(270deg, #FFE300 0%, #FFC500 100%)",
        color: "#3A3933",
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 15, 
        letterSpacing: 0.79,
        textAlign: "center",
        border: "none", 
        borderRadius: 8,
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "0 0 0 0.15rem #50504a"
        } 
    },
    buttonLBGlow: {
        textDecoration: 'none',
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        width: "60%",
        height: 48,
        background: "linear-gradient(270deg, #FFE300 0%, #FFC500 100%)",
        color: "#3A3933",
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 15, 
        letterSpacing: 0.79,
        textAlign: "center",
        border: "1px solid #ffe483", 
        borderRadius: 8,
        boxShadow: "#ffc107 0px 1px 8px 4px, rgb(0 0 0 / 16%) 0px 4px 16px 2px",
        ":focus:active": {
            boxShadow: "0 0 0 0.15rem #50504a"
        } 
    },
    cannonShotValue: {
        position: 'absolute', 
        top: '30%', 
        width: '36%', 
        right: '31%',
        textAlign: 'center', 
        fontSize: 27, 
        letterSpacing: -0.65,
        color: "#FFE400", 
        fontFamily: 'SF Pro Text Heavy'
    },
    closeSideMenuButton: {
        position: 'absolute', 
        top: 9, 
        left: 9, 
        border: '2px solid', 
        borderRadius: 8, 
        padding: 5,
        zIndex: 1, 
        ':hover': {
            cursor: 'pointer'
        }
    },
    countdownBar: {
        position: 'absolute', 
        height: 9, 
        // width: '25%', 
        background: 'linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)'

    },
    countdownBase: {
        background: 'linear-gradient(270deg, #666666 0%, #3A3933 100%)',
        width: '100%', 
        height: 9,
        position: 'relative'
    },
    countdownRow: {
        marginTop: 5
    },
    currentUserBar: {
        position: 'relative',
        paddingTop: 5,
        paddingBottom: 5,
    },
    currentUserBarFirst: {
        position: 'relative',
        paddingTop: 20,
        paddingBottom: 5,
    },
    currentUserSection: {
        position: "absolute", 
        bottom: 0,
        height: '22.3%'
    },
    fadeBox: {
        position: 'absolute', 
        bottom: 0, 
        height: '10%', 
        width: '100%',
        background: 'linear-gradient(#3a393400,#3a3934)'
    },
    fadeBoxTop: {
        zIndex: 1,
        position: 'absolute', 
        top: 0, 
        height: 20, 
        width: '100%',
        background: 'linear-gradient(#3a3934,#3a393400)'
    },
    fixedBoxContainer: {
        position: "relative", 
        height: "82vh", 
        width: 350,
        // width: '24.3vw', 
        backgroundColor: "#3A3934", 
        borderRadius: 8,
        boxShadow: "0 0 6px rgba(0,0,0,0.16), 0 6px 6px 0 rgba(0,0,0,0.16)"
    },
    hiddenMenu: {
        visibility: 'hidden'
    },
    leaderboardGenInfo: {
        height: "30%"
    }, 
    leaderboardPhoto: {
        width: 48,
        borderRadius: 50,
        margin: "0 auto"
    },
    leaderboardPhotoFirst: {
        width: 77,
        borderRadius: 50,
        margin: "0 auto"
    },
    leaderboardPhotoContainer: {
        position: 'relative'
    },
    leaderboardPrizeBox: {
        display: 'flex', 
        justifyContent: 'center', 
        position: 'absolute',
        width: '91%', 
        marginTop: -13
    },
    leaderboardPrizeBoxHidden: {
        visibility: 'hidden',
        display: 'flex', 
        justifyContent: 'center', 
        position: 'relative'
    },
    leaderboardPrizeGraphic: {
        width: "62.5%", 
    },
    leaderboardPrizeGraphicSafari: {
        height: 134
    },  
    leaderboardPrizeValue: {
        position: 'absolute', 
        color: '#FAC51C', 
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 25, 
        letterSpacing: -0.79,
        transform: 'rotate(-12.5deg)',
        textAlign: 'center',
        top: '27%', 
        right: '38%', 
        width: '26%'
    },
    leaderBox: {
        marginLeft: 40
    },
    leaderList: {
        height: '47.7%',
        position: 'relative'
    },
    leaderListContainer: {
        height: '100%',
        overflowY: 'scroll', 
        overflowX: 'hidden', 
        scrollbarWidth: 'none', 
        MsOverflowStyle: 'none',
        '::-webkit-scrollbar': {
            width: 0, 
            height: 0
        }
    },
    myUser: {
        backgroundColor: "#3a3933", 
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid',
    },
    newLeaderboard: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '100%',
        fontFamily: 'SF Pro Text Medium', 
        fontSize: 15, 
        color: '#ffc400',
        letterSpacing: 0.79, 
        fontWeight: 300,
        textAlign: 'center'
    },
    noSelect: {
        WebkitTouchCallout: 'none', /* iOS Safari */
        WebkitUserSelect: 'none', /* Safari */
        KhtmlUserSelect: 'none', /* Konqueror HTML */
        MozUserSelect: 'none', /* Old versions of Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */
        userSelect: 'none' /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    },
    notLoggedIn: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '100%',
        fontFamily: 'SF Pro Text Medium', 
        fontSize: 15, 
        color: '#ffc400',
        letterSpacing: 1, 
        fontWeight: 300,
        textAlign: 'center'
    }, 
    numberContainer: {
        position: "relative"
    },
    photoContainer: {
        display: "flex", 
        alignContent: "center", 
        alignItems: "center",
        marginRight: -5,
        marginLeft: 5
    },
    photoContainerFirst: {
        display: "flex", 
        alignContent: "center", 
        alignItems: "center",
        marginRight: -5,
        marginLeft: -10
    },
    rankingContainer: {
        display: "flex", 
        alignContent: "center", 
        alignItems: "center"
    },
    shotsGraphic: {
        width: 122
    },
    shotsGraphicContainer: {
        position: 'absolute', 
        right: 0, 
        bottom: -10 
    },
    timerDays: {

    },
    timerGrid: {
        width: '100%', 
        display: 'grid', 
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
    },
    timerGroup: {

    },
    timerNumber: {
        textAlign: 'center',
        color: "#FAC51C", 
        fontSize: 19, 
        letterSpacing: 1, 
        fontFamily: 'SF Pro Text Heavy'
    },
    timerRow: {
        marginTop: -16
    },
    timerText: {
        textAlign: 'center',
        color: "#FAC51C", 
        fontSize: 11, 
        fontFamily: 'SF Pro Text Light'
    },
    userCurrentPointsLB: {
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 19, 
        letterSpacing: 1, 
        background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text", 
        WebkitTextFillColor: "transparent",
        marginTop: 1
    },
    userCurrentPointsLBFirst: {
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 35, 
        letterSpacing: 1.84, 
        background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text", 
        WebkitTextFillColor: "transparent",
        marginTop: 1
    },
    usernameLB: {
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 13, 
        letterSpacing: 0.68, 
        background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text", 
        WebkitTextFillColor: "transparent",
        marginTop: -3
    },
    userInfo: {
        position: 'absolute', 
        left: 0
    },
    userInfoContainer: {
        position: 'relative'
    },
    userInfoContainerFirst: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    userLeaderboardRanking: {
        margin: "0 auto",
        background: "linear-gradient(to right, #fac91f, #ffde01)",
        WebkitBackgroundClip: "text", 
        WebkitTextFillColor: "transparent",
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 19, 
        letterSpacing: 1, 
    }, 
    mqbuttonLB: {
        "@media (max-height: 550px)": {
            height: 38
        }
    }, 
    mqbuttonLBLogged: {
        "@media (max-height: 725px)": {
            height: 40
        }, 
        "@media (max-height: 650px)": {
            height: 36
        }, 
        "@media (max-width: 375px)": {
            fontSize: 13
        }
    },
    mqbuttonBoxLB: {
        "@media (max-height: 600px)": {
            marginTop: 0
        }
    },
    mqbuttonBoxLBLogged: {
        "@media (max-height: 725px)": {
            marginTop: 3
        }, 
        "@media (max-height: 650px)": {
            marginTop: 0 
        }
    },
    mqcurrentUserBar: {
        "@media (max-height: 600px)": {
            paddingTop: 3
        }
    },
    mquserInfo: {
        "@media (max-width: 450px)": {
            left: 15
        }
    }, 
    mqleaderList: {
        "@media (max-height: 630px)": {
            height: '44.7%', 
            marginTop: 15
        }, 
        "@media (max-height: 565px)": {
            height: "42%", 
            marginTop: 26
        }, 
        '@media (max-height: 525px)': {
            height: '40%', 
            marginTop: 35
        }
    }, 
    mquserInfoContainerFirstTut: {
        '@media (max-width: 799px)': {
            left: 15
        }
    }, 
    mquserInfoContainerTut: {
        '@media (max-width: 799px)': {
            left: 22
        }
    }, 
    mqleaderboardPrizeValueTut: {
        '@media (max-width: 799px)': {
            top: '22%'
        }
    }, 
    mquserInfoTut: {
        '@media (max-width: 799px)': {
            left: 8
        }
    }, 
    mqleaderListTut: {
        '@media (max-width: 615px)': {
            marginTop: -35
        }, 
        '@media (max-width: 499px)': {
            marginTop: -50
        }
    }
});