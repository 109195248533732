import { StyleSheet } from 'aphrodite'; 

export const styles = StyleSheet.create({
    addFundsIcon: {
        width: 24,
        height: 24
    },
    closeSideMenuButton: { 
        marginTop: 9, 
        marginLeft: 9, 
        border: '2px solid', 
        borderRadius: 8, 
        padding: 5,
        zIndex: 1, 
        ':hover': {
            cursor: 'pointer'
        }
    },
    menuContainer: {
        position: 'absolute',
        right: 15, 
        zIndex: 1000000, 
        top: '100%', 
        backgroundColor: "#3A3934", 
        borderRadius: 8,
        boxShadow: "0 0 6px rgba(0,0,0,0.16), 0 6px 6px 0 rgba(0,0,0,0.16)",
        width: '26.1vw', 
        height: '85vh',
        minWidth: 291
    },
    menuDivider: {
        // backgroundColor: '#737272',
        backgroundColor: '#BCBBC1',
        marginTop: '0.5rem', 
        marginBottom: '0.5rem'
    },
    menuMobileMyProfile: {
        color: "#FFCC00", 
        fontFamily: 'SF Pro Text Medium', 
        fontSize: 15, 
        letterSpacing: 0.1
    },
    menuSubDivider: {
        backgroundColor: '#BCBBC1',
        marginTop: '0.8rem', 
        marginBottom: '0.8rem'
    },
    menuIconCol: {
        display: 'flex', 
        alignItems: 'center', 
        alignContent: 'center', 
        justifyContent: 'center'
    },
    menuOptionCol: {
        paddingLeft: 0,
        color: "#FFCC00", 
        fontFamily: 'SF Pro Text Medium', 
        fontSize: 15, 
        letterSpacing: 0.1
    },
    menuProfilePhoto: {
        height: 64,
        width: 64, 
        borderRadius: 50
    },
    menuRow: {
        width: '100%',
        margin: '0 auto',
        paddingTop: '2%', 
        paddingBottom: '2%'
    },
    menuRowOption: {
        ':hover': {
            cursor: 'pointer'
        }
    },
    menuRowSocials: {
        width: '100%',
        margin: '0 auto',
        paddingTop: '7%', 
        paddingBottom: '2%',
        // position: 'absolute', 
        // bottom: 25
    },
    menuRowSubSelect: {
        background: "rgba(250, 197, 28, 0.25) !important"
    },
    menuUserName: {
        color: '#FFCC00', 
        fontFamily: 'SF Pro Display', 
        fontWeight: 'bold',
        letterSpacing: 0.38,
        wordBreak: "break-all"
    },
    menuUserNameCol: {
        paddingLeft: 0, 
        display: 'flex', 
        alignItems: 'center'
    },
    menuUserRow: {
        paddingTop: '5%', 
        paddingBottom: '5%',
        marginBottom: '3%',
        ":hover": {
            backgroundColor: "rgba(250,197,28,0.25)",
            borderTopLeftRadius: 8, 
            borderTopRightRadius: 8,
        }
    },
    menuUserRowUserNameCol: {
        ":hover": {
            textDecoration: "underline",
            cursor: 'pointer'
        }
    },
    noSelect: {
        WebkitTouchCallout: 'none', /* iOS Safari */
        WebkitUserSelect: 'none', /* Safari */
        KhtmlUserSelect: 'none', /* Konqueror HTML */
        MozUserSelect: 'none', /* Old versions of Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */
        userSelect: 'none' /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    },
    rowContainer: {
        position: 'relative'
    },
    supportMenuLink: {
        ':hover': {
            textDecoration: 'none'
        }
    }, 
    mqmenuContainer: {
        border: '1px solid',
        boxShadow: "-3px 3px 14px 2px rgb(0 0 0 / 30%), 0 2px 4px 0 rgb(0 0 0 / 10%)", 
        '@media (max-width: 699px)': {
            border: '1px solid',
            boxShadow: "-3px 3px 14px 2px rgb(0 0 0 / 50%), 0 2px 4px 0 rgb(0 0 0 / 10%)"
        }, 
        '@media (max-width: 450px)': {
            minWidth: 260, 
            width: 260
        }
    },
    mqmenuMobileMyProfile: {
            '@media (max-width: 1299px)': {
                marginLeft: 10
            }, 
            '@media (max-width: 1150px)': {
                marginLeft: 20
            }, 
            '@media (max-width: 1000px)': {
                marginLeft: 0
            }
    },
    mqmenuUserName: {
        '@media (max-width: 1299px)': {
            marginLeft: 10
        }, 
        '@media (max-width: 1150px)': {
            marginLeft: 20
        }, 
        '@media (max-width: 1000px)': {
            marginLeft: 0
        }
    }
});