import { paymentActionTypes, menuActionTypes, gameActionTypes, loginActionTypes, pending, rejected, fulfilled } from '../constants/ActionConstants';

export default function reducer(state = {
    clientToken: '',
    paymentTransactionID: '',
    paymentTransactionAmount:0.0,
    paymentStatus:'',
    paymentHistory: [],
    transactPayment:false,
    message: 'Payment',
    gotClientToken: false,
    gettingClientToken: false
}, action) {
    switch (action.type) {
        case fulfilled(paymentActionTypes.CLEAR_PAYMENT_INFO):
            return {
                ...state,
                clientToken: '',
                paymentTransactionID: '',
                paymentTransactionAmount: 0.0,
                paymentStatus:'',
                paymentHistory: [],
                transactPayment:false,
                message: 'Clear Payment Information',
                gotClientToken: false
            }
        case fulfilled(menuActionTypes.OPEN_ADD_CREDITS):
            return {
                ...state,
                transactPayment: false
            }
        case fulfilled(menuActionTypes.CLOSE_ADD_CREDITS):
            return {
                ...state,
                transactPayment: false
            }
        case fulfilled(gameActionTypes.UPDATE_USER): 
            return {
                ...state, 
                transactPayment: true,
                user: action.payload
            }
        case pending(paymentActionTypes.MAKE_PAYMENT):
            return {
                ...state,
                transactPayment: false,
                message: "Adding Funds"
            }
        case rejected(paymentActionTypes.MAKE_PAYMENT):
            return {
                ...state,
                transactPayment: false,
                message: action.payload.message
            }
        case fulfilled(paymentActionTypes.MAKE_PAYMENT): 
            return {
                ...state, 
                transactPayment: false,
                paymentTransactionID: action.payload.data.payments[0].processorAuthorizationCode,
                paymentTransactionAmount: action.payload.data.payments[0].amount,
                paymentStatus: action.payload.data.payments[0].processorResponseText,
                message: action.payload.message
            }
        case pending(paymentActionTypes.ADD_PAYPAL_PAYMENT):
            return {
                ...state,
                transactPayment: false,
                message: "Adding Paypal Funds"
            }
        case rejected(paymentActionTypes.ADD_PAYPAL_PAYMENT):
            return {
                ...state,
                transactPayment: false,
                message: action.payload.message
            }
        case fulfilled(paymentActionTypes.ADD_PAYPAL_PAYMENT): 
            return {
                ...state, 
                transactPayment: false,
                paymentTransactionID: action.payload.data.payments[0].processorAuthorizationCode,
                paymentTransactionAmount: action.payload.data.payments[0].amount,
                paymentStatus: action.payload.data.payments[0].processorResponseText,
                message: action.payload.message
            }
        case pending(paymentActionTypes.GET_TOKEN):
            return {
                ...state,
                gotClientToken: false,
                gettingClientToken: true,
                message: "Getting Token"
            }
        case rejected(paymentActionTypes.GET_TOKEN):
            return {
                ...state,
                gotClientToken: false,
                gettingClientToken : false,
                message: action.payload.message
            }
        case fulfilled(paymentActionTypes.GET_TOKEN):
            return {
                ...state,
                gotClientToken: true,
                gettingClientToken: false,
                clientToken: action.payload,
                message: action.payload.message
            }
        case pending(paymentActionTypes.GET_PAYMENT_HISTORY):
                return {
                    ...state,
                    message: "Fetching payment history"
                }
        case fulfilled(paymentActionTypes.GET_PAYMENT_HISTORY):
                return {
                    ...state,
                    paymentHistory: action.payload.data
                }
        case rejected(paymentActionTypes.GET_PAYMENT_HISTORY):
                return {
                    ...state,
                    message: action.payload.message
                }
        default:
            return state;
    }
};
