import React from 'react';
import { Button, Modal, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { css } from 'aphrodite';
import { styles } from './PlayerWheelStyles.js';
import './gameEntryMenu.css';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { finalizeSlot, transactValue, clearCurrentGame } from '../../actions/GameActions';
import { showReferCode } from '../../actions/MenuActions.js';
import { sleep } from '../../constants/ActionConstants';
import swal from 'sweetalert';

class GameEntryMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

        this.paymentEntry = this.paymentEntry.bind(this);
        this.finalizeSlot = this.finalizeSlot.bind(this);
    }

    paymentEntry() {

        if (this.props.currUser.balance >= this.props.currentgame.entryValue) {

            var player = [];
            player.push(this.props.currUser);
            player = player.slice();

            var payload = {
                gameid: this.props.currentgame.gameid,
                slot: this.props.selectedslot,
                id: this.props.currUser.id,
                player: player[0]
            }
            payload.player.balance = this.props.currUser.balance - this.props.currentgame.entryValue;

            // Finalize the slot, if the user has enough ads
            this.props.finalizeSlot(this.props.loginToken, payload);
        }
        else {
            var bal = this.props.currUser.balance;
            if (!bal) {
                bal = 0;
            }
            // alert('Insufficient Funds - Please deposit $' + (this.props.currentgame.entryValue - bal).toFixed(2) + ' or more to enter.');
            swal({
                text: `Insufficient Funds - Please deposit ${(this.props.currentgame.entryValue - bal).toFixed(2)} or more to enter.`,
                icon: "error"
            });
        }
    }

    finalizeSlot() {
        this.props.clearCurrentGame();
    }

    render() {
        if (this.props.finalized) {
            this.finalizeSlot();
            // navigate to the next page                    
            sleep(1000).then(() => {
                if (this.props.finalized) {
                    // alert("You are now entered!");
                    swal({
                        title: "Game Entry",
                        text: "You are now entered to win $" + String(this.props.currentgame.prizeValue) + "!  You have been awarded " + String(this.props.currentgame.cannonsGiven ? this.props.currentgame.cannonsGiven : 5) + " cannon shots!",
                        icon: "success"
                    }).then((value) => {
                        if (this.props.currentgame.filled) {
                            document.location = window.location.href;
                        }
                        else {
                            document.location = "/";
                        }
                    });

                }
            })
        }

        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                animation={false}
                className={css(styles.gameEntryModal)}
            >
                <Modal.Header className={css(styles.gameEntryHeader)}>
                    <Modal.Title className={css(styles.gameEntryTitle)} id="contained-modal-title-vcenter">
                        Game Entry Method
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={css(styles.gameEntryBody)}>
                    <p className={css(styles.gameEntrySubtitle)}>
                        How would you like to enter this game?
                    </p>
                    <Row style={{ paddingBottom: 20 }}>
                        <Col md={2}>
                        </Col>
                        <Col className={css(styles.addFundsBox)} md={4}>
                            <img className={css(styles.addFundsGraphic)} src={require("../../images/addFundsGraphic.svg")} />
                            <Button className={css(styles.addFundsButton, styles.gameEntryButton)} disabled={this.props.disableClick} onClick={() => this.paymentEntry()}>
                                USE CREDITS
                            </Button>
                        </Col>
                        {/* <Col className={css(styles.addFundsBox)} md={4}>
                            <img className={css(styles.watchAdsGraphic)} src={require("../../images/watchAdsIcon.png")} />
                            <Link className={css(styles.addFundsButton)} to="/ads">
                                <Button className={css(styles.gameEntryButton)}>
                                    WATCH ADS
                                </Button>
                            </Link>
                        </Col> */}
                        <Col className={css(styles.addFundsBox)} md={4}>
                            <img className={css(styles.watchAdsGraphic)} src={require("../../images/watchAdsIcon.png")} />
                            <Button className={css(styles.gameEntryButton, styles.addFundsButton, styles.gameEntryButtonWide)} onClick={() => this.props.showReferCode()}>
                                REFER A FRIEND
                            </Button>
                        </Col>
                        <Col md={2} className={css(styles.addFundsBox)}>
                            <div className={css(styles.goBackButton)}>
                                <a className={css(styles.closeGameEntry)} onClick={this.props.onHide}>GO BACK</a>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        );

    }
}

function mapStateToProps(state) {
    return {
        loginToken: state.loginReducer.loginToken,
        currUser: state.loginReducer.user,
        currentgame: state.gameReducer.currentGame,
        finalized: state.gameReducer.finalized,
        disableClick: state.gameReducer.disableClick
    }
}

export default connect(mapStateToProps, {
    transactValue,
    finalizeSlot,
    clearCurrentGame,
    showReferCode
})(GameEntryMenu); 