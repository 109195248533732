import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
    avatarButtonsRow: {
        position: 'absolute',
        right: 0,
        marginRight: 5,
        bottom: 15
    },
    avatarModal: {
        zIndex: "1000000"
    },
    avatarSelectionButtonAlt: {
        padding: ".375rem 1.5rem"
    },
    avatarSelectionTitle: {
        color: "#FFCC00",
        fontFamily: "SF Pro Text Heavy",
        fontSize: 35,
        letterSpacing: -0.84,
        marginTop: 15,
        '@media (max-width: 500px)': {
            fontSize: 26
        }
    },
    avatarSelectionRow: {
        marginTop: 40,
        marginBottom: 70
    },
    cropImageRow: {
        marginTop: 10,
        marginBottom: 70
    },
    dataBox: {
        borderRadius: 8,
        backgroundColor: "#3A3934",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        marginTop: 20,
        marginRight: 20
    },
    dataBoxTop: {
        borderRadius: 8,
        backgroundColor: "#3A3934",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        marginRight: 20
    },
    dataBoxHeader: {
        color: "#858A8C",
        fontFamily: 'SF Pro Text Medium',
        fontSize: 12,
        letterSpacing: 0.07
    },
    dataBoxRow: {
        marginTop: 16,
        marginBottom: 16
    },
    dataBoxSpacer: {
        marginTop: 26
    },
    dataBoxTitle: {
        color: "#858A8C",
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 15,
        letterSpacing: 0.79,
        marginTop: 20
    },
    gameHistoryTitle: {
        color: "#F9CA14",
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 55,
        letterSpacing: 3.32
    },
    histContainer: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        maxHeight: '90.8vh',
        '::-webkit-scrollbar': {
            WebkitAppearance: 'none',
            width: 8
        },
        '::-webkit-scrollbar-thumb': {
            backgroundColor: '#ffc501',
            borderRadius: 8,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#ffc501',
            borderImage: 'initial'
        },
        '::-webkit-scrollbar-track': {
            backgroundColor: '#ffc5010d',
            borderRadius: 8,
            borderColor: "#ffc501",
            borderWidth: 0.5
        }
    },
    imageUploadButton: {
        width: 126.41, 
        height: 37, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        ':hover': {
            cursor: 'pointer'
        }
    },
    noSelect: {
        WebkitTouchCallout: 'none', /* iOS Safari */
        WebkitUserSelect: 'none', /* Safari */
        KhtmlUserSelect: 'none', /* Konqueror HTML */
        MozUserSelect: 'none', /* Old versions of Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */
        userSelect: 'none' /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    },
    profileModalAvatar: {
        borderRadius: 68,
        width: 124
    },
    profileModalButton: {
        background: 'none',
        border: "2px solid #FFCC00",
        borderRadius: 4,
        fontFamily: 'SF Pro Text Medium',
        fontSize: 14,
        letterSpacing: 0.79,
        color: "#FFCC00",
        ":focus": {
            boxShadow: "none",
            border: "2px solid #FFCC00"
        }
    },
    viewResultButton: {
        background: 'none',
        border: "2px solid #FFCC00",
        borderRadius: 4,
        fontFamily: 'SF Pro Text Medium',
        fontSize: 14,
        letterSpacing: 0.79,
        color: "#FFCC00",
        position: 'absolute',
        right: 15,
        bottom: 15,
        ":focus": {
            boxShadow: "none",
            border: "2px solid #FFCC00"
        }
    },
    avatarSaveButton: {
        background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
        border: '2px solid #FFCC00',
        borderRadius: 4,
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 14,
        letterSpacing: 0.79,
        color: "#3A3934",
        ":focus": {
            boxShadow: "none",
            border: "2px solid #FFCC00"
        }
    },
    avatarSaveButtonDisabled: {
        background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
        border: '2px solid #FFCC00',
        borderRadius: 4,
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 14,
        letterSpacing: 0.79,
        opacity: 0.35,
        color: "#3A3934",
        ":focus": {
            boxShadow: "none",
            border: "2px solid #FFCC00"
        }, 
        ":hover": {
            cursor: 'default'
        }
    },
    profileModalButtonAlt: {
        padding: ".375rem .90rem"
    },
    profileModalButtonEdit: {
        marginTop: 10,
        marginRight: 15,
        position: 'absolute',
        right: 0
    },
    profileModalFormInput: {
        paddingLeft: 0,
        height: "unset",
        // background: "none",
        border: "none",
        borderBottom: "1px solid #A1A1A1",
        borderRadius: 0,
        color: "#B6BCBF",
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 15,
        letterSpacing: -0.41,
        background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        "::placeholder": {
            color: "#B6BCBF"
        },
        ":-ms-input-placeholder": {
            color: "#B6BCBF"
        },
        "::-ms-input-placeholder": {
            color: "#B6BCBF"
        }
    },
    profileModalSaveButton: {
        background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
        border: "none",
        borderRadius: 4,
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        color: "#3A3934",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "none"
        }
    },
    pushBottom: {
        marginTop: 30
    },
    selectAvatar: {
        width: 124,
        '@media (max-width: 405px)': {
            width: 115
        },
        '@media (max-width: 383px)': {
            width: 105
        }
    },
    selectAvatarChosen: {
        border: "5px solid #F8D400",
        borderRadius: 70
    },
    selectAvatarDarken: {
        opacity: 0.5
    },
    toHomeButton: {
        position: 'fixed',
        bottom: '8%',
        right: '19%',
        background: "none",
        color: "#FFCC00",
        border: "2px solid #FFCC00",
        // background: 'linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)',
        // color: "#3A3933",
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        // border: "none",
        borderRadius: 8,
        height: 45,
        width: 220,
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "0 0 0 0.15rem #50504a"
        }
    },
    toHomeButtonMobile: {
        margin: '0 auto',
        background: "none",
        color: "#FFCC00",
        border: "2px solid #FFCC00",
        // background: 'linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)',
        // color: "#3A3933",
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        // border: "none",
        borderRadius: 8,
        height: 38,
        width: 161,
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "0 0 0 0.15rem #50504a"
        }
    },
    valueText: {
        color: "#FFCC00",
        fontFamily: "SF Pro Text Medium",
        fontSize: 16,
        letterSpacing: -0.41
    },
    withdrawContactInfo: {
        wordBreak: 'break-all'
    },
    asOfDate: {
        // height: 18,
        // width: 202,
        color: "#F9CA14",
        fontFamily: "SF Pro Text Heavy",
        fontSize: 15,
        // fontWeight: 900,
        letterSpacing: 0.79,
        //lineHeight: 18
    },
    referCodeCopyBtn: {
        background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
        border: '2px solid #FFCC00',
        borderRadius: 4,
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 14,
        letterSpacing: 0.79,
        color: "#3A3934",
        ":focus": {
            boxShadow: "none",
            border: "2px solid #FFCC00"
        }
    },  
    referCodeDivider: {
        width: '40%',
        borderTop: '1px solid #F9CA14',
        '@media (max-width: 991px)': {
            width: '30%'
        }
    }, 
    referCodeInput: {
        background: "none",
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        borderImage: "initial",
        borderBottom: "1px solid rgb(161, 161, 161)",
        borderRadius: 0,
        color: "rgb(182, 188, 191)",
        fontSize: 17,
        letterSpacing: -0.41, 
        ':focus': {
            // border: "none",
            outline: "none"
        }
    },
    referCodeLinkText: {
        margin: '0 auto', 
        textAlign: 'center'
    },
    mqdataBoxCol: {
        '@media (max-width: 575px)': {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    mqdataBoxGame: {
        '@media (max-width: 575px)': {
            width: 280
        }
    },
    mqdataBoxPayment: {
        '@media (max-width: 575px)': {
            width: 280
        }
    },
    mqdataBoxPayout: {
        '@media (max-width: 575px)': {
            width: 280
        }
    },
    mqeditProfileTitle: {
        '@media (max-width: 520px)': {
            fontSize: 42
        },
        '@media (max-width: 395px)': {
            fontSize: 40
        },
        '@media (max-width: 370px)': {
            fontSize: 38
        },
        '@media (max-width: 349px)': {
            fontSize: 36
        },
    },
    mqgameHistoryRow: {
        '@media (max-width: 1019px)': {
            justifyContent: 'center'
        }
    },
    mqgameHistoryText: {
        '@media (max-width: 800px)': {
            fontSize: 50
        },
        '@media (max-width: 650px)': {
            fontSize: 52,
            lineHeight: '55px',
            marginBottom: 20
        },
        '@media (min-width: 551px) and (max-width: 575px)': {
            fontSize: 55,
            lineHeight: '55px',
            marginBottom: 15
        },
        '@media (max-width: 498px)': {
            fontSize: 46
        },
        '@media (max-width: 400px)': {
            fontSize: 42
        }
    },
    mqgameHistoryTitle: {
        '@media (max-width: 1019px)': {
            textAlign: 'center'
        },
        '@media (max-width: 349px)': {
            fontSize: 14
        },
    },
    mqhistContainer: {
        '@media (max-width: 575px)': {
            maxHeight: '81.8vh'
        }
    },
    mqgamehistContainer: {
        '@media (max-width: 575px)': {
            maxHeight: '77.8vh'
        }
    },
    mqprofilecol1: {
        '@media (max-width: 377px)': {
            paddingLeft: 1
        }
    },
    mqprofileContainer: {
        '@media (max-width: 575px)': {
            maxHeight: '77.8vh'
        }
    },
    mqPaymentHistoryItem: {
        '@media (max-width: 575px)': {
            justifyContent: 'center'
        }
    },
    mqpaymentHistoryTitle: {
        '@media (max-width: 1062px) and (min-width: 1020px)': {
            fontSize: 52
        },
        '@media (max-width: 843px)': {
            fontSize: 42
        },
        '@media (max-width: 768px)': {
            fontSize: 36
        },
        '@media (max-width: 590px)': {
            fontSize: 43,
            lineHeight: '48px'
        },
        '@media (max-width: 440px)': {
            fontSize: 34
        },
        '@media (max-width: 349px)': {
            fontSize: 29,
            lineHeight: '40px'
        }
    },
    mqtoHomeButtonProfile: {

    },
    mqwithdrawHistoryTitle: {
        '@media (max-width: 1300px)': {
            fontSize: 46
        },
        '@media (max-width: 775px)': {
            fontSize: 42
        },
        '@media (max-width: 600px)': {
            fontSize: 36
        },
        '@media (max-width: 590px)': {
            lineHeight: '48px'
        },
        '@media (max-width: 500px)': {
            fontSize: 32
        },
        '@media (max-width: 420px)': {
            fontSize: 29,
            lineHeight: '40px'
        },
        '@media (max-width: 400px)': {
            fontSize: 24
        }
    },
    mqPickAvCol: {
        '@media (max-width: 767px)': {
            marginTop: 15
        }
    }
});