// GameHistoryModal
import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Label from 'react-bootstrap/FormLabel';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { styles } from './ModalStyles.js';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';

class GameHistoryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.backToPlndr = this.backToPlndr.bind(this);
        this.viewResult = this.viewResult.bind(this);
    }

    componentDidMount() {
        // console.log(this.props.gameHistory);
    }

    mapGameHistory(gameHistory) {
        if (gameHistory) {
            let gameHistoryQueue = gameHistory.map((j, r) => {
                return (
                    <Row key={r} className={css(styles.mqPaymentHistoryItem)}>
                        <Col className={css(styles.mqdataBoxCol)} md={5} sm={6} xs={11}>
                            <div className={css(r == 0 ? [styles.dataBoxTop, styles.mqdataBoxGame] : [styles.dataBox, styles.mqdataBoxGame])}>
                                {/* {
                                j.winnerName ?
                                    (j.winnerName === this.props.currUser.name ?
                                        "YOU WON " + (j.gameType ? j.gameType : "") + " for $" + String(j.reward.toFixed(2)) + " on " + j.endDate.substring(0, 10) + (j.points ? " with " + String(j.points) : " points") + "!!" :
                                        j.winnerName + " won " + (j.gameType ? j.gameType : "") + " for $" + String(j.reward.toFixed(2)) + " on " + j.endDate.substring(0, 10) + (j.points ? "; You ranked #" + String(j.rank) + " with " + String(j.points) + " points" : "") + "!!")
                                    :
                                    ("You entered a $" + String(j.reward.toFixed(2)) + " " + (j.gameType ? j.gameType : "") + " for $" + String(j.cost.toFixed(2)) + " on " + j.entryDate.substring(0, 10)
                                    )
                            } */}
                                {(j.gameType == "Wheel" && !j.winnerName) &&
                                    (
                                        <Col>
                                            <Row><Col className={css(styles.dataBoxTitle)}>UNFINISHED GAME ENTERED</Col></Row>
                                            <div className={css(styles.dataBoxRow)}>
                                                <Row><Col className={css(styles.dataBoxHeader)}>PRIZE VALUE</Col></Row>
                                                <Row><Col className={css(styles.valueText)}>{`$${j.reward.toFixed(2)}`}</Col></Row>
                                            </div>
                                            <div className={css(styles.dataBoxRow)}>
                                                <Row><Col className={css(styles.dataBoxHeader)}>ENTRY VALUE</Col></Row>
                                                <Row><Col className={css(styles.valueText)}>{`$${j.cost.toFixed(2)}`}</Col></Row>
                                            </div>
                                            <div className={css(styles.dataBoxRow)}>
                                                <Row><Col className={css(styles.dataBoxHeader)}>DATE</Col></Row>
                                                <Row><Col className={css(styles.valueText)}>{j.entryDate.substring(0, 10)}</Col></Row>
                                            </div>
                                            <div className={css(styles.dataBoxRow)}>
                                                <Row><Col className={css(styles.dataBoxHeader)}>GAME ID</Col></Row>
                                                <Row><Col className={css(styles.valueText)}>{j.gameID}</Col></Row>
                                            </div>
                                            <div className={css(styles.dataBoxSpacer)}>
                                                <Row><Col></Col></Row>
                                            </div>
                                        </Col>
                                    )
                                }
                                {(j.gameType == "Wheel" && j.winnerName) &&
                                    (
                                        <Col>
                                            <Row><Col className={css(styles.dataBoxTitle)}>
                                                COMPLETED GAME{j.winnerName == this.props.currUser.className ? <span style={{ color: "rgb(255, 204, 0)" }}> - YOU WON!</span> : ""}
                                            </Col></Row>
                                            <div className={css(styles.dataBoxRow)}>
                                                <Row><Col className={css(styles.dataBoxHeader)}>WINNER</Col></Row>
                                                <Row><Col className={css(styles.valueText)}>{j.winnerName}</Col></Row>
                                            </div>
                                            <div className={css(styles.dataBoxRow)}>
                                                <Row><Col className={css(styles.dataBoxHeader)}>PRIZE WON</Col></Row>
                                                <Row><Col className={css(styles.valueText)}>{`$${j.reward.toFixed(2)}`}</Col></Row>
                                            </div>
                                            <div className={css(styles.dataBoxRow)}>
                                                <Row><Col className={css(styles.dataBoxHeader)}>ENTRY VALUE</Col></Row>
                                                <Row><Col className={css(styles.valueText)}>{`$${j.cost.toFixed(2)}`}</Col></Row>
                                            </div>
                                            <div className={css(styles.dataBoxRow)}>
                                                <Row><Col className={css(styles.dataBoxHeader)}>DATE</Col></Row>
                                                <Row><Col className={css(styles.valueText)}>{j.entryDate.substring(0, 10)}</Col></Row>
                                            </div>
                                            <div className={css(styles.dataBoxRow)}>
                                                <Row><Col className={css(styles.dataBoxHeader)}>GAME ID</Col></Row>
                                                <Row><Col className={css(styles.valueText)}>{j.gameID}</Col></Row>
                                            </div>
                                            <Button onClick={() => this.viewResult(j.gameID)} className={css(styles.viewResultButton)}>VIEW RESULT</Button>
                                            <div className={css(styles.dataBoxSpacer)}>
                                                <Row><Col></Col></Row>
                                            </div>
                                        </Col>
                                    )
                                }
                                {(j.gameType == "Leaderboard" && j.winnerName) &&
                                    (
                                        <Col>
                                            <Row><Col className={css(styles.dataBoxTitle)}>
                                                PAST LEADERBOARD{j.winnerName == this.props.currUser.className ? <span style={{ color: "rgb(255, 204, 0)" }}> - YOU WON!</span> : ""}
                                            </Col></Row>
                                            <div className={css(styles.dataBoxRow)}>
                                                <Row><Col className={css(styles.dataBoxHeader)}>WINNER</Col></Row>
                                                <Row><Col className={css(styles.valueText)}>{j.winnerName}</Col></Row>
                                            </div>
                                            <div className={css(styles.dataBoxRow)}>
                                                <Row><Col className={css(styles.dataBoxHeader)}>PRIZE WON</Col></Row>
                                                <Row><Col className={css(styles.valueText)}>{`$${j.reward.toFixed(2)}`}</Col></Row>
                                            </div>
                                            <div className={css(styles.dataBoxRow)}>
                                                <Row><Col className={css(styles.dataBoxHeader)}>WINNER'S POINTS</Col></Row>
                                                <Row><Col className={css(styles.valueText)}>{j.points}</Col></Row>
                                            </div>
                                            <div className={css(styles.dataBoxRow)}>
                                                <Row><Col className={css(styles.dataBoxHeader)}>DATE</Col></Row>
                                                <Row><Col className={css(styles.valueText)}>{j.entryDate.substring(0, 10)}</Col></Row>
                                            </div>
                                            <div className={css(styles.dataBoxRow)}>
                                                <Row><Col className={css(styles.dataBoxHeader)}>LEADERBOARD ID</Col></Row>
                                                <Row><Col className={css(styles.valueText)}>{j.gameID}</Col></Row>
                                            </div>
                                            <div className={css(styles.dataBoxSpacer)}>
                                                <Row><Col></Col></Row>
                                            </div>
                                        </Col>
                                    )
                                }
                            </div>
                        </Col>
                    </Row>
                )
            }
            );
            return gameHistoryQueue;
        }
    }

    viewResult(id) {
        document.location = `/game/${id}`;
    }

    backToPlndr() {
        document.location = "/"
    }
    render() {

        return (
            <>
                <MediaQuery maxWidth={575}>
                    <Row style={{ marginTop: 10, marginBottom: 10 }}>
                        <Button className={css(styles.toHomeButtonMobile)} onClick={this.backToPlndr}>BACK TO PLNDR</Button>
                    </Row>
                </MediaQuery>
                <Container className={css(styles.histContainer, styles.mqgamehistContainer, styles.noSelect)}>
                    <Row>
                        <Col className={css(styles.mqprofilecol1)} md={1} sm={1} xs={1}></Col>
                        <Col md={11} sm={11} xs={11}>
                            <Row><Col><div className={css(styles.gameHistoryTitle, styles.mqgameHistoryTitle, styles.mqgameHistoryText)}>GAME HISTORY</div></Col></Row>
                            {this.mapGameHistory(this.props.gameHistory)}
                            <Row className={css(styles.pushBottom)}></Row>
                        </Col>
                    </Row>
                </Container>
                <MediaQuery minWidth={576}>
                    <Button className={css(styles.toHomeButton)} onClick={this.backToPlndr}>BACK TO PLNDR</Button>
                </MediaQuery>            {/* </Link> */}
            </>
        );
    }

}

function mapStateToProps(state) {
    return {
        currUser: state.loginReducer.user
    }
}

export default connect(mapStateToProps, {})(GameHistoryModal);
