import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import PlayerWheel from '../PlayerWheel';
import Login from '../Login';
import { connect } from 'react-redux';
import { clearCurrentGame, enterGameView } from '../../actions/GameActions';
import { sleep } from '../../constants/ActionConstants';
import { css } from 'aphrodite';
import { styles } from './GameViewStyles.js';
import MediaQuery from 'react-responsive';

class GameView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timer: false,
            time: 5,
            countdownComplete: false
        };
        this.countDownWinner = this.countDownWinner.bind(this);
        this.decrement = this.decrement.bind(this);
        this.backToPlndr = this.backToPlndr.bind(this);
    }

    backToPlndr() {
        document.location = "/"
    }

    countDownWinner() {
        this.setState({ timer: true });
        setInterval(this.decrement, 1000);
    }

    decrement() {
        if (this.state.time <= 0) {
            this.setState({ countdownComplete: true, timer: false });
        }
        else {
            this.setState({ time: this.state.time - 1 });
        }
    }

    mapWheelNodes(slots, players, winner) {
        let wheelNodes = [];
        let lastSlot = 0;

        let startIndex = null; 

        for (let i = 1; i <= slots; i++) {
            var taken = null;
            for (var p = lastSlot; p < i && p < players.length; p++) {
                if (i === players[p].slot) {
                    taken = players[p].player;
                    lastSlot = p;
                }
            }

            if (!taken && !startIndex) {
                startIndex = i;
                this.setState({ startIndex: startIndex})
            }

            if (taken && winner === taken.name) {
                this.setState({ wheelWinner: taken });
            }

            wheelNodes.push(
                // <div disabled={taken && taken.length} className={css(styles.wheelNodeDisplay)}>
                <div disabled={taken && taken.length} className={css(taken !== null && [styles.wheelNodeDisplayTaken, styles.mqwheelNodeDisplay], taken === null && [styles.wheelNodeDisplayTest, styles.mqwheelNodeDisplay])}>
                    {taken && (<img className={css(styles.takenImg)} src={taken.userProfileURL ? (taken.userProfileURL.indexOf("facebook.com") !== -1 ? taken.userProfileURL + "&width=500" : taken.userProfileURL) : <em>taken</em>} width="65" />)}
                    {taken && winner === taken.name && <span className={css(styles.nameDisplay)}>{taken.name} won!</span>}
                    {!taken && (<div className={css(i < 100 && [styles.innerNum, styles.mqinnerNum], i >= 100 && i < 1000 && [styles.innerNum, styles.mqinnerNumHundreds], i >= 1000 && [styles.innerNum, styles.mqinnerNumThousands])}>{String(i)}</div>)}
                </div>
            )
        }
        return wheelNodes;
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        var winner = '';
        for (var i = 0; i < this.props.gameHistory.length; i++) {
            if (String(this.props.gameHistory[i].gameID) === String(id)) {
                winner = this.props.gameHistory[i].winnerName;
                break;
            }
        }

        this.props.enterGameView(id, this.props.token);
        sleep(200).then(() => {
            if (id === String(this.props.currentgame.id)) {
                var wheelNodes = this.mapWheelNodes(this.props.currentgame.maxUsers, this.props.currentgame.players, winner);
                this.setState({ wheelNodes: wheelNodes });
            }
        })
    }

    componentWillUnmount() {
        this.setState({ wheelNodes: null });
    }

    render() {
        if(this.props.token && this.props.currentgame.id == 0)
        {
            this.props.enterGameView(this.props.match.params.id, this.props.token);
         
        }
        return (
            !this.props.token ? <Login /> : (
            <div className={css(styles.gameView, styles.noSelect)}>
                <Row className={css(styles.gameViewTopBar)}>
                    <MediaQuery minWidth={500}>
                    <Link to="/" className={css(styles.gameViewBackButton)}><img className={css(styles.mqgameViewBackButtonImg)} src={require('../../images/backToHome.svg')} /></Link>
                    </MediaQuery>
                    <MediaQuery maxWidth={499}>
                    <Button className={css(styles.plndrHomeButton)} onClick={this.backToPlndr}>PLNDR HOME</Button>
                    </MediaQuery>
                </Row>
                <Row className={css(styles.gameViewInfoRow)}>
                    <Col md={2} className={css(styles.gameViewGameInfo)}>
                        {this.props.currentgame.entryValue ?
                            <span className={css(styles.gameInfoBox)}>
                                <span className={css(styles.playForText)}>
                                    {this.state.wheelWinner ? "PRIZE" : "PLAY FOR"}
                                </span><br />
                                <span className={css(styles.gameViewPrizeValueText)}>
                                    ${this.props.currentgame.prizeValue}
                                </span><br />
                                {!this.state.wheelWinner &&
                                    <>
                                        <span className={css(styles.playForText)}>
                                            FOR ${this.props.currentgame.entryValue.toFixed(2)}
                                        </span><br />
                                    </>
                                }
                                <span className={css(styles.gameViewGameId)}>
                                    GAME {this.props.currentgame.gameid}
                                </span>
                            </span> : ""
                        }
                    </Col>
                    <Col md={2} className={css(styles.gameViewAccountInfo, styles.mqgameViewAccountInfo)}>
                        <div className={css(styles.accountInfoBox)}>
                            <span className={css(styles.gameViewMyAccountText)}>MY ACCOUNT</span><br />
                            <span className={css(styles.gameViewAccountValueText)}>{this.props.currUser.balance >= 0 ? `$${this.props.currUser.balance.toFixed(2)}` : "$0.00"}</span>
                        </div>
                    </Col>
                </Row>
                {this.props.currentgame.id !== 0 && (
                    this.state.wheelWinner ?
                        (<>
                            {this.state.countdownComplete ?
                                (<Row className={css(styles.gameViewTitleRow)}>
                                    <div className={css(styles.gameViewTitle)}>WINNER!</div>
                                </Row>
                                )
                                :
                                (
                                    <Row className={css(styles.gameViewTitleRow)}>
                                        <div className={css(styles.gameViewTitle, styles.mqgameViewTitleLong)}>SELECTING WINNER</div>
                                    </Row>
                                )
                            }
                            <div style={{ position: 'fixed', top: '50%', width: '100%' }}>
                                <div style={{ position: 'relative' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {this.state.timer &&
                                            <div className={css(styles.countDown)}><span className={css(styles.countDownTime)}>{this.state.time}</span></div>
                                        }
                                        {!this.state.timer && !this.state.countdownComplete &&
                                            <Button onClick={this.countDownWinner} className={css(styles.showWinnerButton)}>SHOW ME<br />THE WINNER!</Button>
                                        }
                                        {!this.state.timer && this.state.countdownComplete &&
                                            <>
                                            <div style={{marginTop: -110}}>
                                                <div className={css(styles.winnersCircle)}><img className={css(styles.winnerImg)} src={this.state.wheelWinner.userProfileURL.indexOf("facebook.com") !== -1 ? this.state.wheelWinner.userProfileURL + "&width=1000" : this.state.wheelWinner.userProfileURL} /></div>
                                                <br />
                                                <div className={css(styles.winnersCircleText)}>{(`${this.state.wheelWinner.name} wins $${this.props.currentgame.prizeValue}!`).toUpperCase()}</div>
                                            </div>
                                                <img className={css(styles.particleEffect)} src={require("../../images/particles.gif")} /><br/>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                        )
                        :
                        (
                            <Row className={css(styles.gameViewTitleRow)}>
                                <div className={css(styles.gameViewTitle)}>PICK A SLOT</div>
                            </Row>
                        )
                )}

                {this.props.currentgame.id !== 0 && this.state.wheelNodes && !this.state.wheelWinner && (
                    <PlayerWheel startIndex={this.state.startIndex}>
                        {this.state.wheelNodes}
                    </PlayerWheel>
                )}

            </div>
            )
            );

    }

}

function mapStateToProps(state) {
    return {
        currentgame: state.gameReducer.currentGame,
        currUser: state.loginReducer.user,
        token: state.loginReducer.loginToken,
        gameHistory: state.gameReducer.gameHistory
    }
}

export default withRouter(connect(mapStateToProps, {
    clearCurrentGame,
    enterGameView
}
)(GameView));