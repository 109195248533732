// PayoutHistoryModal
import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from 'react-bootstrap/FormLabel';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { css } from 'aphrodite';
import { styles } from './ModalStyles.js';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';

class PayoutHistoryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.backToPlndr = this.backToPlndr.bind(this);
    }

    mapPayoutHistory(payoutHistory) {
        if (payoutHistory) {
            let payoutHistoryList = payoutHistory.map((j, r) => {
                return (
                    <Row key={r} className={css(styles.mqPaymentHistoryItem)}>
                        <Col className={css(styles.mqdataBoxCol)} md={5} sm={6} xs={11}>
                            <div className={css(r == 0 ? [styles.dataBoxTop, styles.mqdataBoxPayout] : [styles.dataBox, styles.mqdataBoxPayout])}>
                                <Col>
                                    <Row><Col className={css(styles.dataBoxTitle)}>WITHDRAWAL</Col></Row>
                                    <div className={css(styles.dataBoxRow)}>
                                        <Row><Col className={css(styles.dataBoxHeader)}>
                                            <MediaQuery minWidth={372}>AMOUNT WITHDRAWN</MediaQuery>
                                            <MediaQuery maxWidth={371}>AMT WITHDRAWN</MediaQuery>
                                        </Col></Row>
                                        <Row><Col className={css(styles.valueText)}>{`$${j.amount.toFixed(2)}`}</Col></Row>
                                    </div>
                                    <div className={css(styles.dataBoxRow)}>
                                        <Row><Col className={css(styles.dataBoxHeader)}>DATE</Col></Row>
                                        <Row><Col className={css(styles.valueText)}>{j.modifiedDate.substring(0, 10)}</Col></Row>
                                    </div>
                                    <div className={css(styles.dataBoxRow)}>
                                        <Row><Col className={css(styles.dataBoxHeader)}>
                                            <MediaQuery minWidth={322}>PAYOUT METHOD</MediaQuery>
                                            <MediaQuery maxWidth={321}>PAYOUT VIA</MediaQuery>
                                        </Col></Row>
                                        <Row><Col className={css(styles.valueText)}>{(j.payoutMethodType)}</Col></Row>
                                        <Row><Col className={css(styles.valueText, styles.withdrawContactInfo)}> {(j.contactInfo)}</Col></Row>
                                    </div>
                                    <div className={css(styles.dataBoxSpacer)}>
                                        <Row><Col></Col></Row>
                                    </div>
                                </Col>
                            </div>
                        </Col>
                    </Row>
                )
            }
            );
            return payoutHistoryList;
        }
    }
    backToPlndr() {
        document.location = "/"
    }
    render() {
        return (
            <>
                <MediaQuery maxWidth={575}>
                    <Row style={{ marginTop: 10, marginBottom: 10 }}>
                        <Button className={css(styles.toHomeButtonMobile)} onClick={this.backToPlndr}>BACK TO PLNDR</Button>
                    </Row>
                </MediaQuery>
                <Container className={css(styles.histContainer, styles.mqhistContainer, styles.noSelect)}>
                    <Row>
                        <Col className={css(styles.mqprofilecol1)} md={1} sm={1} xs={1}></Col>
                        <Col md={11} sm={11} xs={11}>
                            <Row><Col><div className={css(styles.gameHistoryTitle, styles.mqgameHistoryTitle, styles.mqwithdrawHistoryTitle)}>WITHDRAWAL HISTORY</div></Col></Row>
                            <Row><Col><div className={css(styles.asOfDate, styles.mqgameHistoryTitle)}>AS OF {Date().substring(0, 15).toUpperCase()}</div></Col></Row>
                            <Row><Col><div> <br></br></div></Col></Row>
                            {this.mapPayoutHistory(this.props.payoutHistory)}
                            <Row className={css(styles.pushBottom)}></Row>
                        </Col>
                    </Row>
                </Container>
                {/* <Link to="/"> */}
                <MediaQuery minWidth={576}>
                    <Button className={css(styles.toHomeButton)} onClick={this.backToPlndr}>BACK TO PLNDR</Button>
                </MediaQuery>
                {/* </Link> */}
            </>
        );
    }

}

function mapStateToProps(state) {
    return {
        currUser: state.loginReducer.user,
        payouthistory: state.payoutReducer.payoutHistory
    }
}

export default connect(mapStateToProps, {})(PayoutHistoryModal);
