import { combineReducers } from 'redux'; 
import noPersistReducer from './NoPersistReducer';
import gameReducer from './GameReducer'; 
import loginReducer from './LoginReducer';
import minigameReducer from './MinigameReducer';
import paymentReducer from './PaymentReducer';
import payoutReducer from './PayoutReducer';
import walletReducer from './WalletReducer';

export default combineReducers({
    noPersistReducer,
    gameReducer,
    loginReducer,
    minigameReducer,
    paymentReducer,
    payoutReducer,
    walletReducer
});