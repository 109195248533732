import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { css } from 'aphrodite'
import { styles } from './LandingStyles.js'

class Landing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    getCookie() {
        var cookiename = "AcceptCookie";
        var name = cookiename + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    saveCookie() {
        var cookievalue = "Yes";
        document.cookie = "AcceptCookie = " + cookievalue;
        document.getElementById("cookiediv").style.display = "none";
    }

    render() {
        return (
            <>
                {this.props.firstTimeVisitor ? (
                    <div className={css(styles.landingOverlay)}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><img style={{ width: 280, position: "absolute" }} src={require('../../images/PlndrLogo.png')} /></div>
                        <div className={css(styles.landingContentContainer)}>
                            <div className={css(styles.landingContentBig, styles.mqlandingContentBig)}>WELCOME TO PLNDR</div>
                            <div className={css(styles.landingContentSmall, styles.mqlandingContentSmall)}>#WINFORREAL</div>
                            <div className={css(styles.mqLandingButtons)}>
                                <Link to="/signup"><Button className={css(styles.landingContentButton)}>GET STARTED</Button></Link>
                                <Link to="/login"><Button className={css(styles.landingContentButton)}>LOGIN</Button></Link>
                            </div>
                        </div>
                        {this.getCookie() === "" ?
                            <div id="cookiediv" className={css(styles.landingFooterContainer, styles.mqlandingFooterContainer)}>
                                <span className={css(styles.landingFooterText, styles.mqlandingFooterText)}>Please note that we use cookies. By clicking "Accept", you agree to our </span>
                                <a className={css(styles.landingFooterTextLink, styles.mqlandingFooterText)} href="https://plndrmobile.com/terms-and-conditions/" target="_blank">
                                    Cookie Policy
                            </a>
                                <span className={css(styles.landingFooterText, styles.mqlandingFooterText)}> -</span>
                                <button id="savecookiebtn" className={css(styles.landingAcceptButton, styles.mqlandingAcceptButton)} onClick={() => this.saveCookie()}>Accept</button>
                            </div>
                            : null}
                    </div>
                ) : <></>}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.loginReducer.isLoggedIn,
        firstTimeVisitor: state.loginReducer.firstTimeVisitor
    }
}

export default connect(mapStateToProps, {
})(Landing)