import * as React from "react";
import { store } from "../../../store";
import { connect } from 'react-redux';
import watch from 'redux-watch';
import * as actions from '../../../actions/MinigameActions';
import { constants } from "../constants";
import { mobile } from "../config";
import Phaser from "phaser";

var sun;
var sky;
var island;
var waterLayerBack;
var waterLayer2;
var waterLayer3;
var waterLayer4;
var waterLayer5;
var waterLayer6;
var waterLayer7;
var yellowShip;
var yellowShipMovement;
var yellowShipSinks;
var yellowShipVelocity;
var yellowShipCollider;
var yellowCollideCancel = false;
var purpleShip;
var purpleShipMovement;
var purpleShipSinks;
var purpleShipVelocity;
var purpleShipCollider;
var purpleCollideCancel = false;
var greenShip;
var greenShipMovement;
var greenShipSinks;
var greenShipVelocity;
var greenShipCollider;
var greenCollideCancel = false;
var shipDock;
var cannonBarrel;
var cannonBase;
var cannonIcon;
var shotText;
var scoreText;
var rankText;
var tutorialText;
var tween;
var cannonball;
var isDragged = false;
var postFire = false;
var graphics;
var path;
var curve;
var loadedCannonball;
var cannonballVelocityX;
var cannonballVelocityY;
var degrees;
var dragDistance;
var dragDistanceY;
var response;
var cannonballs;
var cannonballsDisabled = false;
var cannonball;
var consecutiveHits = 0;
var consecutiveHitSpeedMultiplier = 1.25;
var speedUpShips = false;
var resetShipSpeed = false;
var fireComplete = false;
var cannonballScaleDownMultiplier = .97;
var firstShot = true;
var multiText;
var transactionMonitor = false;
var transactionComplete = false;
var gameState;
var purpleShipText;
var greenShipText;
var yellowShipText;

var iter = 10;
var iter2 = 10;
var rank = 0;
var shots;
var points = 0;

function addCannonballBalance(loginToken, userid, gameid) {
    // console.log("add more shots clicked");
    var transaction = {
        gameID: gameid,
        userID: userid,
        //deltaFunds: funds,
        deltaCannonBalls: 1,
        deltaPoints: 0, // points per cannonballs value
        deltaGames: 0
    };
    // update balance 
    this.props.updateCannonballBalance(loginToken, transaction);
}
function subtractCannonballBalance(pointDelta) {
    // console.log("subract shots clicked");
    shots--;
    shotText.setText(shots);
    let state = store.getState();
    var transaction = {
        gameID: state.gameReducer.currentGame.gameid,
        userID: state.loginReducer.user.id,
        ///deltaFunds: funds,
        deltaCannonBalls: -1,
        deltaPoints: pointDelta, // points per cannonballs value
        deltaGames: 0
    };
    // update balance
    store.dispatch(actions.updateCannonballBalance(state.loginReducer.loginToken, transaction));
}

function getRank() {
    store.getState().gameReducer.leaderboard.rankings.forEach(function (item, index, array) {
        if (item.player.userId == store.getState().loginReducer.user.id) {
            rank = item.rank;
            switch (rank) { //We should probably look at the ones digit for 21, 22, 23, 31, 32, 33, 141, 144, 143, etc. 
                //Only exceptions are the "Teens" (11, 12, 13, 111, 112, 113, etc.)
                case 1: rank = rank + "st";
                    break;
                case 2: rank = rank + "nd";
                    break;
                case 3: rank = rank + "rd";
                    break;
                default:
                    rank = rank + "th";
            }
        }
    });
    // console.log("localrank", rank);
    return rank;
}

function onShipYoYo(tween, target) {

    if (target.type === "Text") {
        let textAdjustmentMultiplier = (target.x < 0) ? 1 : -1
        let textAdjustmentPixels
        if (target.name === "yellowShipText") {
            textAdjustmentPixels = 3 * textAdjustmentMultiplier
        } else {
            textAdjustmentPixels = 7 * textAdjustmentMultiplier
        }
        target.setDisplayOrigin(target.displayOriginX + textAdjustmentPixels, target.displayOriginY )
    } else {
        target.toggleFlipX()
    }
}

function explodeGreenShip(cannonball, ship) {
    //GET WIDTH OF SCENE 
    let sceneWidth = this.systems.canvas.width;
    //RANDOMLY PLACE SHIP OUTSIDE OF LEFT OR RIGHT SCENE BOUNDS
    let shipX = Math.floor(Math.random() * sceneWidth) + 1;
    //GET Y-COORD PRIOR TO BEING HIT
    let shipY = greenShip.y;
    greenShipText.setVisible(false)
    //CHANGE THE TEXTURE TO FIRE 
    greenShip.setTexture('shipFireGreen');
    //STOP THE LEFT AND RIGHT ANIMATION
    greenShipMovement.stop();
    //CREATE THE SINKING ANIMATION
    greenShipSinks = this.scene.tweens.add({
        targets: greenShip,
        y: greenShip.y + 200,
        duration: 5000,
        ease: "Linear"
    });

    //CREATE SCORE RIBBON
    // let ribbonBack = this.scene.add.image(this.systems.canvas.width / 2, this.systems.canvas.height * 0.15, 'ribbonBack');
    let scoredPoints = consecutiveHits < 1 ? 10 : (consecutiveHits * 10);
    let ribbonScore = this.scene.add.text((this.systems.canvas.width / 2 - (this.systems.canvas.width * .05)), this.systems.canvas.height * .1, `+${scoredPoints}!`, { fontFamily: 'SF Pro Text Heavy', fontSize: 50, color: "#FFFFFF", letterSpacing: 2.63, textAlign: "center" });
    if (consecutiveHits < 2) {
        ribbonScore.setFill("white");
    }
    else if (consecutiveHits === 2) {
        ribbonScore.setFill("green");
    }
    else if (consecutiveHits === 3) {
        ribbonScore.setFill("purple");
    }
    else if (consecutiveHits > 3) {
        ribbonScore.setFill("red")
    }
    setTimeout(function () {
        ribbonScore.destroy();
    }, 1500);


    subtractCannonballBalance(scoredPoints);


    //DISABLE COLLISION DETECTION ON SINKING TARGET
    greenCollideCancel = true;

    //CREATE THE NEW LEFT AND RIGHT ANIMATION TO BE PLAYED ONCE TARGET RESPAWNS
    greenShipMovement = this.scene.tweens.createTimeline();
    greenShipMovement.loop = -1;
    if (consecutiveHits > 1) {
        greenShipMovement.timeScale = consecutiveHitSpeedMultiplier ** (consecutiveHits - 1);
    }
    if (greenShip.flipX) {
        greenShipMovement.add({
            targets: [greenShip, greenShipText],
            x: sceneWidth + 200,
            ease: 'Linear',
            duration: ((sceneWidth + 200) - shipX) / greenShipVelocity,
            yoyo: true,
            flipX: false,
            onYoyo: onShipYoYo
        });
        greenShipMovement.add({
            targets: [greenShip, greenShipText],
            x: -200,
            ease: 'Linear',
            duration: (shipX - (-200)) / greenShipVelocity,
            yoyo: true,
            flipX: false,
            onYoyo: onShipYoYo
        });
    }
    else {
        greenShipMovement.add({
            targets: [greenShip, greenShipText],
            x: -200,
            ease: 'Linear',
            duration: (shipX - (-200)) / greenShipVelocity,
            yoyo: true,
            flipX: false,
            onYoyo: onShipYoYo
        });
        greenShipMovement.add({
            targets: [greenShip, greenShipText],
            x: sceneWidth + 200,
            ease: 'Linear',
            duration: ((sceneWidth + 200) - shipX) / greenShipVelocity,
            yoyo: true,
            flipX: false,
            onYoyo: onShipYoYo
        });
    }
    speedUpShips = true;
    //WAIT FOR SHIP TO FINISH SINKING BEFORE THE FOLLOWING: 
    setTimeout(function () {
        //STOP THE SINKING ANIMATION
        greenShipSinks.stop();
        //REVERT THE SHIP BACK TO IT'S NORMAL TEXTURE
        greenShip.setTexture('greenShip');
        //ENABLE COLLISION ONCE AGAIN
        greenCollideCancel = false;
        //SET Y COORD
        greenShip.y = shipY;
        //SET X COORD
        greenShip.x = shipX;
        //REPLAY THE MOVEMENT ANIMATION

        greenShipText.y = greenShip.y
        greenShipText.x = greenShip.x
        greenShipText.setVisible(true)

        greenShipMovement.play();
    }, 5000);

    // Below line removes cannonball from scene
    ship.disableBody(true, true);
}


function explodePurpleShip(cannonball, ship) {
    let sceneWidth = this.systems.canvas.width;
    let shipX = Math.floor(Math.random() * sceneWidth) + 1;
    let shipY = purpleShip.y;
    purpleShipText.setVisible(false)
    purpleShip.setTexture('shipFirePurple');
    purpleShipMovement.stop();
    purpleShipSinks = this.scene.tweens.add({
        targets: purpleShip,
        y: purpleShip.y + 100,
        duration: 5000,
        ease: "Linear"
    });

    let scoredPoints = consecutiveHits < 1 ? 25 : (consecutiveHits * 25);
    let ribbonScore = this.scene.add.text((this.systems.canvas.width / 2 - (this.systems.canvas.width * .05)), this.systems.canvas.height * .1, `+${scoredPoints}!`, { fontFamily: 'SF Pro Text Heavy', fontSize: 50, color: "#FFFFFF", letterSpacing: 2.63, textAlign: "center" });
    if (consecutiveHits < 2) {
        ribbonScore.setFill("white");
    }
    else if (consecutiveHits === 2) {
        ribbonScore.setFill("green");
    }
    else if (consecutiveHits === 3) {
        ribbonScore.setFill("purple");
    }
    else if (consecutiveHits > 3) {
        ribbonScore.setFill("red")
    }
    setTimeout(function () {
        ribbonScore.destroy();
    }, 1500)

    subtractCannonballBalance(scoredPoints);

    purpleCollideCancel = true;

    purpleShipMovement = this.scene.tweens.createTimeline();
    purpleShipMovement.loop = -1;
    if (consecutiveHits > 1) {
        purpleShipMovement.timeScale = consecutiveHitSpeedMultiplier ** (consecutiveHits - 1);
    }
    if (purpleShip.flipX) {
        purpleShipMovement.add({
            targets: [purpleShip, purpleShipText],
            x: -200,
            ease: 'Linear',
            duration: (shipX - (-200)) / purpleShipVelocity,
            yoyo: true,
            flipX: false,
            onYoyo: onShipYoYo
        });
        purpleShipMovement.add({
            targets: [purpleShip, purpleShipText],
            x: sceneWidth + 200,
            ease: 'Linear',
            duration: ((sceneWidth + 200) - shipX) / purpleShipVelocity,
            yoyo: true,
            flipX: false,
            onYoyo: onShipYoYo
        });
    } else {
        purpleShipMovement.add({
            targets: [purpleShip, purpleShipText],
            x: sceneWidth + 200,
            ease: 'Linear',
            duration: ((sceneWidth + 200) - shipX) / purpleShipVelocity,
            yoyo: true,
            flipX: false,
            onYoyo: onShipYoYo
        });
        purpleShipMovement.add({
            targets: [purpleShip, purpleShipText],
            x: -200,
            ease: 'Linear',
            duration: (shipX - (-200)) / purpleShipVelocity,
            yoyo: true,
            flipX: false,
            onYoyo: onShipYoYo
        });
    }
    speedUpShips = true;
    setTimeout(function () {
        purpleShipSinks.stop();
        purpleShip.setTexture('purpleShip');
        purpleCollideCancel = false;
        purpleShip.y = shipY;
        purpleShip.x = shipX;
        purpleShipText.y = purpleShip.y
        purpleShipText.x = purpleShip.x
        purpleShipText.setVisible(true)
        purpleShipMovement.play();
    }, 5000);

    // Below line removes cannonball from scene
    ship.disableBody(true, true);
}

function explodeYellowShip(cannonball, ship) {
    let sceneWidth = this.systems.canvas.width;
    let shipX = Math.floor(Math.random() * sceneWidth) + 1;
    let shipY = yellowShip.y;
    yellowShipText.setVisible(false)
    yellowShip.setTexture('shipFireYellow');
    yellowShip.setDepth(2);
    yellowShipMovement.stop();
    yellowShipSinks = this.scene.tweens.add({
        targets: yellowShip,
        y: yellowShip.y + 75,
        duration: 5000,
        ease: "Linear"
    });
    let scoredPoints = consecutiveHits < 1 ? 50 : (consecutiveHits * 50);
    let ribbonScore = this.scene.add.text((this.systems.canvas.width / 2 - (this.systems.canvas.width * .05)), this.systems.canvas.height * .1, `+${scoredPoints}!`, { fontFamily: 'SF Pro Text Heavy', fontSize: 50, letterSpacing: 2.63, textAlign: "center" });
    if (consecutiveHits < 2) {
        ribbonScore.setFill("white");
    }
    else if (consecutiveHits === 2) {
        ribbonScore.setFill("green");
    }
    else if (consecutiveHits === 3) {
        ribbonScore.setFill("purple");
    }
    else if (consecutiveHits > 3) {
        ribbonScore.setFill("red");
    }
    setTimeout(function () {
        ribbonScore.destroy();
    }, 1500);

    subtractCannonballBalance(scoredPoints);

    yellowCollideCancel = true;

    yellowShipMovement = this.scene.tweens.createTimeline();
    yellowShipMovement.loop = -1;
    if (consecutiveHits > 1) {
        yellowShipMovement.timeScale = consecutiveHitSpeedMultiplier ** (consecutiveHits - 1);
    }
    if (yellowShip.flipX) {
        yellowShipMovement.add({
            targets: [yellowShip, yellowShipText],
            x: sceneWidth + 200,
            ease: 'Linear',
            duration: ((sceneWidth + 200) - shipX) / yellowShipVelocity,
            yoyo: true,
            flipX: false,
            onYoyo: onShipYoYo
        });
        yellowShipMovement.add({
            targets: [yellowShip, yellowShipText],
            x: -200,
            ease: 'Linear',
            duration: (shipX - (-200)) / yellowShipVelocity,
            yoyo: true,
            flipX: false,
            onYoyo: onShipYoYo
        });
    } else {
        yellowShipMovement.add({
            targets: [yellowShip, yellowShipText],
            x: -200,
            ease: 'Linear',
            duration: (shipX - (-200)) / yellowShipVelocity,
            yoyo: true,
            flipX: false,
            onYoyo: onShipYoYo
        });
        yellowShipMovement.add({
            targets: [yellowShip, yellowShipText],
            x: sceneWidth + 200,
            ease: 'Linear',
            duration: ((sceneWidth + 200) - shipX) / yellowShipVelocity,
            yoyo: true,
            flipX: false,
            onYoyo: onShipYoYo
        });
    }
    speedUpShips = true;
    setTimeout(function () {
        yellowShipSinks.stop();
        yellowShip.setTexture('yellowShip');
        yellowCollideCancel = false;
        yellowShip.y = shipY;
        yellowShip.x = shipX;
        yellowShipText.y = yellowShip.y
        yellowShipText.x = yellowShip.x
        yellowShipText.setVisible(true)
        yellowShipMovement.play();
    }, 5000);

    // Below line removes cannonball from scene
    ship.disableBody(true, true);
}

// BEZIER TEST VARS
var bezierPath;
var bezierCurve;
var bezierPoints;
var resetCannon = false;
var resetCannonWait = 0;
var midX;
var controlY;
var endpointY;
var endPointImg;
var controlPointImg;


class CannonGameScene extends Phaser.Scene {
    constructor() {
        super({
            key: constants.SCENES.CANNONGAME
        });
    }

    init() {

    }

    preload() {
        this.load.image('cannonBarrel', 'assets/cannonBarrel.png');
        this.load.image('cannonBase', 'assets/cannonBase.png');
        this.load.image('shipDock', 'assets/shipDock.png');
        this.load.image('shipFire', 'assets/shipFire.png');
        this.load.image('shipFireGreen', 'assets/shipFireGreen.png');
        this.load.image('shipFirePurple', 'assets/shipFirePurple.png');
        this.load.image('shipFireYellow', 'assets/shipFireYellow.png');
        this.load.image('island', 'assets/island.png');
        this.load.image('greenShip', 'assets/greenShip.png');
        this.load.image('orangeShip', 'assets/orangeShip.png');
        this.load.image('purpleShip', 'assets/purpleShip.png');
        this.load.image('redShip', 'assets/redShip.png');
        this.load.image('blueShip', 'assets/blueShip.png');
        this.load.image('yellowShip', 'assets/yellowShip.png');
        this.load.image('skyBase', 'assets/skyBase.png');
        this.load.image('waterLayerBack', 'assets/waterLayer1_back.png');
        this.load.image('waterLayer2', 'assets/waterLayer2.png');
        this.load.image('waterLayer3', 'assets/waterLayer3.png');
        this.load.image('waterLayer4', 'assets/waterLayer4.png');
        this.load.image('waterLayer5', 'assets/waterLayer5.png');
        this.load.image('waterLayer6', 'assets/waterLayer6.png');
        this.load.image('waterLayerFront', 'assets/waterLayer7_front.png');
        this.load.image('sun', 'assets/sun.png');
        this.load.image('cannonIcon', 'assets/cannonIcon.png');
        this.load.image('cannonball', 'assets/cannonBall.png');
        // this.load.image('ribbonBack', 'assets/yellowBadge.png');

        //BEZIER TEST
        this.load.spritesheet('dragcircle', 'assets/bezierTest/dragcircle.png', { frameWidth: 16 });
    }

    create() {
        let game = this;
        let { width, height } = this.sys.game.canvas;
        gameState = store.getState();

        // console.log(store.getState().loginReducer.user);

        let w = watch(store.getState, 'loginReducer.user.points')
        store.subscribe(w((newVal, oldVal, objectPath) => {
            scoreText.setText(newVal);
            rankText.setText(`Rank: ${getRank()}`);
        }));
        // store.subscribe(function () {
        //     let newState = store.getState();
        //     if (newState == gameState) {
        //         console.log("SAME")
        //     }
        //     else {
        //         console.log("DIFFERENT")
        //     }
        // console.log(gameState);
        // let newShots = gameState.loginReducer.user.cannonBallBalance;
        // let newPoints = gameState.loginReducer.user.points;
        // let newRank = getRank();
        // shotText.setText(newShots);
        // scoreText.setText(newPoints);
        // rankText.setText(`Rank: ${newRank}`);


        graphics = this.add.graphics();

        //Adds the sky layer (the base layer) 
        sky = this.add.image(0, 0, 'skyBase').setDepth(0).setScale(2);
        sky.height = height;
        sky.width = width;

        //Sun image
        sun = this.add.image(width * 0.8, height * 0.25, 'sun').setScale(0.5).setDepth(1);

        // //Island image
        island = this.add.image(width * 0.8, height * 0.25, 'island').setScale(.5).setDepth(2);

        //water Layers
        waterLayerBack = this.add.image(width / 2, height * 0.61, 'waterLayerBack').setDepth(3).setScale(1, .6);
        waterLayer2 = this.add.tileSprite(width / 2, height * 0.645, width, 0, 'waterLayer2').setDepth(5).setScale(1, .6);

        // Bezier set up
        var startPoint = new Phaser.Math.Vector2(width / 2, height * .81);
        var controlPoint1 = new Phaser.Math.Vector2(width / 2, height * .10);
        var endPoint = new Phaser.Math.Vector2(width / 2, height * .65);
        //BEZIER POINTS IMAGES
        controlPointImg = this.add.image(controlPoint1.x, controlPoint1.y, 'dragcircle', 2).setInteractive().setDepth(50).setAlpha(0);
        endPointImg = this.add.image(endPoint.x, endPoint.y, 'dragcircle', 2).setInteractive().setDepth(50).setAlpha(0);

        controlPointImg.setData('vector', controlPoint1);
        endPointImg.setData('vector', endPoint);
        bezierPath = { t: 0, vec: new Phaser.Math.Vector2() };
        bezierCurve = new Phaser.Curves.QuadraticBezier(startPoint, controlPoint1, endPoint);

        // BEZIER TWEEN EXAMPLE
        // this.tweens.add({
        //     targets: bezierPath,
        //     t: 1,
        //     ease: 'Sine.easeInOut',
        //     duration: 2000,
        //     yoyo: true,
        //     repeat: -1
        // });



        //BEZIER DRAG EVENT
        // this.input.on('drag', function (pointer, gameObject, dragX, dragY) {
        //     var xMultiplier = 15;
        //     var yMultiplier = 2.8;
        //     var controlXMultiplier = .5;
        //     var controlYMultiplier = .8;

        //     var newEndpointDragX;
        //     var newEndpointDragY;
        //     var newControlX;
        //     var newControlY;
        //     var dragXAbsolute;

        //     isDragged = true;
        //     midX = (width / 2);
        //     endpointY = (height * .75);
        //     controlY = (height * .20);




        //     dragDistance = Phaser.Math.Distance.Between(gameObject.x, gameObject.y, pointer.x, pointer.y);

        //     if (dragX <= midX) {
        //         dragXAbsolute = (midX - dragX);
        //         newEndpointDragX = midX + (dragXAbsolute * xMultiplier);
        //         newControlX = midX + ((dragXAbsolute * xMultiplier) * controlXMultiplier);
        //     }

        //     else if (dragX > midX) {
        //         dragXAbsolute = (dragX - midX);
        //         newEndpointDragX = midX - (dragXAbsolute * xMultiplier);
        //         newControlX = midX - ((dragXAbsolute * xMultiplier) * controlXMultiplier);
        //     }

        //     newEndpointDragY = endpointY - (dragDistance * yMultiplier);
        //     newControlY = controlY - (dragDistance * controlYMultiplier);


        //     endPointImg.x = newEndpointDragX;
        //     endPointImg.y = newEndpointDragY;
        //     controlPointImg.x = newControlX;
        //     controlPointImg.y = newControlY;
        //     endPointImg.data.get('vector').set(newEndpointDragX, newEndpointDragY);
        //     controlPointImg.data.get('vector').set(newControlX, newControlY);
        //     bezierPoints = bezierCurve.getSpacedPoints(32);

        //     // Drag cannon
        //     var radians = Phaser.Math.Angle.Between(gameObject.x, gameObject.y, pointer.x, pointer.y);
        //     degrees = Phaser.Math.RadToDeg(radians);
        //     var cannonRotate = degrees - 90;
        //     loadedCannonball = { x: gameObject.x, y: gameObject.y };
        //     cannonBarrel.angle = cannonRotate;
        // });

        // let bombTest = this.add.image(width * 0.5, height * 0.5, 'bomb').setDepth(16);

        // BEZIER DRAG END
        // this.input.on('dragend', function (pointer, gameObject) {
        // if (isDragged == true) {
        //     console.log('dragEnd');
        //     var pi = Math.PI;
        //     var distance = 500;
        //     var calcDegrees = (degrees - 180) * (-1);
        //     var calcRadians = calcDegrees * (pi / 180);
        //     var velocityMultiplier = dragDistance * 5;
        //     cannonballVelocityX = velocityMultiplier * (Math.cos(calcRadians));
        //     cannonballVelocityY = velocityMultiplier * (Math.sin(calcRadians));

        // resetCannon = true;
        //     // bomb = game.physics.add.group({
        //     //     key: 'bomb',
        //     //     frameQuantity: 1,
        //     //     velocityX: bombVelocityX,
        //     //     velocityY: -bombVelocityY,
        //     //     collideWorldBounds: false,
        //     //     setXY: loadedBomb
        //     // });
        //     // console.log(bombVelocityX, bombVelocityY)
        //     // bomb.setDepth(13.5);
        //     // bomb.enableBody = true;
        //     // bomb.physicsBodyType = Phaser.Physics.ARCADE;

        //     // bomb.createMultiple(1, 'bomb');
        //     // bomb.setAll('checkWorldBounds', true);
        //     // bomb.setAll('outOfBoundsKill', true);
        // }
        // isDragged = false;

        // followers = game.add.group();

        // var ball = followers.create(0, -50, 'cannonball');
        // ball.setDisplaySize(20, 20)

        // ball.setData('vector', new Phaser.Math.Vector2());

        // game.tweens.add({
        //     targets: ball,
        //     z: 1,
        //     ease: 'easeInOut',
        //     duration: 750
        // });

        // setTimeout(function () {
        //     ball.destroy();
        // }, 751);

        // game.tweens.add({
        //     targets: bezierPath,
        //     t: 1,
        //     ease: 'Sine.easeInOut',
        //     duration: 2000
        // });

        // console.log(game);

        // });




        waterLayer3 = this.add.tileSprite(width / 2, height * 0.69, width, 0, 'waterLayer3').setDepth(6).setScale(.75, .6);

        waterLayer4 = this.add.tileSprite(width / 2, height * 0.72, width, 0, 'waterLayer4').setDepth(7).setScale(.75, .6);

        waterLayer5 = this.add.tileSprite(width / 2, height * 0.74, width, 0, 'waterLayer5').setDepth(8).setScale(.75, .6);

        waterLayer6 = this.add.tileSprite(width / 2, height * 0.78, width, 0, 'waterLayer6').setDepth(9).setScale(.75, .6);

        waterLayer7 = this.add.tileSprite(width / 2, height * .80, width, 0, 'waterLayerFront').setDepth(10).setScale(.75, .6);

        //ships
        yellowShip = game.physics.add.image(width * 0.65, height * 0.243, 'yellowShip').setDepth(3).setScale(.05);
        yellowShipText = game.add.text(yellowShip.x, yellowShip.y, "50",{ font: "15px Arial", fill: "#FF0000" }).setDepth(3)
        yellowShipText.setDisplayOrigin(yellowShipText.displayOriginX + 7, yellowShipText.displayOriginY + 10)
        yellowShipText.setName("yellowShipText")
        yellowShip.body.allowGravity = false;
        yellowShipVelocity = 0.15;
        yellowShipMovement = this.tweens.timeline({
            targets: [yellowShip, yellowShipText],
            loop: -1,
            tweens: [
                {
                    x: -200,
                    ease: 'Linear',
                    duration: (yellowShip.x - (-200)) / yellowShipVelocity,
                    yoyo: true,
                    flipX: false,
                    onYoyo:  onShipYoYo
                },

                {
                    x: width + 200,
                    ease: 'Linear',
                    duration: ((width + 200) - yellowShip.x) / yellowShipVelocity,
                    yoyo: true,
                    flipX: false,
                    onYoyo:  onShipYoYo

                }
            ]
        });
        purpleShip = game.physics.add.image(width * 0.4, height * 0.26, 'purpleShip').setDepth(4).setScale(.5);
        purpleShipText = game.add.text(purpleShip.x, purpleShip.y, "25",{ font: "25px Arial", fill: "#ffffff" }).setDepth(4)
        purpleShipText.setName("purpleShipText")
        purpleShipText.setDisplayOrigin(purpleShip.displayOriginX - 80, purpleShip.displayOriginY - 50)
        purpleShip.body.allowGravity = false;
        purpleShipVelocity = 0.3;
        purpleShipMovement = this.tweens.timeline({
            targets: [purpleShip, purpleShipText],
            loop: -1,
            flipX: false,
            tweens: [
                {
                    x: width + 200,
                    ease: 'Linear',
                    duration: ((width + 200) - purpleShip.x) / purpleShipVelocity,
                    yoyo: true,
                    flipX: false,
                    onYoyo: onShipYoYo
                },
                {
                    x: -200,
                    ease: 'Linear',
                    duration: (purpleShip.x - (-200)) / purpleShipVelocity,
                    yoyo: true,
                    flipX: false,
                    onYoyo:  onShipYoYo
                }
            ]
        });

        greenShip = game.physics.add.image(width * .8, height * 0.445, 'greenShip').setDepth(7).setScale(.52);
        greenShipText = game.add.text(greenShip.x, greenShip.y, "10",{ font: "50px Arial", fill: "#ffffff" }).setDepth(7)
        greenShipText.setName("greenShipText")
        greenShipText.setDisplayOrigin(greenShip.displayOriginX - 135, greenShip.displayOriginY - 80)
        greenShip.body.allowGravity = false;
        greenShipVelocity = 0.4;
        greenShipMovement = this.tweens.timeline({
            targets: [greenShip, greenShipText],
            loop: -1,
            tweens: [
                {
                    x: -200,
                    ease: 'Linear',
                    duration: (greenShip.x - (-200)) / greenShipVelocity,
                    yoyo: true,
                    flipX: false,
                    onYoyo:  onShipYoYo
                },

                {
                    x: width + 200,
                    ease: 'Linear',
                    duration: ((width + 200) - greenShip.x) / greenShipVelocity,
                    yoyo: true,
                    flipX: false,
                    onYoyo:  onShipYoYo

                }
            ]
        });

        //THE CANNONBALL GROUP - Cannonballs get created on release event
        cannonballs = game.physics.add.group();
        //

        //CANNONBALL COLLISION EVENTS 
        // GO HERE
        //

        shots = gameState.loginReducer.user.cannonBallBalance;
        // console.log(shots);
        points = gameState.loginReducer.user.points;
        // console.log(points);
        rank = getRank();
        // console.log(rank);
        //ship control
        shipDock = this.add.image(width / 2, height * .89, 'shipDock').setDepth(11).setScale(.45);
        cannonBarrel = this.add.image(width / 2, height * .79, 'cannonBarrel').setDepth(15).setScale(.45).setInteractive();
        cannonBase = this.add.image(width / 2, height * .82, 'cannonBase').setDepth(13).setScale(.432);
        cannonIcon = this.add.image((width / 2) - (width * 0.09), height * 0.92, 'cannonIcon').setDepth(12).setScale(.6);
        //scoreText = this.add.text(width / 2 - (width * 0.07), height * 0.91, '8', { fontFamily: 'SF Pro Text', fontSize: '14px', color: "#000" }).setDepth(12);
        //scoreText = this.add.text(width / 2 + (width * 0.065), height * 0.91, '842', { fontFamily: 'SF Pro Text', fontSize: '22px', color: "#000" }).setDepth(12);
        //scoreText = this.add.text(width / 2 + (width * 0.07), height * 0.95, '87th', { fontFamily: 'SF Pro Text', fontSize: '14px', color: "#000" }).setDepth(12);
        shotText = this.add.text(width / 2 - (width * 0.07), height * 0.91, shots, { fontFamily: 'SF Pro Text', fontSize: '14px', color: "#000" }).setDepth(12);
        scoreText = this.add.text(width / 2 + (width * 0.065), height * 0.91, points, { fontFamily: 'SF Pro Text', fontSize: '22px', color: "#000" }).setDepth(12);
        rankText = this.add.text(width / 2 + (width * 0.065), height * 0.95, `Rank: ${rank}`, { fontFamily: 'SF Pro Text', fontSize: '14px', color: "#000" }).setDepth(12);
        tutorialText = this.add.text(width/2, height * .06, "Click and drag up on the cannon to Fire!", { fontFamily: 'SF Pro Text Heavy', fontSize: (mobile) ? 20 : 50, color: "#FFFFFF", letterSpacing: 2.63, align: "center", wordWrap: (mobile) ? { width: 300, useAdvancedWrap: true } : false});
        tutorialText.setOrigin(0.5, tutorialText.originY)
        multiText = this.add.text(width / 2 - (width * 0.013), height * .91, "", { fontFamily: 'SF Pro Text Heavy', fontSize: 40, letterSpacing: 2.63, color: "#FFFFFF", align: 'center' }).setDepth(12);
        //The pointer (mouse or finger --> touch devices)
        // cannonBarrel.on('pointerover', function(event) {
        //     cannonBarrel.angle = -180;
        // });

        // cannonBarrel.on('pointerout', function(event) {
        //     cannonBarrel.angle = 0; 
        // });

        // cannonball = this.add.image(width * 0.5, height * 0.5, 'cannonball').setDepth(16);

        this.input.setDraggable(cannonBarrel);
        this.input.on('drag', function (pointer, gameObject, dragX, dragY) {
            // vv OLD VALUES BELOW vv
            // var xMultiplier = 15;
            // var yMultiplier = 2.8;
            var xMultiplier = 1; 
            var yMultiplier = 1; 
            var controlXMultiplier = .5;
            var controlYMultiplier = .8;

            var newEndpointDragX;
            var newEndpointDragY;
            var newControlX;
            var newControlY;
            var dragXAbsolute;

            isDragged = true;
            midX = (width / 2);
            endpointY = (height * .75);
            controlY = (height * .20);




            dragDistance = Phaser.Math.Distance.Between(gameObject.x, gameObject.y, pointer.x, pointer.y);
            dragDistanceY = Phaser.Math.Distance.Between(gameObject.x, gameObject.y, gameObject.x, pointer.y);
            console.log('dragdistanceY - ' + dragDistanceY)
            console.log('pointerY ' + pointer.y)

            if (dragX <= midX) {
                dragXAbsolute = (midX - dragX);
                newEndpointDragX = midX - (dragXAbsolute * xMultiplier);
                newControlX = midX - ((dragXAbsolute * xMultiplier) * controlXMultiplier);
            }

            else if (dragX > midX) {
                dragXAbsolute = (dragX - midX);
                newEndpointDragX = midX + (dragXAbsolute * xMultiplier);
                newControlX = midX + ((dragXAbsolute * xMultiplier) * controlXMultiplier);
            }


            // newEndpointDragY = endpointY - (dragDistance * yMultiplier);
            newEndpointDragY = pointer.y < 170 ? 170 : (pointer.y > 630 ? 630 : pointer.y); 
            // newControlY = controlY - (dragDistance * controlYMultiplier);
            newControlY = pointer.y < 130 ? -312 : (pointer.y - (dragDistance * controlYMultiplier)); 

            endPointImg.x = newEndpointDragX;
            endPointImg.y = newEndpointDragY;
            controlPointImg.x = newControlX;
            controlPointImg.y = newControlY;
            endPointImg.data.get('vector').set(newEndpointDragX, newEndpointDragY);
            controlPointImg.data.get('vector').set(newControlX, newControlY);
            bezierPoints = bezierCurve.getSpacedPoints(32);

            // console.log("CANNON: " + gameObject.x + " , " + gameObject.y + " /MOUSE: " + pointer.x + ", " + pointer.y);
            var radians = Phaser.Math.Angle.Between(gameObject.x, gameObject.y, pointer.x, pointer.y);
            // console.log(radians);
            degrees = Phaser.Math.RadToDeg(radians);
            // console.log(degrees); 

            // vv [OLD CODE - INVERSE BASED] vv
            // var cannonRotate = degrees - 90; 

            var cannonRotate = degrees + 90;
            // console.log(cannonRotate)
            loadedCannonball = { x: gameObject.x, y: gameObject.y };

            if (degrees > 90 && degrees < 180) {
                cannonBarrel.angle = -65
            }
            else if (degrees < -155) {
                cannonBarrel.angle = -65
            }
            else if (degrees > 0 && degrees < 90) {
                cannonBarrel.angle = 65
            }
            else if (degrees < 0 && degrees > -25) {
                cannonBarrel.angle = 65
            }
            else { 
                cannonBarrel.angle = cannonRotate; 
            }


            // vv rotates the cannonBarrel [OLD CODE - INVERSE BASED] vv 
            // if (degrees < -90 && degrees > -180) {
            //     cannonBarrel.angle = 70;
            // }
            // else if (degrees > 160) {
            //     cannonBarrel.angle = 70;
            // }
            // else if (degrees <= 0) {
            //     cannonBarrel.angle = -70;
            // }
            // else if (degrees < 20) {
            //     cannonBarrel.angle = -70;
            // }
            // else {
            //     cannonBarrel.angle = cannonRotate;
            // }

            //     // //Generate the bezier arc 
            //     // var path = new Phaser.Curves.Path(); 
            //     // var curve = new Phaser.Curves.Line([gameObject.x, gameObject.y, 680, 600]);
            //     // graphics.lineStyle(1, 0x00ff00, 1); 
            //     // curve.draw(graphics); 


            //     //Generate the bezier arc 
            //     // path = new Phaser.Curves.Path(); 
            //     // curve = new Phaser.Curves.Line([gameObject.x, gameObject.y, 680, 1]);
            //     // graphics.lineStyle(1, 0x00ff00, 1); 
            //     // curve.draw(graphics); 

        });


        // let cannonballTest = this.add.image(width * 0.5, height * 0.5, 'cannonball').setDepth(16);
        this.input.on('dragend', function (pointer, gameObject) {
            //Grab colliders
            var greenShipCollider = game.physics.world.colliders.getActive().find(function (i) {
                return i.name === 'greenShipCollider';
            });
            var purpleShipCollider = game.physics.world.colliders.getActive().find(function (i) {
                return i.name === 'purpleShipCollider';
            });
            var yellowShipCollider = game.physics.world.colliders.getActive().find(function (i) {
                return i.name === 'yellowShipCollider';
            });

            if (greenShipCollider !== undefined) {
                greenShipCollider.destroy();
            }
            if (purpleShipCollider !== undefined) {
                purpleShipCollider.destroy();
            }
            if (yellowShipCollider !== undefined) {
                yellowShipCollider.destroy();
            }

            //Add conditional for cannon ('gameObject')
            // if (isDragged == true && gameState.loginReducer.user.cannonBallBalance >= 1) {
            // if statement below for testing feature without cannonball balance check
            if (isDragged == true) {
                // var pi = Math.PI;
                // var distance = 500

                // var calcDegrees = (degrees - 180) * (-1)

                // var calcRadians = calcDegrees * (pi / 180);
                // dragDistance = dragDistance * 5
                // cannonballVelocityX = dragDistance * (Math.cos(calcRadians))
                // cannonballVelocityY = dragDistance * (Math.sin(calcRadians))


                // cannonball = game.physics.add.group({
                //     key: 'cannonball',
                //     frameQuantity: 1,
                //     velocityX: cannonballVelocityX,
                //     velocityY: -cannonballVelocityY,
                //     collideWorldBounds: false,
                //     setXY: loadedCannonball
                // });
                // console.log(cannonballVelocityX, cannonballVelocityY)
                resetCannon = true;

                let airtime = (pointer.y > 0 && pointer.y < 165 ? 1500 : (pointer.y >= 165 && pointer.y < 215 ? dragDistanceY * 3.2 : (pointer.y >= 215 && pointer.y < 250 ? dragDistanceY * 2.8 : (pointer.y >= 250 && pointer.y < 378 ? dragDistanceY * 3 : 750)))); 
                // let airtime = dragDistanceY * 2;

                cannonball = cannonballs.create(0, -50, 'cannonball');
                cannonball.setDisplaySize(40, 40);

                cannonball.setData('vector', new Phaser.Math.Vector2());

                game.tweens.add({
                    targets: cannonball,
                    z: 1,
                    ease: 'easeInOut',
                    duration: airtime,
                    callbackScope: this,
                    onStart: function () {
                        cannonballsDisabled = true;
                    },
                    onComplete: function () {
                        cannonballsDisabled = false;
                        postFire = false;
                        fireComplete = true;
                        if (greenCollideCancel === false && cannonballsDisabled === false) {
                            game.physics.add.overlap(cannonballs, greenShip, explodeGreenShip, null, this).name = 'greenShipCollider';
                        }
                        if (purpleCollideCancel === false && cannonballsDisabled === false) {
                            game.physics.add.overlap(cannonballs, purpleShip, explodePurpleShip, null, this).name = 'purpleShipCollider';
                        }
                        if (yellowCollideCancel === false && cannonballsDisabled === false) {
                            game.physics.add.overlap(cannonballs, yellowShip, explodeYellowShip, null, this).name = 'yellowShipCollider';
                        }
                    }
                });



                setTimeout(function () {
                    cannonball.destroy();
                }, airtime + 25);

                cannonball.setDepth(13.5);
                cannonball.enableBody = true;
                cannonball.physicsBodyType = Phaser.Physics.ARCADE;

                // cannonball.createMultiple(1, 'cannonball');
                // cannonball.setAll('checkWorldBounds', true);
                // cannonball.setAll('outOfBoundsKill', true);


                // if (gameState.loginReducer.user.cannonBallBalance >= 1) {
                //         subtractCannonballBalance(gameState.loginReducer.loginToken, gameState.loginReducer.user.id, gameState.gameReducer.currentGame.gameid);
                //         gameState = store.getState();
                //         shots = gameState.loginReducer.user.cannonBallBalance;
                //         //console.log(shots);
                //         points = gameState.loginReducer.user.points;
                //         //console.log(points);
                //         rank = getRank();
                //         //console.log(rank);
                //         shotText.setText(shots);
                //         scoreText.setText(points);
                //         rankText.setText(rank);
                // }
                // else
                // {
                //     isDragged = false;
                // }

            }
            postFire = true;
            isDragged = false;

            if (firstShot) {
                firstShot = false;
            }
        });

    }

    update() {
        // gameState = store.getState();
        // console.log(gameState)
        waterLayer2.tilePositionX += 6;
        waterLayer3.tilePositionX -= 5;
        waterLayer4.tilePositionX += 3;
        waterLayer5.tilePositionX -= 7;
        waterLayer6.tilePositionX += 5;
        waterLayer7.tilePositionX -= 2;

        if (!firstShot) {
            tutorialText.destroy();
        }






        // if (transactionMonitor && transactionComplete) {
        //     shots = gameState.loginReducer.user.cannonBallBalance;
        //     points = gameState.loginReducer.user.points;
        //     rank = getRank();
        //     shotText.setText(shots);
        //     scoreText.setText(points);
        //     rankText.setText(`Rank: ${rank}`);
        //     transactionComplete = false; 
        //     transactionMonitor = false; 
        // }

        // BEZIER CURVE STUFF
        if (isDragged) {
            graphics.clear();
            //  Draw the curve through the points
            graphics.lineStyle(3, 0xF9CA14, 1);
            bezierCurve.draw(graphics).setDepth(13);
            bezierCurve.getPoint(bezierPath.t, bezierPath.vec);
            graphics.fillStyle(0xffff00, 1);
            // graphics.fillCircle(bezierPath.vec.x, bezierPath.vec.y, 16);
        }

        else if (!isDragged && postFire) {
            graphics.clear();
            graphics.lineStyle(3, 0xff0000, 0);
            bezierCurve.draw(graphics).setDepth(13);
            var balls = cannonballs.getChildren();
            for (var i = 0; i < balls.length; i++) {
                var t = balls[i].z;
                var vec = balls[i].getData('vector');

                bezierCurve.getPoint(t, vec);
                balls[i].setPosition(vec.x, vec.y);
                balls[i].setDepth(10);
            }
        } else {
            graphics.clear();
        }


        if (iter == 0 || iter == 600) {
            iter2 = iter2 * -1;
        }

        if (postFire && cannonball && cannonball.scene) {
            if (dragDistanceY < 150) {
                cannonball.scale = cannonball.scale * .996;
                
            }
            else if (dragDistanceY >= 150 && dragDistanceY <= 275) {
                cannonball.scale = cannonball.scale * .99;
       
            }
            else if (dragDistanceY > 275 && dragDistanceY <= 325) {
                cannonball.scale = cannonball.scale * .985;
          
            }
            // else if (dragDistanceY > 325 && dragDistanceY <= 365) {
            //     cannonball.scale = cannonball.scale * .98;
            //     console.log('100 and 150')
            // }
            // else if (dragDistanceY > 115 && dragDistanceY <= 120) {
            //     cannonball.scale = cannonball.scale * .975;
            //     console.log('100 and 150')
            // }
            else {
                cannonball.scale = cannonball.scale * .98;
            }
            // cannonball.scale = cannonball.scale * cannonballScaleDownMultiplier
        }

        if (fireComplete) {
            // console.log("fire complete")
            if (speedUpShips) {
                // console.log(greenShipMovement.timeScale);
                // console.log(purpleShipMovement.timeScale);
                // console.log(yellowShipMovement.timeScale);
                if (consecutiveHits > 0) {
                    if (consecutiveHits < 4) {
                        purpleShipMovement.timeScale = purpleShipMovement.timeScale * consecutiveHitSpeedMultiplier;
                        greenShipMovement.timeScale = greenShipMovement.timeScale * consecutiveHitSpeedMultiplier;
                        yellowShipMovement.timeScale = yellowShipMovement.timeScale * consecutiveHitSpeedMultiplier;
                        consecutiveHits++;
                        multiText.setText(`x${consecutiveHits}`)
                        if (consecutiveHits === 2) {
                            multiText.setFill("green");
                        }
                        else if (consecutiveHits === 3) {
                            multiText.setFill("purple");
                        }
                        else if (consecutiveHits === 4) {
                            multiText.setFill("red");
                        }
                    }
                    speedUpShips = false;
                }
                else {
                    consecutiveHits++;
                    multiText.setText(`x${consecutiveHits}`)
                    speedUpShips = false;
                }
                // console.log("Multiplier X" + consecutiveHits)
            } else {
                if (consecutiveHits > 0) {
                    resetShipSpeed = true;
                }
                // gameState = store.getState();
                subtractCannonballBalance(0);
                // shots = gameState.loginReducer.user.cannonBallBalance;
                // rank = getRank();
                // shotText.setText(shots);
                // rankText.setText(rank);
            }

            fireComplete = false;
        }

        if (resetShipSpeed) {
            // console.log("reset ship speed")
            purpleShipMovement.timeScale = 1;
            greenShipMovement.timeScale = 1;
            yellowShipMovement.timeScale = 1;
            consecutiveHits = 0;
            multiText.setText("");
            multiText.setFill("white");
            resetShipSpeed = false;
        }

        if (resetCannon) {
            if (resetCannonWait < 5) {
                resetCannonWait++;
                return;
            }
            if (cannonBarrel.angle < -.5) {
                cannonBarrel.angle += 1;
            } else if (cannonBarrel.angle > .5) {
                cannonBarrel.angle -= 1;
            } else {
                resetCannonWait = 0;
                endPointImg.x = midX;
                controlPointImg.x = midX;
                resetCannon = false;
            }
        }
    }
}

export default CannonGameScene; 