import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { styles } from './ModalStyles.js';
import { css } from 'aphrodite';
import { serverEnvironment } from '../../constants/ActionConstants.js';
import { closeSelectAvatar } from '../../actions/MenuActions';
import { updateUserProfile } from '../../actions/LoginActions';

class AvatarSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.currUser,
            selected: false, 
            avatar: "", 


        };
        this.selectAvatar = this.selectAvatar.bind(this);
        this.saveData = this.saveData.bind(this);
        this.regPic = this.regPic.bind(this);
    }

    regPic() {
        this.props.selectPic(serverEnvironment.photosHost + this.state.avatar);
        this.props.closeSelectAvatar();
    }

    selectAvatar(input) {
        if (this.state.selected === false) {
        this.setState({ selected: true });
        }
        this.setState({ avatar: input})
    }

    saveData() {
        var user = this.props.currUser;
        let avURL = serverEnvironment.photosHost;
        user.userProfileURL = avURL + this.state.avatar;
        this.props.updateUserProfile(this.props.token, user);
        // document.location = "/profile";
        this.props.closeSelectAvatar();
    }
    
    render() {

        let url = serverEnvironment.photosHost;

        return (
            <>
                <Col className={css(styles.avatarSelectionTitle)}>Pick an avatar for your account</Col>
                <Row className={css(styles.avatarSelectionRow)}>
                    <Col style={{ marginTop: 8, marginBottom: 8, display: "flex", justifyContent: "center" }}><img onClick={() => this.selectAvatar("/Blackbeard3x.svg")} className={css(styles.selectAvatar, (this.state.selected && this.state.avatar === "/Blackbeard3x.svg" ) && styles.selectAvatarChosen, (this.state.selected && this.state.avatar !== "/Blackbeard3x.svg") && styles.selectAvatarDarken)} src={url + "/Blackbeard3x.svg"} /></Col>
                    <Col style={{ marginTop: 8, marginBottom: 8, display: "flex", justifyContent: "center" }}><img onClick={() => this.selectAvatar("/AnneBonny3x.svg")} className={css(styles.selectAvatar, (this.state.selected && this.state.avatar === "/AnneBonny3x.svg" ) && styles.selectAvatarChosen, (this.state.selected && this.state.avatar !== "/AnneBonny3x.svg") && styles.selectAvatarDarken)} src={url + "/AnneBonny3x.svg"} /></Col>
                    <Col style={{ marginTop: 8, marginBottom: 8, display: "flex", justifyContent: "center" }}><img onClick={() => this.selectAvatar("/Parrot1.svg")} className={css(styles.selectAvatar, (this.state.selected && this.state.avatar === "/Parrot1.svg" ) && styles.selectAvatarChosen, (this.state.selected && this.state.avatar !== "/Parrot1.svg") && styles.selectAvatarDarken)} src={url + "/Parrot1.svg"} /></Col>
                    <Col style={{ marginTop: 8, marginBottom: 8, display: "flex", justifyContent: "center" }}><img onClick={() => this.selectAvatar("/Skull.svg")} className={css(styles.selectAvatar, (this.state.selected && this.state.avatar === "/Skull.svg" ) && styles.selectAvatarChosen, (this.state.selected && this.state.avatar !== "/Skull.svg") && styles.selectAvatarDarken)} src={url + "/Skull.svg"} /></Col>
                </Row>
                <Row className={css(styles.avatarButtonsRow)}>
                    <Col>
                        <Button onClick={this.props.closeSelectAvatar} className={css(styles.profileModalButton)}>CANCEL</Button>
                    </Col>
                    <Col>
                        {this.props.registration ? 
                        <Button disabled={!this.state.selected} className={this.state.selected ? css(styles.avatarSaveButton, styles.avatarSelectionButtonAlt) : css(styles.avatarSaveButtonDisabled, styles.avatarSelectionButtonAlt)} onClick={() => this.regPic()}>SELECT</Button>
                        :
                        <Button disabled={!this.state.selected} className={this.state.selected ? css(styles.avatarSaveButton, styles.avatarSelectionButtonAlt) : css(styles.avatarSaveButtonDisabled, styles.avatarSelectionButtonAlt)} onClick={this.saveData}>SAVE</Button>
                        }
                    </Col>
                </Row>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        currUser: state.loginReducer.user
    }
}

export default connect(mapStateToProps, {
    closeSelectAvatar,
    updateUserProfile
})(AvatarSelection);