export const userInterests = {
    toDoInterests: [
        {
            title: 'Home Cleaning',
            //OPTIONAL FIELD - USE THE FIELD BELOW IF WE PLAN ON HAVING "TAILOR ADS" FEATURE,
            //AND NOT JUST USE THIS PIECE FOR A USER SURVEY
            adTies: [
                'Johnson & Johnson', 'Pyrex', 'PineSol', 'EcoClean', 'Charmin', 'Roomba', 'Swiffer'
            ],
            //Photo Address stored in S3 Bucket 
            interestThumbnail: 'https://cdn.massagemag.com/wordpress/wp-content/uploads/house-keeping-e1489099892674.jpeg',
            //Has the user selected this as an interest or no? 
            userInterest: false
        },
        {
            title: 'Landscaping',
            adTies: [
                "Lowe's", 'Home Depot', 'Armstrong Garden Center', 'LeafLove', 'Roundup', 'John Deere'
            ],
            interestThumbnail: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.nighthelper.com%2Fwp-content%2Fuploads%2F2017%2F04%2Fcf-landscaping-logo-small.png.jpg&f=1&nofb=1',
            userInterest: true
        },
        {
            title: 'Dress Professional',
            adTies: [
                "Taft", "Men's Wearhouse", 'Jos A Bank', "Henry's", "Sak's Fifth"
            ],
            interestThumbnail: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2F2x1dks3q6aoj44bz1r1tr92f-wpengine.netdna-ssl.com%2Fwp-content%2Fuploads%2F2017%2F09%2FHeadless-Grey-Business-Professional-Suit.jpg&f=1&nofb=1',
            userInterest: true
        },
        {
            title: "Valentine's Day",
            adTies: [
                'Pandora', 'Pleasure Chest', 'Flowers.com', 'Rocky Mountain Chocolate', 'Hallmark'
            ],
            interestThumbnail: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fc2.staticflickr.com%2F8%2F7048%2F6938685946_917e5bf173_b.jpg&f=1&nofb=1',
            userInterest: false
        },
    ],
    generalInterests: [
        {
            title: "Travel", 
            adTies: [
                'Delta', 'REI', 'AirBnB', 'Expedia', 'Trivago', 'Atlantis Resorts'
            ],
            interestThumbnail: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.huffingtonpost.com%2Fasset%2F58a60b452900002b00f26e11.jpg%3Fops%3Dscalefit_720_noupscale&f=1&nofb=1',
            userInterest: false
        },
        {
            title: "Listen to Music", 
            adTies: [
                'Spotify', 'Taylor Guitars', 'Music Mart', 'HotNewHipHop', 'AirPods'
            ],
            interestThumbnail: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fs.thestreet.com%2Ffiles%2Ftsc%2Fv2008%2Fphotos%2Fcontrib%2Fuploads%2F4bc2fe3e-a643-11e8-9a56-cfe325b9a299_600x400.jpg&f=1&nofb=1',
            userInterest: true
        },
        {
            title: "Game", 
            adTies: [
                'Xbox', 'Playstation', 'Nintendo Switch', 'League of Legends', 'Alienware PC', 'Call of Duty'
            ],
            interestThumbnail: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fboygeniusreport.files.wordpress.com%2F2019%2F02%2Fanker-gaming-mouse.jpg%3Fquality%3D98%26strip%3Dall%26w%3D782&f=1&nofb=1',
            userInterest: true
        },
        {
            title: "Watch Movies", 
            adTies: [
                'Delta', 'REI', 'AirBnB', 'Expedia', 'Trivago', 'Atlantis Resorts'
            ],
            interestThumbnail: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.thepennyhoarder.com%2Fwp-content%2Fuploads%2F2014%2F08%2F29100047%2FWatch-Netflix2-1024x573.jpg&f=1&nofb=1',
            userInterest: false
        },
        {
            title: "Work Out", 
            adTies: [
                '24 Hour Fitness', 'Gymshark', 'Fitbit', 'Apple', 'Nike'
            ],
            interestThumbnail: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fstatic.thatsup.co%2Fcontent%2Fimg%2Farticle%2F12%2Foct%2Fguiden-till-stockholms-basta-gym.jpg%3F1546447650&f=1&nofb=1',
            userInterest: true
        },
        {
            title: "Read", 
            adTies: [
                'Audible', 'Barnes and Noble', 'Amazon'
            ],
            interestThumbnail: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmedia.salon.com%2F2010%2F09%2Fthe_trouble_with_google_books.jpg&f=1&nofb=1', 
            userInterest: false
        }, 
        {
            title: "Watch Live Sports", 
            adTies: [
                'Hulu', 'ESPN', 'SeatGeek', 'TicketMaster'
            ],
            interestThumbnail: 'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fs3.amazonaws.com%2Fdigitaltrends-uploads-prod%2F2016%2F01%2Fstadium-football-soccer-sports.jpg&f=1&nofb=1', 
            userInterest: false
        },
        {
            title: "Surf", 
            adTies: [
                'WSL', 'Billabong', 'Lost', 'Hansens'
            ], 
            interestThumbnail: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcrosseyedyo.files.wordpress.com%2F2013%2F01%2Fsurfer.jpg&f=1&nofb=1', 
            userInterest: false
        }, 
        {
            title: "Eat Out", 
            adTies: [
                'Yelp', 'Open Table'
            ],
            interestThumbnail: 'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F3.bp.blogspot.com%2F_LB1DvV0elJI%2FTNbR4iLy7mI%2FAAAAAAAAAEI%2F07EosQJIUYw%2Fs1600%2Frestaurant-fine-dining-1.jpg&f=1&nofb=1', 
            userInterest: false
        }
    ]
}