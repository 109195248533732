import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
  fadeBoxparent: {
    position: 'relative',
    width: '100%',
    //background: 'white',
    background: 'transparent',
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: "100vh",
    top: '0', right: '0', bottom: '0', left: '0',
    zIndex: 1
  },
  minigameContainer: {
    position: 'absolute',
    // height: '100%',
    width: '100%',
    //background: 'blue',
    background: 'transparent',
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: "100vh",
    //top: '0', right: '0', bottom: '0', left: '0',
    zIndex: 999
  },
  overlayContainer: {
    // position: 'absolute',
    // // height: '100%',
    // // width: '100%',
    // background: '#46433773',
    // //background: '#3A3934',
    // display: "flex",
    // alignContent: "center",
    // justifyContent: "center",
    // flexDirection: "row",
    //   height: "70vh",
    //   // marginTop: 50,
    //   // marginBottom: 80,
    //   //top: '0', right: '0', bottom: '0', left: '0',
    position: 'fixed', 
    zIndex: 999,
    transform: 'none',
    right: 0,
    bottom: 0,
    backgroundColor: "#00000030"
  },
  dataTop: {
    marginTop: 60,
    justifyContent: "center"
  },
  dataRow: {
    marginTop: 16,
    marginBottom: 16
  },
  dataSpacer: {
    marginTop: 26
  },
  imageAlignLeft: {
    marginLeft: 40
  },
  leaveGameButton: {
    background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
    position: 'fixed',
    width: 180,
    height: 32,
    right: "14%",
    top: "80%",
    border: "none",
    borderRadius: 4,
    fontFamily: 'SF Pro Text Semibold',
    fontSize: 15,
    letterSpacing: 0.79,
    textAlign: "center",
    color: "#3A3934",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
    ":focus:active": {
      boxShadow: "none",
      outline: "none"
    },
    ":focus": {
      outline: "none"
    },
    zIndex: 9999
  },
  leaveGameButtonConfirm: {
    background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
    position: 'fixed',
    width: 180,
    height: 32,
    right: "14%",
    top: "80%",
    border: "none",
    borderRadius: 4,
    fontFamily: 'SF Pro Text Semibold',
    fontSize: 15,
    letterSpacing: 0.79,
    textAlign: "center",
    color: "#3A3934",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
    ":focus:active": {
      boxShadow: "none",
      outline: "none"
    },
    ":focus": {
      outline: "none"
    },
    zIndex: 9999
  },
  addmoreshotsText: {
    height: 24,
    width: 205,
    color: "#3A3933",
    fontFamily: "SF Pro Text",
    fontSize: 20,
    fontWeight: 900,
    letterSpacing: 1.05,
    textAlign: "center"
  },
  addMoreButton: {
    background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
    position: 'fixed',  
    right: '36%',
    top: "75%",
    width: 280,
    height: 70,
    border: "none",
    borderRadius: 4,
    fontFamily: 'SF Pro Text Semibold',
    fontSize: 20,
    fontWeight: 'bold',
    letterSpacing: 1.05,
    textAlign: "center",
    fontColor: "#3A3934",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
    ":focus:active": {
      boxShadow: "none",
      outline: "none"
    },
    ":focus": {
      outline: "none"
    },
    zIndex: 9999
  },

  addShots: {
    height: 65,
    width: 250
  },
  smallWhiteText: {
    color: "#FFFFFF",
    fontFamily: "SF Pro Text Heavy",
    fontSize: 20,
    letterSpacing: 0.89,
    textAlign: "center"
  },
  largeWhiteText: {
    color: "#FFFFFF",
    fontFamily: "SF Pro Text Heavy",
    fontSize: 50,
    letterSpacing: 2.63,
    textAlign: "center"
  },
  largeWhiteTextDesc: {
    color: "#FFFFFF", 
    fontFamily: "SF Pro Text Heavy", 
    fontSize: 28, 
    fontWeight: 900, 
    letterSpacing: 1.47, 
    textAlign: "center"
  },
  AddMoreShotsBody: {
    paddingTop: 0,
    background: 'transparent',
    fontFamily: 'SF Pro Text Semibold'
  },
  AddMoreShotsHeader: {
    borderBottom: 'none',
    fontFamily: 'SF Pro Text Heavy',
    color: "#FFE400",
    letterSpacing: -0.17,
    textAlign: 'center'
  },
  AddMoreShotsSection: {
    textAlign: 'center'
  },
  AddMoreShotsSubtitle: {
    textAlign: 'center'
  },
  AddMoreShotsTitle: {
    margin: '0 auto'
  },
  overlayOrientation: {
    display: "flex", 
    justifyContent: "center"
  }, 
  skullContainer: {
    position: "absolute",
    top: 0, 
    left: 0, 
    width: "100%", 
    height: "100%", 
    justifyContent: "center", 
    display: "flex", 
    alignItems: "center" 
  }, 
  skullRibbonContainer: {
    position: "relative", 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center"
  }
}); 