import React from 'react'
import { Redirect } from 'react-router';
import { Overlay, Modal, Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Logic from './Logic'
import AddCredits from './../AddCredits'
import UI from './UI'
import { css } from 'aphrodite';
import { styles } from './MinigameStyles.js';
import { addMoreShots, closeMoreShots, getCannonballBalance, updateCannonballBalance } from '../../actions/MinigameActions';
import { getClientToken } from '../../actions/PaymentActions';
import { openAddCredits, closeAddCredits } from '../../actions/MenuActions';

class Minigame extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            leavingGame: false
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.backToPlndr = this.backToPlndr.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ leaveGame: false });
        }
    }
    GetCannonballBalance() {
        // console.log("getting balance");
        this.props.getCannonballBalance(this.props.logintoken, this.props.currUser.id);
    }
    onClickLeaveGame() {
        // console.log("LEAVE GAME CLICK");
        this.props.closeMoreShots();
        this.setState({ leavingGame: true });
    }
    onClickAddMoreShots() {
        this.props.addMoreShots();
        this.props.openAddCredits();
    }

    onHideModal() {
        this.setState({ leavingGame: false });
    }

    getRank() {
        var rank = 0;
        var userid = this.props.currUser.id;
        // var topRankPoints = this.props.leaderboard.rankings[0].points;
        // console.log(topRankPoints);
        this.props.leaderboard.rankings.forEach(function (item, index, array) {
            if (item.player.userId === userid) {
                rank = item.rank;
                switch (rank) { //We should probably look at the ones digit for 21, 22, 23, 31, 32, 33, 141, 144, 143, etc. 
                    //Only exceptions are the "Teens" (11, 12, 13, 111, 112, 113, etc.)
                    case 1: rank = rank + "st";
                        break;
                    case 2: rank = rank + "nd";
                        break;
                    case 3: rank = rank + "rd";
                        break;
                    default:
                        rank = rank + "th";
                }
            }
        })
        return rank;
    }

    backToPlndr() {
        document.location = "/"
    }

    render() {
        return (
            <div className={css(styles.fadeBoxparent)} ref={this.setWrapperRef}>
                {(this.props.currUser.cannonBallBalance <= 0 || this.state.leavingGame) ?
                    (
                        <Overlay target={this.ref} show={true} placement="auto">
                            {({ placement, arrowProps, show: _show, popper, ...props }) => (
                                <div
                                    {...props}
                                    className={css(styles.overlayContainer)}
                                >
                                { (this.props.leaderboard.rankings.length > 0) ?
                                ( <div>
                                    <Row className={css(styles.dataTop)}>
                                        <Col className={css(styles.overlayOrientation)}>
                                        <div>
                                                <div>&nbsp;</div>
                                                <div className={css(styles.skullRibbonContainer)}>
                                                    <img
                                                        alt=""
                                                        src={require("../../images/IllustartionYellowBadge.png")}
                                                        width="250"
                                                        height="250"
                                                    />
                                                    <div className={css(styles.skullContainer)}>
                                                        <img
                                                            alt=""
                                                            src={require("../../images/skull.svg")}
                                                            style={{ marginTop: -10 }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={css(styles.smallWhiteText)}>You're ranked</div>
                                                <div className={css(styles.largeWhiteText)}>{this.getRank()} &nbsp;
                                                <img
                                                            alt=""
                                                            src={require("../../images/AvatarPlndr.png")}
                                                            width="50"
                                                            height="50"
                                                        />
                                                </div>
                                                <div className={css(styles.largeWhiteTextDesc)}>and {this.props.leaderboard.rankings[0].points - this.props.currUser.points} points away</div>
                                                <div className={css(styles.largeWhiteTextDesc)}>from winning ${this.props.leaderboard.prizeValue}</div>
                                                {/* <div className={css(styles.smallWhiteText)}> with {this.props.leaderboard.rankings[0].points} remaining</div> */}
                                                </div>
                                        </Col>
                                    </Row> 
                                    </div>)
                                    : 
                                    ( <div>
                                        <Row className={css(styles.dataTop)}>
                                            <Col className={css(styles.overlayOrientation)}>
                                            <div>
                                                    <div>&nbsp;</div>
                                                    <div className={css(styles.skullRibbonContainer)}>
                                                        <img
                                                            alt=""
                                                            src={require("../../images/IllustartionYellowBadge.png")}
                                                            width="250"
                                                            height="250"
                                                        />
                                                        <div className={css(styles.skullContainer)}>
                                                            <img
                                                                alt=""
                                                                src={require("../../images/skull.svg")}
                                                                style={{ marginTop: -10 }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={css(styles.smallWhiteText)}>You're not ranked yet!</div>
                                                    <div className={css(styles.largeWhiteText)}> &nbsp;
                                                    <img
                                                                alt=""
                                                                src={require("../../images/AvatarPlndr.png")}
                                                                width="50"
                                                                height="50"
                                                            />
                                                    </div>
                                                    <div className={css(styles.largeWhiteTextDesc)}> Add Shots and Earn Points </div>
                                                    <div className={css(styles.largeWhiteTextDesc)}> to Enter the Leaderboard!</div>
                                                    </div>
                                            </Col>
                                        </Row> 
                                </div>)
                                }
                                {(this.props.currUser.cannonBallBalance <= 0) ?
                                    (
                                        <button id="addmoreshots" ref={this.setWrapperRef()} className={css(styles.addMoreButton)} onClick={() => this.onClickAddMoreShots()}>ADD MORE SHOTS</button>
                                    )
                                    :
                                    (
                                        null
                                    )
                                }
                                <button id="leavebtn" ref={this.setWrapperRef()} className={css(styles.leaveGameButtonConfirm)} onClick={this.backToPlndr}>BACK TO PLNDR</button>

                                </div>
                            )}
                        </Overlay>
                    ) : null
                }
                {(!this.state.leavingGame && this.props.currUser.cannonBallBalance > 0) &&
                    <button id="leavebtn" ref={this.setWrapperRef()} className={css(styles.leaveGameButton)} onClick={() => this.onClickLeaveGame()}>LEAVE GAME</button>
                }
                <Logic />
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        currUser: state.loginReducer.user,
        logintoken: state.loginReducer.loginToken,
        cannonshotsupdated: state.minigameReducer.cannonshotsupdated,
        leaderboard: state.gameReducer.leaderboard,
        addingcredits: state.noPersistReducer.addingCredits,
        addingmoreshots: state.noPersistReducer.addingMoreShots
    }
}

export default connect(mapStateToProps, {
    getCannonballBalance,
    addMoreShots,
    closeMoreShots,
    openAddCredits
})(Minigame);

//export default Minigame