import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
    fixedBoxContainer: {
        position: "relative",
        height: "82vh",
        width: 350,
        // width: '24.3vw', 
        backgroundColor: "#3A3934",
        borderRadius: 8,
        boxShadow: "0 0 6px rgba(0,0,0,0.16), 0 6px 6px 0 rgba(0,0,0,0.16)",
    },
    homeContentRow: {
        minHeight: '100vh',
        marginTop: -10
    },
    leaderBox: {
        marginLeft: 40, 
    },
    leaderBoxMobile: {
        position: 'absolute', 
        zIndex: 1000000, 
        '@media (max-width: 450px)': {

        },
        '@media (max-width: 400px)': {
            marginLeft: 32
        }, 
        '@media (max-width: 375px)': {
            marginLeft: 20
        }
    },
    plndrViewContainer: {
        overflow: "hidden",
        maxHeight: '100vh',
        zIndex: 1
    },
    cookieOverlay: {
        zIndex: 10000000,
        backdropFilter: "blur(8px)",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "10%",
        top: "90%",
        left: 0,
        position: "fixed",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    cookieOverlayHidden: {
        display: "none"
    },
    homeFooterContainer: {
        position: "absolute",
        top: "90%",
        //left: "10%",
        // transform: "translate(-50%, -50%)",
        textAlign: "center",
        width: "100%"
    },
    homeFooterText: {
        fontSize: "20px",
        fontFamily: 'SF Pro Text Light'
    },
    homeFooterTextLink: {
        fontSize: 20, 
        fontFamily: "SF Pro Text Semibold", 
        textDecorationColor: "#F9CA14", 
        color: "#F9CA14", 
        textDecoration: "underline"
    },
    homeAcceptButton: {
        width: "140px",
        height: "40px",
        margin: "10px",
        background: "linear-gradient(270deg, #FFE300 0%, #FFC500 100%)",
        border: "none",
        borderRadius: 4,
        fontSize: 20,
        letterSpacing: 0.79,
        fontFamily: "SF Pro Text Heavy",
        color: "#3A3933",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "none"
        }
    }, 
    noSelect: {
        WebkitTouchCallout: 'none', /* iOS Safari */
        WebkitUserSelect: 'none', /* Safari */
        KhtmlUserSelect: 'none', /* Konqueror HTML */
        MozUserSelect: 'none', /* Old versions of Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */
        userSelect: 'none' /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    },
    openSideMenuButton: {
        marginTop: 9, 
        marginLeft: 9, 
        border: '2px solid', 
        borderRadius: 8, 
        padding: 5,
        zIndex: 1, 
        ':hover': {
            cursor: 'pointer'
        }
    }, 
    openSideMenuButtonAlt: {
        marginTop: 9, 
        marginLeft: 9, 
        borderRadius: 8, 
        zIndex: 1, 
        ':hover': {
            cursor: 'pointer'
        }
    }, 
    mqcontainerBox: {
        '@media (min-width: 1101px) and (max-width: 1350px)': {
            marginLeft: 35
        }, 
        '@media (min-width: 1041px) and (max-width: 1100px)': {
            marginLeft: 56
        }, 
        '@media (min-width: 768px) and (max-width: 1040px)': {
            marginLeft: 68
        }, 
        '@media (max-width: 767px)': {
            marginRight: 10
        }
    },
    mqfixedBoxContainer: {
        '@media (max-width: 450px)': {
            width: 300
        }, 
        '@media (max-width: 375px)': {
            width: 290
        }
    },  
    mqleaderBox: {
        '@media (max-width: 1350px)': {
            marginLeft: 25
        }, 
        '@media (max-width: 1040px)': {
            marginLeft: 15
        }
    }, 
});