/*
WalletActions.js
These are actions that return information assocaited with customer's payment methods
*/

import { walletActionTypes, pending_function, rejected_function, fulfilled_function, serverEnvironment, loginActionTypes, rejected } from '../constants/ActionConstants';
import axios from 'axios';
import swal from 'sweetalert'; 

export var axiosConfig = {
    headers: { 'token': '', 'refUrl': document.location.host }
};


export function getPaymentMethods(token, payload) {
    const { GET_PAYMENT_METHODS } = walletActionTypes;

    return function (dispatch) {
        dispatch(pending_function(GET_PAYMENT_METHODS)); 
        axiosConfig.headers.token = token;
        axios
            .get(serverEnvironment.apiHost + '/getpaymentmethods/' + String(payload.userId), axiosConfig)
            .then((res) => {
                let paymentmethodList = res.data;
                // console.log(paymentmethodList);
                dispatch(fulfilled_function(GET_PAYMENT_METHODS, paymentmethodList));
            })
            .catch(err => {
                dispatch(rejected_function(GET_PAYMENT_METHODS, err));
                // swal("Error on getPaymentMethods", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    }
}

export function createPaymentMethod(token, payload) {
    const { CREATE_PAYMETHOD } = walletActionTypes; 

    return function (dispatch) {
        dispatch(pending_function(CREATE_PAYMETHOD)); 
        axiosConfig.headers.token = token;
        axios
            .post(serverEnvironment.apiHost + '/createpaymentmethod' , payload, axiosConfig)
            .then((res) => {
                let paymentmethodList = res.data;
                // console.log(paymentmethodList);
                dispatch(fulfilled_function(CREATE_PAYMETHOD, paymentmethodList)); 
            })
            .catch(err => {
                dispatch(rejected_function(CREATE_PAYMETHOD, err)); 
                swal("Error on createPaymentMethod", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    };
}

export function updatePaymentMethod(token, payload) {
    const { UPDATE_PAYMETHOD } = walletActionTypes;

    return function (dispatch) {
        dispatch(pending_function(UPDATE_PAYMETHOD));
        axiosConfig.headers.token = token;
        axios
            .post(serverEnvironment.apiHost + '/updatepaymentmethod/' + String(payload.userId), {}, axiosConfig)
            .then((res) => {
                let paymentmethodList = res.data;
                // console.log(paymentmethodList);
                dispatch(fulfilled_function(UPDATE_PAYMETHOD, paymentmethodList));
            })
            .catch(err => {
                dispatch(rejected_function(UPDATE_PAYMETHOD, err));
                swal("Error on updatePaymentMethod", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    };
}

export function deletePaymentMethod(token, payload) {
    const { DELETE_PAYMETHOD } = walletActionTypes;

    return function (dispatch) {
        dispatch(pending_function(DELETE_PAYMETHOD));
        axiosConfig.headers.token = token;
        axios
            .post(serverEnvironment.apiHost + '/deletepaymentmethod/' + String(payload.userId), {}, axiosConfig)
            .then((res) => {
                // console.log(res);
                let paymentmethodList = res.data;
                // console.log(paymentmethodList);
                dispatch(fulfilled_function(DELETE_PAYMETHOD, paymentmethodList));
            })
            .catch(err => {
                dispatch(rejected_function(DELETE_PAYMETHOD, err));
                swal("Error on deletePaymentMethod", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    };
}

