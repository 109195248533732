import React from 'react';
import { Container, Button, Modal, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { sleep } from '../../constants/ActionConstants.js';
import { closePayout } from '../../actions/MenuActions';
import { createPayout, updateUser } from '../../actions/PayoutActions';
import { css } from 'aphrodite';
import { styles } from './PayoutStyles.js';
import { core } from '../../lib/core/lib';
import { payouts } from '../../lib/payouts/lib';
import { payoutsSdk } from '@paypal/payouts-sdk';
import { payPalClient } from './payPalClient';
import swal from 'sweetalert';
import MediaQuery from 'react-responsive';

class Payout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paypalSelected: true,
            validated: false,
            transactionValue: "10"
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onClickClosePayout = this.onClickClosePayout.bind(this);
        this.onClickClosePayoutConfirm = this.onClickClosePayoutConfirm.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            //this.props.closePayout();
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        this.onClickClosePayout();
    }

    onClickClosePayout() {
        this.props.closePayout();
    }

    onClickClosePayoutConfirm() {
        if (this.props.transactpayout) {
            this.props.updateUser(this.props.logintoken, this.props.currentuser.id);
        }
        sleep(500).then(() => {
            document.location = "/";
        })
    }

    onclickpayoutMethod() {
        if (document.getElementById("chkpaypalmethod").checked) {
            document.getElementById("chkvenmomethod").checked = false;
            this.setState({ paypalSelected: true });
        }
        else if (document.getElementById("chkvenmomethod").checked) {
            document.getElementById("chkpaypalmethod").checked = false;
            this.setState({ paypalSelected: false });
        }
    }
    createPayoutAction() {
        // console.log("In createPayoutAction");
        //var chargeAmount = Number(document.getElementById("chargeAmount").innerHTML.replace(/[^0-9\.]+/g, ""));
        var chargeAmount = parseInt(document.getElementById("chargeAmount").value);
        //console.log(chargeAmount);
        var chargeAmountWithTransFee = chargeAmount - ((chargeAmount * 0.032) + 0.30);
        var payload = {
            userId: this.props.currentuser.id,
            paypalamount: chargeAmountWithTransFee.toFixed(2),  // Paypal transaction fee 2.9% + $0.30
            plndramount: chargeAmount,
            authToken: this.props.authtoken,
            note: 'Enjoy Your Payout!'
        }
        if (this.state.validated) {
            if (this.state.paypalSelected) {
                payload.email = document.getElementById("paypalemail").value;
                payload.wallet = "PAYPAL";
            }
            else {
                payload.phone = document.getElementById("venmophone").value.toString();
                payload.wallet = "VENMO";
            }
            if ((this.props.currentuser.balance >= chargeAmount) && (chargeAmount >= 6)) {
                this.props.createPayout(this.props.logintoken, payload);
            }
            else {
                // alert("Insufficient Minimum balance for cash-out")
                if (chargeAmount < 6) {
                    swal({
                        text: "Minimum Cash-out amount is $6.00",
                        icon: "error"
                    });

                }
                else if (this.props.currentuser.balance < chargeAmount) {
                    swal({
                        text: "Insufficient Funds for cash-out",
                        icon: "error"
                    });
                }
            }
            // console.log('payout is called with: ' + JSON.stringify(payload));
        }
    }

    increaseAmount(e) {
        var newnum = parseInt(document.getElementById("chargeAmount").value);
        newnum += 1;
        this.setState({ transactionValue: newnum });
        // var chargeamtdouble = Number(document.getElementById("chargeAmount").innerHTML.replace(/[^0-9\.]+/g, ""));
        // var newnum = chargeamtdouble + 1;
        // document.getElementById("chargeAmount").innerText = "$" + newnum + ".00";
        // this.setState({ transactionValue: newnum + ".00" })

    }
    decreaseAmount(e) {
        var newnum = parseInt(document.getElementById("chargeAmount").value);
        newnum -= 1;
        if (newnum >= 6) {
            this.setState({ transactionValue: newnum });
        }
        else {
            swal({
                text: "Minimum Value for cash-out is $6.00",
                icon: "info"
            });
        }
        // var chargeamtdouble = Number(document.getElementById("chargeAmount").innerHTML.replace(/[^0-9\.]+/g, ""));
        // var newnum = chargeamtdouble - 1;
        // if (newnum >= 6) {
        //     document.getElementById("chargeAmount").innerText = "$" + newnum + ".00";
        //     this.setState({ transactionValue: newnum + ".00" });
        // }
    }
    validateEmail(e) {
        // Email without unicode support
        //RegExp(^[-.\w]+@(?![^.]{0,2}\.[a-zA-Z]{2,}$)([-a-zA-Z0-9]+\.)+[a-zA-Z]{2,}$)
        let value = e.target.value
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emailvalidate = re.test(value);
        if (!emailvalidate) {
            // alert("Please enter a valid email");
            swal({
                text: "Please enter a valid email",
                icon: "warning"
            });
        }
        else {
            if ((document.getElementById("paypalemail").value != "" && document.getElementById("confirmpaypalemail").value != "") && (document.getElementById("paypalemail").value != document.getElementById("confirmpaypalemail").value)) {
                // alert("Email addresses did not match");
                swal({
                    text: "Email addresses did not match",
                    icon: "error"
                });
            }
            else {
                this.setState({ validated: emailvalidate })
            }
        }
    }
    validatePhone(e) {
        let value = e.target.value
        var phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
        let phonevalidate = phoneRe.test(value);
        if (!phonevalidate)
            // alert("Please enter a valid phone number");
            swal({
                text: "Please enter a valid phone number",
                icon: "warning"
            });
        else {
            if ((document.getElementById("venmophone").value != "" && document.getElementById("confirmvenmophone").value != "") && (document.getElementById("venmophone").value != document.getElementById("confirmvenmophone").value)) {
                // alert("Phone Numbers did not match");
                swal({
                    text: "Phone Numbers did not match",
                    icon: "error"
                });
            }
            else {
                this.setState({ validated: phonevalidate })
            }
        }
    }
    calculateFee() {
        let transactionValue = Number(this.state.transactionValue);
        let fee = (transactionValue * 0.032) + 0.3;
        return fee.toFixed(2);
    }
    updateTransactValue(e) {
        //var chargeamtdouble = parseInt(document.getElementById("chargeAmount").value);
        this.setState({ transactionValue: parseInt(e.target.value) ? parseInt(e.target.value) : '' });
        //document.getElementById("chargeAmount").innerText = newnum;
        //this.setState({ transactionValue: newnum + ".00" });
    }
    render() {
        return (
            this.props.transactpayout && this.props.dowithdrawing ?
                (
                    <div className={css(styles.bootstrapBasic, styles.withdrawbody, styles.noSelect)} ref={this.setWrapperRef}>
                        <Row className={css(styles.menuRowExit)}>
                            <Col className={css(styles.closeMenuButton, styles.mqcloseMenuButton)} onClick={() => this.onClickClosePayoutConfirm()}><img className={css(styles.addFundsIcon, styles.menuRowOption)} src={require('../../images/exitalt.svg')} /></Col>
                        </Row>
                        <Row className={css(styles.menuRowOption)}>
                            <Col className={css(styles.withdrawTitleRow, styles.withdrawTitle)} >Withdraw Confirmation</Col>
                        </Row>
                        <Row className={css(styles.menuRow, styles.menuRowOptionPayoutValue)}>
                            <Col className={css(styles.confirmAmountTitlefonts)}>
                                <span> {this.props.payout.amount ?
                                    `$${this.props.payout.amount.toFixed(2)}`
                                    : null} </span>
                                <div className={css(styles.paidText)}> {this.props.payout.payoutStatus === "PENDING" ? "Withdrawn" : this.props.payout.payoutStatus}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <hr className={css(styles.confirmationDivider)}></hr>
                            </Col>
                            <Col md={1}>
                            </Col>
                        </Row>
                        <Row className={css(styles.menuRow, styles.menuRowConfirmTitle)}>
                            <Col md={10} className={css(styles.menuOptionCol, styles.payoutconfirmSmallFonts)} >
                                <span>Transaction ID: </span>
                            </Col>
                        </Row>
                        <Row className={css(styles.menuRowConfirm)}>
                            <Col md={10} className={css(styles.menuOptionCol, styles.payoutconfirmSmallDarkFonts)} >
                                <span>{this.props.payout.payoutId}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <hr className={css(styles.confirmationDivider)}></hr>
                            </Col>
                            <Col md={1}>
                            </Col>
                        </Row>
                        <Row className={css(styles.menuRow, styles.menuRowConfirmTitle)}>
                            <Col md={10} className={css(styles.menuOptionCol, styles.payoutconfirmSmallFonts)} >
                                <span> Funds Sent To</span>
                            </Col>
                        </Row>
                        <Row className={css(styles.menuRow, styles.menuRowConfirm)}>
                            <Col md={10} className={css(styles.menuOptionCol, styles.payoutconfirmSmallDarkFonts)} >
                                <span> {this.props.payout.payoutMethodType} Account</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <hr className={css(styles.confirmationDivider)}></hr>
                            </Col>
                            <Col md={1}>
                            </Col>
                        </Row>
                        <Row className={css(styles.menuRow, styles.menuRowConfirmTitle)}>
                            <Col md={10} className={css(styles.menuOptionCol, styles.payoutconfirmSmallFonts)} >
                                <span> Receipt Sent To: </span>
                            </Col>
                        </Row>
                        <Row className={css(styles.menuRowConfirm)}>
                            <Col md={10} className={css(styles.menuOptionCol, styles.payoutconfirmSmallDarkFonts)} >
                                <span> {this.props.payout.contactInfo} </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <hr className={css(styles.confirmationDivider)}></hr>
                            </Col>
                            <Col md={1}>
                            </Col>
                        </Row>
                        <Row className={css(styles.menuRow, styles.menuRowConfirmTitle)}>
                            <Col md={10} className={css(styles.menuOptionCol, styles.payoutconfirmSmallFonts)} >
                                <span> Estimated Arrival </span>
                            </Col>
                        </Row>
                        <Row className={css(styles.menuRowConfirm)}>
                            <Col md={10} className={css(styles.menuOptionCol, styles.payoutconfirmSmallDarkFonts)} >
                                <span> 2-3 Business days </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <hr className={css(styles.confirmationDivider)}></hr>
                            </Col>
                            <Col md={1}>
                            </Col>
                        </Row>
                        <Row className={css(styles.menuRowOptionPayRow)}>
                            <Col>
                                <div className={css(styles.dropInBtnContainer)}>
                                    <button className={css(styles.dropinbtn, styles.addcreditButton)} id="confirmButton" onClick={() => this.onClickClosePayoutConfirm()}>Back to Plndr</button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                )
                :
                (
                    <>
                        <div className={css(styles.bootstrapBasic, styles.withdrawbody, styles.noSelect)} ref={this.setWrapperRef}>
                            <Row className={css(styles.menuRowExit)}>
                                <Col className={css(styles.closeMenuButton, styles.mqcloseMenuButton)}><img className={css(styles.addFundsIcon, styles.menuRowOption)} src={require('../../images/exitalt.svg')} onClick={() => this.onClickClosePayout()} /></Col>
                            </Row>
                            <Row className={css(styles.menuRowWithdrawFunds)}>
                                <Col md={6} className={css(styles.withdrawTitleRow, styles.withdrawTitle, styles.noSelect)}>Withdraw Funds</Col>
                            </Row>
                            <Row className={css(styles.menuRow, styles.menuRowOptionPaymentValue)}>
                                {/* <Col md={1}></Col>
                                <Col md={2} className={css(styles.minusSignContain, styles.noSelect)}><div className={css(styles.circle)}><span className={css(styles.minussign)} id="minus" onClick={() => this.decreaseAmount()}> - </span></div> </Col>
                                <Col md={6} className={css(styles.amountTitlefonts)}> <span id="chargeAmount">${this.state.transactionValue}</span> </Col>
                                <Col md={2} className={css(styles.minusSignContain, styles.noSelect)}><div className={css(styles.circle)}><span className={css(styles.plussign)} id="plus" onClick={() => this.increaseAmount()}> + </span></div></Col>
                                <Col md={1}></Col> */}
                                <MediaQuery minWidth={992}>
                                    <Col md={1}></Col>
                                    <Col md={2} className={css(styles.minusSignContain)}><div className={css(styles.circle)} onClick={(e) => this.decreaseAmount(e)}><span className={css(styles.minussign)} id="minus"> - </span></div> </Col>
                                    <Col md={6} className={css(styles.amountTitlefontsRow)}>
                                        &nbsp;&nbsp;&nbsp;
                                    <span>$</span>
                                        <input className={css(styles.dollarAmount, styles.amountTitlefonts)} type="number" value={this.state.transactionValue} id="chargeAmount" required min="1" maxLength="6" onChange={(e) => this.updateTransactValue(e)}></input>
                                        {/* <span>.00</span> */}
                                    </Col>
                                    <Col md={2} className={css(styles.minusSignContain)}><div className={css(styles.circle)} onClick={(e) => this.increaseAmount(e)}><span className={css(styles.plussign)} id="plus"> + </span></div></Col>
                                    <Col md={1}></Col>
                                </MediaQuery>
                                <MediaQuery maxWidth={991}>

                                    <Col md={3} sm={3} xs={3} className={css(styles.minusSignContain)}><div className={css(styles.circle, styles.mqcircle)} onClick={(e) => this.decreaseAmount(e)}><span className={css(styles.minussign)} id="minus"> - </span></div> </Col>
                                    <Col md={6} sm={6} xs={6} className={css(styles.amountTitlefontsRow, styles.mqamountTitlefontsRow)}>
                                        <span>$</span>
                                        <input className={css(styles.dollarAmount, styles.amountTitlefonts, styles.mqdollarAmount)} type="number" value={this.state.transactionValue} id="chargeAmount" required min="1" maxLength="6" onChange={(e) => this.updateTransactValue(e)}></input>
                                        {/* <span>.00</span> */}
                                    </Col>
                                    <Col md={3} sm={3} xs={3} className={css(styles.minusSignContain)}><div className={css(styles.circle, styles.mqcircle)} onClick={(e) => this.increaseAmount(e)}><span className={css(styles.plussign)} id="plus"> + </span></div></Col>

                                </MediaQuery>
                            </Row>
                            <Row>
                                <Col md={2} sm={2} xs={2}></Col>
                                <Col md={8} sm={8} xs={8} style={{ paddingRight: 0, paddingLeft: 0 }}><hr className={css(styles.menuDivider)}></hr></Col>
                                <Col md={2} sm={8} xs={8}></Col>
                            </Row>
                            <Row className={css(styles.menuRowMin)}>
                                <Col className={css(styles.withdrawSmallFonts)}> <span>Minimum Withdrawal: $6.00</span> </Col>
                            </Row>
                            <div className={css(styles.payoutContainer)}>
                                <Row className={css(styles.menuRowSendFunds)}>
                                    <Col md={1}></Col>
                                    <Col md={7} className={css(styles.menuOptionHeader)}>
                                        <span>Send funds to:</span>
                                    </Col>
                                    <Col md={4} className={css(styles.payoutOptionsCol)}>
                                    </Col>
                                </Row>
                                <Row className={css(styles.menuRow, styles.menuRowOptionPaymentValue)}>
                                    <MediaQuery minWidth={401}><Col md={6} sm={6} xs={6} className={css(styles.menuOptionColLogo, styles.amountfonts, styles.noSelect)} >
                                        <input type="radio" id="chkpaypalmethod" name="payoutmethod" value="paypal" onClick={(event) => this.onclickpayoutMethod(event)} defaultChecked />
                                        {/* <label htmlFor="paypal">Paypal</label> */}
                                        <img className={css(styles.mqOptionImage)} style={{ width: 140 }} src={require("../../images/payPalLogo.png")} />
                                    </Col>
                                        <Col md={6} sm={6} xs={6} className={css(styles.menuOptionColLogo, styles.amountfonts, styles.noSelect)} >
                                            <input type="radio" id="chkvenmomethod" name="payoutmethod" value="venmo" onClick={(event) => this.onclickpayoutMethod(event)} />
                                            {/* <label htmlFor="venmo">Venmo</label> */}
                                            <img className={css(styles.mqOptionImage)} style={{ width: 130 }} src={require("../../images/venmoLogo.png")} />
                                        </Col>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={400}>
                                    <Col xs={12} className={css(styles.menuOptionColLogo, styles.amountfonts, styles.noSelect)} >
                                        <input type="radio" id="chkpaypalmethod" name="payoutmethod" value="paypal" onClick={(event) => this.onclickpayoutMethod(event)} defaultChecked />
                                        {/* <label htmlFor="paypal">Paypal</label> */}
                                        <img className={css(styles.mqOptionImage)} style={{ width: 140 }} src={require("../../images/payPalLogo.png")} />
                                    </Col>
                                    <Col xs={12} className={css(styles.menuOptionColLogo, styles.amountfonts, styles.noSelect)} >
                                        <input type="radio" id="chkvenmomethod" name="payoutmethod" value="venmo" onClick={(event) => this.onclickpayoutMethod(event)} />
                                        {/* <label htmlFor="venmo">Venmo</label> */}
                                        <img className={css(styles.mqOptionImage)} style={{ width: 130 }} src={require("../../images/venmoLogo.png")} />
                                    </Col>
                                    </MediaQuery>
                                </Row>
                                {this.state.paypalSelected ? (
                                    <>
                                        <Row className={css(styles.menuRowPayoutEntry)}>
                                            <Col className={css(styles.menuOptionCol, styles.withdrawSmallFonts)} >
                                                <span className={css(styles.payoutMethodTitle)} id="payoutMethodTitle"> Paypal Email:  </span>
                                                <input className={css(styles.payoutInput)} type="email" id="paypalemail" pattern=".+@.com" size="30" onBlur={(event) => this.validateEmail(event)}></input>
                                            </Col>
                                        </Row>
                                        <Row className={css(styles.menuRowPayoutEntry)}>
                                            <Col className={css(styles.menuOptionCol, styles.withdrawSmallFonts)} >
                                                <span className={css(styles.payoutMethodTitle)} id="payoutMethodTitle"> Confirm Paypal Email:  </span>
                                                <input className={css(styles.payoutInput)} type="email" id="confirmpaypalemail" pattern=".+@.com" size="30" onBlur={(event) => this.validateEmail(event)}></input>
                                            </Col>
                                        </Row>
                                    </>
                                ) :
                                    (<>
                                        <Row className={css(styles.menuRowPayoutEntry)}>
                                            <Col className={css(styles.withdrawSmallFonts)} >
                                                <span className={css(styles.payoutMethodTitle)} id="payoutMethodTitle"> Venmo Phone #:  </span>
                                                <input className={css(styles.payoutInput)} placeholder="1234567890" type="tel" id="venmophone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" size="12" onBlur={(event) => this.validatePhone(event)}></input>
                                            </Col>
                                        </Row>
                                        <Row className={css(styles.menuRowPayoutEntry)}>
                                            <Col className={css(styles.withdrawSmallFonts)} >
                                                <span className={css(styles.payoutMethodTitle)} id="payoutMethodTitle"> Confirm Venmo Phone #:  </span>
                                                <input className={css(styles.payoutInput)} placeholder="1234567890" type="tel" id="confirmvenmophone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" size="12" onBlur={(event) => this.validatePhone(event)}></input>
                                            </Col>
                                        </Row>
                                    </>
                                    )}
                                <Row className={css(styles.menuRow, styles.menuRowOption)}>
                                    <Col className={css(styles.menuOptionCol, styles.withdrawSmallFonts, styles.transactionFee)} >
                                        <span> Transaction Fee: </span>
                                        <span> {`$${this.calculateFee()}`} </span>
                                    </Col>
                                </Row>
                            </div>
                            <Row className={css(styles.menuRowOptionPayRow)}>
                                <Col>
                                    <div className={css(styles.dropInBtnContainer, styles.noSelect)}>
                                        <button className={css(styles.dropinbtn, styles.addcreditButton)} id="submit-button" visibility="hidden" onClick={(event) => this.createPayoutAction(event)}>Withdraw</button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </>
                )
        );
    }
}

function mapStateToProps(state) {
    return {
        currentuser: state.loginReducer.user,
        logintoken: state.loginReducer.loginToken,
        dowithdrawing: state.noPersistReducer.doWithdrawing,
        authtoken: state.payoutReducer.authToken,
        transactpayout: state.payoutReducer.transactPayout,
        payout: state.payoutReducer.payout
    }
}
export default connect(mapStateToProps, {
    createPayout,
    updateUser,
    closePayout
})(Payout);
