import { loginActionTypes, gameActionTypes, pending, rejected, fulfilled, serverEnvironment, fulfilled_function } from '../constants/ActionConstants';
import swal from 'sweetalert';

export default function reducer(state = {
    loginToken: '',
    loginTime: new Date(),
    isLoggedIn: false,
    inProgress: false,
    emailVerified: false,
    user: { id: 0, name: "Not logged in" },
    message: 'Please log in',
    firstTimeVisitor: true,
    tutorial: true, 
    signUpErrors: false,
    signupErrorDuplicateUser: false,
    forcePasswordChange: false,
    url: 'https://via.placeholder.com/200',
    urls: [],
    recaptchaScore: 0.0
}, action) {

    switch (action.type) {
        case fulfilled(loginActionTypes.CLEAR_ERR):
            return {
                ...state,
                emailVerified: false,
                signupErrors: false,
                signupErrorDuplicateUser: false,
                urls: []
            }
        case pending(gameActionTypes.UPDATE_USER):
            return {
                ...state,
                message: "Updating User info..."
            }
        case rejected(gameActionTypes.UPDATE_USER):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(gameActionTypes.UPDATE_USER): 
            return {
                ...state, 
                user: action.payload
            }

        case pending(loginActionTypes.LOGIN):
            return {
                ...state,
                inProgress: true,
                emailVerified: false,
                message: "Logging in...",
                urls: [
                    'http://christinevadai.com/tRTLO.jpg',
                    'http://christinevadai.com/tBRPE.jpg',
                    'http://christinevadai.com/tBCNH.jpg',
                    'http://christinevadai.com/tGBHE2.jpg',
                    'http://christinevadai.com/tSNGO.jpg',
                    'http://christinevadai.com/tWODU.jpg',
                    'http://christinevadai.com/tBUFF.jpg',
                    'http://christinevadai.com/tBAEA4.jpg',
                    'http://christinevadai.com/tCAQU2.jpg',
                    'http://christinevadai.com/tNOFL.jpg']
            }
        case fulfilled(loginActionTypes.LOGIN):

            return {
                ...state,
                loginToken: action.payload.token,
                loginTime: new Date(),
                user: action.payload.user,
                isLoggedIn: true,
                emailVerified: action.payload.user.isEmailVerified,
                inProgress: false,
                firstTimeVisitor: false,
                tutorial: false, 
                message: ''
            }
        case rejected(loginActionTypes.LOGIN):
            // alert("Invalid user name or password");
            swal({
                text: "Invalid username or password", 
                icon: "error"
            });
            return {
                ...state,
                loginToken: "",
                loginTime: new Date(),
                user: [],
                isLoggedIn: false,
                inProgress: false,
                emailVerified: false,
                message: 'Invalid user name or password'
            }
        case pending(loginActionTypes.LOGOUT):
            return {
                ...state,
                loginToken: '',
                isLoggedIn: false,
                emailVerified: false,
                message: "Logging out..."
            }
        case fulfilled(loginActionTypes.LOGOUT):
            return {
                ...state,
                loginShow: false,                
                loginToken: '',                
                user: [],
                message: "",
                emailVerified: false,
                isLoggedIn: false
            }
        case rejected(loginActionTypes.LOGOUT):
            return {
                ...state,
                loginShow: true,
                loginToken: '',
                isLoggedIn: false,
                message: action.payload.message
            }
        case rejected(loginActionTypes.LOG_VERIFICATION):
            return {
                ...state,
                emailVerified: false,
                message: action.payload.message
            }
        case fulfilled(loginActionTypes.LOG_VERIFICATION):
            return {
                ...state,
                emailVerified: true
            }
        case rejected(loginActionTypes.VERIFY_EMAIL):
            return {
                ...state,
                emailVerified: false,
                message: action.payload.message
            }
        case fulfilled(loginActionTypes.VERIFY_EMAIL):
            return {
                ...state,
                emailVerified: false
            }
        case rejected(loginActionTypes.IS_VALID_RECAPTCHA):
            return {
                ...state,
                recaptchaScore: 0.0,
                message: action.payload.message
            }
        case fulfilled(loginActionTypes.IS_VALID_RECAPTCHA):
            return {
                ...state,
                recaptchaScore:action.payload.data.score,
                message: action.payload.message
            }
        case pending(loginActionTypes.FACEBOOK_LOGIN):
            return {
                ...state,
                message: "Logging Facebook sign in..."
            }
        case fulfilled(loginActionTypes.FACEBOOK_LOGIN):

            return {
                ...state,
                loginToken: action.payload.data.token,
                loginTime: new Date(),
                user: action.payload.data.user,
                isLoggedIn: true,
                emailVerified: true,
                firstTimeVisitor: false,
                tutorial: false,
                message: ''
            }
        case rejected(loginActionTypes.FACEBOOK_LOGIN):
            return {
                ...state,
                loginToken: "",
                loginTime: new Date(),
                user: [],
                isLoggedIn: false,
                message: 'Invalid user name or password'
            }
        case fulfilled(loginActionTypes.CLOSE_LANDING_POPUP):
            return {
                ...state,
                firstTimeVisitor: false
            }
        case pending(loginActionTypes.SIGNUP):
            return {
                ...state,
                message: "Logging sign up request..."
            }
        case fulfilled(loginActionTypes.SIGNUP):
            
            return {
                ...state,
                loginToken: action.payload.token,
                loginTime: new Date(),
                user: action.payload.user,
                isLoggedIn: true,
                firstTimeVisitor: false,
                message: ''
            }
        case rejected(loginActionTypes.SIGNUP):
            // alert("Sign up failed.  Try another user name and/or email");
            swal({
                text: "Sign up failed. Please try another username and/or email", 
                icon: "error"
            });
            return {
                ...state,
                loginToken: "",
                loginTime: new Date(),
                user: [],
                firstTimeVisitor: true,
                signupErrors: true,
                isLoggedIn: false,
                message: 'Invalid sign up request'
            }
        case rejected(loginActionTypes.DUPLICATE_USER): 
            return {
                ...state, 
                signupErrorDuplicateUser: true
            }
        case fulfilled(loginActionTypes.DUPLICATE_USER): 
            return {
                ...state, 
                signupErrorDuplicateUser: false
            }
        case pending(loginActionTypes.UPDATE_FACEBOOK_USER):
                return {
                    ...state,
                    message: "Updating Facebook User info..."
                }
        case fulfilled(loginActionTypes.UPDATE_PROFILE_PIC):
            return {
                ...state,
                user: action.payload
            }
        case rejected(loginActionTypes.UPDATE_PROFILE_PIC):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(loginActionTypes.UPDATE_FACEBOOK_USER):
            return {
                ...state,
                user: action.payload.data
            }
        case rejected(loginActionTypes.UPDATE_FACEBOOK_USER):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(loginActionTypes.RESET_PASSWORD):
            return {
                ...state,
                forcePasswordChange: true,
                user: action.payload.data
            }
        case rejected(loginActionTypes.RESET_PASSWORD):
            if (action.payload.response) {
                switch (action.payload.response.status) {
                    case 400:
                        // alert("You signed up with Facebook.");
                        swal({
                            text: "You signed up with Facebook.", 
                            icon: "success"
                        });
                        break;
                    case 404:
                        // alert("Email not found");
                        swal({
                            text: "Email not found", 
                            icon: "error"
                        })
                        break;
                    default:
                        // alert("An error occurred");
                        swal({
                            text: "An error occurred", 
                            icon: "warning"
                        });
                        break;
                }
            }
            return {
                ...state,
                forcePasswordChange: false,
                message: action.payload.message
            }
        case fulfilled(loginActionTypes.CHANGE_PASSWORD):
            alert("Password changed!");
            return {
                ...state,
                loginToken: action.payload.token,
                loginTime: new Date(),
                user: action.payload.user,
                isLoggedIn: true,
                emailVerified: action.payload.user.isEmailVerified,
                inProgress: false,
                firstTimeVisitor: false
            }
        case rejected(loginActionTypes.CHANGE_PASSWORD):
            alert("Invalid token");
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(loginActionTypes.TUTORIAL_END): 
            return {
                ...state,
                tutorial: false
            }
        default:
            return state;
    }
}