import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';

import { useEmblaCarousel } from 'embla-carousel/react';
import swal from 'sweetalert';

import { Container, Row, Col, Button } from 'react-bootstrap';
import GameEntryMenu from './GameEntryMenu';

import { connect, useSelector, useDispatch, shallowEqual } from 'react-redux';
import { requestSlot, unrequestSlot } from '../../actions/GameActions';

import { css } from 'aphrodite';
import { styles } from './PlayerWheelStyles.js';
import "./gameEntryMenu.css";
import { tutorialPlayers } from '../../JSON/tutorialPlayers.js';

// OLD CODE BELOW

// const PlayerWheel = ({ children }) => {
//     const [embla, setEmbla] = useState(null);
//     const [selectedIndex, setSelectedIndex] = useState(0);
//     const [modalShow, setModalShow] = React.useState(false);
//     const scrollPrev = useCallback(() => embla.scrollPrev(), [embla])
//     const scrollNext = useCallback(() => embla.scrollNext(), [embla])

// const scrollTo = useCallback(index => {
//     if (embla.clickAllowed()) {
//         embla.scrollTo(index)
//     }
// }, [embla]);

// const dispatch = useDispatch();
// const { currentuser, currentgame, currentslot, token } = useSelector(state => ({
//     currentuser: state.loginReducer.user,
//     currentgame: state.gameReducer.currentGame,
//     currentslot: state.gameReducer.slot,
//     token: state.loginReducer.loginToken
// }), shallowEqual);


// const reserveSlot = useCallback(() => {

//     // dispatch(requestSlot({ player: currentuser, gameid: currentgame.gameid, slot: selectedIndex + 1 }));
//     const payload = {
//         player: currentuser,
//         gameid: currentgame.gameid,
//         slot: embla.selectedScrollSnap() + 1,
//         isFinalized: false,
//         id: currentuser.id,
//         name: currentuser.name
//     }

//     dispatch(requestSlot(token, payload));
// }, [embla]);


// const unreserveSlot = useCallback(() => {
//     dispatch(unrequestSlot(token, currentgame.gameid, currentuser.id));
// })


// useEffect(() => {
//     if (embla) {
//         embla.on('select', () => {
//             //console.log(`Current slot is ${embla.selectedScrollSnap() + 1}`);
//             var slot = embla.selectedScrollSnap();
//             setSelectedIndex(slot);
//         })
//         embla.changeOptions({});
//     }

// }, [embla]);

const embla__viewport = {
    overflow: 'hidden',
    width: "100%"
}
const embla__container = {
    display: 'flex',
    userSelect: "none",
    WebkitTouchCallout: "none",
    KhtmlUserSelect: "none",
    WebkitTapHighlightColor: "transparent"
}
const slideCss = {
    position: 'relative',
    minWidth: '100%',
}
const embla = {
    position: 'relative',
    // backgroundColor: '#f7f7f7',
    padding: "20px 0px 20px 0px",
    // maxWidth: 670,
    marginLeft: "auto",
    marginRight: "auto",
    // marginTop: "10%"
}
const embla__slide = {
    position: "relative",
    minWidth: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}
const embla__slide__fourth = {
    position: "relative",
    minWidth: "35%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}
const embla__slide__sixth = {
    position: "relative",
    minWidth: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}
const embla__slide__eigth = {
    position: "relative",
    minWidth: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}
const embla__slide__tenth = {
    position: "relative",
    minWidth: "12%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}
const embla__slide__inner = {
    position: "relative",
    overflow: "hidden"
}
const selected__wrapper = {
    background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
    padding: 6,
    borderRadius: '8rem'
}

const PlayerWheel = props => {
    const [emblaRef, emblaApi] = useEmblaCarousel({
        align: "center",
        loop: true, 
        startIndex: props.tutorial ? 5 : (props.startIndex ? props.startIndex - 1 : 0)
    });

    const dispatch = useDispatch();
    const { currentuser, currentgame, currentslot, token, tutorial } = useSelector(state => ({
        currentuser: state.loginReducer.user,
        currentgame: state.gameReducer.currentGame,
        currentslot: state.gameReducer.slot,
        token: state.loginReducer.loginToken,
        tutorial: state.loginReducer.tutorial
    }), shallowEqual);

    const wheelRef = useRef(null);

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [tutEntry, setTutEntry] = useState(false); 
    const [wheelDeg, setWheelDeg] = useState(0);

    const onSelect = useCallback(() => {
        if (!emblaApi) return;

        let slot = emblaApi.selectedScrollSnap();
        setSelectedIndex(slot);
        if(tutorial && slot !== 5) {
            setTutEntry(false);
        }
    }, [emblaApi]);
    const scrollTo = useCallback(index => {
        if (!emblaApi) return;

        if (emblaApi.clickAllowed()) {
            emblaApi.scrollTo(index)
        }
    }, [emblaApi]);
    const scrollPrev = useCallback(() => {
        emblaApi.scrollPrev();
        let newWheelDeg = wheelDeg - 15;
        setWheelDeg(newWheelDeg);
        rotateWheel();
    }, [emblaApi, wheelDeg]);
    const scrollNext = useCallback(() => {
        emblaApi.scrollNext();
        let newWheelDeg = wheelDeg + 15;
        setWheelDeg(newWheelDeg);
        rotateWheel();
    }, [emblaApi, wheelDeg]);
    const rotateWheel = useCallback(() => {
        wheelRef.current.style.transform = `rotate(${wheelDeg}deg)`;
    }, [wheelDeg]);
    const selectSlot = useCallback(() => {
        var taken = currentgame.players.filter(function (e) {
            return e.slot === selectedIndex + 1;
        });
        if (taken && taken.length) {
            swal({
                text: `This slot is already taken by ${taken[0].player.name}`,
                icon: "warning"
            });
        }
        else if (currentuser.id >= 1) {
            setModalShow(true);
            reserveSlot();
        }
        else { document.location = '/login' };
    });
    const selectSlotTut = useCallback(() => {
        var taken = tutorialPlayers.filter(function (e) {
            return e.slot === selectedIndex + 1; 
        });
        if (taken && taken.length) {
            swal({ 
                text: `This slot is already taken by ${taken[0].player.name}`, 
                icon: "warning"
            });
        }
        else {
            setTutEntry(true);     
        }
    });
    const reserveSlot = useCallback(() => {
        const payload = {
            player: currentuser,
            gameid: currentgame.gameid,
            slot: emblaApi.selectedScrollSnap() + 1,
            isFinalized: false,
            id: currentuser.id,
            name: currentuser.name
        };
        // console.log("reserveslot payload", payload);
        dispatch(requestSlot(token, payload));
    }, [emblaApi]);
    const unreserveSlot = useCallback(() => {
        dispatch(unrequestSlot(token, currentgame.gameid, currentuser.id));
    });

    useEffect(() => {
        if (!emblaApi) return;
        // Embla API is ready
        emblaApi.on("select", onSelect);
        onSelect();
        rotateWheel();
    }, [emblaApi, onSelect, wheelDeg]);


    return (
        <>

            <div className={css(styles.mqWheelControl, props.tutorial && styles.mqWheelControlMock)} style={{ position: 'relative' }}>
                <div style={embla}>
                    <div style={embla__viewport} ref={emblaRef}>
                        <div style={embla__container} className={css(styles.mqDraggableNodes)}>
                            {/* <div style={slideCss}>Slide 1</div>
                    <div style={slideCss}>Slide 2</div>
                    <div style={slideCss}>Slide 3</div> */}
                            {props.children.map((Child, index) => (
                                <div className={css((props.children.length >= 4 && props.children.length < 6) && styles.embla__slide__fourth, (props.children.length >= 6 && props.children.length < 8) && styles.embla__slide__sixth, (props.children.length >= 8 && props.children.length < 10) && styles.embla__slide__eighth, (props.children.length === 10) && styles.embla__slide__tenth, props.children.length > 10 && styles.embla__slide)} key={index} onMouseUp={() => scrollTo(index)}>
                                    <div style={embla__slide__inner}>
                                        {Child}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={css(styles.playerWheelBezel)}>
                    <img id="rotater" ref={wheelRef} onDragStart={() => { return false; }} style={{ width: 420 }} draggable="false" src={require("../../images/centerWheel.svg")} />
                    <img className={css(styles.playerWheelPointer)} src={require("../../images/pointer.svg")} />
                    {/* Uncomment for Particle Effect below - Makes things run a bit slower */}
                    <img className={css(styles.particleEffect)} src={require("../../images/particles.gif")} />
                </div>
            </div>

            {/* <Row className={css(styles.slotButtonsRow)}>
                <Col>
                    <img className={css(styles.slotPrevButton)} onClick={scrollPrev} src={require("../../images/scrollSlideLeft.svg")} />
                    <img className={css(styles.slotNextButton)} onClick={scrollNext} src={require("../../images/scrollSlideRight.svg")} />
                </Col>
            </Row> */}
            <Row className={css(styles.controlRow, props.tutorial && styles.mqcontrolRowMock)}>
            <Col md={4} sm={4} xs={4}><img className={css(styles.slotPrevButton)} onClick={scrollPrev} src={require("../../images/scrollSlideLeft.svg")} /></Col>
            <Col md={4} sm={4} xs={4} className={css(styles.selectionButtonRow)}>
                <div style={{ textAlign: "center" }}>
                    {!currentgame.userEntered && !tutEntry && (
                        <>
                            <Button className={css(styles.slotSelectionButton)} onClick={tutorial ? selectSlotTut : selectSlot}>SELECT SLOT {selectedIndex + 1}</Button>
                            <br />
                        </>
                    )}
                    {!currentgame.userEntered && tutEntry && (
                        <>
                            <Button className={css(styles.tutEntryButton)} onClick={props.setTutWinner}>JOIN FOR FREE</Button>
                            <br />
                        </>
                    )}
                    <div className={css(styles.termsConditionsBox)}><Link className={css(styles.termsConditionsLink)}>TERMS AND CONDITIONS</Link></div>
                </div>
            </Col>
            <Col md={4} sm={4} xs={4}><img className={css(styles.slotNextButton)} onClick={scrollNext} src={require("../../images/scrollSlideRight.svg")} /></Col>
            </Row>
            {currentslot ? (
                <GameEntryMenu
                    show={modalShow}
                    onHide={() => {
                        setModalShow(false);
                        unreserveSlot();
                    }}
                    selectedslot={selectedIndex + 1}
                >
                </GameEntryMenu>
            ) : ("")
            }
        </>




        // OLD CODE BELOW


        // <div>
        //     <div className={css(styles.embla)}>
        //         <EmblaCarouselReact
        //             className={css(styles.embla__viewport)}
        //             emblaRef={setEmbla}
        //             options={{ loop: true }}
        //             htmlTagName="div"
        //         >
        //             <div className={css(styles.embla__container)}>
        //                 {children.map((Child, index) => (
        //                     index == selectedIndex ?
        //                         <div className={css(styles.embla__slide)} key={index} onMouseUp={() => scrollTo(index)}>
        //                             <div className={css(styles.selectedWrapper)}>
        //                                 <div className={css(styles.embla__slide__inner)}>{Child}</div>
        //                             </div>
        //                         </div>
        //                         :
        //                         <div className={css(styles.embla__slide)} key={index} onMouseUp={() => scrollTo(index)}>
        //                             <div className={css(styles.embla__slide__inner)}>{Child}</div>
        //                         </div>
        //                 ))}
        //             </div>
        //         </EmblaCarouselReact>
        //         <Row className={css(styles.playerWheelControls)}>
        //             <Col md={2} className={css(styles.pwControlCol)}>
        //                 <button onClick={scrollPrev}>Prev</button>
        //             </Col>
        //             <Col md={8} className={css(styles.pwControlCol)}>
        //                 <div className={css(styles.gameEntrySection)}>
        //                     {!currentgame.userEntered ?
        //                         <Button className={css(styles.slotSelectionButton)} onClick={() => {

        //                             var taken = currentgame.players.filter(function (e) {
        //                                 return e.slot === selectedIndex + 1;
        //                             });

        //                             if (taken && taken.length) {
        //                                 // alert("This slot is already taken by " + taken[0].player.name);
        //                                 swal({
        //                                     text: `This slot is already taken by ${taken[0].player.name}`, 
        //                                     icon: "warning"
        //                                 });
        //                             }
        //                             else if (currentuser.id >= 1) {
        //                                 setModalShow(true);
        //                                 // INSERT requestSlot Action Here
        //                                 reserveSlot();
        //                             }
        //                             else { document.location = '/login' };
        //                         }
        //                         }>SELECT SLOT {selectedIndex + 1}</Button>
        //                     : "" }
        //                     <br />
        //                     <Link className={css(styles.termsConditionsLink)}>TERMS AND CONDITIONS</Link>
        //                 </div>
        //             </Col>e
        //             <Col md={2} className={css(styles.pwControlCol)}>
        //                 <button onClick={scrollNext}>Next</button>
        //             </Col>
        //         </Row>
        //     </div>
        //     {currentslot ? (
        //         <GameEntryMenu
        //             show={modalShow}
        //             onHide={() => {
        //                 setModalShow(false);
        //                 unreserveSlot();
        //             }}
        //             selectedslot={selectedIndex + 1}
        //         >

        //         </GameEntryMenu>
        //     ) : ( "") }
        // </div>
    );
}

function mapStateToProps(state) {
    return {
        currentuser: state.loginReducer.user,
        currentgame: state.gameReducer.currentGame,
        currentslot: state.gameReducer.slot,
        token: state.loginReducer.loginToken, 
        tutorial: state.loginReducer.tutorial
    }
}

export default connect(mapStateToProps, {
    requestSlot,
    unrequestSlot
}
)(PlayerWheel); 