import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
    avatarModal: {
        zIndex: '100000000',
        backgroundColor: "#000000d1"
    },
    avatarSelectionSection: {
        display: "flex",
        justifyContent: "center",
        '@media (max-width: 767px)': {
            alignItems: 'center'
        },
        '@media (max-width: 575px)': {
            margin: '0 auto'
        }
    },
    containerBoundsTutCenter: {
        textAlign: 'center'
    },
    containerBoundsTut: {
        '@media (max-width: 767px)': {
            maxWidth: 'unset'
        },
        '@media (max-width: 435px)': {
            paddingRight: 0,
            paddingLeft: 0
        }
    },
    fixedBoxContainer: {
        position: "relative",
        height: "82vh",
        width: 350,
        // width: '24.3vw', 
        backgroundColor: "#3A3934",
        borderRadius: 8,
        boxShadow: "0 0 6px rgba(0,0,0,0.16), 0 6px 6px 0 rgba(0,0,0,0.16)",
    },
    getStartedButton: {
        background: 'linear-gradient(270deg, #FFCC00 0%, #FF9500 100%)',
        height: 80,
        width: 275,
        fontSize: 28,
        color: "#3A3933",
        fontFamily: 'SF Pro Text Heavy',
        letterSpacing: 1,
        textAlign: "center",
        border: "none",
        borderRadius: 16,
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "0 0 0 0.15rem #50504a"
        },
        marginTop: 38
    },
    homeContentRow: {
        minHeight: '100vh',
        marginTop: -10
    },
    imageUploadButton: {
        width: 126.41,
        height: 37,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ':hover': {
            cursor: 'pointer'
        }
    },
    landingContentContainer: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",

        width: "100%"
    },
    landingContentContainerStep3: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 100001,
        width: "100%"
    },
    landingOverlay: {
        zIndex: 10000000,
        backdropFilter: "blur(8px)",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        position: "fixed"
    },
    leaderBox: {
        marginLeft: 30
    },
    logoGetStarted: {
        width: 300,
        marginBottom: -40
    },
    pirateOath: {
        zIndex: 10
    },
    selectAvatarButton: {
        background: 'none',
        border: "2px solid #FFCC00",
        borderRadius: 4,
        fontFamily: 'SF Pro Text Medium',
        fontSize: 14,
        letterSpacing: 0.79,
        color: "#FFCC00",
        ":focus": {
            boxShadow: "none",
            border: "2px solid #FFCC00"
        },
        '@media (max-width: 490px)': {
            width: 130
        }
    },
    selectAvatarButtonBox: {
        display: "flex",
        justifyContent: "center",
        marginTop: 25,
        '@media (max-width: 490px)': {
            marginTop: 10
        }
    },
    selectedAvatar: {
        display: "inherit",
        width: 200,
        margin: '0 auto',
        '@media (max-width: 767px)': {
            width: 120
        },
        '@media (max-width: 499px)': {
            width: 92,
            margin: '0 auto'
        }
    },
    signUpButton: {
        background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
        height: 32,
        border: "none",
        display: "flex",
        alignItems: "center",
        borderRadius: 4,
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        color: "#3A3934",
        padding: "calc(.34435vw + 13.38843px) calc(.34435vw + 18.38843px)",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "none"
        }
    },
    signUpCloseButton: {
        background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
        height: 41,
        border: "none",
        borderRadius: 4,
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        color: "#3A3934",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "none"
        }
    },
    signUpFormInput: {
        background: "none",
        border: "none",
        borderBottom: "1px solid #A1A1A1",
        borderRadius: 0,
        color: "#B6BCBF",
        fontSize: 17,
        letterSpacing: -0.41,
        padding: 0,
        "::placeholder": {
            color: "#B6BCBF"
        },
        ":-ms-input-placeholder": {
            color: "#B6BCBF"
        },
        "::-ms-input-placeholder": {
            color: "#B6BCBF"
        }
    },
    signUpFormInputPhone: {
        "::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0
        },
        "::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0
        }
    },
    signUpPopup: {
        zIndex: 10000000,
        fontFamily: "SF Pro Text Semibold, Arial Black, Gadget, sans-serif"
    },
    signUpPopupBackdrop: {
        zIndex: 10000000,
        backdropFilter: "blur(4px)"
    },
    signUpTermsLink: {
        textDecoration: 'none'
    },
    signUpTitle: {
        fontFamily: "SF Pro Text Heavy",
        color: "#FFCC00",
        letterSpacing: -0.84,
        fontSize: 35
    },
    menuButton: {
        margin: "10px",
        height: 43,
        background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
        border: "none",
        borderRadius: 4,
        fontSize: "15px",
        fontFamily: "SF Pro Text Semibold, Arial Black, Gadget, sans-serif",
        color: "#3A3934",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "none"
        }
    },
    smallMenuButton: {
        background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
        border: "none",
        borderRadius: 4,
        marginBottom: "10px",
        marginTop: "15px",
        fontSize: "14px",
        fontFamily: "SF Pro Text Semibold, Arial Black, Gadget, sans-serif",
        color: "#3A3934",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "none"
        }
    },
    termsandagreementsbox: {
        width: '100%',
        height: 150,
        border: '1px solid',
        borderRadius: 6,
        overflowY: 'scroll',
        paddingLeft: 11,
        paddingRight: 11
    },
    tutorialArrow2: {
        position: 'absolute',
        zIndex: 1,
        bottom: -30,
        width: 700,
        marginLeft: 150
    },
    tutorialArrow3: {
        position: 'absolute',
        zIndex: 1,
        bottom: 20,
        width: 700,
        right: 332
    },
    tutorialArrow4: {
        position: 'absolute',
        zIndex: 1,
        bottom: 12,
        width: 650,
        right: 345
    },
    tutorialArrow4Alt: {
        position: 'absolute',
        zIndex: 1,
        marginTop: 12,
        transform: "scaleY(-1)",
        width: 587,
        right: 345
    },
    tutorialPrompt: {
        display: "flex",
        alignItems: "center"
    },
    tutorialPromptGetStarted: {
        color: "#FFFFFF",
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 28,
        letterSpacing: 1.47
    },
    tutorialPromptHash: {
        color: "#FFC700",
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 35,
        letterSpacing: 1.84,
        lineHeight: "41px"
    },
    tutorialPromptHurry: {
        display: "flex",
        alignItems: "center",
        marginTop: -185
    },
    tutorialPromptMoney: {
        fontSize: 63,
        fontFamily: 'SF Pro Text Heavy',
        letterSpacing: 3.32,
        color: "#FFC700"
    },
    tutorialPromptWin: {
        fontSize: 63,
        fontFamily: 'SF Pro Text Heavy',
        letterSpacing: 3.32,
        color: "FFC700",
        lineHeight: "0px"
    },
    modalBorder: {
        border: "none"
    },
    // uploadColumn: {
    //     float: "right"
    // },
    imageInput: {
        display: "none"
    },
    mqAgreeButton: {
        '@media (max-width: 452px)': {
            marginTop: 8,
            marginLeft: 20
        }
    },
    mqBackButtonOath: {
        '@media (max-width: 452px)': {
            marginLeft: 20
        }
    },
    mqFormText: {
        '@media (max-width: 490px)': {
            fontSize: 12
        }
    },
    mqPirateOathText: {
        '@media (max-width: 1250px)': {
            fontSize: 30
        },
        '@media (max-width: 823px)': {
            fontSize: 24
        },
        '@media (min-width: 651px) and (max-width: 660px)': {
            fontSize: 23
        },
        '@media (max-width: 511px)': {
            fontSize: 20
        },
        '@media (max-width: 430px)': {
            fontSize: 18
        },
        '@media (max-width: 389px)': {
            fontSize: 16
        }
    },
    mqsignUpButtonsRow: {
        '@media (max-width: 799px)': {
            width: '100%'
        },
        '@media (max-width: 500px)': {
            textAlign: 'center'
        }
    },
    mqsignUpTitle: {
        '@media (max-width: 1000px)': {
            fontSize: 30
        },
        '@media (max-width: 575px)': {
            fontSize: 24
        },
        '@media (max-width: 474px)': {
            fontSize: 21
        },
        '@media (max-width: 417px)': {
            fontSize: 19
        },
        '@media (max-width: 380px)': {
            fontSize: 18
        }
    },
    mqTermsButton: {
        '@media (max-width: 1050px)': {
            width: '75%',
            margin: '0 auto',
            lineHeight: 1.3,
            height: 40
        },
        '@media (max-width: 960px)': {
            fontSize: 13,
            width: '85%',

        },
        '@media (max-width: 770px)': {
            fontSize: 12
        },
        '@media (min-width: 651px) and (max-width: 734px)': {
            fontSize: 11
        },
        '@media (max-width: 563px)': {
            fontSize: 11
        }
    },
    mqTermsContainer: {
        textAlign: 'center',
        '@media (max-width: 452px)': {
            marginTop: 11
        }
    },
    mqTermsIcon: {
        '@media (max-width: 1050px)': {
            width: 200
        },
        '@media (max-width: 823px)': {
            width: 125
        }
    },
    mqlandingContentContainerBegin: {
        '@media (max-width: 784px)': {
            height: '100%'
        }
    },
    mqLeaderBoardTut: {
        '@media (max-width: 784px)': {
            visibility: 'hidden'
        }
    },
    mqtutorialPromptWin: {
        '@media (max-width: 699px)': {
            fontSize: 58
        },
        '@media (max-width: 525px)': {
            fontSize: 52
        },
        '@media (max-width: 565px)': {
            fontSize: 48
        },
        '@media (max-width: 474px)': {
            fontSize: 44
        },
        '@media (max-width: 435px)': {
            fontSize: 40
        },
        '@media (max-width: 395px)': {
            fontSize: 38
        },
        '@media (max-width: 375px)': {
            fontSize: 36
        },
        '@media (max-width: 345px)': {
            fontSize: 34
        }
    },
    mqtutorialArrow2: {
        '@media (max-width: 1199px)': {
            bottom: 5,
            width: 630,
            marginLeft: 65,
            transform: 'rotate(-9deg)'
        },
        '@media (max-width: 1099px)': {
            bottom: 15,
            width: 615,
            marginLeft: 30
        },
        '@media (max-width: 991px)': {
            bottom: 49,
            width: 490,
            marginLeft: 0,
            transform: 'rotate(-18deg)'
        },
        '@media (max-width: 899px)': {
            bottom: 65,
            width: 465,
            transform: 'rotate(-23deg)',
            marginLeft: 3
        },
        '@media (max-width: 799px)': {
            bottom: 77,
            width: 440,
            transform: 'rotate(-27deg)'
        },
        '@media (max-width: 725px)': {
            width: 426,
            marginLeft: '-16px'
        }
    },
    mqlandingContentContainerStep3: {
        '@media (max-width: 674px)': {
            top: '65%'
        }
    },
    mqtutStep3Container: {
        '@media (max-width: 674px)': {
            margin: '0 auto'
        }
    },
    mqtutStep3Arrow3: {
        '@media (max-width: 674px)': {
            bottom: 20,
            width: 360,
            transform: 'rotate(90deg)',
            top: -250,
            right: -89
        },
        '@media (max-width: 474px)': {
            right: -48
        }
    },
    mqtutorialPromptHashStep3: {
        '@media (max-width: 435px)': {
            fontSize: 32
        },
        '@media (max-width: 375px)': {
            fontSize: 30
        }
    },
    mqtutorialArrow4: {
        "@media (max-width: 1419px)": {
            width: 620
        },
        '@media (max-width: 1375px)': {
            width: 570,
            right: 364
        },
        '@media (max-width: 1309px)': {
            width: 480,
            right: 415
        },
        '@media (max-width: 1250px)': {
            right: 363
        },
        '@media (max-width: 1199px)': {
            right: 338
        },
        '@media (max-width: 1160px)': {
            width: 450,
            right: 322
        }
    },
    mqtutorialPromptSideChallenges: {
        '@media (max-width: 1419px)': {
            marginBottom: 100
        },
        '@media (max-width: 1309px)': {
            marginBottom: 212
        },
        '@media (max-width: 1109px)': {
            marginBottom: 0
        },
        '@media (max-width: 640px)': {
            backgroundColor: '#171715',
            borderRadius: 6
        },
        '@media (max-width: 615px)': {
            marginTop: 240,
            right: 25
        }
    },
    mqtutorialPromptWin4: {
        '@media (max-width: 1309px)': {
            fontSize: 58
        },
        '@media (max-width: 1199px)': {
            fontSize: 55
        },
        '@media (max-width: 1160px)': {
            fontSize: 50
        },
        '@media (max-width: 1020px)': {
            lineHeight: 'unset',
            fontSize: 46,
            width: 533
        },
        '@media (max-width: 945px)': {
            width: 526,
            fontSize: 48
        },
        '@media (max-width: 830px)': {
            width: 430,
            fontSize: 44
        },
        '@media (max-width: 710px)': {
            width: 398,
            fontSize: 40
        },
        '@media (max-width: 670px)': {
            fontSize: 37
        },
        '@media (max-width: 640px)': {
            width: 334
        },
        '@media (max-width: 615px)': {
            width: 440
        },
        '@media (max-width: 555px)': {
            width: 418,
            fontSize: 35
        },
        '@media (max-width: 499px)': {
            width: 365,
            fontSize: 30
        },
        '@media (max-width: 415px)': {
            fontSize: 29,
            width: 355
        },
        '@media (max-width: 399px)': {
            width: 336,
            fontSize: 27
        },
        '@media (max-width: 375px)': {
            width: 315,
            fontSize: 25
        },
        '@media (max-width: 350px)': {
            fontSize: 24,
            width: 302
        },
        '@media (max-width: 335px)': {
            width: 293,
            fontSize: 23
        }
    },
    mqtutorialPromptHash4: {
        '@media (max-width: 830px)': {
            fontSize: 32
        },
        '@media (max-width: 670px)': {
            fontSize: 30
        },
        '@media (max-width: 555px)': {
            fontSize: 32
        },
        '@media (max-width: 499px)': {
            fontSize: 28
        },
        '@media (max-width: 415px)': {
            fontSize: 27
        },
        '@media (max-width: 399px)': {
            fontSize: 26
        },
        '@media (max-width: 375px)': {
            fontSize: 24
        },
        '@media (max-width: 350px)': {
            fontSize: 23
        },
        '@media (max-width: 335px)': {
            fontSize: 22
        }
    },
    mqfixedBoxContainerTut: {
        '@media (max-width: 799px)': {
            width: 280
        }
    },
    mqleaderBoxTut: {
        '@media (max-width: 725px)': {
            marginLeft: 15,
            marginRight: 15
        },
        '@media (max-width: 335px)': {
            marginRight: 10
        }
    },
    mqtutStep5Arrow3: {
        '@media (max-width: 1425px)': {
            width: 595,
            bottom: 15
        },
        '@media (max-width: 1299px)': {
            width: 505
        },
        '@media (max-width: 1199px)': {
            width: 425
        },
        '@media (max-width: 1125px)': {
            width: 354,
            bottom: 28
        }
    },
    mqtutStep5Arrow2: {
        transform: 'rotate(-19deg) rotateY(180deg)',
        width: 294,
        position: 'absolute',
        right: 338,
        top: 45,
        '@media (max-width: 949px)': {
            top: 85
        },
        '@media (max-width: 885px)': {
            top: 167,
            left: -54,
            width: 352
        },
        '@media (max-width: 699px)': {
            top: 188,
            left: 40,
            width: 280
        },
        '@media (max-width: 645px)': {
            top: 168,
            left: -15,
            width: 245
        }
    },
    mqtutStep5Arrow1: {
        '@media (max-width: 616px)': {
            width: 225,
            position: 'absolute',
            right: 85,
            top: -105,
            transform: 'rotate(-151deg)'
        },
        '@media (max-width: 585px)': {
            width: 215,
            right: 55,
            top: -100,
        }
    },
    mqtutStep5Arrow3Alt: {
        transform: 'rotate(90deg) rotateX(-180deg)',
        width: 94,
        right: 185,
        top: -62,
        position: 'absolute',
        '@media (max-width: 470px)': {
            right: 160
        },
        '@media (max-width: 430px)': {
            right: 125
        },
        '@media (max-width: 390px)': {
            right: 88
        },
        '@media (max-width: 350px)': {
            top: -65,
            right: 52
        }
    },
    mqtutorialPromptWin5: {
        '@media (max-width: 1309px)': {
            fontSize: 58
        },
        '@media (max-width: 1199px)': {
            fontSize: 55
        },
        '@media (max-width: 1125px)': {
            fontSize: 50
        },
        '@media (max-width: 980px)': {
            fontSize: 48
        },
        '@media (max-width: 949px)': {
            lineHeight: 'unset',
            width: 524,
            fontSize: 47
        },
        '@media (max-width: 935px)': {
            fontSize: 44
        },
        '@media (max-width: 899px)': {
            width: 428
        },
        '@media (max-width: 885px)': {
            marginTop: -150
        },
        '@media (max-width: 645px)': {
            width: 325,
            fontSize: 40
        },
        '@media (max-width: 633px)': {
            width: 310
        },
        '@media (max-width: 615px)': {
            width: 455,
            fontSize: 37
        },
        '@media (max-width: 555px)': {
            width: 418,
            fontSize: 35
        },
        '@media (max-width: 499px)': {
            width: 365,
            fontSize: 30
        },
        '@media (max-width: 415px)': {
            fontSize: 29,
            width: 355
        },
        '@media (max-width: 399px)': {
            width: 336,
            fontSize: 27
        },
        '@media (max-width: 375px)': {
            width: 315,
            fontSize: 25
        },
        '@media (max-width: 350px)': {
            fontSize: 24,
            width: 302
        },
        '@media (max-width: 335px)': {
            width: 285,
            fontSize: 23
        }
    },
    mqtutorialPromptHash5: {
        '@media (max-width: 645px)': {
            fontSize: 32
        },
        '@media (max-width: 499px)': {
            fontSize: 28
        },
        '@media (max-width: 415px)': {
            fontSize: 27
        },
        '@media (max-width: 399px)': {
            fontSize: 26
        },
        '@media (max-width: 375px)': {
            fontSize: 24
        },
        '@media (max-width: 350px)': {
            fontSize: 23
        },
        '@media (max-width: 335px)': {
            fontSize: 22
        }
    },
    mqtutorialPromptLeaderWins: {
        '@media (max-width: 640px)': {
            backgroundColor: '#171715',
            borderRadius: 6
        },
        '@media (max-width: 615px)': {
            marginTop: 385,
            right: 25
        }
    },
    mqtutorialArrow4Alt: {
        '@media (max-width: 1375px)': {
            width: 495
        },
        '@media (max-width: 1285px)': {
            width: 450
        },
        '@media (max-width: 1225px)': {
            width: 415
        },
        '@media (max-width: 1199px)': {
            width: 375
        },
        '@media (max-width: 1125px)': {
            width: 308
        },
        '@media (max-width: 1065px)': {
            width: 265
        },
        '@media (max-width: 1025px)': {
            width: 230
        },
        '@media (max-width: 970px)': {
            width: 180
        },
        '@media (max-width: 918px)': {
            right: 300
        },
        '@media (max-width: 865px)': {
            top: 130,
            right: 180,
            width: 240
        },
        '@media  (max-width: 735px)': {
            top: 110,
            width: 199
        }
    },
    mqtutorialPromptWin6: {
        '@media (max-width: 1309px)': {
            fontSize: 58
        },
        '@media (max-width: 1199px)': {
            fontSize: 55
        },
        '@media (max-width: 1125px)': {
            fontSize: 50
        },
        '@media (max-width: 1065px)': {
            fontSize: 48
        },
        '@media (max-width: 1034px)': {
            lineHeight: 'unset',
            width: 580
        },
        '@media (max-width: 1005px)': {
            width: 569,
            fontSize: 47
        },
        '@media (max-width: 995px)': {
            fontSize: 45
        },
        '@media (max-width: 970px)': {
            width: 493,
            fontSize: 40
        },
        '@media (max-width: 918px)': {
            width: 450,
            fontSize: 36
        },
        '@media (max-width: 865px)': {
            width: 420,
            fontSize: 38
        },
        '@media (max-width: 735px)': {
            width: 365,
            fontSize: 34
        },
        '@media (max-width: 675px)': {
            width: 295,
            fontSize: 36
        },
        '@media (max-width: 615px)': {
            width: 455,
            fontSize: 36
        },
        '@media (max-width: 555px)': {
            width: 435,
            fontSize: 34
        },
        '@media (max-width: 499px)': {
            width: 380,
            fontSize: 29
        },
        '@media (max-width: 415px)': {
            fontSize: 28,
            width: 365
        },
        '@media (max-width: 399px)': {
            width: 345,
            fontSize: 26
        },
        '@media (max-width: 375px)': {
            width: 325,
            fontSize: 24
        },
        '@media (max-width: 350px)': {
            fontSize: 23,
            width: 312
        },
        '@media (max-width: 340px)': {
            width: 300,
            fontSize: 22
        }, 
        '@media (max-width: 330px)': {
            width: 290, 
            fontSize: 21
        }
    },
    mqtutorialPromptUseShots: {
        '@media (max-width: 1285px)': {
            marginTop: 18
        },
        '@media (max-width: 1199px)': {
            marginTop: 75
        },
        '@media (max-width: 1125px)': {
            marginTop: 130
        },
        '@media (max-width: 1065px)': {
            marginTop: 170
        },
        '@media (max-width: 970px)': {
            marginTop: 225
        },
        '@media (max-width: 918px)': {
            marginTop: 245
        },
        '@media (max-width: 865px)': {
            marginTop: 180
        },
        '@media (max-width: 735px)': {
            marginTop: 270
        },
        '@media (max-width: 675px)': {
            backgroundColor: '#171715',
            borderRadius: 6,
            marginTop: 330
        },
        '@media (max-width: 615px)': {
            marginTop: 245,
            right: 25
        }, 
        '@media (max-width: 389px)': {
            marginTop: 190
        }
    },
    mqtutorialPromptHash6: {
        '@media (max-width: 918px)': {
            fontSize: 32
        },
        '@media (max-width: 499px)': {
            fontSize: 28
        }, 
        '@media (max-width: 415px)': {
            fontSize: 27
        },
        '@media (max-width: 399px)': {
            fontSize: 26
        },
        '@media (max-width: 375px)': {
            fontSize: 24
        },
        '@media (max-width: 350px)': {
            fontSize: 23
        },
        '@media (max-width: 335px)': {
            fontSize: 22
        }
    },
    mqtutStep6Arrow3: {
        '@media (max-width: 675px)': {
            position: 'absolute',
            width: 175,
            right: 160,
            top: 185,
            transform: 'rotateX(180deg)'
        }
    },
    mqtutStep6Arrow1: {
        position: 'absolute',
        width: 150,
        top: 190,
        right: 115,
        transform: 'rotateY(-180deg) rotate(36deg)',
        '@media (max-width: 555px)': {
            right: 65
        }, 
        '@media (max-width: 499px)': {
            right: 10, 
            top: 170
        }, 
        '@media (max-width: 465px)': {
            right: -20
        }, 
        '@media (max-width: 430px)': {
            width: 140
        }, 
        '@media (max-width: 415px)': {
            right: -15, 
            width: 105
        }, 
        '@media (max-width: 389px)': {
            transform: 'rotate(55deg)', 
            right: 162, 
            top: 150, 
            width: 80
        }, 
        '@media (max-width: 375px)': {
            right: 145, 
            top: 144
        }, 
        '@media (max-width: 350px)': {
            right: 120
        }, 
        '@media (max-width: 330px)': {
            right: 105, 
            top: 137
        }
    }
});

