import * as React from "react";
import { connect } from "react-redux";

import { GAME_HEIGHT, GAME_WIDTH, LEFT_UI_WIDTH } from "./config";

class UI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            leftOffset: 0
        };
    }

    componentDidMount() {
        this.setState({ leftOffset: this.calculateLeftOffset() });

        window.addEventListener("resize", () => {
            this.setState({ leftOffset: this.calculateLeftOffset() });
        });
    }

    calculateLeftOffset = () => {
        return window.innerWidth / 2 - GAME_WIDTH / 2;
    };

    render() {
        const { showUi } = this.props;
        const { leftOffset } = this.state;
        return (
            <div>
                {/* Left */}
                <div
                    style={{
                        position: "absolute",
                        left: leftOffset,
                        width: LEFT_UI_WIDTH,
                        height: GAME_HEIGHT,
                        backgroundColor: "yellow",
                        opacity: .3
                    }}
                />
                {/* Top */}
                <div>
                    {showUi &&
                        (
                            <div
                                style={{
                                    position: "absolute",
                                    width: GAME_WIDTH,
                                    height: 100,
                                    backgroundColor: "#fcfcfc"
                                }}
                            >
                                <div>Toggleable UI</div>
                            </div>
                        )
                    }
                </div>
            </div>
            // <span></span>
        );
    }
}

function mapStateToProps(state) {
    return {
        showUi: state.minigameReducer.toggleUi
    };
}

export default connect(mapStateToProps, {

}
)(UI);