import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import AdsContainer from '../AdsContainer';
import Adsboard from '../Adsboard';
import GameContainer from '../GameContainer';
import Leaderboard from '../Leaderboard';
import NavBar from '../NavBar';
import GameView from '../GameView';
import Minigame from '../Minigame';
import Login from '../Login';
import Landing from '../Landing';
import SignUp from '../SignUp';
import swal from 'sweetalert';
import GameHistoryModal from '../Modals/GameHistoryModal';
import PaymentHistoryModal from '../Modals/PaymentHistoryModal';
import PayoutHistoryModal from '../Modals/PayoutHistoryModal';
import EditProfileModal from '../Modals/EditProfileModal';
import SubMenu from '../UserMenu/SubMenu';
import MediaQuery from 'react-responsive';
import { ListOl, LayoutSidebarInset } from 'react-bootstrap-icons';

import { Container, Row, Col } from 'react-bootstrap';

import axios from 'axios';
import { data } from '../../JSON/games.js'
import { user } from '../../JSON/currentUser.js';

import { connect } from 'react-redux';
import { logVerification } from '../../actions/LoginActions';
import { getGames, getLeaderboard, getUnreadCount, getGameHistory } from '../../actions/GameActions';
import { gotAlert, openSideMenus } from '../../actions/MenuActions';
import { getClientToken } from '../../actions/PaymentActions';
import { getAuthToken } from '../../actions/PayoutActions';
import { serverEnvironment } from '../../constants/ActionConstants';

import { css } from 'aphrodite';
import { styles } from './HomeStyles.js';

var source = new EventSource(serverEnvironment.apiHost + '/chat');

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cookieaccepted: false
        };

        source.onmessage = function (e) {
            var data = e.data.split('|');
            var username = data[0];
            var text = data[1];
            // console.log(data[2]);

            // 1) display notification or 
            // 2) refresh games / leaderboard

            if (username === "") {
                this.props.getLeaderboard(this.props.token);
                this.props.getGames(this.props.token)
            }
            else if (username === this.props.user.name) {
                this.props.gotAlert(this.props.alertCount + 1);
                // alert(text);
                swal({
                    text: text,
                    icon: "info"
                });
            }

        }.bind(this);

        if (this.props.token) {
            this.props.getUnreadCount(this.props.token);
        }
    }

    componentDidMount() {
        var token = this.getQueryParam("token");
        if (token) {
            this.props.logVerification(token);
        }

        if (this.props.token && !this.props.loadLeaderboard && (!this.props.gamesList || !this.props.gamesList.length)) {
            //console.log("in did mount getleaderboard");
            this.props.getGames(this.props.token);
            this.props.getLeaderboard(this.props.token);
        }
        if (this.props.token && this.props.user.id && !this.props.clienttoken) {
            this.getToken();
        }

        if (this.props.token && this.props.user.id && !this.props.authtoken) {
            this.getPayoutAuthToken();
        }
    }


    getQueryParam(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        return null;
    }
    getToken() {
        if (this.props.user.id && this.props.token) {
            this.props.getClientToken(this.props.token, this.props.user.id);
        }
    }

    getPayoutAuthToken() {
        if (this.props.token) {
            this.props.getAuthToken();
        }
    }

    getCookie() {
        var cookiename = "AcceptCookie";
        var name = cookiename + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    saveCookie() {
        var cookievalue = "Yes";
        document.cookie = "AcceptCookie = " + cookievalue;
        this.setState({ cookieaccepted: true });
        document.getElementById("cookiediv").style.display = "none";
    }
    render() {
        if (this.props.token && (!this.props.leaderboard || this.props.leaderboard.length === 0)) {
            if (this.props.loadLeaderboard) {
                //console.log("getting leaderboard with token");
                this.props.getLeaderboard(this.props.token);
            }
        }
        else if (!this.props.isLoggedIn && (!this.props.leaderboard || this.props.leaderboard.length === 0)) {
            if (this.props.loadLeaderboard) {
                //console.log("getting leaderboard without token");
                this.props.getLeaderboard();
            }
        }

        if (!this.props.isLoggedIn) {
            if (!this.props.gamesList || this.props.gamesList.length === 0) {
                if (this.props.loadGames) {
                    // console.log("getting games without token");
                    this.props.getGames();
                }
            }
        }
        else if (this.props.token && (!this.props.gamesList || this.props.gamesList.length === 0)) {
            if (this.props.loadGames) {
                //console.log("getting games with token");
                this.props.getGames(this.props.token);
                this.props.getGameHistory(this.props.token, this.props.user.id);
            }
        }

        if (this.props.token && !this.props.gotclienttoken && !this.props.gettingclienttoken) {
            console.log("getting payment token");
            this.getToken();
        }

        if (this.props.isLoggedIn && !this.props.gotauthtoken && !this.props.gettingAuthToken) {
            console.log("getting payout token");
            this.getPayoutAuthToken();
        }
        return (
            <Router>
                <div className={css(styles.plndrViewContainer)}>
                    <Route path='/'>
                        <Switch>
                            <Route path='/minigame'>
                                <Minigame />
                            </Route>
                        </Switch>
                    </Route>
                    <NavBar user={user} />
                    <Route path='/'>
                        <Switch>
                            {/* <Route path='/minigame'>
                                {this.props.isLoggedIn ?
                                    <Minigame />
                                    :
                                    <Redirect to='/' />}
                            </Route> */}
                            <Route path='/game/:id' render={(props) => <GameView {...props} />}>
                                {/* {this.props.isLoggedIn ?
                                    <GameView />
                                    :
                                    <Redirect to="/login" />
                                } */}
                            </Route>
                            {/* <Route path='/landing'>
                                {this.props.firstTimeVisitor ? (
                                    <Landing />
                                ) :
                                <Redirect to='/' />}
                            </Route> */}
                            <Route path='/login'>
                                {(!this.props.isLoggedIn && !this.props.token) ? (
                                    <Login />
                                ) :
                                    <Redirect to='/' />}
                            </Route>
                            <Route path='/signup/:referralCode?' render={(props) => <SignUp {...props} />}>
                            </Route>
                            <Route path='/'>
                                {this.props.firstTimeVisitor ? (
                                    <Landing />
                                )
                                    :
                                    <Row className={css(styles.homeContentRow)}>
                                        <MediaQuery minWidth={1020}>
                                            <Col md={3} className={css(styles.leaderBox, styles.mqleaderBox)}>
                                                <br />
                                                {!this.props.leaderboard ? "Loading..." :
                                                    <div className={css(styles.fixedBoxContainer)}>
                                                        <Switch>
                                                            <Route exact path='/ads'>
                                                                <Adsboard />
                                                            </Route>
                                                            <Route exact path='/gamehistory'>
                                                                {this.props.isLoggedIn && this.props.gameHistory ?
                                                                    <SubMenu selection={"gamehistory"} />
                                                                    :
                                                                    <Redirect to="/" />}
                                                            </Route>
                                                            <Route exact path='/paymenthistory'>
                                                                {this.props.isLoggedIn && this.props.paymentHistory ?
                                                                    <SubMenu selection={"paymenthistory"} />
                                                                    :
                                                                    <Redirect to="/" />}
                                                            </Route>
                                                            <Route exact path='/payouthistory'>
                                                                {this.props.isLoggedIn && this.props.payoutHistory ?
                                                                    <SubMenu selection={"payouthistory"} />
                                                                    :
                                                                    <Redirect to="/" />}
                                                            </Route>
                                                            <Route exact path='/profile'>
                                                                {this.props.isLoggedIn && this.props.currUser ?
                                                                    <SubMenu selection={"profile"} />
                                                                    :
                                                                    <Redirect to="/" />}
                                                            </Route>
                                                            <Route path='/'>
                                                                <Leaderboard />
                                                            </Route>
                                                        </Switch>
                                                    </div>
                                                }
                                            </Col>
                                        </MediaQuery>
                                        <MediaQuery maxWidth={1019}>
                                            {this.props.sideMenu ?
                                                <div className={css(styles.leaderBox, styles.leaderBoxMobile)}>
                                                    <br className={css(styles.noSelect)} />
                                                    {!this.props.leaderboard ? "Loading..." :
                                                        <div className={css(styles.fixedBoxContainer, styles.mqfixedBoxContainer)}>
                                                            <Switch>
                                                                <Route exact path='/ads'>
                                                                    <Adsboard />
                                                                </Route>
                                                                <Route exact path='/gamehistory'>
                                                                    {this.props.isLoggedIn && this.props.gameHistory ?
                                                                        <SubMenu selection={"gamehistory"} />
                                                                        :
                                                                        <Redirect to="/" />}
                                                                </Route>
                                                                <Route exact path='/paymenthistory'>
                                                                    {this.props.isLoggedIn && this.props.paymentHistory ?
                                                                        <SubMenu selection={"paymenthistory"} />
                                                                        :
                                                                        <Redirect to="/" />}
                                                                </Route>
                                                                <Route exact path='/payouthistory'>
                                                                    {this.props.isLoggedIn && this.props.payoutHistory ?
                                                                        <SubMenu selection={"payouthistory"} />
                                                                        :
                                                                        <Redirect to="/" />}
                                                                </Route>
                                                                <Route exact path='/profile'>
                                                                    {this.props.isLoggedIn && this.props.currUser ?
                                                                        <SubMenu selection={"profile"} />
                                                                        :
                                                                        <Redirect to="/" />}
                                                                </Route>
                                                                <Route path='/'>
                                                                    <Leaderboard />
                                                                </Route>
                                                            </Switch>
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                <div className={css(styles.leaderBox, styles.leaderBoxMobile)}>
                                                    <br className={css(styles.noSelect)} />
                                                    <Switch>
                                                        <Route exact path='/ads'>
                                                            <LayoutSidebarInset className={css(styles.openSideMenuButtonAlt)} onClick={this.props.openSideMenus} color={"#ffc400"} size={49} />
                                                        </Route>
                                                        <Route exact path='/gamehistory'>
                                                            {this.props.isLoggedIn && this.props.gameHistory ?
                                                                <LayoutSidebarInset className={css(styles.openSideMenuButtonAlt)} onClick={this.props.openSideMenus} color={"#ffc400"} size={49} />
                                                                :
                                                                <Redirect to="/" />}
                                                        </Route>
                                                        <Route exact path='/paymenthistory'>
                                                            {this.props.isLoggedIn && this.props.paymentHistory ?
                                                                <LayoutSidebarInset className={css(styles.openSideMenuButtonAlt)} onClick={this.props.openSideMenus} color={"#ffc400"} size={49} />
                                                                :
                                                                <Redirect to="/" />}
                                                        </Route>
                                                        <Route exact path='/payouthistory'>
                                                            {this.props.isLoggedIn && this.props.payoutHistory ?
                                                                <LayoutSidebarInset className={css(styles.openSideMenuButtonAlt)} onClick={this.props.openSideMenus} color={"#ffc400"} size={49} />
                                                                :
                                                                <Redirect to="/" />}
                                                        </Route>
                                                        <Route exact path='/profile'>
                                                            {this.props.isLoggedIn && this.props.currUser ?
                                                                <LayoutSidebarInset className={css(styles.openSideMenuButtonAlt)} onClick={this.props.openSideMenus} color={"#ffc400"} size={49} />
                                                                :
                                                                <Redirect to="/" />}
                                                        </Route>
                                                        <Route exact path='/minigame'>

                                                        </Route>                
                                                        <Route path='/'>
                                                            <ListOl className={css(styles.openSideMenuButton)} onClick={this.props.openSideMenus} color={"#ffc400"} size={49} />
                                                        </Route>
                                                    </Switch>
                                                </div>
                                            }
                                        </MediaQuery>
                                        <MediaQuery minWidth={1020}>
                                            <Col md={8} className={css(styles.mqcontainerBox)}>
                                                {!this.props.gamesList ? "Loading games..." :
                                                    <Switch>
                                                        <Route exact path='/ads'>
                                                            <AdsContainer />
                                                        </Route>
                                                        <Route exact path='/gamehistory'>
                                                            {this.props.isLoggedIn && this.props.gameHistory ?
                                                                <GameHistoryModal gameHistory={this.props.gameHistory} onHide={this.onHideModal} />
                                                                :
                                                                <Redirect to="/" />}
                                                        </Route>
                                                        <Route exact path='/paymenthistory'>
                                                            {this.props.isLoggedIn && this.props.paymentHistory ?
                                                                <PaymentHistoryModal paymentHistory={this.props.paymentHistory} onHide={this.onHideModal} />
                                                                :
                                                                <Redirect to="/" />}
                                                        </Route>
                                                        <Route exact path='/payouthistory'>
                                                            {this.props.isLoggedIn && this.props.payoutHistory ?
                                                                <PayoutHistoryModal payoutHistory={this.props.payoutHistory} onHide={this.onHideModal} />
                                                                :
                                                                <Redirect to="/" />}
                                                        </Route>
                                                        <Route exact path='/profile'>
                                                            {this.props.isLoggedIn && this.props.currUser ?
                                                                <EditProfileModal user={this.props.currUser} onHide={this.onHideModal} />
                                                                :
                                                                <Redirect to="/" />}
                                                        </Route>
                                                        <Route path='/'>
                                                            <GameContainer className={css(styles.gameContainer)} />
                                                            {this.getCookie() === "" && !this.state.cookieaccepted ?
                                                                <div id="cookiediv" className={css(styles.cookieOverlay)}>
                                                                    <div>
                                                                        <span className={css(styles.homeFooterText)}>Please note that we use cookies. By clicking "Accept", you agree to our </span>
                                                                        <a className={css(styles.homeFooterTextLink)} href="https://plndrmobile.com/terms-and-conditions/" target="_blank"> Cookie Policy</a>
                                                                        <span className={css(styles.homeFooterText)}> -</span>
                                                                        <button id="savecookiebtn" className={css(styles.homeAcceptButton)} onClick={() => this.saveCookie()}>Accept</button>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </Route>
                                                    </Switch>
                                                }
                                            </Col>
                                        </MediaQuery>
                                        <MediaQuery maxWidth={1019}>
                                            <Col md={10} className={css(styles.mqcontainerBox)}>
                                                {!this.props.gamesList ? "Loading games..." :
                                                    <Switch>
                                                        <Route exact path='/ads'>
                                                            <AdsContainer />
                                                        </Route>
                                                        <Route exact path='/gamehistory'>
                                                            {this.props.isLoggedIn && this.props.gameHistory ?
                                                                <GameHistoryModal gameHistory={this.props.gameHistory} onHide={this.onHideModal} />
                                                                :
                                                                <Redirect to="/" />}
                                                        </Route>
                                                        <Route exact path='/paymenthistory'>
                                                            {this.props.isLoggedIn && this.props.paymentHistory ?
                                                                <PaymentHistoryModal paymentHistory={this.props.paymentHistory} onHide={this.onHideModal} />
                                                                :
                                                                <Redirect to="/" />}
                                                        </Route>
                                                        <Route exact path='/payouthistory'>
                                                            {this.props.isLoggedIn && this.props.payoutHistory ?
                                                                <PayoutHistoryModal payoutHistory={this.props.payoutHistory} onHide={this.onHideModal} />
                                                                :
                                                                <Redirect to="/" />}
                                                        </Route>
                                                        <Route exact path='/profile'>
                                                            {this.props.isLoggedIn && this.props.currUser ?
                                                                <EditProfileModal user={this.props.currUser} onHide={this.onHideModal} />
                                                                :
                                                                <Redirect to="/" />}
                                                        </Route>
                                                        <Route path='/'>
                                                            <GameContainer className={css(styles.gameContainer)} />
                                                            {this.getCookie() === "" && !this.state.cookieaccepted ?
                                                                <div id="cookiediv" className={css(styles.cookieOverlay)}>
                                                                    <div>
                                                                        <span className={css(styles.homeFooterText)}>Please note that we use cookies. By clicking "Accept", you agree to our </span>
                                                                        <a className={css(styles.homeFooterTextLink)} href="https://plndrmobile.com/terms-and-conditions/" target="_blank"> Cookie Policy</a>
                                                                        <span className={css(styles.homeFooterText)}> -</span>
                                                                        <button id="savecookiebtn" className={css(styles.homeAcceptButton)} onClick={() => this.saveCookie()}>Accept</button>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </Route>
                                                    </Switch>
                                                }
                                            </Col>
                                        </MediaQuery>
                                    </Row>}
                            </Route>
                        </Switch>
                    </Route>
                </div>
            </Router>
        );
    }
}

function mapStateToProps(state) {
    return {
        firstTimeVisitor: state.loginReducer.firstTimeVisitor,
        isLoggedIn: state.loginReducer.isLoggedIn,
        token: state.loginReducer.loginToken,
        loadGames: state.gameReducer.loadGames,
        loadLeaderboard: state.gameReducer.loadLeaderboard,
        gamesList: state.gameReducer.gamesList,
        leaderboard: state.gameReducer.leaderboard,
        user: state.loginReducer.user,
        alertCount: state.noPersistReducer.alertCount,
        clienttoken: state.paymentReducer.clientToken,
        gotclienttoken: state.paymentReducer.gotClientToken,
        gettingclienttoken: state.paymentReducer.gettingClientToken,
        authtoken: state.payoutReducer.authToken,
        gotauthtoken: state.payoutReducer.gotAuthToken,
        gettingAuthToken: state.payoutReducer.gettingAuthToken,
        gameHistory: state.gameReducer.gameHistory,
        paymentHistory: state.paymentReducer.paymentHistory,
        payoutHistory: state.payoutReducer.payoutHistory,
        currUser: state.loginReducer.user,
        sideMenu: state.noPersistReducer.sideMenu
    }
}

export default connect(mapStateToProps, {
    getGames,
    getLeaderboard,
    gotAlert,
    getUnreadCount,
    logVerification,
    getClientToken,
    getAuthToken,
    getGameHistory,
    openSideMenus
})(Home);