/*
PaymentActions.js
These are actions that return information assocaited with payments
*/

import { paymentActionTypes, gameActionTypes, loginActionTypes, pending_function, rejected_function, fulfilled_function, serverEnvironment, rejected, pending } from '../constants/ActionConstants';
import axios from 'axios';
import swal from 'sweetalert'

export var axiosConfig = {
    headers: { 'token': '', 'refUrl': document.location.host }
};
 export function getClientToken(token, userid) {
     const { GET_TOKEN } = paymentActionTypes;
     axiosConfig.headers.token = token;
    return function (dispatch) {
        dispatch(pending_function(GET_TOKEN));
        axios
            .post(serverEnvironment.apiHost + '/getToken/' + String(userid), {}, axiosConfig)
            .then((res) => {
                let clientToken = res.data;
                dispatch(fulfilled_function(GET_TOKEN, clientToken));
            })
            .catch(err => {
                dispatch(rejected_function(GET_TOKEN, err));
                swal("Error on getClientToken", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    }
} 

export function addPaypalPayment(token, payload) {
    const { ADD_PAYPAL_PAYMENT } = paymentActionTypes;
    const { UPDATE_USER } = gameActionTypes;
    const { LOGOUT } = loginActionTypes;

    return function (dispatch, getState) {
        dispatch(pending_function(ADD_PAYPAL_PAYMENT));
        axiosConfig.headers.token = token;
        axios
            .post(serverEnvironment.apiHost + '/paypalpaymentinfo', payload, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(ADD_PAYPAL_PAYMENT, res));
            })
            .then(() => {
                axios.get(serverEnvironment.apiHost + '/user/byId/' + payload.userId, axiosConfig)
                    .then((res) => {
                        dispatch(fulfilled_function(UPDATE_USER, res.data));
                    });
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT, err));
                }
                dispatch(rejected_function(ADD_PAYPAL_PAYMENT, err));
                swal("Error on addPaypalPayment", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    }

}

export function makePayment(token, payload) {
    const { MAKE_PAYMENT } = paymentActionTypes;
    const { UPDATE_USER } = gameActionTypes;
    const { LOGOUT } = loginActionTypes; 
    return function (dispatch, getState) {
        dispatch(pending_function(MAKE_PAYMENT));
        axiosConfig.headers.token = token;
        axios
            .post(serverEnvironment.apiHost + '/pay', payload, axiosConfig)
            .then((res)=> {
                dispatch(fulfilled_function(MAKE_PAYMENT, res));
            })
            .then(() => {
               axios.get(serverEnvironment.apiHost + '/user/byId/' + payload.userId, axiosConfig)
                   .then((res) => {
                       dispatch(fulfilled_function(UPDATE_USER, res.data));
                   });
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT, err)); 
                }
                dispatch(rejected_function(MAKE_PAYMENT, err));
                swal("Error on makePayment", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    }

}
export function getPaymentHistory(token,  userId) {
    const { GET_PAYMENT_HISTORY } = paymentActionTypes;
    const { LOGOUT } = loginActionTypes;

    return function (dispatch) {
        dispatch(pending_function(GET_PAYMENT_HISTORY));
        axiosConfig.headers.token = token;

        axios
            .get(serverEnvironment.apiHost + '/paymenthistory/' + String(userId), axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_PAYMENT_HISTORY, res));
            })
            .catch(err => {
                if (err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT, err));
                }
                dispatch(rejected_function(GET_PAYMENT_HISTORY, err));
                swal("Error on getPaymentHistory", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    };
}
