import { StyleSheet } from 'aphrodite'; 

export const styles = StyleSheet.create({
    adsIframe: {
        position: 'absolute', 
        borderRadius: 8, 
        borderColor: '#ffffff00', 
        left: 0, 
        top: 0, 
        bottom: 0, 
        right: 0
    },
    adsToHomeButton: {
        position: 'fixed', 
        bottom: '8%',
        right: '8%',
        background: 'linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)',
        color: "#3A3933",
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        border: "none",
        borderRadius: 8,
        height: 45,
        width: 220, 
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "0 0 0 0.15rem #50504a"
        }
    },
    containerBounds: {
        overflowY: 'auto', 
        overflowX: 'hidden', 
        maxHeight: '82vh',
        position: 'relative',
        '::-webkit-scrollbar': {
            WebkitAppearance: 'none',
            width: 8
        },
        '::-webkit-scrollbar-thumb': {
            backgroundColor: '#ffc501', 
            borderRadius: 8, 
            borderWidth: 1, 
            borderStyle: 'solid', 
            borderColor: '#ffc501', 
            borderImage: 'initial'
        },
        '::-webkit-scrollbar-track': {
            backgroundColor: '#ffc5010d',
            borderRadius: 8,
            borderColor: "#ffc501", 
            borderWidth: 0.5
        }
    },
    generalInterestContainer: {
        marginTop: '3%',
        position: 'relative',
        paddingBottom: '5%'
    },
    hidden: {
        display: 'none'
    },
    interestButton: {
        width: '78%', 
        alignSelf: 'center', 
        color: '#3A3934', 
        fontSize: 14,
        letterSpacing: 0.79, 
        fontFamily: 'SF Pro Text Medium',
        background: 'linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)',
        borderRadius: 4, 
        border: "none"

    },
    interestImage: {
        width: '100%', 
        height: 150
    },
    interestQuestion: {
        color: '#7f7e79',
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 35, 
        letterSpacing: -0.84
    },
    interestRow: {
        paddingTop: '2%'
    },
    interestRowFirst: {

    },
    interestTile: {
        borderRadius: 8,
        backgroundColor: '#3A3933',
        boxShadow: '0 4px 16px 2px rgba(0,0,0,0.16), 0 2px 4px 0 rgba(0,0,0,0.16)',
        paddingBottom: '8%'
    },
    interestTitle: {
        color: "#FFCC00", 
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 19, 
        letterSpacing: -0.48,
        textAlign: 'center',
        paddingTop: '8%'
    },
    noSelect: {
        WebkitTouchCallout: 'none', /* iOS Safari */
        WebkitUserSelect: 'none', /* Safari */
        KhtmlUserSelect: 'none', /* Konqueror HTML */
        MozUserSelect: 'none', /* Old versions of Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */
        userSelect: 'none' /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    },
    placeholderGif: {
        position: 'absolute', 
        height: '100%', 
        width: '100%',
        left: 0, 
        borderRadius: 8
    },
    questionInfoValue: {
        fontFamily: 'SF Pro Text Heavy',
        fontSize: 13,
        letterSpacing: 0.68,
        background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    showMoreButton: {
        color: "#F9CA14", 
        fontFamily: 'SF Pro Text Heavy', 
        fontSize: 15, 
        letterSpacing: 0.79,
        paddingTop: 8, 
        marginLeft: '1%',
        display: 'inline-block',
        ':hover': {
            cursor: 'pointer'
        }
    },
    spacer: {
        height: 20
    },
    toDoInterestContainer: {
        position: 'relative'
    },
    videoPlayerBox: {
        height: '82vh', 
        right: '3vw', 
        position: 'fixed', 
        width: '67vw',
        backgroundColor: "#3A3933", 
        borderRadius: 8, 
        boxShadow: '0 4px 16px 2px rgba(0,0,0,0.16), 0 2px 4px 0 rgba(0,0,0,0.16)', 
        '@media (max-width: 1260px)': {
            right: '1vw'
        }, 
        '@media (min-width: 1020px) and (max-width: 1200px)': {
            width: '63vw'
        },
        '@media (max-width: 1019px)': {
            width: '100%', 
            right: '3vw'
        }, 
        '@media (max-width: 991px)': {
            maxWidth: 'unset', 
            right: 0, 
            height: '88vh'
        }, 
        '@media (min-width: 750px) and (max-width: 850px)': {
            height: '80vh', 
            top: 98 
        }, 
        '@media (min-width: 700px) and (max-width: 749px)': {
            height: '65vh', 
            top: 162
        }, 
        '@media (min-width: 650px) and (max-width: 699px)': {
            height: '60vh', 
            top: 185
        }, 
        '@media (min-width: 600px) and (max-width: 649px)': {
            height: '56vh', 
            top: 185
        }, 
        '@media (min-width: 550px) and (max-width: 599px)': {
            height: '52vh', 
            top: 195
        }, 
        '@media (min-width: 500px) and (max-width: 549px)': {
            height: '50vh', 
            top: 200
        }, 
        '@media (min-width: 450px) and (max-width: 499px)': {
            height: '48vh', 
            top: 210
        }, 
        '@media (min-width: 400px) and (max-width: 450px)': {
            height: '42vh', 
            top: 230
        },
        '@media (min-width: 350px) and (max-width: 399px)': {
            height: '40vh', 
            top: 245
        }, 
        '@media (min-width: 300px) and (max-width: 349px)': {
            height: '38vh', 
            top: 250
        }
    },
    videoPlayerControls: {
        position: 'absolute', 
        width: '100%', 
        bottom: 0
    }
});