import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
    buyInText: {
        color: "#FAC51C",
        fontFamily: 'SF Pro Text Medium',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        marginTop: 6
    },
    buyInTextHidden: {
        color: "#FAC51C",
        fontFamily: 'SF Pro Text Medium',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        marginTop: 6,
        visibility: "hidden"
    },
    enteredGameSection: {
        height: 56,
        width: 176,
        border: "none",
        borderRadius: 8,
        fontFamily: 'SF Pro Text Heavy',
        fontWeight: "bold",
        fontSize: 15,
        letterSpacing: 0.79,
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        textAlign: 'center'
    },
    enteredPlayersSVG: {
        transform: "rotate(-65deg)",
        marginTop: "-133%"
    },
    enteredPlayersSVG2: {
        transform: "rotate(-63deg)",
        marginTop: "-133%"
    },
    enteredPlayersSVG3: {
        transform: "rotate(-61deg)",
        marginTop: "-133%"
    },
    enteredPlayersSVG4: {
        transform: "rotate(-59deg)",
        marginTop: "-133%"
    },
    enteredPlayersText: {
        fontSize: 13,
        fill: "#FFC100",
        letterSpacing: 0.68,
        fontFamily: 'SF Pro Text Medium'
    },
    enteredPlayersTextEntered: {
        fontSize: 13,
        fill: "#00C556",
        letterSpacing: 0.68,
        fontFamily: 'SF Pro Text Medium'
    },
    enteredSlotNumber: {
        color: "#00CA44", 
        fontFamily: 'SF Pro Text', 
        fontSize: 26, 
        fontWeight: 'bold', 
        letterSpacing: 1.47
    },
    enteredValueText: {
        fontSize: 13,
        color: "#FFC100",
        letterSpacing: 0.68,
        fontFamily: 'SF Pro Text Medium',
        marginTop: '-9.5%'
    },
    enteredValueTextEntered: {
        fontSize: 13,
        color: "#00C556",
        letterSpacing: 0.68,
        fontFamily: 'SF Pro Text Medium',
        marginTop: '-9.5%'
    },
    fillTextPaths: {

    },
    gameBodyBase: {
        position: "absolute",
        marginLeft: "-6.9%",
        marginTop: "-5%"
    },
    gameBodyshadow: {
        boxSizing: "border-box",
        height: 271,
        width: 271,
        // border: "15px solid #FAC51C", 
        borderRadius: 400,
        backgroundColor: "transparent",
        // boxShadow: "0 2px 4px 0 rgba(0,0,0,0.25), 0 4px 16px 2px rgba(0,0,0,0.25)",
        textAlign: "center",
        position: "relative"
    },
    gameBodyWrapper: {
        position: "relative",
        zIndex: "100000"
    },
    gameBodyWrapperTut: {
        position: "relative", 
        zIndex: 100000, 
        paddingTop: "20%"
    }, 
    gameCard: {
        background: "none",
        border: "none",
        borderRadius: "none",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 50
    },
    gameFire: {
        position: "absolute", 
        marginLeft: "-6.3%", 
        bottom: "-5%"
    },
    gameFooter: {
        background: "none",
        border: "none",
        marginTop: 35
    },
    gameHashMarks: {
        zIndex: "10000",
        position: "absolute",
        top: "0",
        left: "0"
    },
    gameHashMarksTut: {
        ":hover": {
            cursor: 'pointer'
        }
    },
    joinGameButton: {
        background: "linear-gradient(270deg, #FFE300 0%, #FFC500 100%)",
        height: 56,
        width: 176,
        border: "none",
        borderRadius: 8,
        fontFamily: 'SF Pro Text Heavy',
        fontWeight: "bold",
        fontSize: 15,
        letterSpacing: 0.79,
        color: "#3A3933",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "0 0 0 0.25rem #50504a"
        }
    },
    joinGameButtonTut: {
        background: "linear-gradient(270deg, #FFCC00 0%, #FF9500 100%)",
        height: 56,
        width: 176,
        border: "none",
        borderRadius: 8,
        fontFamily: 'SF Pro Text Heavy',
        fontWeight: "bold",
        fontSize: 15,
        letterSpacing: 0.79,
        color: "#3A3933",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "0 0 0 0.25rem #50504a"
        }
    },
    noSelect: {
        WebkitTouchCallout: 'none', /* iOS Safari */
        WebkitUserSelect: 'none', /* Safari */
        KhtmlUserSelect: 'none', /* Konqueror HTML */
        MozUserSelect: 'none', /* Old versions of Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */
        userSelect: 'none' /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    },
    prizeText: {
        display: "inline-block",
        color: "#FFC400",
        fontSize: 19,
        fontWeight: "300",
        letterSpacing: 1,
        textAlign: "center",
        fontFamily: 'SF Pro Text Light'
    },
    prizeTextEntered: {
        display: "inline-block",
        color: "#31C163",
        fontSize: 19,
        fontWeight: "300",
        letterSpacing: 1,
        textAlign: "center",
        fontFamily: 'SF Pro Text Light'
    },
    prizeTextContainer: {
        position: "relative",
        top: "25%"
    },
    prizeValue: {
        fontSize: 50,
        letterSpacing: -2,
        textAlign: "center",
        background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontFamily: 'SF Pro Text Heavy',
        marginBottom: -10
    },
    prizeValueEntered: {
        fontSize: 50,
        letterSpacing: -2,
        textAlign: "center",
        background: "linear-gradient(to right, #189042, #2fbe60)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontFamily: 'SF Pro Text Heavy',
        marginBottom: -10
    },
    progressBorderSVG: {
        height: 275,
        width: 275,
        marginTop: "-50%",
        marginLeft: "-9%",
        transform: "rotate(90deg)"
    },
    remainingSlots: {
        color: "#FAC51C",
        fontFamily: 'SF Pro Text Medium',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center"
    },
    remainingSlotsTut: {
        color: "#FF9000",
        fontFamily: 'SF Pro Text Medium',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center"
    },
    remainingSlotsEntered: {
        color: "#31C163",
        fontFamily: 'SF Pro Text Medium',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center"
    },
    remainingSlotsOpaque: {
        opacity: 0.25,
        color: "#FAC51C",
        fontFamily: 'SF Pro Text Medium',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center"
    },
    remainingSlotsOpaqueEntered: {
        opacity: 0.25,
        color: "#31C163",
        fontFamily: 'SF Pro Text Medium',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center"
    },
    youreIn: {
        fontFamily: 'SF Pro Text', 
        fontWeight: 'bold', 
        letterSpacing: 0.79, 
        color: "#00C556"
    }, 
    mqgameCard: {
        '@media (max-width: 500px)': {
            marginLeft: 30
        }
    }, 
    mqgameFire: {
        '@media (max-width: 991px)': {
            width: '94%', 
            marginLeft: '2.7%', 
            bottom: '1.3%'
        }, 
        '@media (max-width: 475px)': {
            width: '85%', 
            marginLeft: '7.7%', 
            bottom: '5.3%'
        }, 
        '@media (max-width: 425px)': {
            width: '76%', 
            marginLeft: '11.7%', 
            bottom: '13.3%'
        }
    }, 
    mqgameBodyBase: {
        '@media (max-width: 991px)': {
            width: '95%', 
            marginLeft: '2.5%', 
            marginTop: '3.6%'
        }, 
        '@media (max-width: 475px)': {
            width: '85%', 
            marginLeft: '7.5%', 
            marginTop: '8.6%'
        }, 
        '@media (max-width: 425px)': {
            width: '75%', 
            marginLeft: '12.5%'
        }
    }, 
    mqgameHashMarksTut: {
        '@media (max-width: 991px)': {
            top: 20, 
            left: 20, 
            width: '85%'
        }, 
        '@media (max-width: 475px)': {
            width: '75%', 
            top: 35, 
            left: 33
        }, 
        '@media (max-width: 425px)': {
            width: '66%', 
            top: 33, 
            left: 45
        }
    }, 
    mqprizeValue: {
        '@media (max-width: 991px)': {
            fontSize: 42
        }, 
        '@media (max-width: 425px)': {
            fontSize: 38
        }
    }, 
    mqprizeText: {
        '@media (max-width: 991px)': {
            fontSize: 18
        }
    }, 
    mqprogressBorderSVG: {
        '@media (max-width: 991px)': {
            marginTop: '-44.2%'
        }, 
        '@media (max-width: 475px)': {
            marginTop: '-43.9%'
        }, 
        '@media (max-width: 425px)': {
            marginTop: '-46.9%'
        }
    }, 
    mqenteredPlayersSVG2: {
        '@media (max-width: 991px)': {
            marginTop: '-150%'
        }, 
        '@media (max-width: 475px)': {
            marginTop: '-162%'
        }, 
        '@media (max-width: 425px)': {
            marginTop: '-173%', 
            transform: 'rotate(-65deg)'
        }
    }, 
    mqprizeTextContainer: {
        '@media (max-width: 991px)': {
            top: '30%'
        }, 
        '@media (max-width: 425px)': {
            top: '27%'
        }
    },
    mqgameBodyWrapperTut: {
        '@media (max-width: 991px)': {
            paddingTop: '13%'
        }, 
        '@media (max-width: 425px)': {
            paddingTop: '11%'
        }
    }, 
    mqgameFooter: {
        '@media (max-width: 899px)': {
            marginTop: 0
        }, 
        '@media (max-width: 475px)': {
            marginTop: -19
        }, 
        '@media (max-width: 425px)': {
            marginTop: -46
        }
    }, 
    mqjoinGameButtonTut: {
        '@media (max-width: 475px)': {
            height: 45, 
            width: 160
        }, 
        '@media (max-width: 425px)': {
            height: 36, 
            width: 148
        }
    }, 
    mqbuyInTextTut: {
        '@media (max-width: 475px)': {
            fontSize: 14
        }
    }, 
    mqremainingSlotsTut: {
        '@media (max-width: 475px)': {
            fontSize: 14
        }
    }, 
    mqenteredPlayersTextTut: {
        '@media (max-width: 425px)': {
            fontSize: 12
        }
    }
});

