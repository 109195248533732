/*
PaymentActions.js
These are actions that return information assocaited with Payouts
*/

import { payoutActionTypes, gameActionTypes, loginActionTypes, pending_function, rejected_function, fulfilled_function, serverEnvironment, rejected, pending } from '../constants/ActionConstants';
import axios from 'axios';
import swal from 'sweetalert';

// const payoutsSdk = require('@paypal/payouts-sdk');
// const payPalClient = require('../components/Payout/payPalClient');

export var axiosConfig = {
    headers: { 'token': '', 'refUrl': document.location.host, ContentType: 'application/json' }
};

export function getAuthToken() {
  //console.log("in payout getauthtoken");
  const { GET_AUTHTOKEN } = payoutActionTypes;
  var qs = require('qs');
  var authData = qs.stringify({
  'grant_type': 'client_credentials' 
  });
  let encoded = new Buffer(`${serverEnvironment.clientID}:${serverEnvironment.clientSecret}`).toString('base64');
  axiosConfig.headers = 
  {
    'ContentType': 'application/x-www-form-urlencoded',
    'Authorization': `Basic ${encoded}`
  }
  //console.log("next step in getauthtoken", serverEnvironment.paypalPayoutAPIHost, axiosConfig, authData);
  return function (dispatch) {
      dispatch(pending_function(GET_AUTHTOKEN));
      axios
          .post(serverEnvironment.paypalPayoutAPIHost + '/oauth2/token?', authData, axiosConfig)
          .then((res) => {
              let authToken = res.data;
              //console.log("return auth", authToken);
              dispatch(fulfilled_function(GET_AUTHTOKEN, authToken));
          })
          .catch(err => {
              dispatch(rejected_function(GET_AUTHTOKEN, err));
              swal("Error on getAuthToken", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
          });
  }
}

export function createPayout(token, payload) {
  //console.log("in create payout");
  const { CREATE_PAYOUT } = payoutActionTypes;
  const { ADD_USER_PAYOUT_INFO } = payoutActionTypes;
  const { UPDATE_USER } = gameActionTypes;
  const { LOGOUT } = loginActionTypes;

  let breartoken = 'Bearer ' + payload.authToken;
  axiosConfig.headers = 
  {
    'Content-Type': 'application/json', 
    'Authorization': breartoken
  }
  return function (dispatch) {
      dispatch(pending_function(CREATE_PAYOUT));
      axiosConfig.headers.token = token;
      //console.log("in payout action dispatch", payload);
      let payout = {};
      let payoutresponse = {};
      axios   
        .post(serverEnvironment.paypalPayoutAPIHost + '/payments/payouts', buildRequestBody(payload), axiosConfig)
          .then((response) => {
              payoutresponse = response;
              console.log(response)
              dispatch(fulfilled_function(CREATE_PAYOUT, payoutresponse));
          })
          .then(() => {
                  payout.userId = payload.userId;
                  payout.amount = payload.plndramount;
                  payout.payoutID = payoutresponse.data.batch_header.payout_batch_id;
                  payout.payoutStatus = payoutresponse.data.batch_header.batch_status;
                  payout.payoutMethodType = payload.wallet;
                  if(payload.wallet === "PAYPAL")
                  {
                     payout.contactInfo = payload.email;
                  }
                  else
                  {
                     payout.contactInfo = payload.phone;
                  }
                  if ((payout.payoutID != null) && (payout.payoutStatus === "PENDING")) {
                      axios.post(serverEnvironment.apiHost + '/payoutinfo', payout, axiosConfig)
                      .then((res) => {
                      let returnuser = res;
                      dispatch(fulfilled_function(ADD_USER_PAYOUT_INFO, returnuser));
                  });
               }
          })
          // .then(() => {
          //    axios.get(serverEnvironment.apiHost + '/user/byId/' + payload.userId, axiosConfig)
          //        .then((res) => {
          //         console.log("update user", res.data.balance);
          //         dispatch(fulfilled_function(UPDATE_USER, res.data));
          //        });
          // })
          .catch(err => {
              if (err.response && err.response.status === 401) {
                  dispatch(fulfilled_function(LOGOUT, err)); 
              }
              dispatch(rejected_function(CREATE_PAYOUT, err));
              swal("Error on createPayout", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
          }); 
  }
}

export function updateUser(token, userId) {
  const { UPDATE_USER } = gameActionTypes;
  const { LOGOUT } = loginActionTypes;

  return function (dispatch) {
      dispatch(pending_function(UPDATE_USER));
      axiosConfig.headers.token = token;
      axios
          .get(serverEnvironment.apiHost + '/user/byId/' + String(userId), axiosConfig)
             .then((res) => {
              dispatch(fulfilled_function(UPDATE_USER, res.data));
             })
           .catch(err => {
              if (err.response.status === 401) {
                  dispatch(fulfilled_function(LOGOUT, err));
              }
              dispatch(rejected_function(UPDATE_USER, err));
              swal("Error in updateUser", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
          });
  };

}

export function getPayoutHistory(token, userId) {
    const { GET_PAYOUT_HISTORY } = payoutActionTypes;
    const { LOGOUT } = loginActionTypes;

    return function (dispatch) {
        dispatch(pending_function(GET_PAYOUT_HISTORY));
        axiosConfig.headers.token = token;

        axios
            .get(serverEnvironment.apiHost + '/payouthistory/' + String(userId), axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_PAYOUT_HISTORY, res));
            })
            .catch(err => {
                if (err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT, err));
                }
                dispatch(rejected_function(GET_PAYOUT_HISTORY, err));
                swal("Error on getPayoutHistory", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    };
}


function buildRequestBody(payload)
{
  var requestBody = {};
  payload.wallet === "PAYPAL" ? requestBody = buildPaypalRequestBody(payload) : requestBody= buildVenmoRequestBody(payload);
  //console.log(requestBody);
  return requestBody
}

function buildPaypalRequestBody(payload) {
    let senderBatchId = "Test_sdk_" + Math.random().toString(36).substring(7);
    let paypalItem = {"note": payload.note,
                      "amount": {
                        "currency": "USD",
                        "value": payload.paypalamount
                      },
                      "receiver": payload.email,
                      "recipient_wallet": "PAYPAL", 
                      "sender_item_id": payload.userId}
    var paypalItemJSON = JSON.stringify(paypalItem);                 
    return {
        "sender_batch_header": {
        "recipient_type": "EMAIL",
        "email_message": "You have received payouts from Plndr",
        "note": payload.note,
        "sender_batch_id": senderBatchId,
        "email_subject": "Plndr Payouts"
      },
      "items": [
        {"note": payload.note,
         "amount": {
          "currency": "USD",
          "value": payload.paypalamount
        },
        "receiver": payload.email,
        "recipient_wallet": "PAYPAL", 
        "sender_item_id": payload.userId
        }]
    }
  }

  function buildVenmoRequestBody(payload)
  {
  //console.log("in request body", payload);
  let senderBatchId = "Test_sdk_" + Math.random().toString(36).substring(7);
  let venmoItem = {
    "recipient_type": "PHONE",
    "amount": {
      "value": payload.paypalamount,
      "currency": "USD"
    },
    "note": payload.note,
    "sender_item_id": payload.userId,
    "recipient_wallet": "VENMO",
    "receiver": payload.phone
  }   
  var venmoItemJSON = JSON.stringify(venmoItem);            
  return {
    "sender_batch_header": {
    "recipient_type": "PHONE",
    "email_message": "You have received payouts from Plndr",
    "note": payload.note,
    "sender_batch_id": senderBatchId,
    "email_subject": "Plndr Payouts"
  },
  "items": [
    {
      "amount": {
      "value": payload.paypalamount,
      "currency": "USD"
    },
    "note": payload.note,
    "sender_item_id": payload.userId,
    "receipient_wallet": "VENMO",
    "receiver": payload.phone
    }]
    }
} 


