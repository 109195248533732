import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
    buyInText: {
        color: "#FAC51C",
        fontFamily: 'SF Pro Text Medium',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        marginTop: 6
    },
    dropinbtn: {
        textDecoration: 'none',
        height: 56,
        width: 283,
        borderRadius: 5,
        backgroundColor: "#3E89F0",
        border: 'none',
        color: "#FFFFFF",
        fontFamily: "-apple-system, BlinkMacSystemFont, ‘Segoe UI’, ‘Roboto’, ‘Oxygen’, ‘Ubuntu’, ‘Cantarell’, ‘Fira Sans’, ‘Droid Sans’, ‘Helvetica Neue’, sans-serif;",
        fontSize: 20,
        fontWeight: 'bold',
        letterSpacing: 0
    },
    dropInBtnContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    enteredPlayersSVG: {
        transform: "rotate(-65deg)",
        marginTop: "-133%"
    },
    enteredPlayersSVG2: {
        transform: "rotate(-63deg)",
        marginTop: "-133%"
    },
    enteredPlayersSVG3: {
        transform: "rotate(-61deg)",
        marginTop: "-133%"
    },
    enteredPlayersSVG4: {
        transform: "rotate(-59deg)",
        marginTop: "-133%"
    },
    enteredPlayersText: {
        fontSize: 13,
        fill: "#FFC100",
        letterSpacing: 0.68,
        fontFamily: 'SF Pro Text Medium'
    },
    enteredValueText: {
        fontSize: 13,
        color: "#FFC100",
        letterSpacing: 0.68,
        fontFamily: 'SF Pro Text Medium',
        marginTop: '-9.5%'
    },
    fillTextPaths: {

    },
    gameBodyBase: {
        position: "absolute",
        marginLeft: "-6.9%",
        marginTop: "-5%"
    },
    gameBodyshadow: {
        boxSizing: "border-box",
        height: 271,
        width: 271,
        // border: "15px solid #FAC51C", 
        borderRadius: 400,
        backgroundColor: "transparent",
        // boxShadow: "0 2px 4px 0 rgba(0,0,0,0.25), 0 4px 16px 2px rgba(0,0,0,0.25)",
        textAlign: "center",
        position: "relative"
    },
    gameBodyWrapper: {
        position: "relative",
        zIndex: "100000"
    },
    gameCard: {
        background: "none",
        border: "none",
        borderRadius: "none",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 50
    },
    gameFooter: {
        background: "none",
        border: "none",
        marginTop: 35
    },
    gameHashMarks: {
        zIndex: "10000",
        position: "absolute",
        top: "0",
        left: "0"
    },
    joinGameButton: {
        background: "linear-gradient(270deg, #FFE300 0%, #FFC500 100%)",
        height: 56,
        width: 176,
        border: "none",
        borderRadius: 8,
        fontFamily: 'SF Pro Text Heavy',
        fontWeight: "bold",
        fontSize: 15,
        letterSpacing: 0.79,
        color: "#3A3933",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "0 0 0 0.25rem #50504a"
        }
    },
    noSelect: {
        WebkitTouchCallout: 'none', /* iOS Safari */
        WebkitUserSelect: 'none', /* Safari */
        KhtmlUserSelect: 'none', /* Konqueror HTML */
        MozUserSelect: 'none', /* Old versions of Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */
        userSelect: 'none' /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    },
    prizeText: {
        display: "inline-block",
        color: "#FFC400",
        fontSize: 19,
        fontWeight: "300",
        letterSpacing: 1,
        textAlign: "center",
        fontFamily: 'SF Pro Text Light'
    },
    prizeTextContainer: {
        position: "relative",
        top: "25%"
    },
    prizeValue: {
        fontSize: 50,
        letterSpacing: -2,
        textAlign: "center",
        background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontFamily: 'SF Pro Text Heavy',
        marginBottom: -10
    },
    progressBorderSVG: {
        height: 275,
        width: 275,
        marginTop: "-50%",
        marginLeft: "-9%",
        transform: "rotate(90deg)"
    },
    remainingSlots: {
        color: "#FAC51C",
        fontFamily: 'SF Pro Text Medium',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center"
    },
    remainingSlotsOpaque: {
        opacity: 0.25,
        color: "#FAC51C",
        fontFamily: 'SF Pro Text Medium',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center"
    },

    body: {
        backgroundColor: '#ffffff',
        padding: '2%'
    },

    bootstrapBasic: {
        // [OLD STYLES]
        // backgroundColor: "#FFFFFF",
        // [NEW STYLES]
        backgroundColor: "#3A3934",
        border: "2px solid",

        borderRadius: 8,
        boxShadow: "0 9px 10px 0 rgba(0,0,0,0.1)"
    },
    addFundsIcon: {
        width: 16,
        height: 16
    },
    menuContainer: {
        position: 'absolute',
        right: 15,
        zIndex: 1000000,
        top: '100%',
        backgroundColor: "#3A3934",
        borderRadius: 8,
        boxShadow: "0 0 6px rgba(0,0,0,0.16), 0 6px 6px 0 rgba(0,0,0,0.16)",
        width: '26.1vw',
        height: '85vh'
    },
    menuDivider: {
        // backgroundColor: '#737272',
        backgroundColor: '#DEE2E5',
        marginTop: '0.5rem',
        marginBottom: '0.5rem'
    },
    menuIconCol: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center'
    },
    menuOptionCol: {
        paddingLeft: 0,
        color: "#FFCC00",
        fontFamily: 'SF Pro Text Medium',
        fontSize: 15,
        letterSpacing: 0.1
    },
    menuOptionColLogo: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    menuOptionHeader: {
        paddingLeft: 0,
        color: "#000000",
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        fontSize: 16,
        fontWeight: 400,
        paddingLeft: 30
    },
    menuProfilePhoto: {
        height: 64,
        width: 64,
        borderRadius: 50
    },
    menuRow: {
        width: '100%',
        margin: '0 auto',
        paddingTop: '2%',
        paddingBottom: '2%'
    },
    menuRowPayoutEntry: {
        width: '100%',
        margin: '0 auto',
        paddingTop: '0%',
        paddingBottom: '2%',
        display: 'flex',
        justifyContent: 'center'
    },
    menuRowSendFunds: {
        width: '100%',
        margin: '0 auto',
        paddingBottom: '2%',
        paddingTop: '2%',
        justifyContent: 'center',
        display: 'flex',
        borderBottom: "1px solid #B5B5B5"
    },
    menuRowMin: {
        width: '100%',
        margin: '0 auto',
        paddingTop: '0%',
        paddingBottom: '2%'
    },
    menuRowExit: {
        width: '100%',
        margin: '0 auto',
        paddingTop: 0,
        paddingBottom: 0
    },
    menuRowOption: {
        ':hover': {
            cursor: 'pointer'
        }
    },
    menuRowOptionPaymentValue: {
        paddingTop: '0%',
        paddingBottom: '0%'
    },
    menuRowOptionPayRow: {
        marginBottom: 15,
        marginTop: 15
    },
    menuRowSocials: {
        width: '100%',
        margin: '0 auto',
        paddingTop: '7%',
        paddingBottom: '2%'
    },
    menuRowWithdrawFunds: {
        width: '100%',
        margin: '0 auto',
        paddingTop: '2%',
        paddingBottom: '2%',
        marginTop: -30
    },
    menuUserName: {
        color: '#FFCC00',
        fontFamily: 'SF Pro Display',
        fontWeight: 'bold',
        letterSpacing: 0.38

    },
    menuUserNameCol: {
        paddingLeft: 0,
        display: 'flex',
        alignItems: 'center'
    },
    menuUserRow: {
        paddingTop: '5%',
        paddingBottom: '8%'
    },
    rowContainer: {
        position: 'relative'
    },
    // Withdraw page specific 
    withdrawbody: {
        // padding: '15',
        // position: 'absolute',
        // zIndex: 1000000,
        // top: '80%',
        // left: '35%',
        // backgroundColor: '#fff',
        // borderRadius: 8,
        // boxShadow: "0 0 0.2px rgba(0, 123, 255, 0.25)",
        // //boxShadow: "0 0 6px rgba(0,0,0,0.16), 0 6px 6px 0 rgba(0,0,0,0.16)",
        // width: '40vw',
        // height: '100vh'
    },
    exitIcon: {
        width: 14,
        height: 14,
        backgroundColor: "#A9A9A9"
    },
    withdrawTitleRow: {
        margin: '0 auto',
        textAlign: 'center'
    },
    withdrawTitle: {
        // OLD STYLE
        // color: "#0075B9",
        // [NEW STYLE]
        color: "#F9CA14",

        fontFamily: "-apple-system, BlinkMacSystemFont, ‘Segoe UI’, ‘Roboto’, ‘Oxygen’, ‘Ubuntu’, ‘Cantarell’, ‘Fira Sans’, ‘Droid Sans’, ‘Helvetica Neue’, sans-serif",
        zIndex: 0,
        letterSpacing: 0,
        fontSize: 20,
        fontWeight: 500
    },
    menuRowConfirm: {
        width: '100%',
        margin: '0 auto',
        paddingBottom: '0%',
        paddingTop: '0%'
    },
    menuRowConfirmTitle: {
        width: '100%',
        margin: '0 auto',
        paddingBottom: '0%',
        paddingTop: '0%'
    },
    menuRowOptionPayoutValue: {
        paddingTop: '0%',
        paddingBottom: '0%'
    },
    confirmAmountTitlefonts: {
        color: "#31C163",
        fontFamily: "-apple-system, BlinkMacSystemFont, ‘Segoe UI’, ‘Roboto’, ‘Oxygen’, ‘Ubuntu’, ‘Cantarell’, ‘Fira Sans’, ‘Droid Sans’, ‘Helvetica Neue’, sans-serif;",
        letterSpacing: 0,
        fontSize: 50,
        textAlign: 'center',
        fontWeight: 500
    },
    paidText: {
        fontSize: 16,
        margiTop: -10
    },
    dollarAmount: {
        width: 150,
        // borderColor: 'transparent'
        border: '2px solid', 
        backgroundColor: 'transparent'
    },
    plussignamount: {
        content: "+",
        height: 60,
        width: 60,
        fontSize: 60,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        fontFamily: 'courier',
        color: 'white',
        backgroundColor: 'rgb(26, 102, 217)'
    },
    minussignamount: {
        content: "-",
        height: 50,
        width: 50,
        fontSize: 50,
        borderRadius: 10,
        //display: "inline-block",
        textAlign: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        fontFamily: 'courier',
        color: 'white',
        backgroundColor: 'rgb(26, 102, 217)'
    },
    circle: {
        backgroundColor: "#f9ca14",
        width: 48,
        height: 48,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 40,
        color: "#3a3934",
        fontWeight: 'bold',
        fontFamily: 'courier'
    },
    minussign: {
        marginBottom: -3,
        ":hover": {
            cursor: 'pointer'
        }
    },
    plussign: {
        ":hover": {
            cursor: 'pointer'
        }
    },
    minusSignContain: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    amountTitlefontsRow: {
        // [OLD STYLES]
        // color: '#0075B9',
        // [NEW STYLES]
        color: "#F9CA14",

        fontFamily: "-apple-system, BlinkMacSystemFont, ‘Segoe UI’, ‘Roboto’, ‘Oxygen’, ‘Ubuntu’, ‘Cantarell’, ‘Fira Sans’, ‘Droid Sans’, ‘Helvetica Neue’, sans-serif;",
        letterSpacing: 0,
        fontSize: 50,
        textAlign: 'center',
        justifyContent: 'center',
        fontWeight: 500
    },
    amountTitlefonts: {
        // [OLD STYLES]
        // color: '#0075B9',
        // [NEW STYLES]
        color: "#f9ca14",
        
        fontFamily: "-apple-system, BlinkMacSystemFont, ‘Segoe UI’, ‘Roboto’, ‘Oxygen’, ‘Ubuntu’, ‘Cantarell’, ‘Fira Sans’, ‘Droid Sans’, ‘Helvetica Neue’, sans-serif;",
        letterSpacing: 0,
        fontSize: 50,
        textAlign: 'left',
        fontWeight: 500
    },
    withdrawSmallFonts: {
        // [OLD STYLES]
        // color: 'rgb(128,128,128)',
        // fontFamily: "-apple-system, BlinkMacSystemFont, ‘Segoe UI’, ‘Roboto’, ‘Oxygen’, ‘Ubuntu’, ‘Cantarell’, ‘Fira Sans’, ‘Droid Sans’, ‘Helvetica Neue’, sans-serif;",
        // [NEW STYLES]
        color: "#f9ca14", 
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important",

        letterSpacing: 0,
        fontWeight: 400,
        fontSize: 16,
        textAlign: 'center'
    },
    amountsRowInput: {
        width: '2',
        position: 'absolute',
        left: '30%',
        top: '12.5%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: -1
    },
    amountfonts: {
        color: 'rgb(26, 102, 217)',
        fontFamily: 'SF Pro Text Heavy',
        letterSpacing: 3.68,
        fontSize: 15
    },
    payoutInput: {
        border: "1px solid #bfbfbf",
        height: 44,
        padding: "0 8px",
        '@media (max-width: 475px)': {
            width: '90%'
        }
    },
    payoutMethodTitle: {
        color: "black",
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", 
        fontSize: 16,
        fontWeight: 400
    },
    payoutmethodTitleRow: {
        width: '18',
        position: 'absolute',
        left: '10%',
        top: '20.5%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: -2
    },
    payoutmethodTitlefonts: {
        color: 'rgb(128,128,128)',
        fontFamily: 'SF Pro Text Heavy',
        letterSpacing: 3.68,
        fontSize: 20
    },
    cardbutton: {
        fontWeight: 500,
        left: 3,
        lineHeight: 'inherit',
        position: 'relative',
        textDecoration: 'none',
        textAlign: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 3
    },
    buttonSmall: {
        paddingTop: 10,
        fontSize: 0.875
    },
    buttonBlueRow: {
        width: '15',
        position: 'absolute',
        left: '40%',
        top: '30.5%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: -2
    },
    buttonBlue: {
        outline: 'none',
        backgroundColor: 'blue',
        borderColor: 'white',
        borderRadius: 1,
        color: 'white'
    },
    addcreditButtonRow: {
        position: 'absolute',
        left: '40%',
        top: '90%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: -1
    },
    addcreditButton: {
        // [OLD STYLES]
        // backgroundColor: '#1a66d9',
        // color: "#ffffff",
        // [NEW STYLES]
        backgroundColor: "#f9ca14",
        color: 'black',

        height: 40,
        width: 200,
        border: "none",
        borderRadius: 3,
        fontFamily: "-apple-system, BlinkMacSystemFont, ‘Segoe UI’, ‘Roboto’, ‘Oxygen’, ‘Ubuntu’, ‘Cantarell’, ‘Fira Sans’, ‘Droid Sans’, ‘Helvetica Neue’, sans-serif;",
        fontSize: 20,
        textAlign: "center",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "none"
        }
    },
    // payout Confirmation page specific 
    payoutconfirmbody: {
        padding: '15',
        position: 'absolute',
        zIndex: 1000000,
        top: '100%',
        left: '35%',
        backgroundColor: '#fff',
        borderRadius: 8,
        boxShadow: "0 0 0.2px rgba(0, 123, 255, 0.25)",
        //boxShadow: "0 0 6px rgba(0,0,0,0.16), 0 6px 6px 0 rgba(0,0,0,0.16)",
        width: '40vw',
        height: '100vh'
    },
    payoutconfirmTitleFonts: {
        color: 'rgb(26, 102, 217)',
        fontFamily: 'SF Pro Text Heavy',
        top: '3.5%',
        letterSpacing: 1.50,
        fontSize: 20
    },

    payoutconfirmAmountTitlefonts: {
        color: 'rgb(34,139,34)',
        fontFamily: "-apple-system, BlinkMacSystemFont, ‘Segoe UI’, ‘Roboto’, ‘Oxygen’, ‘Ubuntu’, ‘Cantarell’, ‘Fira Sans’, ‘Droid Sans’, ‘Helvetica Neue’, sans-serif;",
        letterSpacing: 3.68,
        fontSize: 40,
        justifyContent: 'center',
        left: '25%',
    },
    payoutContainer: {
        marginRight: 15,
        marginLeft: 15,
        // [OLD STYLES]
        // border: "1px solid #B5B5B5",
        // [NEW STYLES]
        border: "5px solid",
        backgroundColor: "#FFFFFF", 

        borderRadius: 4, 
    },
    amountPaidfonts: {
        color: 'rgb(34,139,34)',
        fontFamily: 'SF Pro Text Heavy',
        letterSpacing: 3.68,
        fontSize: 20,
        justifyContent: 'center',
        left: '30%',
    },
    payoutconfirmSmallFonts: {
        color: 'rgb(169,169,169)',
        fontFamily: "-apple-system, BlinkMacSystemFont, ‘Segoe UI’, ‘Roboto’, ‘Oxygen’, ‘Ubuntu’, ‘Cantarell’, ‘Fira Sans’, ‘Droid Sans’, ‘Helvetica Neue’, sans-serif;",
        left: '10%',
        top: '3.5%',
        letterSpacing: 1.50,
        fontSize: 15
    },
    payoutconfirmSmallDarkFonts: {
        color: 'rgb(128,128,128)',
        fontFamily: "-apple-system, BlinkMacSystemFont, ‘Segoe UI’, ‘Roboto’, ‘Oxygen’, ‘Ubuntu’, ‘Cantarell’, ‘Fira Sans’, ‘Droid Sans’, ‘Helvetica Neue’, sans-serif;",
        left: '10%',
        top: '0.0%',
        letterSpacing: 1.50,
        fontSize: 17
    },
    addcreditButtonRow: {
        position: 'absolute',
        left: '40%',
        top: '90%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: -1
    },
    backtogameButton: {
        position: 'relative',
        background: '#1a66d9',
        height: 40,
        width: 200,
        left: '30%',
        border: "none",
        borderRadius: 3,
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 20,
        textAlign: "center",
        color: "#ffffff",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "none"
        }
    },
    closeMenuButton: {
        textAlign: 'right',
        marginTop: 12
    },

    // [NEW CODE (ENTIRE STYLE)]]
    transactionFee: {
        color: "black"
    },
    mqamountTitlefontsRow: {
        '@media (max-width: 490px)': {
            fontSize: 42
        },
        '@media (max-width: 404px)': {
            fontSize: 36
        }
    },
    mqcircle: {
        '@media (max-width: 400px)': {
            width: 40,
            height: 40,
            fontSize: 39
        },
        '@media (max-width: 358px)': {
            width: 36,
            height: 36,
            fontSize: 36
        }
    },
    mqcloseMenuButton: {
        '@media (max-width: 767px)': {
            zIndex: 2
        }
    },
    mqdollarAmount: {
        '@media (max-width: 991px)': {
            width: 130
        },
        '@media (max-width: 490px)': {
            width: 100,
            fontSize: 42
        },
        '@media (max-width: 404px)': {
            width: 75,
            fontSize: 36
        }
    },
    mqOptionImage: {
        '@media (max-width: 500px)': {
            width: 118
        },
        '@media (max-width: 450px)': {
            width: 100
        }
    }
});