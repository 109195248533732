import { payoutActionTypes, menuActionTypes, gameActionTypes, pending, rejected, fulfilled } from '../constants/ActionConstants';

export default function reducer(state = {
    user: { id: 0, name: "Not logged in"},
    authToken: '',
    payoutHistory: [],
    transactPayout: false,
    payout: {},
    message: 'Payout',
    gotAuthToken: false,
    gettingAuthToken: false
}, action) {
    switch (action.type) {
        case fulfilled(payoutActionTypes.CLEAR_PAYOUT_INFO):
            console.log("Clear payout info");
            return {
                ...state,
                authToken: '',
                payoutHistory: [],
                transactPayout: false,
                message: 'Clear Payout Information',
                gotAuthToken: false
            }
        case fulfilled(menuActionTypes.OPEN_PAYOUT):
            return {
                ...state,
                transactPayout: false
            }
        case fulfilled(menuActionTypes.CLOSE_PAYOUT):
            return {
                ...state,
                transactPayout: false
            }
        case fulfilled(gameActionTypes.UPDATE_USER): 
            return {
                ...state, 
                //transactPayout: true,
                user: action.payload
            }
        case pending(payoutActionTypes.GET_AUTHTOKEN):
            return {
                ...state,
                gotAuthToken: false,
                gettingAuthToken: true,
                message: "Getting Auth Token"
            }
        case rejected(payoutActionTypes.GET_AUTHTOKEN):
            return {
                ...state,
                gotAuthToken: false,
                gettingAuthToken: false,
                message: action.payload.message
            }
        case fulfilled(payoutActionTypes.GET_AUTHTOKEN):
            return {
                ...state,
                gotAuthToken: true,
                gettingAuthToken: false,
                authToken: action.payload.access_token,
                message: 'AuthToken received'
            }
        case pending(payoutActionTypes.CREATE_PAYOUT):
            return {
                ...state,
                message: "Creating Payouts"
            }
        case rejected(payoutActionTypes.CREATE_PAYOUT):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(payoutActionTypes.CREATE_PAYOUT): 
            return {
                ...state, 
                message: action.payload.message
            }
        case fulfilled(payoutActionTypes.ADD_USER_PAYOUT_INFO): 
            return {
                ...state, 
                payout: action.payload.data.payouts[0],
                transactPayout: true,
                message: action.payload.message
            }
        case pending(payoutActionTypes.CANCEL_PAYOUT):
            return {
                ...state,
                message: "Cancelling Payout"
            }
        case rejected(payoutActionTypes.CANCEL_PAYOUT):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(payoutActionTypes.CANCEL_PAYOUT):
            return {
                ...state,
                payout: action.payload
            }
        case pending(payoutActionTypes.GET_PAYOUT_HISTORY):
                return {
                    ...state,
                    message: "Fetching payment history"
                }
        case fulfilled(payoutActionTypes.GET_PAYOUT_HISTORY):
                return {
                    ...state,
                    payoutHistory: action.payload.data
                }
        case rejected(payoutActionTypes.GET_PAYOUT_HISTORY):
                return {
                    ...state,
                    message: action.payload.message
                }
        default:
            return state;
    }
};
