import { walletActionTypes, pending, rejected, fulfilled } from '../constants/ActionConstants';

export default function reducer(state = {
    user: { id: 0, name: "Not logged in", balance:0.0},
    customerId:'',
    clientToken: '',
    paymentMethodList:[],
    walletDone: false,
    message: 'My Wallet'
}, action) {
    switch (action.type) {
        case pending(walletActionTypes.GET_CLIENT_TOKEN):
            return {
                ...state,
                message: "Getting My Payment Methods"
            }
        case rejected(walletActionTypes.GET_CLIENT_TOKEN):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(walletActionTypes.GET_CLIENT_TOKEN):
            return {
                ...state,
                walletDone: false,
                clientToken: action.payload,
                message: 'Payment Methods received'
            }
        case pending(walletActionTypes.GET_PAYMENT_METHODS):
            return {
                ...state,
                message: "Getting My Payment Methods"
            }
        case rejected(walletActionTypes.GET_PAYMENT_METHODS):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(walletActionTypes.GET_PAYMENT_METHODS):
            return {
                ...state,
                walletDone: false,
                paymentMethodList : action.payload,
                message: 'Payment Methods received'
            }
        case pending(walletActionTypes.CREATE_PAYMETHOD):
            return {
                ...state,
                message: "Creating Payment Methods"
            }
        case rejected(walletActionTypes.CREATE_PAYMETHOD):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(walletActionTypes.CREATE_PAYMETHOD): 
            return {
                ...state, 
                walletDone: true,
                paymentMethodList : action.payload,
                message: 'Payment Methods created'
            }
        case pending(walletActionTypes.UPDATE_PAYMETHOD):
            return {
                ...state,
                message: "Updating Payment Methods"
            }
        case rejected(walletActionTypes.UPDATE_PAYMETHOD):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(walletActionTypes.UPDATE_PAYMETHOD):
            return {
                ...state,
                walletDone: true,
                paymentMethodList : action.payload,
                message: action.payload.message
            }
        case pending(walletActionTypes.DELETE_PAYMETHOD):
            return {
                ...state,
                message: "Deleting Payment Method"
            }
        case rejected(walletActionTypes.DELETE_PAYMETHOD):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(walletActionTypes.DELETE_PAYMETHOD):
            return {
                ...state,
                walletDone: true,
                paymentMethodList: action.payload,
                message: action.payload.message
            }
        default:
            return state;
    }
};
