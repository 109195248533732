export const user = {
    //Hash ID for each user, used to pull stored payment info, etc (more secure information)
        //Could also just be a number
    userId: "1b3cjgh49cmgh30cn20nd",
    username: "Kyle Connolly",
    profilePhoto: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fvignette.wikia.nocookie.net%2Frocky%2Fimages%2Ff%2Ffd%2FMichael-B-Jordan.jpg%2Frevision%2Flatest%3Fcb%3D20160226012927&f=1&nofb=1",
    userBalance: 3.00,
    currentRank: 87,
    currentPoints: 842,
    storedCannonShots: 3,
    //GameIds of each currently active game the user has entered into
    enteredActiveGames: ["5"],
    gameHistory: [
        {
            gameId: "50",
            userWon: false,
            prizeValue: "100",
            //userId of winning player
            winningPlayer: 'w9bhqb20bnw0nbaj394nf'
        },
        {
            gameId: "51",
            userWon: true,
            prizeValue: "50",
            //userId of winning player
            winningPlayer: '1b3cjgh49cmgh30cn20nd'
        }
    ],
    paymentHistory: [

    ],
    wihdrawlHistory: [

    ],
    //Unsure as to how we handle grabbing payment information/More Sensitive Information
        //Do we store in the reducer? 
        //Do we make an API request every time we want to use it? 
        //Do we make the user manually enter in their payment info everytime?
            //Most sites store payment info - so there must be a standard convention for this
}