import { StyleSheet } from 'aphrodite'; 

export const styles = StyleSheet.create({
    buyInText: {
        color: "#FAC51C",
        fontFamily: 'SF Pro Text Medium',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        marginTop: 6
    },
    enteredPlayersSVG: {
        transform: "rotate(-65deg)",
        marginTop: "-133%"
    },
    enteredPlayersSVG2: {
        transform: "rotate(-63deg)",
        marginTop: "-133%"
    },
    enteredPlayersSVG3: {
        transform: "rotate(-61deg)",
        marginTop: "-133%"
    },
    enteredPlayersSVG4: {
        transform: "rotate(-59deg)",
        marginTop: "-133%"
    },
    enteredPlayersText: {
        fontSize: 13,
        fill: "#FFC100",
        letterSpacing: 0.68,
        fontFamily: 'SF Pro Text Medium'
    },
    enteredValueText: {
        fontSize: 13,
        color: "#FFC100",
        letterSpacing: 0.68,
        fontFamily: 'SF Pro Text Medium',
        marginTop: '-9.5%'
    },
    fillTextPaths: {

    },
    noSelect: {
        WebkitTouchCallout: 'none', /* iOS Safari */
        WebkitUserSelect: 'none', /* Safari */
        KhtmlUserSelect: 'none', /* Konqueror HTML */
        MozUserSelect: 'none', /* Old versions of Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */
        userSelect: 'none' /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    },
    prizeText: {
        display: "inline-block",
        color: "#FFC400",
        fontSize: 19,
        fontWeight: "300",
        letterSpacing: 1,
        textAlign: "center",
        fontFamily: 'SF Pro Text Light'
    },
    prizeTextContainer: {
        position: "relative",
        top: "25%"
    },
    prizeValue: {
        fontSize: 50,
        letterSpacing: -2,
        textAlign: "center",
        background: "linear-gradient(to right, #efba00, #ffde01)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontFamily: 'SF Pro Text Heavy',
        marginBottom: -10
    },
    progressBorderSVG: {
        height: 275,
        width: 275,
        marginTop: "-50%",
        marginLeft: "-9%",
        transform: "rotate(90deg)"
    },
    remainingSlots: {
        color: "#FAC51C",
        fontFamily: 'SF Pro Text Medium',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center"
    },
    remainingSlotsOpaque: {
        opacity: 0.25,
        color: "#FAC51C",
        fontFamily: 'SF Pro Text Medium',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center"
    },
    body:{
        backgroundColor: '#ffffff',
        padding: '2%'
    },
    bootstrapBasic: {
        // [OLD STYLES]
        // backgroundColor: "#FFFFFF",
        // [NEW STYLES]
        backgroundColor: "#3A3934",
        border: "2px solid", 

        borderRadius: 8, 
        boxShadow: "0 9px 10px 0 rgba(0,0,0,0.1)"
    },
    addFundsIcon: {
        width: 16,
        height: 16
    },
    closeMenuButton: {
        textAlign: 'right', 
        marginTop: 12
    }, 
    menuContainer: {
        position: 'absolute',
        right: 15, 
        zIndex: 1000000, 
        top: '100%', 
        backgroundColor: "#3A3934", 
        borderRadius: 8,
        boxShadow: "0 0 6px rgba(0,0,0,0.16), 0 6px 6px 0 rgba(0,0,0,0.16)",
        width: '26.1vw', 
        height: '85vh'
    },
    menuDivider: {
        // backgroundColor: '#737272',
        backgroundColor: '#A9A9A9',
        marginTop: '0.5rem', 
        marginBottom: '0.5rem',
        left: '20%',
        right:'20%'
    },
    menuIconCol: {
        display: 'flex', 
        alignItems: 'center', 
        alignContent: 'center', 
        justifyContent: 'center'
    },
    menuOptionCol: {
        paddingLeft: 0,
        color: "#FFCC00", 
        fontFamily: 'SF Pro Text Medium', 
        fontSize: 15, 
        letterSpacing: 0.1
    },
    menuProfilePhoto: {
        height: 64,
        width: 64, 
        borderRadius: 50
    },
    menuRow: {
        width: '100%',
        margin: '0 auto',
        paddingTop: '2%', 
        paddingBottom: '2%'
    },
    menuRowOption: {
        ':hover': {
            cursor: 'pointer'
        }
    },
    menuRowSocials: {
        width: '100%',
        margin: '0 auto',
        paddingTop: '7%', 
        paddingBottom: '2%'
    },
    menuUserName: {
        color: '#FFCC00', 
        fontFamily: 'SF Pro Display', 
        fontWeight: 'bold',
        letterSpacing: 0.38

    },
    menuUserNameCol: {
        paddingLeft: 0, 
        display: 'flex', 
        alignItems: 'center'
    },
    menuUserRow: {
        paddingTop: '5%', 
        paddingBottom: '8%'
    },
    rowContainer: {
        position: 'relative'
    },
    menuRowMyWallet: {
        width: '100%',
        margin: '0 auto',
        paddingTop: '2%',
        paddingBottom: '2%',
        marginTop: -30
    },
    menuRowOptionAddWalletRow: {
        marginBottom: 15
    },
    dropInBtnContainer: {
        display: 'flex', 
        justifyContent: 'center'
    },
    addwalletButton: {
        // [OLD STYLES]
        // backgroundColor: "#3E89F0",
        // color: "#FFFFFF",
        // [NEW STYLES]
        backgroundColor: "#F9CA14", 
        color: "black", 

        textDecoration: 'none',
        height: 56, 
        width: 283, 
        borderRadius: 5, 
        border: 'none', 
        fontFamily: "-apple-system, BlinkMacSystemFont, ‘Segoe UI’, ‘Roboto’, ‘Oxygen’, ‘Ubuntu’, ‘Cantarell’, ‘Fira Sans’, ‘Droid Sans’, ‘Helvetica Neue’, sans-serif;",
        fontSize: 20, 
        fontWeight: 'bold', 
        letterSpacing: 0
    },
    walletConfirmTitle: {
        color: 'rgb(26, 102, 217)',
        fontFamily: 'SF Pro Text Heavy',
        letterSpacing: 1.50,
        fontSize: 20
    },
    myWalletTitle: {
        // [OLD STYLE]
        // color: "#0075B9",
        // [NEW STYLE]
        color: "#F9CA14",

        fontFamily: "-apple-system, BlinkMacSystemFont, ‘Segoe UI’, ‘Roboto’, ‘Oxygen’, ‘Ubuntu’, ‘Cantarell’, ‘Fira Sans’, ‘Droid Sans’, ‘Helvetica Neue’, sans-serif;",
        // left: '30%',
        // top: '3.5%',
        letterSpacing: 0,
        fontSize: 20,
        fontWeight: 500
    },
    myWalletTitleRow: {
        margin: '0 auto', 
        textAlign: 'center'
        // width: '30',
        // position: 'absolute',
        // top: '3.5%',
        // display: 'flex',
        // justifyContent: 'center',
    },
    amountsTitleRow: {
        width: '15',
        position: 'absolute',
        left: '10%',
        top: '12.5%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: -2
    },
    amountTitlefonts: {
        color: 'rgb(26, 102, 217)',
        fontFamily: 'SF Pro Text Heavy',
        letterSpacing: 3.68,
        fontSize: 20
    },
    amountsplusTitleRow: {
        width: '15',
        position: 'absolute',
        left: '2%',
        top: '12.5%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: -2
    },
    amountsRowInput: {
        width: '2',
        position: 'absolute',
        left: '30%',
        top: '12.5%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: -1
    },
    amountfonts: {
        color: 'rgb(26, 102, 217)',
        fontFamily: 'SF Pro Text Heavy',
        letterSpacing: 3.68,
        fontSize: 15
    },
    paymentmethodTitleRow: {
        width: '38',
        position: 'relative',
        left: '10%',
        top: '6.5%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: -2
    },
    paymentmethodTitlefonts: {
        color: 'rgb(26, 102, 217)',
        fontFamily: 'SF Pro Text Heavy',
        letterSpacing: 3.68,
        fontSize: 15
    },
    menuRowExit: {
        width: '100%', 
        margin: '0 auto', 
        paddingTop: 0, 
        paddingBottom: 0 
    },
    menuRowPaymentMethod: {
        margin: '0 auto'
    },
    walletbody: {
        // padding: '15',
        //position: 'relative',
        //zIndex: 1000000,
        // top: '2%',
        // left: '-145%',
        // backgroundColor: '#fff',
        // borderRadius: 8,
        // boxShadow: "0 0 0.2px rgba(0, 123, 255, 0.25)",
        // //boxShadow: "0 0 6px rgba(0,0,0,0.16), 0 6px 6px 0 rgba(0,0,0,0.16)",
        // width: '40vw',
        // height: '100vh'
    },
    addcarddiv: {
        // padding: '10',
        // position: 'relative',
        // top: '10%',
        // left: '2%',
        // //backgroundColor: '#fff',
        // //borderRadius: 8,
        // //boxShadow: "0 0 0.2px rgba(0, 123, 255, 0.25)",
        // //boxShadow: "0 0 6px rgba(0,0,0,0.16), 0 6px 6px 0 rgba(0,0,0,0.16)",
        // width: '30vw',
        // height: '50vh'
    },
    //addcreditcardbody: {
    //    padding: '15',
    //    position: 'relative',
    //    zIndex: -1,
    //    top: '10%',
    //    left: '2%',
    //    backgroundColor: '#fff',
    //    borderRadius: 8,
    //    boxShadow: "0 0 0.2px rgba(0, 123, 255, 0.25)",
    //    //boxShadow: "0 0 6px rgba(0,0,0,0.16), 0 6px 6px 0 rgba(0,0,0,0.16)",
    //    width: '30vw',
    //    height: '65vh'
    //},
    cardbutton: {
        fontWeight: 500,
        left: 3,
        lineHeight: 'inherit',
        position: 'relative',
        textDecoration: 'none',
        textAlign: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 3
    },

    buttonSmall: {
        paddingTop: 10,
        fontSize: 0.875
    },
    buttonBlueRow: {
        width: '15',
        position: 'absolute',
        left: '40%',
        top: '30.5%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: -2
    },
    buttonBlue: {
        outline: 'none',
        backgroundColor: 'blue',
        borderColor: 'white',
        borderRadius: 1,
        color: 'white'
    },
    addcreditButtonRow: {
        position: 'absolute',
        left: '40%',
        top: '90%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: -1
    },
    
    addcreditButton: {
        background: '#1a66d9',
        height: 40,
        width: 180,
        border: "none",
        borderRadius: 3,
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 15,
        textAlign: "center",
        color: "#ffffff",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "none"
        }
    },
    plussignamount: {
        content: "+",
        height: 40,
        width: 40,
        fontSize: 40,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        fontFamily: 'courier',
        color: 'blue'
    },
    minussignamount: {
        content: "-",
        height: 40,
        width: 40,
        fontSize: 40,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        fontFamily: 'courier',
        color: 'blue'
    },
    mqaddWalletButton: {
        '@media (max-width: 490px)': {
            width: 222, 
            height: 46,
            fontSize: 18
        },
        '@media (max-width: 390px)': {
            width: 190, 
            height: 40, 
            fontSize: 18
        }
    },
    mqcloseMenuButton: {   
        '@media (max-width: 767px)': {
            zIndex: 2
        }
    }
});