import React from 'react';
import { Link } from 'react-router-dom';

import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import Iframe from 'react-iframe';
import AdsCount from '../AdsCount';
import { userInterests } from '../../JSON/userInterests';
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';
import { showMoreInterestsTodo, showMoreInterestsGeneral, hideInterestsToDo, hideInterestsGeneral } from '../../actions/InterestsActions';
import { playAllContent } from '../../actions/MenuActions';
import { css } from 'aphrodite';
import { styles } from './AdsContainerStyles.js';

class AdsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.onClickShowMoreTodo = this.onClickShowMoreTodo.bind(this);
        this.onClickShowMoreGeneral = this.onClickShowMoreGeneral.bind(this);
        this.onClickHideTodo = this.onClickHideTodo.bind(this);
        this.onClickHideGeneral = this.onClickHideGeneral.bind(this);
    }

    //THE FOLLOWING METHODS MAY NEED TO BE PORTED OVER TO ACTIONS - PUSH RETURNED RESULTS TO THE REDUCER

    //CREATE CONTENT ROWS OF 4 INTEREST CARDS
    createInterestRows(toDoInterestList, generalInterestList) {
        let interestSets = {
            toDoInterestSet: [],
            generalInterestSet: []
        };
        let temptoDoInterestSet = [];
        let tempgeneralInterestSet = [];

        for (let i = 0; i < toDoInterestList.length; i++) {
            if (Number.isInteger(i / 4) && i / 4 !== 0) {
                interestSets.toDoInterestSet.push(temptoDoInterestSet);
                temptoDoInterestSet = [];
                temptoDoInterestSet.push(toDoInterestList[i]);
            }
            else {
                temptoDoInterestSet.push(toDoInterestList[i]);
            }
        }
        if (temptoDoInterestSet !== []) {
            interestSets.toDoInterestSet.push(temptoDoInterestSet);
        }

        for (let i = 0; i < generalInterestList.length; i++) {
            if (Number.isInteger(i / 4) && i / 4 !== 0) {
                interestSets.generalInterestSet.push(tempgeneralInterestSet);
                tempgeneralInterestSet = [];
                tempgeneralInterestSet.push(generalInterestList[i]);
            }
            else {
                tempgeneralInterestSet.push(generalInterestList[i]);
            }
        }
        if (tempgeneralInterestSet !== []) {
            interestSets.generalInterestSet.push(tempgeneralInterestSet);
        }
        return interestSets;
    }

    //RENDER ROW THAT IS CURRENTLY QUEUED UP (ONCE 'SHOW MORE' IS HIT)
    mapToDoInterestRow(interestSetArray) {
        let interestGrid = interestSetArray.map((interestSet, index) => {
            return index == 0 ?
                (
                    <Row className={css(index <= this.props.todorowrender ? styles.interestRowFirst : styles.hidden)}>
                        {interestSet.map((interest) =>
                            <Col md={3}>
                                <Card className={css(styles.interestTile)}>
                                    <Card.Img className={css(styles.interestImage)} variant="top" src={interest.interestThumbnail} />

                                    <div className={css(styles.interestTitle)}>{interest.title}</div>
                                    <Card.Text>
                                    </Card.Text>
                                    <Button className={css(styles.interestButton)} variant="primary" onClick={this.runAd}>I'M INTERESTED</Button>

                                </Card>
                            </Col>
                        )}
                    </Row>
                )
                :
                (
                    <Row className={css(index <= this.props.todorowrender ? styles.interestRow : styles.hidden)}>
                        {interestSet.map((interest) =>
                            <Col md={3}>
                                <Card className={css(styles.interestTile)}>
                                    <Card.Img className={css(styles.interestImage)} variant="top" src={interest.interestThumbnail} />

                                    <div className={css(styles.interestTitle)}>{interest.title}</div>
                                    <Card.Text>
                                    </Card.Text>
                                    <Button className={css(styles.interestButton)} variant="primary">I'M INTERESTED</Button>

                                </Card>
                            </Col>
                        )}
                    </Row>
                )
        }
        );
        return interestGrid;
    }

    mapGeneralInterestRow(interestSetArray) {
        let interestGrid = interestSetArray.map((interestSet, index) => {
            return index == 0 ?
                (
                    <Row className={css(index <= this.props.generalrowrender ? styles.interestRowFirst : styles.hidden)}>
                        {interestSet.map((interest) =>
                            <Col md={3}>
                                <Card className={css(styles.interestTile)}>
                                    <Card.Img className={css(styles.interestImage)} variant="top" src={interest.interestThumbnail} />

                                    <div className={css(styles.interestTitle)}>{interest.title}</div>
                                    <Card.Text>
                                    </Card.Text>
                                    <Button className={css(styles.interestButton)} variant="primary">I'M INTERESTED</Button>

                                </Card>
                            </Col>
                        )}
                    </Row>
                )
                :
                (
                    <Row className={css(index <= this.props.generalrowrender ? styles.interestRow : styles.hidden)}>
                        {interestSet.map((interest) =>
                            <Col md={3}>
                                <Card className={css(styles.interestTile)}>
                                    <Card.Img className={css(styles.interestImage)} variant="top" src={interest.interestThumbnail} />

                                    <div className={css(styles.interestTitle)}>{interest.title}</div>
                                    <Card.Text>
                                    </Card.Text>
                                    <Button className={css(styles.interestButton)} variant="primary">I'M INTERESTED</Button>

                                </Card>
                            </Col>
                        )}
                    </Row>
                )
        }
        );
        return interestGrid;
    }

    onClickShowMoreTodo() {
        this.props.showMoreInterestsTodo();
    }

    onClickShowMoreGeneral() {
        this.props.showMoreInterestsGeneral();
    }

    onClickHideTodo() {
        this.props.hideInterestsToDo();
    }

    onClickHideGeneral() {
        this.props.hideInterestsGeneral();
    }


    //UNRENDER ALL EXCEPT FOR FIRST ROW (ONCE 'HIDE' IS HIT)
    //Set the queued Row back to '1' in component state

    componentDidMount() {
        this.props.playAllContent();
    }

    render() {
        let interestSets = this.createInterestRows(userInterests.toDoInterests, userInterests.generalInterests);
        return (
            <>
                <br />

                {this.props.contentplaying ?
                    (
                        <Container className={css(styles.containerBounds, styles.noSelect)}>
                            <Container className={css(styles.videoPlayerBox)}>
                                {/* <Row className={css(styles.placeholderGif)} width="100%" height="100%"> */}
                                {/* <Iframe url="adPlayer.html"
                                        width="100%"
                                        height="100%"
                                        id="videoPlayer"
                                        position="inherit"
                                        className={css(styles.adsIframe)}
                                        onload=""
                                    /> */}
                                <video width="100%" height="100%" id="videoPlayer" position="inherit" className={css(styles.adsIframe)} autoPlay>
                                    <source src={require('../../images/placeholderAd1.mp4')} type="video/mp4" />
                                    Your Browser does not support the video element
                                </video>
                                {/* </Row> */}
                                <br /><br />
                                {/* <center><h1>Please disable any ad blockers to view ads</h1></center> */}
                            </Container>
                        </Container>
                    ) : <Container><br /><br /><center><h1>Press 'Play Ads' to resume play</h1></center></Container>
                }
                {1 === 0 &&
                    (
                        <Container className={css(styles.containerBounds, styles.noSelect)}>
                            <Container className={css(styles.toDoInterestContainer)}>
                                <Row className={css(styles.interestQuestion)}>What's currently on your To-Do List?&nbsp;&nbsp;<div className={css(styles.questionInfoValue)}>+ $2.00</div></Row>
                                {/* <Row className={css(styles.interestQuestion)}>Select all that apply&nbsp;&nbsp;</Row> */}
                                <br />
                                {this.mapToDoInterestRow(interestSets.toDoInterestSet)}
                                {interestSets.toDoInterestSet.length - 1 > this.props.todorowrender &&
                                    (
                                        //WILL HAVE TO REWORK THE LOGIC TIED TO THE CLICK HANDLER HERE - DOESN'T WORK
                                        <div className={css(styles.showMoreButton)} onClick={this.onClickShowMoreTodo}>SHOW MORE</div>
                                    )
                                }
                                {interestSets.toDoInterestSet.length - 1 > this.props.todorowrender && this.props.todorowrender > 0 &&
                                    (<span>&nbsp;&nbsp;
                                        <span> |</span>&nbsp;
                                        <div className={css(styles.showMoreButton)} onClick={this.onClickHideTodo}>HIDE</div>
                                    </span>
                                    )
                                }
                                {interestSets.toDoInterestSet.length - 1 == this.props.todorowrender && interestSets.toDoInterestSet.length > 1 && (
                                    <div className={css(styles.showMoreButton)} onClick={this.onClickHideTodo}>HIDE</div>
                                )
                                }
                                {interestSets.toDoInterestSet.length == 1 &&
                                    (
                                        <div className={css(styles.spacer)}></div>
                                    )
                                }
                            </Container>
                            <Container className={css(styles.generalInterestContainer)}>
                                <Row className={css(styles.interestQuestion)}>What do you like to do in your free time?&nbsp;&nbsp;<div className={css(styles.questionInfoValue)}>+ $3.00</div></Row>
                                <br />
                                {this.mapGeneralInterestRow(interestSets.generalInterestSet)}
                                {interestSets.generalInterestSet.length - 1 > this.props.generalrowrender &&
                                    (
                                        //WILL HAVE TO REWORK THE LOGIC TIED TO THE CLICK HANDLER HERE - DOESN'T WORK
                                        <div className={css(styles.showMoreButton)} onClick={this.onClickShowMoreGeneral}>SHOW MORE</div>
                                    )
                                }
                                {interestSets.generalInterestSet.length - 1 > this.props.generalrowrender && this.props.generalrowrender > 0 &&
                                    (<span>&nbsp;&nbsp;
                                        <span> |</span>&nbsp;
                                        <div className={css(styles.showMoreButton)} onClick={this.onClickHideGeneral}>HIDE</div>
                                    </span>
                                    )
                                }
                                {interestSets.generalInterestSet.length - 1 == this.props.generalrowrender && interestSets.generalInterestSet.length > 1 && (
                                    <div className={css(styles.showMoreButton)} onClick={this.onClickHideGeneral}>HIDE</div>
                                )
                                }
                                {interestSets.generalInterestSet.length == 1 &&
                                    (
                                        <div className={css(styles.spacer)}></div>
                                    )
                                }
                            </Container>
                            <Link to="/"><Button className={css(styles.adsToHomeButton)}>RETURN TO GAMES</Button></Link>

                        </Container>
                    )
                }
                {/* <MediaQuery maxWidth={600}>
                    <AdsCount />
                </MediaQuery> */}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        playadcontent: state.noPersistReducer.playAdContent,
        contentplaying: state.noPersistReducer.contentPlaying,
        todorowrender: state.noPersistReducer.toDoRowRender,
        generalrowrender: state.noPersistReducer.generalRowRender
    }
}

export default connect(mapStateToProps, {
    showMoreInterestsTodo,
    showMoreInterestsGeneral,
    hideInterestsToDo,
    hideInterestsGeneral,
    playAllContent
})(AdsContainer);