'use strict';

/**
 * PayPal Node JS SDK dependency
 */
const payoutsNodeJssdk = require('@paypal/payouts-sdk');

/**
 * Returns PayPal HTTP client instance with environment which has access
 * credentials context. This can be used invoke PayPal API's provided the
 * credentials have the access to do so.
 */
function client() {
    return new payoutsNodeJssdk.core.PayPalHttpClient(environment());
}

/**
 * Setting up and Returns PayPal SDK environment with PayPal Access credentials.
 * For demo purpose, we are using SandboxEnvironment. In production this will be
 * LiveEnvironment.
 */
function environment() {
    // let clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID || process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID;
    // let clientSecret = process.env.REACT_APP_PAYPAL_CLIENT_SECRET || process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_SECRET;

    // USE BELOW FOR LOCALHOST DEVELOPMENT
    let clientId = 'AXCZNp2tOqHTWnI5i0GJeI7Gja4WWeY3e8xrCa43e7cYMlv8YfkWInrpK8L7sxoWZ4zQ7Mh1hFuS8YQ7'
    let clientSecret = 'EM6zq15rD2icSbIwgyAOCWrszOfxXXsE-fQwrRFmEY3l_6A4ulGdbpXRJ_KK78QPBGK4nnXmmjSuOGMp'
    
    if (process.env.REACT_APP_STAGE === 'PROD') {
         return new payoutsNodeJssdk.core.LiveEnvironment(clientId, clientSecret);
    }

    return new payoutsNodeJssdk.core.SandboxEnvironment(clientId, clientSecret);
}

/* function environment() {
    let clientId = process.env.PAYPAL_CLIENT_ID || 'PAYPAL-SANDBOX-CLIENT-ID';
    let clientSecret = process.env.PAYPAL_CLIENT_SECRET || 'PAYPAL-SANDBOX-CLIENT-SECRET';

    if (process.env.NODE_ENV === 'production') {
        return new payoutsNodeJssdk.core.LiveEnvironment('PAYPAL-CLIENT-ID', 'PAYPAL-CLIENT-SECRET');
    }
        return new payoutsNodeJssdk.core.SandboxEnvironment(`Abcqc5cfuwPCzGFC9qjDBPsGC9aWZFqwjY0CSoH4cVbEulIfYm1bk-4yhugIQpRco5D6jFasABKt_Bh8`, 'EBMA2Ec8flxm_qq8laUlxXxV6Oyw50yiiljehdXwxUZXlbsrzGSJScvgzjQd328viyh0UGPvdLpvWgH3');

} */
export default { client: client};