import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { leaderboardRefreshed, addUserToLeaderboard } from '../../actions/GameActions';
import { closeSideMenus } from '../../actions/MenuActions';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { ArrowBarLeft } from 'react-bootstrap-icons';

import { css } from 'aphrodite';
import { styles } from './LeaderboardStyles.js';


class Leaderboard extends React.Component {
    constructor(props) {
        super(props);
        this.isMountedVal = 0; 
        this.state = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            progress: 0
        };

        this.getEndDate = this.getEndDate.bind(this);
        this.tick = this.tick.bind(this);
        this.calc = this.calc.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    componentDidMount() {
        this.isMountedVal = 1; 
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
        document.addEventListener('mousedown', this.handleClickOutside);
        //console.log(this.props.leaderboard)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        this.isMountedVal = 0; 
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && !this.props.tutorial) {
            this.props.closeSideMenus();
        }
    }

    tick() {
        this.calc();
        if (this.isMountedVal === 1) {
        this.setState({
            date: new Date()
        });
    }
    }

    mapLeaderboard(leaderboard) {
        if (leaderboard) {
            let leaderboardList;
            if (leaderboard.length > 0) {
                leaderboardList = leaderboard.map((plndrLeader, index) => {
                    return index == 0 ?
                        (
                            <Row key={index} className={css(styles.currentUserBarFirst)}>
                                <Col className={css(styles.rankingContainer)} md={1} sm={1} xs={1}>
                                </Col>
                                <Col className={css(styles.photoContainerFirst)} md={4} sm={4} xs={4}>
                                    <div className={css(styles.leaderboardPhotoContainer)}>
                                        <img src={!plndrLeader.player.playerPhoto == '' ? plndrLeader.player.playerPhoto : 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fcommons%2F7%2F7c%2FProfile_avatar_placeholder_large.png&f=1&nofb=1'} className={css(styles.leaderboardPhotoFirst)} />
                                        {plndrLeader.player.achievementDisplay != '' && (<img className={css(styles.achievementIconFirst)} src={plndrLeader.player.achievementDisplay} />)}
                                    </div>
                                </Col>
                                <Col className={css(styles.userInfoContainerFirst)} md={5} sm={5} xs={5}>
                                    <div className={css(styles.userInfo, styles.mquserInfo)}>
                                        <div className={css(styles.userCurrentPointsLBFirst)}>{plndrLeader.points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                        <div className={css(styles.usernameLB)}>{plndrLeader.player.username}</div>
                                    </div>
                                </Col>
                            </Row>
                        )
                        :
                        (
                            <Row key={index} className={css(styles.currentUserBar)}>
                                <Col className={css(styles.rankingContainer)} md={1} sm={1} xs={1}>
                                    <div className={css(styles.userLeaderboardRanking)}>{plndrLeader.rank}</div>
                                </Col>
                                <Col className={css(styles.photoContainer)} md={3} sm={3} xs={3}>
                                    <div className={css(styles.leaderboardPhotoContainer)}>
                                        <img src={!plndrLeader.player.playerPhoto == '' ? plndrLeader.player.playerPhoto : 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fcommons%2F7%2F7c%2FProfile_avatar_placeholder_large.png&f=1&nofb=1'} className={css(styles.leaderboardPhoto)} />
                                        {plndrLeader.player.achievementDisplay != '' && (<img className={css(styles.achievementIcon)} src={plndrLeader.player.achievementDisplay} />)}
                                    </div>
                                </Col>
                                <Col className={css(styles.userInfoContainer)} md={5} sm={5} xs={5}>
                                    <div className={css(styles.userInfo, styles.mquserInfo)}>
                                        <div className={css(styles.userCurrentPointsLB)}>{plndrLeader.points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                        <div className={css(styles.usernameLB)}>{plndrLeader.player.username}</div>
                                    </div>
                                </Col>
                            </Row>
                        )
                }
                );
            }
            else {
                leaderboardList = (<div className={css(styles.newLeaderboard)}><span>NO PLAYERS YET ON LEADERBOARD</span></div>);
            }
            return leaderboardList;
        }
    }

    getEndDate() {
        var endDate = this.props.leaderboard.endDate;
        if (endDate) {
            return new Date(parseInt(endDate.substring(0, 4)),
                parseInt(endDate.substring(5, 7)) - 1,
                parseInt(endDate.substring(8, 10)),
                parseInt(endDate.substring(11, 13)),
                parseInt(endDate.substring(14, 16)),
                parseInt(endDate.substring(17, 19)));
        }
    }

    calc() {
        var d = new Date();
        var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds());    // current UTC date
        var end = this.getEndDate(); // UTC end date
        var diff = parseInt(end - utc);

        var days = Math.floor(diff / (1000 * 3600 * 24));
        diff = diff - ((1000 * 3600 * 24) * days);
        var hours = Math.floor(diff / (1000 * 60 * 60));
        diff = diff - ((1000 * 3600) * hours);

        var minutes = Math.floor(diff / (1000 * 60));
        diff = diff - (1000 * 60 * minutes);
        var seconds = Math.floor(diff / (1000));

        if (!days && !hours && !minutes && !seconds) {
            days = 0;
            hours = 0;
            minutes = 0;
            seconds = 0;
        }

        var weekLength = 604800;
        var currentLength = seconds + (minutes * 60) + (hours * 3600) + (days * 86400);
        var progress = weekLength - currentLength;

        if (this.isMountedVal === 1) {
        this.setState({
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
            progress: progress
        });
    }

    }
    // addUserToLeaderboard() {
    //     //Check if user exist in leaderboard
    //     var inLeaderboard = false;
    //     this.props.leaderboard.rankings.map( p => {
    //          (p.player.userId === this.props.currUser.id) ? inLeaderboard = true : inLeaderboard= false;
    //      })
    //     if(!inLeaderboard && this.props.logintoken && this.props.currUser.id > 0)
    //     {
    //         this.props.addUserToLeaderboard(this.props.logintoken,this.props.currUser.id);
    //     }
    // }
    render() {
        if (this.props.load) {
            this.props.leaderboardRefreshed();
            // if((this.props.leaderboard.rankings.length === 0) && this.props.logintoken)
            //     this.addUserToLeaderboard();
        }

        var width = this.state.progress / 604800;
        width = (width * 100).toFixed(2);
        var widthStyle = { width: String(width) + '%' };

        return (
            <>
                <span ref={this.setWrapperRef}>
                    <Container className={css(styles.leaderboardGenInfo, styles.noSelect)}>
                        <MediaQuery maxWidth={1019}>
                            <ArrowBarLeft className={css(styles.closeSideMenuButton)} color={"#ffc400"} size={49} onClick={this.props.closeSideMenus} />
                        </MediaQuery>
                        <div className={css(styles.leaderboardPrizeBox)}>
                            <img className={css(navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0 ? styles.leaderboardPrizeGraphicSafari : styles.leaderboardPrizeGraphic)} src={require('../../images/pointsRaceIcon.png')} />
                            <div className={css(this.props.tutorial ? [styles.leaderboardPrizeValue, styles.mqleaderboardPrizeValueTut] : styles.leaderboardPrizeValue)}>{`$${this.props.leaderboard.prizeValue ? this.props.leaderboard.prizeValue : 0}`}</div>
                        </div>
                        <div className={css(styles.leaderboardPrizeBoxHidden)}>
                            <img className={css(navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0 ? styles.leaderboardPrizeGraphicSafari : styles.leaderboardPrizeGraphic)} src={require('../../images/pointsRaceIcon.png')} />
                            <div className={css(styles.leaderboardPrizeValue)}>{this.props.leaderboard.prizeValue ? this.props.leaderboard.prizeValue : 0}</div>
                        </div>
                        <div className={css(styles.countdownAndTimer)}>
                            {this.props.tutorial ?
                                (
                                    <Row className={css(styles.timerRow)}>
                                        <div className={css(styles.timerGrid)}>
                                            <div></div>
                                            <div className={css(styles.timerGroup)}>
                                                <div className={css(styles.timerNumber)}>{0}</div>
                                                <div className={css(styles.timerText)}>days</div>
                                            </div>
                                            <div></div>
                                            <div className={css(styles.timerGroup)}>
                                                <div className={css(styles.timerNumber)}>{0}</div>
                                                <div className={css(styles.timerText)}>hours</div>
                                            </div>
                                            <div></div>
                                            <div className={css(styles.timerGroup)}>
                                                <div className={css(styles.timerNumber)}>{1}</div>
                                                <div className={css(styles.timerText)}>mins</div>
                                            </div>
                                            <div></div>
                                            <div className={css(styles.timerGroup)}>
                                                <div className={css(styles.timerNumber)}>{9}</div>
                                                <div className={css(styles.timerText)}>secs</div>
                                            </div>
                                            <div></div>
                                        </div>
                                    </Row>
                                )
                                :
                                (
                                    <Row className={css(styles.timerRow)}>
                                        {this.state.days >= 0 && (this.state.hours > 0 || this.state.minutes > 0 || this.state.seconds > 0) ?
                                            <div className={css(styles.timerGrid)}>
                                                <div></div>
                                                <div className={css(styles.timerGroup)}>
                                                    <div className={css(styles.timerNumber)}>{this.state.days}</div>
                                                    <div className={css(styles.timerText)}>days</div>
                                                </div>
                                                <div></div>
                                                <div className={css(styles.timerGroup)}>
                                                    <div className={css(styles.timerNumber)}>{this.state.hours}</div>
                                                    <div className={css(styles.timerText)}>hours</div>
                                                </div>
                                                <div></div>
                                                <div className={css(styles.timerGroup)}>
                                                    <div className={css(styles.timerNumber)}>{this.state.minutes}</div>
                                                    <div className={css(styles.timerText)}>mins</div>
                                                </div>
                                                <div></div>
                                                <div className={css(styles.timerGroup)}>
                                                    <div className={css(styles.timerNumber)}>{this.state.seconds}</div>
                                                    <div className={css(styles.timerText)}>secs</div>
                                                </div>
                                                <div></div>
                                            </div>
                                            :
                                            this.isMountedVal === 1? 
                                            (
                                            <div>
                                                <div className={css(styles.timerNumber)}>Game over!  A new one will start soon</div>
                                            </div>
                                            )
                                            :
                                            (
                                            <div style={{marginLeft: 13}}>
                                                <div className={css(styles.timerNumber)}>Loading Countdown...</div>
                                            </div>
                                            )

                                        }
                                    </Row>
                                )
                            }
                            <Row className={css(styles.countdownRow)}>
                                <div className={css(styles.countdownBase)}>
                                    <div className={css(styles.countdownBar)} style={this.props.tutorial ? { width: '90%' } : widthStyle}></div>
                                </div>
                            </Row>
                        </div>
                    </Container>
                    <Container className={css(this.props.tutorial ? [styles.leaderList, styles.noSelect, styles.mqleaderList, styles.mqleaderListTut] : [styles.leaderList, styles.noSelect, styles.mqleaderList])}>
                        {this.props.tutorial ?
                            (
                                <div className={css(styles.leaderListContainer)}>
                                    <Row className={css(styles.currentUserBarFirst)}>
                                        <Col className={css(styles.rankingContainer)} md={1} sm={1} xs={1}>
                                        </Col>
                                        <Col className={css(styles.photoContainerFirst)} md={4} sm={4} xs={4}>
                                            <div className={css(styles.leaderboardPhotoContainer)}>
                                                <img src={require('../../images/avatarTutLB.svg')} className={css(styles.leaderboardPhotoFirst)} />
                                            </div>
                                        </Col>
                                        <Col className={css(styles.userInfoContainerFirst, styles.mquserInfoContainerFirstTut)} md={5} sm={5} xs={5}>
                                            <div className={css(styles.userInfo)}>
                                                <div className={css(styles.userCurrentPointsLBFirst)}>5</div>
                                                <div className={css(styles.usernameLB)}>PlndrBot&nbsp;&nbsp;<span style={{ WebkitTextFillColor: "#D0D5D8" }}>Leader</span></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className={css(styles.currentUserBar)}>
                                        <Col className={css(styles.rankingContainer)} md={1} sm={1} xs={1}>
                                            <div className={css(styles.userLeaderboardRanking)}>2</div>
                                        </Col>
                                        <Col className={css(styles.photoContainer)} md={3} sm={3} xs={3}>
                                            <div className={css(styles.leaderboardPhotoContainer)}>
                                                <img src={require("../../images/noAvSelected.svg")} className={css(styles.leaderboardPhoto)} />
                                            </div>
                                        </Col>
                                    <Col className={css(styles.userInfoContainer, styles.mquserInfoContainerTut)} md={5} sm={5} xs={5}>
                                            <div className={css(styles.userInfo)}>
                                                <div className={css(styles.userCurrentPointsLB)}>{0}</div>
                                                <div className={css(styles.usernameLB)}>You</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                            :
                            (
                                <>
                                    <Row className={css(styles.fadeBoxTop)}></Row>
                                    <div className={css(styles.leaderListContainer)}>
                                        {this.mapLeaderboard(this.props.leaderboard.rankings)}
                                    </div>
                                    <Row className={css(styles.fadeBox)}></Row>
                                </>
                            )
                        }
                    </Container>
                    {this.props.isloggedin ? (
                        <Container className={css(styles.currentUserSection, styles.noSelect)}>
                            <Row className={css(styles.currentUserBar, styles.myUser, styles.mqcurrentUserBar)}>
                                <Col className={css(styles.rankingContainer)} md={1} sm={1} xs={1}>
                                    <div className={css(styles.userLeaderboardRanking)}>{this.props.currUser.currentRank}</div>
                                </Col>
                                <Col className={css(styles.photoContainer)} md={3} sm={3} xs={3}>
                                    <div className={css(styles.leaderboardPhotoContainer)}>
                                        <img src={this.props.currUser.userProfileURL} className={css(styles.leaderboardPhoto)} />
                                        {this.props.currUser.medals && (<img className={css(styles.achievementIcon)} src={this.props.currUser.medals[0]} />)}
                                    </div>
                                </Col>
                                <Col className={css(styles.userInfoContainer)} md={5} sm={5} xs={5}>
                                    <div className={css(styles.userInfo)}>
                                        <div className={css(styles.userCurrentPointsLB)}>{this.props.currUser.points}</div>
                                        <div className={css(styles.usernameLB)}>{this.props.currUser.name}</div>
                                    </div>
                                </Col>
                                <span className={css(styles.shotsGraphicContainer)}>
                                    <div className={css(styles.numberContainer)}>
                                        <img className={css(styles.shotsGraphic)} src={require('../../images/shotsTotalSidebar.png')} />

                                        <div className={css(styles.cannonShotValue)}><span>{this.props.currUser.cannonBallBalance}</span></div>
                                    </div>
                                </span>
                            </Row>
                            <Row>
                                <div className={css(styles.buttonBoxLB, styles.mqbuttonBoxLBLogged)}>
                                    <Button href='/minigame' className={css(styles.buttonLB, styles.mqbuttonLBLogged)}>PLAY FOR POINTS</Button>
                                </div>
                            </Row>
                        </Container>
                    )
                        :
                        (
                            this.props.tutorial ?
                                (
                                    <Container className={css(styles.currentUserSection, styles.noSelect)}>
                                        <Row className={css(styles.currentUserBar, styles.myUser, styles.mqcurrentUserBar)}>
                                            <Col className={css(styles.rankingContainer)} md={1} sm={1} xs={1}>
                                                <div className={css(styles.userLeaderboardRanking)}>{2}</div>
                                            </Col>
                                            <Col className={css(styles.photoContainer)} md={3} sm={3} xs={3}>
                                                <div className={css(styles.leaderboardPhotoContainer)}>
                                                    <img src={require("../../images/noAvSelected.svg")} className={css(styles.leaderboardPhoto)} />
                                                </div>
                                            </Col>
                                            <Col className={css(styles.userInfoContainer)} md={5} sm={5} xs={5}>
                                                <div className={css(styles.userInfo, styles.mquserInfoTut)}>
                                                    <div className={css(styles.userCurrentPointsLB)}>{0}</div>
                                                    <div className={css(styles.usernameLB)}>You</div>
                                                </div>
                                            </Col>
                                            <span className={css(styles.shotsGraphicContainer)}>
                                                <div className={css(styles.numberContainer)}>
                                                    <img className={css(styles.shotsGraphic)} src={require('../../images/shotsTotalSidebar.png')} />

                                                    <div className={css(styles.cannonShotValue)}><span>{5}</span></div>
                                                </div>
                                            </span>
                                        </Row>
                                        <Row>
                                            <div className={css(styles.buttonBoxLB, styles.mqbuttonBoxLB)}>
                                                <Button className={css(styles.buttonLB, styles.mqbuttonLBLogged)}>PLAY FOR POINTS</Button>
                                            </div>
                                        </Row>
                                    </Container>
                                )
                                :
                                (
                                    <Container className={css(styles.currentUserSection, styles.noSelect)}>
                                        <Row className={css(styles.currentUserBar, styles.myUser)}>
                                            <Col className={css(styles.notLoggedIn)}>
                                                NOT LOGGED IN
                                </Col>
                                        </Row>
                                        <Row>
                                            <div className={css(styles.buttonBoxLB, styles.mqbuttonBoxLB)}>
                                                <Button href='/login' className={css(styles.buttonLB, styles.mqbuttonLB)}>LOG IN</Button>
                                            </div>
                                        </Row>
                                    </Container>
                                )
                        )}
                </span>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        leaderboard: state.gameReducer.leaderboard,
        logintoken: state.loginReducer.loginToken,
        isloggedin: state.loginReducer.isLoggedIn,
        currUser: state.loginReducer.user,
        load: state.gameReducer.loadLeaderboard,
        tutorial: state.loginReducer.tutorial
        //addingToLeaderboard: state.gameReducer.addingToLeaderboard
    }
}

export default connect(mapStateToProps, {
    leaderboardRefreshed,
    closeSideMenus
    //addUserToLeaderboard
})(Leaderboard);