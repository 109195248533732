import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from "aws-amplify"
import { serverEnvironment } from './constants/ActionConstants.js';
import 'bootstrap/dist/css/bootstrap.min.css';

Amplify.configure({
    Auth: {
        identityPoolId: 'us-west-2:54822ef9-bf2a-4898-9695-9141334e93c0',
        region: 'us-west-2',
    },
    Storage: {
        AWSS3: {
            bucket: serverEnvironment.photosS3Bucket,
            region: 'us-west-2',
            identityPoolId: 'us-west-2:54822ef9-bf2a-4898-9695-9141334e93c0',
        }
    }
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
