import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Modal, Col, Row } from 'react-bootstrap';
import { connect, useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Redirect } from 'react-router'; 
import { closeWallet } from '../../actions/MenuActions';
import { getPaymentMethods, createPaymentMethod, updatePaymentMethod, deletePaymentMethod } from '../../actions/WalletActions';
import { logout } from '../../actions/LoginActions';
import { css } from 'aphrodite';
import { styles } from './WalletStyles.js';
import { core } from '../../lib/core/lib';


class Wallet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddCard : false
        };
        this.input = React.createRef();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onClickCloseWallet = this.onClickCloseWallet.bind(this);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this.onClickCloseWalletConfirm = this.onClickCloseWalletConfirm.bind(this);
    }
    
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            //this.props.closeWallet();
        }
    }
    forceUpdateHandler() {
        this.forceUpdate();
    };
    
    handleLoad() {
        this.setState({ loaded: true });
    }

    componentDidMount() {
        if (!this.state.loaded) {
            this.createbraintreeClient();
            this.getMyWalletListAction();
        }
        document.addEventListener('load', this.handleLoad);
        //document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        //document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('load', this.handleLoad);
        this.onClickCloseWallet();
    }

    onClickCloseWallet() {
        this.props.closeWallet();
    }
   
    onClickCloseWalletConfirm() {
        document.location = "/";
    }
    getMyWalletListAction() {
        // console.log("In get my WalletAction");
        const payload = {
            userId: this.props.currUser.id,
            name: this.props.currUser.name,
            clientNonce: document.getElementById("hiddenNonce").value
        }
        this.props.getPaymentMethods(this.props.logintoken, payload);
        // console.log('getPaymentMethods is called with: ' + JSON.stringify(payload));
    }
    createPaymentMethodAction(event) {
        // console.log("In create my WalletAction");
        event.preventDefault();
        var payload = {
            userId: this.props.currUser.id,
            name: this.props.currUser.name,
            clientNonce: document.getElementById("hiddenNonce").value
        };
        if (payload.clientNonce) {
            this.props.createPaymentMethod(this.props.logintoken, payload);
        }
        // console.log('createPaymentMethod is called with: ' + JSON.stringify(payload));
    }
    updatePaymentMethodAction() {
        // console.log("In update my WalletAction");
        const payload = {
            user: this.props.currUser, // if user has all other items then id, email etc. not  needed
            userId: this.props.currUser.id,
            name: this.props.currUser.name,
            paymentNonce: ""
        }
        // console.log('updatePaymentMethod is called with: ' + JSON.stringify(payload));
        this.props.updatePaymentMethod(this.props.logintoken, payload);
    }
    deletePaymentMethodAction() {
        // console.log("In delete my WalletAction");
        const payload = {
            user: this.props.currUser, // if user has all other items then id, email etc. not  needed
            userId: this.props.currUser.id,
            name: this.props.currUser.name,
            paymentNonce: ""
        }
        // console.log('deletePaymentMethod is called with: ' + JSON.stringify(payload));
        this.props.deletePaymentMethod(this.props.logintoken, payload);
    }
    renderpayments() {
        // console.log("render payments");
        let paymethods = this.props.paymentmethodlist;
        const methodList = () => (
            <div>
                <ul>{paymethods.map(paymethod => <li key={paymethod}> {paymethod} </li>)}</ul>
            </div>
        );
    }
  
    createbraintreeClient() {
        // console.log("Creating braintree client...");
        var braintreewebdropin = require("braintree-web-drop-in");
        var addcardBtn = document.querySelector('#addcard');
        braintreewebdropin.create({
            //authorization: 'sandbox_249nbbmy_7ctbvsygh9rmvxtm',
            authorization: this.props.clienttoken,
            container: '#dropin-container',
            vaultManager: true,
            card: {
                cardholderName: false,
                overrides: {
                  fields: {
                    number: {
                      placeholder: 'Card Number',
                      formatInput: true // Turn off automatic formatting
                    }
                  }
                }
              },
            translations: {
                payingWith: 'My Wallet',
                chooseAnotherWayToPay: 'Add new payment method',
                otherWaysToPay: 'Add Another Payment Method'
            }
        }, function (err, dropinInstance) {
                dropinInstance.on('noPaymentMethodRequestable', function () {
                    document.getElementById('addcard').setAttribute('disabled', true);
                });
                if (dropinInstance.isPaymentMethodRequestable()) {
                    // This will be true if you generated the client token
                    // with a customer ID and there is a saved payment method
                    // available to tokenize with that customer.
                    document.getElementById('addcard').removeAttribute('disabled');
                }
                dropinInstance.on('paymentMethodRequestable', function (event) {
                    if((event.paymentMethodIsSelected) || (dropinInstance.isPaymentMethodRequestable)) {
                        document.getElementById('addcard').removeAttribute('disabled');
                    }
                });
                document.getElementById('addcard').addEventListener('mousedown', function (event) {
                    event.preventDefault();
                    document.getElementById('addcard').setAttribute('disabled', true);
                    dropinInstance.requestPaymentMethod(function (err, payload) {
                        document.getElementById('hiddenNonce').value = payload.nonce;
                    });
                });
        });
    }
    
    opencard() {
        this.setState({
            showAddCard: true
        });
    }
    render() {
        // console.log("wallet done?", this.props.mywallet, this.props.walletdone);
        return (
            this.props.mywallet && this.props.walletdone ?
            (
                 <div className={css(styles.bootstrapBasic, styles.walletbody, styles.noSelect)} ref={this.setWrapperRef}>
                    <Row className={css(styles.menuRowExit)}>
                        <Col className={css(styles.closeMenuButton, styles.mqcloseMenuButton)} onClick={this.onClickCloseWallet}><img className={css(styles.addFundsIcon, styles.menuRowOption)} src={require('../../images/exitalt.svg')} /></Col>
                    </Row>
                    <Row className={css(styles.menuRow, styles.menuRowOption)}>
                        <Col md={6} className={css(styles.addCreditsTitleRow, styles.addCreditsTitle)}>My Wallet</Col>
                    </Row>
                    <Row className={css(styles.menuRow, styles.menuRowOption)}>
                        <Col md={5} className={css(styles.paymentmethodTitleRow, styles.paymentmethodTitlefonts)}>Wallet updated successfully</Col>
                    </Row>
                     <Row>
                        <script src="https://js.braintreegateway.com/web/dropin/1.22.1/js/dropin.min.js"></script>
                        <Col>
                            <div className={css(styles.addcarddiv)} onLoad={() => this.forceUpdateHandler()}>
                                    <div id="dropin-wrapper">
                                    <div id="dropin-container"></div>
                                    <input id="hiddenNonce" type="hidden"></input>
                                    <button className={css(styles.addcreditButton)} id="addcard" disabled="true" onClick={(event) => this.createPaymentMethodAction(event)}>Add To My Wallet</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                 </div>
            ) 
            :
            (
                <div className={css(styles.bootstrapBasic, styles.walletbody, styles.noSelect)} ref={this.setWrapperRef}>
                     <Row className={css(styles.menuRowExit)}>
                        <Col className={css(styles.closeMenuButton, styles.mqcloseMenuButton)} onClick={this.onClickCloseWallet}><img className={css(styles.addFundsIcon, styles.menuRowOption)} src={require('../../images/exitalt.svg')} /></Col>
                    </Row>
                    <Row className={css(styles.menuRow, styles.menuRowMyWallet)}>
                        <Col md={6} className={css(styles.myWalletTitleRow, styles.myWalletTitle)}>My Wallet</Col>
                    </Row>
                    <Row className={css(styles.menuRowPaymentMethod)}>
                        <script src="https://js.braintreegateway.com/web/dropin/1.22.1/js/dropin.min.js"></script>
                        <Col>
                            <div className={css(styles.addcarddiv)}>
                                <div id="dropin-wrapper">
                                    <div id="dropin-container"></div>
                                    <input id="hiddenNonce" type="hidden"></input>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className={css(styles.menuRowOptionAddWalletRow)}>
                        <Col>
                            <div className={css(styles.dropInBtnContainer)}>
                                <button className={css(styles.dropinbtn, styles.addwalletButton, styles.mqaddWalletButton)} id="addcard" disabled="true" onClick={(event) => this.createPaymentMethodAction(event)}>Add To My Wallet</button>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        );
    }
}

function mapStateToProps(state) {
    return {
        currUser: state.loginReducer.user,
        logintoken: state.loginReducer.loginToken,
        clienttoken: state.paymentReducer.clientToken,
        mywallet: state.noPersistReducer.myWallet,
        walletdone: state.walletReducer.walletDone,
        paymentmethodlist: state.walletReducer.paymentMethodList
    }
}
export default connect(mapStateToProps, {
    closeWallet,
    getPaymentMethods,
    createPaymentMethod,
    updatePaymentMethod,
    deletePaymentMethod
})(Wallet);
