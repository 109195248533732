import React from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import { Navbar, Button } from 'react-bootstrap';
import UserMenu from '../UserMenu';
import AddCredits from '../AddCredits';
import Payout from '../Payout';
import Wallet from '../Wallet/Wallet';
import { connect } from 'react-redux';
import { closeUserMenu, openUserMenu, openAddCredits, closeAddCredits, openPayout, closePayout, clearAlert, closeMenuModals, showGameHistory } from '../../actions/MenuActions';
import { logout } from '../../actions/LoginActions';
import { getGameHistory, markAlertsRead } from '../../actions/GameActions';
import { closeMoreShots } from '../../actions/MinigameActions';
import { css } from 'aphrodite';
import { styles } from './NavBarStyles.js';
// import GameHistoryModal from '../Modals/GameHistoryModal';
// import PaymentHistoryModal from '../Modals/PaymentHistoryModal';
// import PayoutHistoryModal from '../Modals/PayoutHistoryModal';
// import EditProfileModal from '../Modals/EditProfileModal'
import Modal from 'react-bootstrap/Modal';
import MediaQuery from 'react-responsive';
import AvatarSelection from '../Modals/AvatarSelection.js';
import ReferCode from '../Modals/ReferCode';

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showGameHistoryModal: false,
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.onClickCloseMenu = this.onClickCloseMenu.bind(this);
        this.onClickOpenMenu = this.onClickOpenMenu.bind(this);
        this.onClickOpenAddCredits = this.onClickOpenAddCredits.bind(this);
        this.onClickCloseAddCredits = this.onClickCloseAddCredits.bind(this);
        this.onClickOpenPayout = this.onClickOpenPayout.bind(this);
        this.onClickClosePayout = this.onClickClosePayout.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.onClickShowGameHistory = this.onClickShowGameHistory.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            // console.log("target event", event.target);
            this.props.closeAddCredits();
            this.props.closePayout();
        }
    }

    onClickCloseMenu() {
        this.props.closeUserMenu();
    }

    onClickOpenMenu() {
        this.props.openUserMenu();
    }

    onClickOpenPayout() {
        this.props.openPayout();
    }

    onClickClosePayout() {
        this.props.closePayout();
    }

    onClickOpenAddCredits() {
        this.props.closeMoreShots();
        this.props.openAddCredits();
        //this.setState({ showModal: true });
    }
    onClickCloseAddCredits() {
        this.props.closeAddCredits();
    }

    onClickShowGameHistory() {
        this.props.getGameHistory(this.props.logintoken, this.props.currUser.id);
        this.props.showGameHistory();
        this.props.markAlertsRead(this.props.token);
        //document.location = "/gamehistory";
    }

    onHideModal() {
        this.props.closeMenuModals();
        //this.props.markAlertsRead(this.props.token);

    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    render() {
        return (
            <>
                <Navbar className={css(!this.props.hidden ? [styles.navigationContainer, styles.noSelect] : [styles.navigationContainerHidden, styles.noSelect])} bg="dark" variant="dark" ref={this.setWrapperRef}>
                    <Navbar.Brand className={css(this.props.mock ? styles.plndrLogoNavHidden : styles.plndrLogoNav)} href="/">
                        {/* <Link to='/'> */}
                        <MediaQuery minWidth={500}>
                            <img
                                alt=""
                                src={require("../../images/PlndrLogo.png")}
                                width="120"
                                height="115"
                                className="d-inline-block align-top"
                            />
                        </MediaQuery>
                        <MediaQuery maxWidth={499}>
                            <img
                                alt=""
                                src={require("../../images/PlndrLogo.png")}
                                width="94"
                                height="90"
                                className="d-inline-block align-top"
                            />
                        </MediaQuery>
                        {/* </Link>  */}
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        {this.props.isLoggedIn ? (
                            <MediaQuery minWidth={700}>
                                <Button className={css(styles.navBarButton)} onClick={() => {
                                    // console.log("Withdraw Clicked");
                                    this.onClickOpenPayout()
                                }}>WITHDRAW</Button>
                            </MediaQuery>
                        ) :
                            this.props.tutorial && this.props.tutProcess && !this.props.mock ?
                            <MediaQuery minWidth={700}>
                                <Button className={css(styles.navBarButtonDisable)}>WITHDRAW</Button>
                            </MediaQuery>
                                : null
                        }
                        <MediaQuery minWidth={451}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                        </MediaQuery>
                        {this.props.isLoggedIn ? (
                            <MediaQuery minWidth={700}>
                                <Button className={css(styles.navBarButton)} onClick={() => {
                                    // console.log("Add Credits Clicked");
                                    this.onClickOpenAddCredits();
                                }}>ADD CREDITS</Button>
                            </MediaQuery>
                        ) :
                            this.props.tutorial && this.props.tutProcess ?
                                <MediaQuery minWidth={700}>
                                    <Button className={css(styles.navBarButtonDisable)}>ADD CREDITS</Button>
                                </MediaQuery>
                                : null
                        }
                        <MediaQuery minWidth={451}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                        </MediaQuery>
                        {this.props.isLoggedIn ? (
                            <>
                                {1 === 0 && <Link to="/ads" className={css(styles.videoButtonNav)}>
                                    <img alt="adsViewButton" className={css(styles.videoButtonIcon)} src={require('../../images/videoButton.svg')} />
                                </Link>
                                }
                                <MediaQuery minWidth={451}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </MediaQuery>
                                <Navbar.Text onClick={this.onClickOpenMenu} className={css(styles.userInfoNav)}>
                                    <span className={css(styles.userBalanceNav)}>${this.props.currUser.balance}</span> <span className={css(styles.navBarPipe)}>|</span> <span className={css(styles.userNameNav)}>{this.props.currUser.name}</span>
                                </Navbar.Text>
                                <MediaQuery minWidth={451}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </MediaQuery>
                                <Navbar.Text >
                                    {this.props.alertCount > 0 && (
                                        <div className={css(styles.notificationCount)} onClick={this.onClickShowGameHistory}>{this.props.alertCount} </div>
                                    )}
                                </Navbar.Text>
                            &nbsp;&nbsp;
                            <div className={css(styles.profileMenuToggle)}>
                                    {this.props.usermenu ?
                                        <div className={css(styles.closeMenuButton)} onClick={this.onClickCloseMenu}><img alt="exitmenu" className={css(styles.closeMenuIcon)} src={require('../../images/exit.svg')} /></div>
                                        :
                                        <img alt="openmenu" className={css(styles.navProfilePhoto)} src={this.props.currUser.userProfileURL} onClick={this.onClickOpenMenu} />
                                    }
                                </div>
                            </>
                        ) :
                            this.props.tutorial && this.props.tutProcess ?
                                (
                                    <>
                                        <Navbar.Text className={css(styles.userInfoNav)}>
                                            <span className={css(styles.userBalanceNav)}>${this.props.mock ? '2.00' : '0.00'}</span> <span className={css(styles.navBarPipe)}>|</span> <span className={css(styles.userNameNav)}>Guest</span>
                                        </Navbar.Text>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className={css(styles.profileMenuToggle)}>
                                            <div className={css(styles.closeMenuButton)}><img alt="exitmenu" className={css(styles.closeMenuIcon)} src={require('../../images/noAvSelected.svg')} /></div>
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <Link to="/login">
                                        <Button className={css(styles.navBarButton)}>LOG IN</Button>
                                    </Link>

                                )
                        }
                        {(this.props.tutorial && this.props.mock) &&
                            <div className={css(styles.navBarEmphasis)}>
                            </div>
                        }
                        {/* {
                            this.props.gameHistory && this.state.showGameHistoryModal && (
                                <Modal
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    className={css(styles.menuModals)}
                                    show={true}
                                    onHide={this.onHideModal}>
                                    <Modal.Body>
                                        <GameHistoryModal gameHistory={this.props.gameHistory} onHide={this.onHideModal} />
                                    </Modal.Body>
                                </Modal>
                            )
                        } */}
                    </Navbar.Collapse>
                    {this.props.usermenu && <UserMenu staticUser={this.props.user} />}
                    {/* {this.props.addingcredits && <AddCredits />}
                    {this.props.dowithdrawing && <Payout />} */}
                </Navbar>
                {this.props.addingcredits && (
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className={css(styles.menuModals)}
                        show={true}
                        onHide={this.onHideModal}>
                        <AddCredits />
                    </Modal>
                )}
                {this.props.dowithdrawing &&
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className={css(styles.menuModals)}
                        show={true}
                        onHide={this.onHideModal}>
                        <Payout />
                    </Modal>
                }
                {this.props.referCode &&
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className={css(styles.menuModals)}
                        show={true}
                        onHide={this.onHideModal}>
                        <Modal.Body className={css(styles.menuModalBody)}>
                            <ReferCode />
                        </Modal.Body>
                    </Modal>
                }
                {
                    this.props.currUser && this.props.showEditProfileModal && (
                        // <Modal
                        //     size="lg"
                        //     aria-labelledby="contained-modal-title-vcenter"
                        //     centered
                        //     className={css(styles.menuModals)}
                        //     show={true}
                        //     onHide={this.onHideModal}>
                        //     <Modal.Body className={css(styles.menuModalBody)}>
                        //         <EditProfileModal user={this.props.currUser} onHide={this.onHideModal} />
                        //     </Modal.Body>
                        // </Modal>
                        <Redirect to="/profile" />
                    )
                }
                {
                    this.props.gameHistory && this.props.showGameHistoryModal && (
                        // <Modal
                        //     size="lg"
                        //     aria-labelledby="contained-modal-title-vcenter"
                        //     centered
                        //     className={css(styles.menuModals)}
                        //     show={true}
                        //     onHide={this.onHideModal}>
                        //     <Modal.Body className={css(styles.menuModalBody)}>
                        //         <GameHistoryModal gameHistory={this.props.gameHistory} onHide={this.onHideModal} />
                        //     </Modal.Body>
                        // </Modal>
                        <Redirect to="/gamehistory" />
                    )
                }
                {
                    this.props.paymentHistory && this.props.showPaymentHistoryModal && (
                        //<Modal
                        //    size="lg"
                        //    aria-labelledby="contained-modal-title-vcenter"
                        //    centered
                        //    className={css(styles.menuModals)}
                        //    show={true}
                        //    onHide={this.onHideModal}>
                        //    <Modal.Body className={css(styles.menuModalBody)}>
                        //        <PaymentHistoryModal paymentHistory={this.props.paymentHistory} onHide={this.onHideModal} />
                        //    </Modal.Body>
                        //</Modal>
                        <Redirect to="/paymenthistory" />
                    )
                }
                {
                    this.props.payoutHistory && this.props.showPayoutHistoryModal && (
                        //<Modal
                        //    size="lg"
                        //    aria-labelledby="contained-modal-title-vcenter"
                        //    centered
                        //    className={css(styles.menuModals)}
                        //    show={true}
                        //    onHide={this.onHideModal}>
                        //    <Modal.Body className={css(styles.menuModalBody)}>
                        //        <PayoutHistoryModal payoutHistory={this.props.payoutHistory} onHide={this.onHideModal} />
                        //    </Modal.Body>
                        //</Modal>
                        <Redirect to="/payouthistory" />
                    )
                }
                {
                    this.props.mywallet && (
                        <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            className={css(styles.menuModals)}
                            show={true}
                            onHide={this.onHideModal}>
                            <Wallet />
                        </Modal>
                    )
                }
            </>

        );
    }
}

function mapStateToProps(state) {
    return {
        usermenu: state.noPersistReducer.userMenu,
        currUser: state.loginReducer.user,
        isLoggedIn: state.loginReducer.isLoggedIn,
        addingcredits: state.noPersistReducer.addingCredits,
        dowithdrawing: state.noPersistReducer.doWithdrawing,
        gameHistory: state.gameReducer.gameHistory,
        alertCount: state.noPersistReducer.alertCount,
        token: state.loginReducer.loginToken,
        paymentHistory: state.paymentReducer.paymentHistory,
        payoutHistory: state.payoutReducer.payoutHistory,
        showEditProfileModal: state.noPersistReducer.editProfile,
        showGameHistoryModal: state.noPersistReducer.gameHistoryModal,
        showPaymentHistoryModal: state.noPersistReducer.paymentHistoryModal,
        showPayoutHistoryModal: state.noPersistReducer.payoutHistoryModal,
        mywallet: state.noPersistReducer.myWallet,
        tutorial: state.loginReducer.tutorial,
        tutProcess: state.noPersistReducer.tutProcess,
        referCode: state.noPersistReducer.referCode
    }
}

export default connect(mapStateToProps, {
    closeUserMenu,
    openUserMenu,
    logout,
    openAddCredits,
    closeAddCredits,
    openPayout,
    closePayout,
    getGameHistory,
    clearAlert,
    markAlertsRead,
    closeMenuModals,
    closeMoreShots,
    showGameHistory
}
)(NavBar);