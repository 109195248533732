import React from 'react'
import { Button, Modal, InputGroup, FormControl, Row } from 'react-bootstrap'
import FacebookLogin from 'react-facebook-login'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { facebookLogin, login, closeLandingPopup, resetPassword, changePassword } from '../../actions/LoginActions'
import { sleep } from '../../constants/ActionConstants'
import { css } from 'aphrodite'
import { styles } from './LoginStyles.js'
import swal from 'sweetalert';
import './login.css';

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loginView: true
        }
        this.statusChangeCallback = this.statusChangeCallback.bind(this)
        this.handleFormChange = this.handleFormChange.bind(this)
        this.onClickLogin = this.onClickLogin.bind(this)
        this.enterPressed = this.enterPressed.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.toggleView = this.toggleView.bind(this);
    }

    enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) {
            this.onClickLogin();
        }
    }

    forgotPassword() {
        // if (!this.state.email) {
        //     // alert("Please enter an email");
        //     swal({
        //         text: "Please enter an email", 
        //         icon: "warning"
        //     });
        // }
        if (!this.state.username) {
            alert("Please enter Username");
        }
        else {
            if (!this.props.forcePasswordChange) {
                this.props.resetPassword(this.state.username);
            }
            else {
                this.props.changePassword(this.state.username, this.state.password, this.state.token);
                sleep(500).then(() => {
                    if (this.props.token) {
                        document.location = '/';
                    }
                })

            }
        }
    }

    toggleView() {
        this.setState({ loginView: !this.state.loginView });
    }

    // This is called with the results from from FB.getLoginStatus().
    statusChangeCallback(response) {
        // console.log(response)

        if (response.accessToken) {
            this.props.facebookLogin(response)
            this.setState({ loggedin: true })
            //this.props.closeLandingPopup();
            sleep(500).then(() => {
                if (this.props.token) {
                    document.location = '/';
                }
            })

        } else {
            // The person is not logged into Facebook, so we're not sure if
            // they are logged into this app or not.
            // console.log('Please log ' +
            //     'into Facebook.')
            // this.setState({ loggedin: false })
        }
        // this.props.closeUserMenu();
    }

    verify(username, password) {
        if (!username || !password) {
            return false;
        }
        return true;
    }

    onClickLogin() {
        if (this.state.loginView) {
            if (!this.props.inProgress) {
                let userName = this.state.username
                let password = this.state.password;

                if (this.verify(userName, password)) {
                    this.props.login(userName, password);
                    sleep(500).then(() => {
                        if (this.props.token) {
                            document.location = '/';
                        }
                    })
                }
                else {
                    alert("Please enter a user name and password");
                }
            }
        }
        else {
            this.forgotPassword();
        }
    }

    handleFormChange(e) {
        let name = e.target.id
        let value = e.target.value
        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            <>
                <Modal className={css(styles.loginPopup)} show={true} animation={true} size="lg"
                    aria-labelledby="contained-modal-title-vcenter" backdrop="static" backdropClassName={css(styles.loginPopupBackdrop)}
                    centered>
                    <Modal.Header className={css(styles.modalBorder)}>
                        <Modal.Title className={css(styles.loginTitle)}>{this.state.loginView ? 'Login' : <span className={css(styles.mqforgotPasswordText)}>Forgot Password</span>}</Modal.Title>
                        <img
                            alt=""
                            src={require("../../images/PlndrLogo.png")}
                            width="110"
                            height="105"
                            className={css(styles.loginPlndrLogo)}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <Row className={css(styles.loginButtonRowFB)}>
                            {this.state.loginView && (
                                <FacebookLogin
                                    // TEST APP BELOW
                                    // appId="273683907662682"
                                    // PRODUCTION APP BELOW
                                    appId="202325361011005"
                                    // autoLoad
                                    fields="name,email,picture.width(1000).height(1000)"
                                    icon="fa-facebook"
                                    isDisabled={this.props.isloggedin ? true : false}
                                    callback={this.statusChangeCallback} />
                            )}
                        </Row>
                        <Row> &nbsp;&nbsp;&nbsp;</Row>
                        <Row className={css(styles.loginButtonRowFB)}>
                            {this.state.loginView ? (<> <hr className={css(styles.loginDivider)} /><span style={{ fontFamily: 'SF Pro Text Semibold' }}> Or With </span> <hr className={css(styles.loginDivider)} /></>) : null}
                        </Row>
                        {/* <Row>&nbsp;&nbsp;&nbsp;</Row> */}
                        <InputGroup className="mb-3">
                            <FormControl onChange={this.handleFormChange}
                                className={css(styles.loginFormInput)}
                                placeholder="Username"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                type="text"
                                id="username"
                            />
                        </InputGroup>
                        {(this.state.loginView || this.props.forcePasswordChange) && (
                            <InputGroup className="mb-3">
                                <FormControl onChange={this.handleFormChange}
                                    className={css(styles.loginFormInput)}
                                    placeholder="Password"
                                    aria-label="Password"
                                    aria-describedby="basic-addon1"
                                    type="password"
                                    id="password"
                                    onKeyPress={this.enterPressed}
                                />
                            </InputGroup>
                        )}
                        {(!this.state.loginView && this.props.forcePasswordChange) && (
                            <InputGroup className="mb-3">
                                <FormControl onChange={this.handleFormChange}
                                    className={css(styles.loginFormInput)}
                                    placeholder="Please enter the token from your email"
                                    aria-label="token"
                                    aria-describedby="basic-addon1"
                                    type="text"
                                    id="token"
                                />
                            </InputGroup>
                        )}
                    </Modal.Body>
                    <Modal.Footer className={css(styles.modalBorderLoginButtons)}>
                        <Row className={css(styles.loginButtonRow, styles.loginButtonRowPrimary)}>
                            <Button variant="secondary" className={css(styles.loginButton)} disabled={this.props.inProgress} onClick={this.onClickLogin}>
                                {this.state.loginView ? "LOGIN" : this.props.forcePasswordChange ? "CHANGE PWD" : "SEND EMAIL"}
                            </Button>
                            {/* <Row className={css(styles.loginButtonRowFB)}> */}
                            {/* {this.state.loginView && (
                                <FacebookLogin
                                    appId="202325361011005"
                                    // autoLoad
                                    fields="name,email,picture.width(1000).height(1000)"
                                    icon="fa-facebook"
                                    isDisabled={this.props.isloggedin ? true : false}
                                    callback={this.statusChangeCallback} />
                            )} */}
                        </Row>
                        <Row className={css(styles.loginButtonRow, styles.mqCloseButtonRow, styles.loginButtonRowPrimary)}>
                        <Link to="/">
                            <Button className={css(styles.loginButton)} variant="secondary">
                                CLOSE
                            </Button>
                        </Link>
                        </Row>
                        <Row className={css(styles.loginButtonRow, styles.mqForgotButton)}>
                            <div onClick={this.toggleView} className={css(styles.loginModalForgotButton)} variant="secondary">
                                {this.state.loginView ? "Forgot Password?" : "Go Back to Login"}
                            </div>
                            <Link to="/" className={css(styles.loginModalCloseButton)}>
                                <Button className={css(styles.loginButton, styles.mqCloseButton)} variant="secondary">
                                    CLOSE
                                </Button>
                            </Link>
                        </Row>

                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        forcePasswordChange: state.loginReducer.forcePasswordChange,
        inProgress: state.loginReducer.inProgress
    }
}

export default connect(mapStateToProps, {
    facebookLogin,
    login,
    closeLandingPopup,
    resetPassword,
    changePassword
})(Login)