import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { styles } from './ModalStyles.js';
import { css } from 'aphrodite';
import { serverEnvironment } from '../../constants/ActionConstants.js';
import { closeCropImage } from '../../actions/MenuActions';
import { updateUserProfile } from '../../actions/LoginActions';
import { Storage } from "aws-amplify";
import ReactCrop from 'react-image-crop';
import { v4 as uuidv4 } from 'uuid';
import 'react-image-crop/dist/ReactCrop.css';

class CropImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.currUser,
            selected: false,
            saveEnable: true,
            editBlob: null
        };
        this.selectAvatar = this.selectAvatar.bind(this);
        this.saveData = this.saveData.bind(this);
        this.regPic = this.regPic.bind(this);
        this.hitClose = this.hitClose.bind(this);
        this.saveNewUserImage = this.saveNewUserImage.bind(this);
    }

    onImageLoaded = image => {
        this.imageRef = image;
        this.setState({ crop: { aspect: 1 / 1, width: 144, height: 144, x: 1, y: 1 } });
        return false;
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    }

    onCropComplete = (crop) => {
        if (this.imageRef && crop.width && crop.height) {
            this.setState({ saveEnable: true })
            this.makeClientCrop(crop);
        }
        else {
            this.setState({ saveEnable: false });
        }
    }


    async makeClientCrop(crop) {
        let name = uuidv4();
        
        let fName = name + ".jpeg";
        const { croppedImageUrl, blob } = await this.getCroppedImg(
            this.imageRef,
            crop,
            fName
        );
        if (this.props.registration) {
            this.props.selectPic(croppedImageUrl, blob);
            console.log('This is on registration step');
        }
        else {
            this.setState({
                editBlob: blob
            });
        }
    }

    async getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve({ croppedImageUrl: this.fileUrl, blob: blob });
            }, "image/jpeg");
        });
    }

    regPic() {
        // this.props.selectPic(serverEnvironment.photosHost + this.state.avatar);
        this.props.closeCropImage();
    }

    hitClose() {
        if (this.props.registration) {
            this.props.selectPic(null);
        }
        this.props.closeCropImage();
    }

    selectAvatar(input) {
        if (this.state.selected === false) {
            this.setState({ selected: true });
        }
        this.setState({ avatar: input })
    }

    saveNewUserImage(user, blob) {
        user.userProfileURL = `${serverEnvironment.photosHost}/${blob.name}`; 
        console.log(user); 
        this.props.updateUserProfile(this.props.token, user);
        // document.location = "/profile";
        this.props.closeCropImage();
    }


    async saveData() {
        var user = this.props.currUser;
        let blob = this.state.editBlob;
        let stored
        try {
            stored = await Storage.put(`photos/${blob.name}`, blob, {
                contentType: blob.type,
                level: 'public',
                customPrefix: {
                    public: "photos/"
                }
            });
        } catch (error) {
            console.log("error: ")
            console.log(error)
        }

        await this.saveNewUserImage(user, blob); 
    }
    render() {

        let url = serverEnvironment.photosHost;

        return (
            <>
                <Col className={css(styles.avatarSelectionTitle)}>Crop Photo</Col>
                <Row className={css(styles.cropImageRow)}>
                    <ReactCrop
                        src={this.props.renderCrop}
                        crop={this.state.crop}
                        onImageLoaded={this.onImageLoaded}
                        onChange={this.onCropChange}
                        onComplete={this.onCropComplete}
                        style={{ width: '100%' }}
                    />
                </Row>
                <Row className={css(styles.avatarButtonsRow)}>
                    <Col>
                        <Button onClick={this.hitClose} className={css(styles.profileModalButton)}>CANCEL</Button>
                    </Col>
                    <Col>
                        {this.props.registration ?
                            <Button disabled={!this.state.saveEnable} className={this.state.saveEnable ? css(styles.avatarSaveButton, styles.avatarSelectionButtonAlt) : css(styles.avatarSaveButtonDisabled, styles.avatarSelectionButtonAlt)} onClick={() => this.regPic()}>SELECT</Button>
                            :
                            <Button disabled={!this.state.editBlob} className={this.state.saveEnable ? css(styles.avatarSaveButton, styles.avatarSelectionButtonAlt) : css(styles.avatarSaveButtonDisabled, styles.avatarSelectionButtonAlt)} onClick={this.saveData}>SAVE</Button>
                        }
                    </Col>
                </Row>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        currUser: state.loginReducer.user
    }
}

export default connect(mapStateToProps, {
    closeCropImage,
    updateUserProfile
})(CropImage);