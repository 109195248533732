import React from 'react';
import Game from '../Game';
import { Container, Row, Col } from 'react-bootstrap';
import { gamesRefreshed } from '../../actions/GameActions';
import { connect } from 'react-redux';
import { tutorialGame } from '../../JSON/tutorialGame.js';
import { css } from 'aphrodite';
import { styles } from './GameContainerStyles.js';
import MediaQuery from 'react-responsive';

class GameContainer extends React.Component {

    createGameRows(gameList, cutoff) {
        let gameSetArray = [];
        let tempGameArray = [];
        for (let i = 0; i < gameList.length; i++) {
            if (Number.isInteger(i / cutoff) && i / cutoff !== 0) {
                gameSetArray.push(tempGameArray);
                tempGameArray = [];
                tempGameArray.push(gameList[i]);
            }
            else {
                tempGameArray.push(gameList[i]);
            }
        }
        if (tempGameArray !== []) {
            gameSetArray.push(tempGameArray);
        }
        return gameSetArray;
    }

    mapGameRows(gameSetArray) {
        let gameGrid = gameSetArray.map((gameSet, index) =>
            <Row key={index}>
                {gameSet.map((game, index) =>
                    this.props.currUser.id ?
                        (
                            !game.filled && (
                                <Game key={index} game={game} userId={this.props.currUser.id}></Game>
                            )
                        )

                        :
                        (
                            !game.filled && (
                                <Game key={index} game={game} userId=""></Game>
                            )
                        )
                )}
            </Row>
        );
        return gameGrid;
    }

    renderGames() {
        return this.mapGameRows(this.createGameRows(this.props.gamesList, 3));
    }

    renderGames2() {
        return this.mapGameRows(this.createGameRows(this.props.gamesList, 2));
    }

    renderGames1() {
        return this.mapGameRows(this.createGameRows(this.props.gamesList, 1));
    }

    render() {
        this.createGameRows(this.props.gamesList);

        if (this.props.load) {
            this.props.gamesRefreshed();
        }

        return (
            <>
                {this.props.tutorial ?
                    <>
                        <Container className={css(styles.containerBoundsTut, styles.mqContainerBoundsTut)}>
                            <Row>
                                <MediaQuery minWidth={911}>
                                    <Col md={5} sm={5} xs={5} className={css(styles.tutorialPrompt)}>
                                        <div style={{ position: 'relative' }}>
                                            <span className={css(styles.tutorialPromptWin)}>WIN REAL</span><br />
                                            <span className={css(styles.tutorialPromptMoney)}>MONEY</span><br />
                                            <span className={css(styles.tutorialPromptHash)}>#SPINTOWIN</span>
                                            <img className={css(styles.tutorialArrow1)} alt="" src={require("../../images/tutorialArrow1.svg")} />
                                        </div>
                                    </Col>
                                </MediaQuery>
                                <MediaQuery maxWidth={910}>
                                    <div className={css(styles.mqtutorialPrompt)}>
                                        <div style={{ position: 'relative' }}>
                                            <span className={css(styles.tutorialPromptWin, styles.mqtutorialPromptWin)}>WIN REAL</span><br />
                                            <span className={css(styles.tutorialPromptMoney, styles.mqtutorialPromptMoney)}>MONEY</span><br />
                                            <span className={css(styles.tutorialPromptHash)}>#SPINTOWIN</span>
                                            <MediaQuery minWidth={560}>
                                                <img className={css(styles.tutorialArrow1)} alt="" src={require("../../images/tutorialArrow1.svg")} />
                                            </MediaQuery>
                                        </div>
                                    </div>
                                </MediaQuery>
                                <MediaQuery maxWidth={910} minWidth={785}>
                                    <Col md={5} sm={5} xs={5}></Col>
                                </MediaQuery>
                                <MediaQuery minWidth={785}>
                                    <Col md={7} sm={7} xs={7} className={css(styles.mqtutorialGameContainer)}>
                                        <div className={css(styles.tutorialGameBack, styles.mqtutorialGameBack)}>
                                            <Game tutorial game={tutorialGame} userId={this.props.currUser.id} tutGame={this.props.tutGame}></Game>
                                        </div>
                                    </Col>
                                </MediaQuery>
                                <MediaQuery maxWidth={784}>
                                    <Col md={12} sm={12} xs={12} className={css(styles.mqtutorialGameContainer)}>
                                        <div className={css(styles.tutorialGameBack, styles.mqtutorialGameBack)}>
                                            <Game tutorial game={tutorialGame} userId={this.props.currUser.id} tutGame={this.props.tutGame}></Game>
                                        </div>
                                    </Col>
                                </MediaQuery>
                            </Row>
                        </Container>
                    </>
                    :
                    <>
                        <br />
                        <Container className={css(styles.containerBounds)}>
                            <MediaQuery minWidth={1256}>
                                {this.renderGames()}
                            </MediaQuery>
                            <MediaQuery maxWidth={1255}>
                                {this.renderGames2()}
                            </MediaQuery>
                        </Container>
                    </>
                }
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        gamesList: state.gameReducer.gamesList,
        load: state.gameReducer.loadGames,
        currUser: state.loginReducer.user,
        tutorial: state.loginReducer.tutorial
    }
}

export default connect(mapStateToProps, {
    gamesRefreshed
})(GameContainer);