export const tutorialPlayers = [
    {
        slot: 1, 
        player: {
            userProfileURL: '../../images/tutorialPhotos/dan-ROJFuWCsfmA-unsplash.jpg', 
            name: 'Lillabsolis'
        }
    },
    {
        slot: 2,
        player: {
            userProfileURL: '../../images/tutorialPhotos/freestocks-9UVmlIb0wJU-unsplash.jpg',
            name: 'BostonGalll'
        }
    }, 
    {
        slot: 3,
        player: {
            userProfileURL: '../../images/tutorialPhotos/brooks-leibee-27QcqVqgVg4-unsplash.jpg',
            name: 'NightLife0284'
        }
    }, 
    {
        slot: 4,
        player: {
            userProfileURL: '../../images/tutorialPhotos/zachary-nelson-98Elr-LIvD8-unsplash.jpg',
            name: 'Zachary Nelson'
        }
    }, 
    {
        slot: 5,
        player: {
            userProfileURL: '../../images/tutorialPhotos/michael-dam-mEZ3PoFGs_k-unsplash.jpg',
            name: 'Violeta Ilona'
        }
    }, 
    {
        slot: 7,
        player: {
            userProfileURL: '../../images/tutorialPhotos/dami-adebayo-k6aQzmIbR1s-unsplash.jpg',
            name: 'Marcus Benjamin'
        }
    }, 
    {
        slot: 8,
        player: {
            userProfileURL: '../../images/tutorialPhotos/jose-alejandro-cuffia-k1LNP6dnyAE-unsplash.jpg',
            name: 'LifeofPups'
        }
    }, 
    {
        slot: 9,
        player: {
            userProfileURL: '../../images/tutorialPhotos/courtney-cook-Q1T4XGEXIgw-unsplash.jpg',
            name: 'Jadeinterior'
        }
    }, 
    {
        slot: 10,
        player: {
            userProfileURL: '../../images/tutorialPhotos/nrd-Y9oI7oLSd_c-unsplash.jpg',
            name: 'Randy Roffe'
        }
    }, 
    {
        slot: 11,
        player: {
            userProfileURL: '../../images/tutorialPhotos/ben-parker-OhKElOkQ3RE-unsplash.jpg',
            name: 'Dan Carson'
        }
    }, 
    {
        slot: 12,
        player: {
            userProfileURL: '../../images/tutorialPhotos/nicholas-green-nPz8akkUmDI-unsplash.jpg',
            name: 'Theboiz298'
        }
    }
]