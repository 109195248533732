import * as React from "react";
import Phaser from "phaser";
import PrimaryScene from "./scenes/primaryScene";
import CannonGameScene from "./scenes/cannonGameScene";
import { GAME_HEIGHT, GAME_WIDTH } from "./config";

import { css } from 'aphrodite';
import { styles } from './MinigameStyles.js';

var config = {
    type: Phaser.AUTO,
    width: GAME_WIDTH, 
    height: GAME_HEIGHT,
    parent: 'game-container',
    physics: {
        default: 'arcade',
        arcade: {
            gravity: { y: 300 },
            debug: false
        },
    },
    scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH
    },
    scene: [CannonGameScene]
}

class Logic extends React.Component {
    componentDidMount() {
        new Phaser.Game(config)
    }

    shouldComponentUpdate() {
        return false
    }

    render() {
        return <div className={css(styles.minigameContainer)} id="game-container" />
    }
}
export default Logic