import React from 'react'
import { Button, Modal, Form, Container, Col, Row, Image, Media } from 'react-bootstrap'
import FacebookLogin from 'react-facebook-login'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { facebookLogin, signUp, clearError, verifyEmail, validateRecaptcha, clearDuplicateUserError, endTutorial } from '../../actions/LoginActions'
import { showSelectAvatar, closeSelectAvatar, showCropImage, closeCropImage } from '../../actions/MenuActions'
import { sleep } from '../../constants/ActionConstants'
import { css } from 'aphrodite'
import { styles } from './SignUpStyles.js'
// import { Link } from 'react-router-dom';
import "./signUp.css";
import { load } from 'recaptcha-v3'
import AvatarSelection from '../Modals/AvatarSelection.js';
import CropImage from '../Modals/CropImage.js';
import Leaderboard from '../Leaderboard';
import GameContainer from '../GameContainer';
import swal from 'sweetalert';
import GameViewMock from '../GameView/GameViewMock'
import NavBar from '../NavBar';
import MediaQuery from 'react-responsive';
import { Storage } from "aws-amplify";
import { serverEnvironment } from '../../constants/ActionConstants.js';

class SignUp extends React.Component {
    constructor(props) {
        super(props)
        this.recaptchaRef = React.createRef();
        this.state = {
            page: 1,
            overThirteen: false,
            agreeToTAC: false,
            time: 3,
            tutStep: 1,
            renderCrop: null
        }
        this.onClickNextPage = this.onClickNextPage.bind(this)
        this.handleFormChange = this.handleFormChange.bind(this)
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
        this.onClickSignUp = this.onClickSignUp.bind(this)
        this.onClickSignUpImg = this.onClickSignUpImg.bind(this);
        this.statusChangeCallback = this.statusChangeCallback.bind(this);
        this.onClickVerify = this.onClickVerify.bind(this);
        this.onClickBack = this.onClickBack.bind(this);
        this.changePic = this.changePic.bind(this);
        this.selectPic = this.selectPic.bind(this);
        this.selectPicAv = this.selectPicAv.bind(this);
        this.throwDupError = this.throwDupError.bind(this);
        this.decrement = this.decrement.bind(this);
        this.tutGame = this.tutGame.bind(this);
        this.tutReturnHome = this.tutReturnHome.bind(this);
        this.tutStepFour = this.tutStepFour.bind(this);
        this.tutStepFive = this.tutStepFive.bind(this);
        this.tutStepSix = this.tutStepSix.bind(this);
        this.tutStepSeven = this.tutStepSeven.bind(this);
        this.getStarted = this.getStarted.bind(this);
    }

    async componentDidMount() {
        if (this.props.match.params.referralCode) {
            this.setState({ referralCode: this.props.match.params.referralCode });
        }
        this.props.clearError();
        // if (this.props.tutorial) {
        //    setInterval(this.decrement, 1000); 
        // }
        // const self = this; 
        // document.querySelector('input[type="file"]').addEventListener('change', function() {
        //     if (this.files && this.files[0]) {
        //         self.setState({renderCrop: URL.createObjectURL(this.files[0])});
        //         self.props.showCropImage(); 
        //     }
        // }); 
    }

    onUploadImage = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ renderCrop: reader.result })
                this.props.showCropImage();
            }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    tutGame() {
        this.setState({ tutStep: 2 });
    }

    tutReturnHome() {
        this.setState({ tutStep: 3 });
    }

    tutStepFour() {
        this.setState({ tutStep: 4 });
    }

    tutStepFive() {
        this.setState({ tutStep: 5 });
    }

    tutStepSix() {
        this.setState({ tutStep: 6 });
    }

    tutStepSeven() {
        this.setState({ tutStep: 7 });
    }

    decrement() {
        if (this.state.time <= 0) {
            console.log('0')
        }
        else {
            this.setState({ time: this.state.time - 1 });
        }
    }

    getStarted() {
        this.props.endTutorial();
    }

    throwDupError() {
        swal("User Already Exists", "A user with this username or email already exists", "warning");
        this.props.clearDuplicateUserError();
        this.setState({
            email: null,
            password: null,
            text: null,
            number: null
        });
    }

    selectPic(url, blob) {
        this.setState({ url: url, blob: blob });
        console.log(this.state);
    }

    selectPicAv(url) {
        this.setState({ url: url });
        this.setState({ blob: null });
        console.log(this.state);
    }

    changePic() {
        var url = this.props.url;
        if (this.props.urls) {
            let pos = Math.floor((Math.random() * this.props.urls.length));
            if (pos >= 0) {
                url = this.props.urls[pos];
            }
        }
        this.setState({ url: url });
    }

    onClickBack() {
        this.setState({ page: 1 });
    }

    // This is called with the results from from FB.getLoginStatus().
    statusChangeCallback(response) {
        //console.log(response);

        if (response.accessToken) {
            if (this.state.referralCode !== null) {
                response.referralCode = this.state.referralCode;
            }
            this.props.facebookLogin(response)
            this.setState({ loggedin: true })
            sleep(500).then(() => {
                if (this.props.token) {
                    document.location = '/';
                }
            })
        } else {
            // The person is not logged into Facebook, so we're not sure if
            // they are logged into this app or not.
            //console.log('Please log ' +    'into Facebook.');
            //this.setState({ loggedin: false })
        }
        // this.props.closeUserMenu();
    }

    onClickNextPage() {
        // COMMENTING BELOW LINE FOR CAPTCHA - UNCOMMENT THIS
        // this.callRecaptcha();
        let nextPage = 2;
        this.setState({ page: nextPage })
        this.props.clearError();
    }

    onClickVerify(checkAll) {
        if (!this.state.email || !this.validateEmail(this.state.email)) {
            return false;
        }
        if (!this.state.text || this.state.text.length < 3 || this.state.text.length > 12) {
            return false;
        }
        if (!this.state.password || this.state.password.length < 6) {
            return false;
        }
        if (checkAll) {
            if (!this.state.overThirteen || !this.state.agreeToTAC) {
                return false;
            }
        }
        if (!this.state.url) {
            return false;
        }
        //this.props.verifyEmail(this.state.email);
        return true
    }

    validateEmail(e) {
        // Email without unicode support
        let value = e;
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emailvalidate = re.test(value);
        if (!emailvalidate) {
            return false;
        }
        return true;
    }


    handleFormChange(e) {
        let name = e.target.type
        let value = e.target.value
        this.setState({
            [name]: value
        })
    }

    handleCheckboxChange(e) {
        let name = e.target.id
        let value = e.target.checked
        this.setState({
            [name]: value
        })
    }

    onClickSignUp() {
        if (this.onClickVerify(true)) {
            console.log("sign up regular")
            this.props.signUp(this.state);
        }
    }

    async onClickSignUpImg() {
        if (this.onClickVerify(true)) {
            console.log("sign up img")
            let blob = this.state.blob
            let stored
            try {
                stored = await Storage.put(`${blob.name}`, blob, {
                    contentType: blob.type,
                    level: 'public',
                    customPrefix: {
                        public: "photos/"
                    }
                });
            } catch (error) {
                console.log("error: ")
                console.log(error)
            }

            await this.setState({ url: `${serverEnvironment.photosHost}/${blob.name}` })

            this.props.signUp(this.state);
        }
    }

    async callRecaptcha() {
        //console.log("In recaptcha submit");
        const recaptcha = await load('6LcnmcEZAAAAAFo3yLdy0KLzsMvKGaPLT189Ptcp');
        const token = await recaptcha.execute('onClick');
        if (token)
            this.props.validateRecaptcha(token);
    }

    render() {
        // console.log("signuperrors",this.props.signupErrors);
        // console.log("token", this.props.token);
        // console.log("page", this.state.page);
        // console.log("recaptcha score",this.props.recaptchascore);
        if (this.props.token && !this.props.signupErrors) {
            document.location = '/';
        }

        return (
            <>
                {(this.state.page === 1 || this.props.signupErrors) && !this.props.tutorial &&
                    <>
                        {this.props.signupErrors && this.props.duplicateUserError ? this.throwDupError() : ""}
                        <Modal id="signupModal" className={css(styles.signUpPopup)} show={true} animation={true} size="lg"
                            aria-labelledby="contained-modal-title-vcenter" backdrop="static" backdropClassName={css(styles.signUpPopupBackdrop)}
                            centered>
                            <Modal.Header className={css(styles.modalBorder)}>
                                <Modal.Title className={css(styles.signUpTitle, styles.mqsignUpTitle)}>Ready to sail the Plndr seas?<br />Create an Account.</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <MediaQuery minWidth={576}>
                                        <Col xs={7} md={6}>
                                            <Form id="page-1">
                                                <Form.Group controlId="formBasicEmail" style={{ position: 'relative' }}>
                                                    <Form.Control type="text" placeholder="Create a Username" onChange={this.handleFormChange} className={css(styles.signUpFormInput)} isValid={this.state.text && this.state.text.length > 2 && this.state.text.length < 13} isInvalid={this.state.text && this.state.text.length > 12} />
                                                    <Form.Control.Feedback type="invalid" tooltip style={{ position: 'absolute', top: '65%' }}>
                                                        Maximum of 12 characters
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="valid" tooltip style={{ position: 'absolute', top: '65%' }}>
                                                        Looks good!
                                                    </Form.Control.Feedback>
                                                    <Form.Text className="text-muted">
                                                        Your username will be visible to others
                                                </Form.Text>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicEmail" style={{ position: 'relative' }}>
                                                    <Form.Control type="email" placeholder="Enter email" onChange={this.handleFormChange} className={css(styles.signUpFormInput)} isValid={this.state.email && this.validateEmail(this.state.email)} />
                                                    <Form.Control.Feedback type="valid" tooltip style={{ position: 'absolute', top: '65%' }}>
                                                        Looks good!
                                                    </Form.Control.Feedback>
                                                    <Form.Text className="text-muted">
                                                        We'll never share your email with anyone else.
                                            </Form.Text>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPhone" style={{ position: 'relative' }}>
                                                    <Form.Control type="number" placeholder="Enter Phone Number (Optional)" onChange={this.handleFormChange} className={css(styles.signUpFormInput, styles.signUpFormInputPhone)} />
                                                    <Form.Text className="text-muted">
                                                        We'll never share your phone number with anyone else.
                                            </Form.Text>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword" style={{ position: 'relative' }}>
                                                    <Form.Control type="password" placeholder="Create a password" onChange={this.handleFormChange} className={css(styles.signUpFormInput)} isValid={this.state.password && this.state.password.length > 5} />
                                                    <Form.Control.Feedback type="valid" tooltip style={{ position: 'absolute', top: '102%' }}>
                                                        Looks good!
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                            </Form>
                                        </Col>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={575}>
                                        <Col xs={12} md={6}>
                                            <Form id="page-1">
                                                <Form.Group controlId="formBasicEmail" style={{ position: 'relative' }}>
                                                    <Form.Control type="text" placeholder="Create a Username" onChange={this.handleFormChange} className={css(styles.signUpFormInput)} isValid={this.state.text && this.state.text.length > 2 && this.state.text.length < 13} isInvalid={this.state.text && this.state.text.length > 12} />
                                                    <Form.Control.Feedback type="invalid" tooltip style={{ position: 'absolute', top: '65%' }}>
                                                        Maximum of 12 characters
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="valid" tooltip style={{ position: 'absolute', top: '65%' }}>
                                                        Looks good!
                                                    </Form.Control.Feedback>
                                                    <Form.Text className="text-muted">
                                                        <span className={css(styles.mqFormText)}>Your username will be visible to others</span>
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicEmail" style={{ position: 'relative' }}>
                                                    <Form.Control type="email" placeholder="Enter email" onChange={this.handleFormChange} className={css(styles.signUpFormInput)} isValid={this.state.email && this.validateEmail(this.state.email)} />
                                                    <Form.Control.Feedback type="valid" tooltip style={{ position: 'absolute', top: '65%' }}>
                                                        Looks good!
                                                    </Form.Control.Feedback>
                                                    <Form.Text className="text-muted">
                                                        <span className={css(styles.mqFormText)}>We'll never share your email with anyone else.</span>
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPhone" style={{ position: 'relative' }}>
                                                    <Form.Control type="number" placeholder="Enter Phone Number" onChange={this.handleFormChange} className={css(styles.signUpFormInput, styles.signUpFormInputPhone)} />
                                                    <Form.Text className="text-muted">
                                                        <span className={css(styles.mqFormText)}>We'll never share your phone number with anyone else.</span>
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword" style={{ position: 'relative' }}>
                                                    <Form.Control type="password" placeholder="Create a password" onChange={this.handleFormChange} className={css(styles.signUpFormInput)} isValid={this.state.password && this.state.password.length > 5} />
                                                    <Form.Control.Feedback type="valid" tooltip style={{ position: 'absolute', top: '102%' }}>
                                                        Looks good!
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                            </Form>
                                        </Col>
                                    </MediaQuery>
                                    <MediaQuery minWidth={576}>
                                        <Col xs={5} md={6} className={css(styles.avatarSelectionSection)}>
                                            <div className={css(styles.uploadColumn)}>
                                                {/* <Image width="200px" onClick={this.changePic} src={url} roundedCircle /> */}
                                                <Image src={this.state.url ? this.state.url : require('../../images/noAvSelected.svg')} roundedCircle className={css(styles.selectedAvatar)} />
                                                <div className={css(styles.selectAvatarButtonBox)}><Button onClick={this.props.showSelectAvatar} className={css(styles.selectAvatarButton)}>PICK AVATAR</Button></div>
                                                <div style={{ textAlign: 'center', marginTop: 10, marginBottom: -10 }}><label htmlFor="file"><div className={css(styles.selectAvatarButton, styles.imageUploadButton)}>UPLOAD IMAGE</div></label></div>
                                                <input style={{ display: 'none' }} type="file" id="file" accept="image/*" onChange={this.onUploadImage} />
                                            </div>
                                        </Col>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={575}>
                                        <Col xs={5} md={6} className={css(styles.avatarSelectionSection)}>
                                            <div className={css(styles.uploadColumn)}>
                                                {/* <Image width="200px" onClick={this.changePic} src={url} roundedCircle /> */}
                                                <Image src={this.state.url ? this.state.url : require('../../images/noAvSelected.svg')} roundedCircle className={css(styles.selectedAvatar)} onClick={this.props.showSelectAvatar} />
                                                <div className={css(styles.selectAvatarButtonBox)}><Button onClick={this.props.showSelectAvatar} className={css(styles.selectAvatarButton)}>PICK AVATAR</Button></div>
                                                <div style={{ textAlign: 'center', marginTop: 10, marginBottom: -10 }}><label htmlFor="file"><div className={css(styles.selectAvatarButton, styles.imageUploadButton)}>UPLOAD IMAGE</div></label></div>
                                                <input style={{ display: 'none' }} type="file" id="file" accept="image/*" onChange={this.onUploadImage} />
                                            </div>
                                        </Col>
                                    </MediaQuery>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer className={css(styles.modalBorder)}>
                                <div className="mr-auto" id="facebookButtonSignUp">
                                    <FacebookLogin
                                        // TEST APP BELOW
                                        // appId="273683907662682"
                                        // PRODUCTION APP BELOW
                                        appId="202325361011005"
                                        // autoLoad
                                        fields="name,email,picture"
                                        icon="fa-facebook"
                                        isDisabled={this.props.isloggedin ? true : false}
                                        callback={this.statusChangeCallback} />
                                </div>
                                {/* <FormGroup>
                                <ControlLabel>Photos</ControlLabel>
                                <FormControl name="images[]" type="file" multiple onChange={this.handlePhotos} />
                            </FormGroup> */}
                                <div className={css(styles.mqsignUpButtonsRow)}>
                                    <Link to="/">
                                        <Button className={css(styles.signUpCloseButton)} variant="secondary">
                                            CLOSE
                                </Button>
                                    </Link>
                                    <Button className={css(styles.menuButton)} disabled={!this.onClickVerify(false)} onClick={() => this.onClickNextPage()}>
                                        NEXT
                            </Button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        {this.props.modalSelectAvatar && (
                            <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                className={css(styles.avatarModal, styles.noSelect)}
                                show={true}
                                onHide={this.props.closeSelectAvatar}>
                                <Modal.Body className={css(styles.menuModalBody)}>
                                    <AvatarSelection registration={true} selectPic={this.selectPicAv} />
                                </Modal.Body>
                            </Modal>
                        )}
                        {this.props.modalCropImage && (
                            <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                className={css(styles.avatarModal, styles.noSelect)}
                                show={true}
                                onHide={this.props.closeCropImage}>
                                <Modal.Body className={css(styles.menuModalBody)}>
                                    <CropImage selectPic={this.selectPic} renderCrop={this.state.renderCrop} text={this.state.text} email={this.state.email} registration={true} />
                                </Modal.Body>
                            </Modal>
                        )}
                    </>
                }
                {/* COMMENTING BELOW LINE FOR CAPTHCA - UNCOMMENT THIS */}
                {/* {this.state.page === 2 && !this.props.token && this.props.recaptchascore >= 0.5 && !this.props.tutorial && */}
                {this.state.page === 2 && !this.props.token && !this.props.tutorial &&
                    <Modal id="signupModal" className={css(styles.signUpPopup)} show={true} animation={true} size="lg"
                        aria-labelledby="contained-modal-title-vcenter" backdrop="static" backdropClassName={css(styles.signUpPopupBackdrop)}
                        centered>
                        <Modal.Header className={css(styles.modalBorder)}>
                            <Modal.Title className={css(styles.signUpTitle, styles.mqPirateOathText)}>It's time to take the pirate oath.<br />Do you agree to the terms? </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={6} sm={6} xs={6} className={css(styles.pirateOath)}>
                                    <Form id="page-2">
                                        <Form.Group controlId="overThirteen">
                                            <Form.Check id="overThirteen" type="checkbox" label="I am 13 years or older." onChange={this.handleCheckboxChange} />
                                        </Form.Group>
                                        {/* <div className={css(styles.termsandagreementsbox)}>Acceptance of the Terms of Use

                                        These terms of use are entered into by and between You and Plndr Mobile, Inc., (“Company”, “we” or “us”). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these “Terms of Use”), govern your access to and use of any content, functionality and services offered on or through plndrmobile.com or a subdomain thereof plndrmobile.com (Websites”) and when you access the Plndr Mobile app on the iOS or Android platforms (“Applications”). Please read the Terms of Use carefully before you start to use the Applications and/or Websites. By using the Applications and Websites, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at www.plndrmobile.com/privacy/, incorporated herein by reference. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Applications and/or Websites.

                                        The Applications and/or Websites are offered and intended only to users who are 13 years of age and older and who are not located in any jurisdiction where prohibited. Use of our Applications and/or Websites may not be legal to use in certain countries. The fact that Plndr Mobile, Inc., is accessible in a country, or appears in the official language of any such country shall not be construed as a representation or warranty with respect to the legality of you to use our Applications and/or Websites in that country.

                                        By using this Applications and/or Websites, you represent and warrant that you meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Applications and/or Websites.

                                        Changes to the Terms of Use

                                        We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Applications and/or Websites thereafter. However, any changes to the dispute resolution provisions set forth in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice on or prior to the date the change is posted on the Applications and/or Websites.

                                        Your continued use of the Applications and/or Websites following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.

                                        Accessing the Applications and/or Websites and Account Security

                                        We reserve the right to withdraw or amend the Applications and/or Websites, and any service or material we provide on the Applications and/or Websites, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Applications and/or Websites is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Applications and/or Websites, or the entire Applications and/or Websites, to users, including registered users.

                                        To access the Applications and/or Websites or some of the resources they offer, you may be asked to provide certain registration details or other information. It is a condition of your use of the Applications and/or Websites that all the information you provide on the Applications and/or Websites is correct, current and complete. You agree that all information you provide to register with the Applications and/or Websites or otherwise, including but not limited to through the use of any interactive features on the Applications and/or Websites, is governed by our Privacy Policy – www.plndrmobile.com/privacy/ and you consent to all actions we take with respect to your information consistent with our Privacy Policy.

                                        If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to the Applications and/or Websites or portions of it using your user name, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.

                                        We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.

                                        Intellectual Property Rights

                                        The Applications and/or Websites and their entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by the Company, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.

                                        These Terms of Use permit you to use the Applications and/or Websites for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Applications and/or Websites, except as follows:

                                        Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.
                                        You may store files that are automatically cached by your Web browser for display enhancement purposes.
                                        You may print or download one copy of a reasonable number of pages of the Applications and/or Websites for your own personal, non-commercial use and not for further reproduction, publication or distribution.
                                        If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.
                                        You must not access or use for any commercial purposes any part of the Applications and/or Websites or any services or materials available through the Applications and/or Websites.

                                        Trademarks

                                        The Company name, the terms, the Company logo and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans on this Website are the trademarks of their respective owners.

                                        Prohibited Uses

                                        You may use the Applications and/or Websites only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Applications and/or Websites:

                                        In any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).
                                        For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.
                                        To send, knowingly receive, upload, download, use or re-use any material which does not comply with these Terms of Use.
                                        To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter” or “spam” or any other similar solicitation.
                                        To impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the foregoing).
                                        To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Applications and/or Websites, or which, as determined by us, may harm the Company or users of the Applications and/or Websites or expose them to liability.
                                        Additionally, you agree not to:

                                        Use the Applications and/or Websites in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the Applications and/or Websites, including their ability to engage in real time activities through the Applications and/or Websites.
                                        Use any robot, spider or other automatic device, process or means to access the Applications and/or Websites for any purpose, including monitoring or copying any of the material on the Applications and/or Websites.
                                        Use any manual process to monitor or copy any of the material on the Applications and/or Websites or for any other unauthorized purpose without our prior written consent.
                                        Use any device, software or routine that interferes with the proper working of the Applications and/or Websites.
                                        Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.
                                        Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Applications and/or Websites, the server(s) on which the Applications and/or Websites are stored, or any server, computer or database connected to the Applications and/or Websites.
                                        Attack the Applications and/or Websites via a denial-of-service attack or a distributed denial-of-service attack.
                                        Otherwise attempt to interfere with the proper working of the Applications and/or Websites.
                                        User Contributions

                                        The Applications and/or Websites may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards and other interactive features (collectively, “Interactive Services”) that allow users to post, submit, publish, display or transmit to other users or other persons (hereinafter, “post”) content or materials (collectively, “User Contributions”) on or through the Applications and/or Websites.

                                        All User Contributions must comply with the Content Standards set out in these Terms of Use.

                                        Any User Contribution you post to the site will be considered non-confidential and non-proprietary. By providing any User Contribution on the Applications and/or Websites, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns the right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material for any purpose/according to your account settings.

                                        You represent and warrant that:

                                        You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns.
                                        All of your User Contributions do and will comply with these Terms of Use.
                                        You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not the Company, have fully responsibility for such content, including its legality, reliability, accuracy and appropriateness.

                                        We are not responsible, or liable to any third party, for the content or accuracy of any User Contributions posted by you or any other user of the Applications and/or Websites.

                                        Children

                                        Our Applications and/or Websites are intended to an adult audience only and is not in any way targeted at children under age 13. Plndr Mobile, Inc. does not knowingly solicit market to, solicit or collect information from any person under the age of 13.

                                        Monitoring and Enforcement; Termination

                                        We have the right to:

                                        Remove or refuse to post any User Contributions for any or no reason in our sole discretion.
                                        Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Applications and/or Websites or the public or could create liability for the Company.
                                        Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.
                                        Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Applications and/or Websites.
                                        Terminate or suspend your access to all or part of the Applications and/or Websites for any or no reason, including without limitation, any violation of these Terms of Use.
                                        Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY/ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.

                                        However, we do not undertake to review all material before it is posted on the Applications and/or Websites, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.

                                        Content Standards

                                        These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local and international laws and regulations. Without limiting the foregoing, User Contributions must not:

                                        Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable.
                                        Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.
                                        Infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person.
                                        Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policy.
                                        Be likely to deceive any person.
                                        Promote any illegal activity, or advocate, promote or assist any unlawful act.
                                        Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person.
                                        Impersonate any person or misrepresent your identity or affiliation with any person or organization.
                                        Involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising.
                                        Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.
                                        Copyright Infringement

                                        If you believe that any User Contributions violate your copyright, please contact us at contact@plndrmobile.com for instructions on sending us a notice of copyright infringement. It is the policy of the Company to terminate the user accounts of repeat infringers.

                                        Reliance on Information Posted

                                        The information presented on or through the Applications and/or Websites is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Applications and/or Websites, or by anyone who may be informed of any of its contents.

                                        This Applications and/or Websites may include content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.

                                        Changes to the Website

                                        We may update the content on this Applications and/or Websites from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Applications and/or Websites may be out of date at any given time, and we are under no obligation to update such material.

                                        Information About You and Your Visits to the Website

                                        All information we collect on this Applications and/or Websites is subject to our Privacy Policy. By using the Applications and/or Websites, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.

                                        Linking to the Website and Social Media Features

                                        You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part without our express written consent.  Any linking must comply in all respects with the Content Standards set out in these Terms of Use.

                                        You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice.

                                        We may disable all or any social media features and any links at any time without notice in our sole discretion.

                                        Links from the Website

                                        If the Applications and/or Websites contain links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to the Applications and/or Websites, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.

                                        Geographic Restrictions

                                        The owner of the Applications and/or Websites is based in the state of California in the United States. We provide the Applications and/or Websites for use only by persons located in the United States. We make no claims that the Applications and/or Websites or any of their contents are accessible or appropriate outside of the United States. Access to the Applications and/or Websites may not be legal by certain persons or in certain countries. If you access the Applications and/or Websites from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.

                                        Disclaimer of Warranties

                                        You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Applications and/or Websites will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.

                                        YOUR USE OF THE APPLICATIONS AND/OR WEBSITES, THE CONTENT CONTAINED WITHIN AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE APPLICATIONS AND/OR WEBSITES IS AT YOUR OWN RISK. THE APPLICATIONS AND/OR WEBSITES, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE APPLICATIONS AND/OR WEBSITES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE APPLICATIONS AND/OR WEBSITES WITHOUT LIMITING THE FOREGOING, NEITHER  THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE APPLICATIONS AND/OR WEBSITES, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE APPLICATIONS AND/OR WEBSITES WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITES OR THE SERVERS THAT MAKE THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE APPLICATIONS AND/OR WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE APPLICATIONS AND/OR WEBSITES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.

                                        THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.

                                        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

                                        Limitation on Liability

                                        IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, APPLICATIONS AND/OR WEBSITES, ANY WEBSITES LINKED TO THEM, ANY CONTENT ON THE APPLICATIONS AND/OR WEBSITES OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE APPLICATIONS AND/OR WEBSITES OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.

                                        THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

                                        Indemnification

                                        You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Use or your use of the Applications and/or Websites, including, but not limited to, your User Contributions, any use of the Website’s content, services and products other than as expressly authorized in these Terms of Use or your use of any information obtained from the Applications and/or Websites.

                                        Governing Law and Jurisdiction

                                        All matters relating to the Applications and/or Websites and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of California without giving effect to any choice or conflict of law provision or rule (whether of the State of California or any other jurisdiction).

                                        Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Applications and/or Websites shall be instituted exclusively in the federal courts of the United States or the courts of the State of California, in each case located in the City of San Diego and County of San Diego, although we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.

                                        Limitation on Time to File Claims

                                        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE APPLICATIONS AND/OR WEBSITES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.

                                        Waiver and Severability

                                        No waiver by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.

                                        If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.

                                        Entire Agreement

                                        The Terms of Use, our Privacy Policy, and the Official Sweepstakes Rules constitute the sole and entire agreement between you and Company with respect to the Applications and/or Websites and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Applications and/or Websites.

                                        Your Comments and Concerns

                                        All other feedback, comments, requests for technical support and other communications relating to the Applications and/or Websites should be directed to: contact@plndrmobile.com

All contents of Website or Service are: Copyright © 2020, Plndr Mobile, Inc. All rights reserved.</div> */}
                                        <Form.Group controlId="agreeToTAC">
                                            <Form.Check id="agreeToTAC" type="checkbox" label="I have read the Terms and Conditions." onChange={this.handleCheckboxChange} />
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: -130 }}>
                                <Col md={6} xs={6} style={{ position: 'relative' }}>
                                    <div className="mr-auto termsAgreeButton">
                                        <Row>
                                            <Button className={css(styles.signUpButton, styles.mqBackButtonOath)} onClick={this.onClickBack}>Back</Button> &nbsp;
                                            <Button disabled={!this.onClickVerify(true)} className={css(styles.signUpButton, styles.mqAgreeButton)} onClick={!this.state.blob ? this.onClickSignUp : this.onClickSignUpImg} >
                                                I AGREE
                                        </Button>

                                        </Row>
                                    </div>

                                </Col>
                                <Col md={6} xs={6} style={{ display: 'flex', justifyContent: 'center', alignItem: 'center' }}>
                                    <div className={css(styles.mqTermsContainer)}>
                                        <img className={css(styles.mqTermsIcon)} alt="" src={require("../../images/termsAndAgreements.svg")} /><br /><br />
                                        <a href="https://plndrmobile.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer" className={css(styles.signUpTermsLink)}>
                                            <Button className={css(styles.signUpButton, styles.mqTermsButton)}>
                                                READ TERMS AND CONDITIONS
                                        </Button>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                }
                {this.props.tutorial && this.state.tutStep === 1 &&
                    <>
                        <Row className={css(styles.homeContentRow)}>
                            <Col md={3} className={css(styles.leaderBox)}>
                                <br />
                                <div className={css(styles.fixedBoxContainer, styles.mqLeaderBoardTut)}>
                                    <Leaderboard />
                                </div>
                            </Col>
                        </Row>
                        <div className={css(styles.landingOverlay)}>
                            <div className={css(styles.landingContentContainer, styles.mqlandingContentContainerBegin)}>
                                <GameContainer tutGame={this.tutGame} />
                            </div>
                        </div>
                    </>
                }
                {this.props.tutorial && this.state.tutStep === 2 &&
                    <GameViewMock tutReturnHome={this.tutReturnHome} />
                }
                {this.props.tutorial && this.state.tutStep === 3 &&
                    <>
                        {setTimeout(this.tutStepFour, 3000)}
                        <Row className={css(styles.homeContentRow)}>
                            <Col md={3} className={css(styles.leaderBox)}>
                                <br />
                                <div className={css(styles.fixedBoxContainer)}>
                                    <Leaderboard />
                                </div>
                            </Col>
                        </Row>
                        <div className={css(styles.landingOverlay)}>
                            <NavBar mock={true} />
                            <div className={css(styles.landingContentContainerStep3, styles.mqlandingContentContainerStep3)}>
                                {/* <GameContainer tutGame={this.tutGame} /> */}
                                <Container className={css(styles.containerBoundsTut)}>
                                    <Row>
                                        <Col className={css(styles.tutorialPrompt)}>
                                            <div className={css(styles.mqtutStep3Container)} style={{ position: 'relative' }}>
                                                <span className={css(styles.tutorialPromptWin, styles.mqtutorialPromptWin)}>THAT'S REALLY</span><br />
                                                <span className={css(styles.tutorialPromptMoney, styles.mqtutorialPromptWin)}>$2.00</span><br />
                                                <span className={css(styles.tutorialPromptHash, styles.mqtutorialPromptHashStep3)}>#WINFORREAL</span>
                                                <MediaQuery minWidth={675}><img className={css(styles.tutorialArrow2, styles.mqtutorialArrow2)} alt="" src={require("../../images/tutorialArrow2.svg")} /></MediaQuery>
                                                <MediaQuery maxWidth={674}><img className={css(styles.tutorialArrow3, styles.mqtutStep3Arrow3)} alt="" src={require("../../images/tutorialArrow3.svg")} /></MediaQuery>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </>
                }
                {this.props.tutorial && this.state.tutStep === 4 &&
                    <>
                        {setTimeout(this.tutStepFive, 3000)}
                        <div className={css(styles.landingOverlay)}>
                            <NavBar hidden={true} />
                            <Row className={css(styles.homeContentRow)}>
                                <Col md={3} sm={3} xs={3} className={css(styles.leaderBox, styles.mqleaderBoxTut)}>
                                    <br />
                                    <div className={css(styles.fixedBoxContainer, styles.mqfixedBoxContainerTut)}>
                                        <Leaderboard />
                                    </div>
                                </Col>
                                <Col md={8} sm={8} xs={8} className={css(styles.tutorialPromptHurry)}>
                                    <div style={{ position: 'absolute', right: 0, textAlign: 'right' }} className={css(styles.mqtutorialPromptSideChallenges)}>
                                        <div style={{ position: 'relative' }}>
                                            <MediaQuery minWidth={1021}>
                                                <span className={css(styles.tutorialPromptWin, styles.mqtutorialPromptWin4)}>YOU CAN ALSO WIN</span><br />
                                                <span className={css(styles.tutorialPromptMoney, styles.mqtutorialPromptWin4)}>SIDE CHALLENGES</span><br />
                                            </MediaQuery>
                                            <MediaQuery maxWidth={1020}>
                                                <div className={css(styles.tutorialPromptWin, styles.mqtutorialPromptWin4)}>
                                                    YOU CAN ALSO WIN SIDE CHALLENGES
                                                </div>
                                            </MediaQuery>
                                            <MediaQuery minWidth={1110}><img className={css(styles.tutorialArrow4, styles.mqtutorialArrow4)} alt="" src={require("../../images/tutorialArrow4.svg")} /></MediaQuery>
                                            <span className={css(styles.tutorialPromptHash, styles.mqtutorialPromptHash4)}>#PLNDRPOINTS</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </>
                }
                {this.props.tutorial && this.state.tutStep === 5 &&
                    <>
                        {setTimeout(this.tutStepSix, 5000)}
                        <div className={css(styles.landingOverlay)}>
                            <NavBar hidden={true} />
                            <Row className={css(styles.homeContentRow)}>
                                <Col md={3} sm={3} xs={3} className={css(styles.leaderBox, styles.mqleaderBoxTut)}>
                                    <br />
                                    <div className={css(styles.fixedBoxContainer, styles.mqfixedBoxContainerTut)}>
                                        <Leaderboard />
                                    </div>
                                </Col>
                                <Col md={8} sm={8} xs={8} className={css(styles.tutorialPromptHurry)}>
                                    <div style={{ position: 'absolute', right: 0, textAlign: 'right' }}>
                                        <div style={{ position: 'relative' }} className={css(styles.mqtutorialPromptLeaderWins)}>
                                            <MediaQuery minWidth={950}>
                                                <span className={css(styles.tutorialPromptWin, styles.mqtutorialPromptWin5)}>THE LEADER WINS</span><br />
                                                <span className={css(styles.tutorialPromptMoney, styles.mqtutorialPromptWin5)}>WHEN TIME HITS 0</span><br />
                                            </MediaQuery>
                                            <MediaQuery maxWidth={949}>
                                                <div className={css(styles.tutorialPromptWin, styles.mqtutorialPromptWin5)}>
                                                    THE LEADER WINS WHEN TIME HITS 0
                                                </div>
                                            </MediaQuery>
                                            <MediaQuery minWidth={1030}><img className={css(styles.tutorialArrow3, styles.mqtutStep5Arrow3)} alt="" src={require("../../images/tutorialArrow3.svg")} /></MediaQuery>
                                            <MediaQuery maxWidth={1029} minWidth={616}><img className={css(styles.mqtutStep5Arrow2)} alt="" src={require("../../images/tutorialArrow2.svg")} /></MediaQuery>
                                            <MediaQuery maxWidth={615} minWidth={500}><img className={css(styles.mqtutStep5Arrow1)} alt="" src={require("../../images/tutorialArrow1.svg")} /></MediaQuery>
                                            <MediaQuery maxWidth={499}><img className={css(styles.mqtutStep5Arrow3Alt)} alt="" src={require("../../images/tutorialArrow3.svg")} /></MediaQuery>
                                            <span className={css(styles.tutorialPromptHash, styles.mqtutorialPromptHash5)}>#PLNDRPOINTS</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </>
                }
                {this.props.tutorial && this.state.tutStep === 6 &&
                    <>
                        {setTimeout(this.tutStepSeven, 5000)}
                        <div className={css(styles.landingOverlay)}>
                            <NavBar hidden={true} />
                            <Row className={css(styles.homeContentRow)}>
                                <Col md={3} sm={3} xs={3} className={css(styles.leaderBox, styles.mqleaderBoxTut)}>
                                    <br />
                                    <div className={css(styles.fixedBoxContainer, styles.mqfixedBoxContainerTut)}>
                                        <Leaderboard />
                                    </div>
                                </Col>
                                <Col md={8} sm={8} xs={8} className={css(styles.tutorialPromptHurry)}>
                                    <div style={{ position: 'absolute', right: 0, textAlign: 'right' }}>
                                        <div style={{ position: 'relative' }} className={css(styles.mqtutorialPromptUseShots)}>
                                            <MediaQuery minWidth={1035}>
                                                <span className={css(styles.tutorialPromptWin, styles.mqtutorialPromptWin6)}>USE YOUR SHOTS</span><br />
                                                <span className={css(styles.tutorialPromptMoney, styles.mqtutorialPromptWin6)}>TO ADVANCE IN RANK</span><br />
                                            </MediaQuery>
                                            <MediaQuery maxWidth={1034}>
                                                <div className={css(styles.tutorialPromptWin, styles.mqtutorialPromptWin6)}>USE YOUR SHOTS TO ADVANCE IN RANK</div>
                                            </MediaQuery>
                                            <MediaQuery minWidth={676}><img className={css(styles.tutorialArrow4Alt, styles.mqtutorialArrow4Alt)} alt="" src={require("../../images/tutorialArrow4.svg")} /></MediaQuery>
                                            <MediaQuery maxWidth={675} minWidth={616}><img className={css(styles.mqtutStep6Arrow3)} alt="" src={require("../../images/tutorialArrow3.svg")} /></MediaQuery>
                                            <MediaQuery maxWidth={615}><img className={css(styles.mqtutStep6Arrow1)} alt="" src={require("../../images/tutorialArrow1.svg")} /></MediaQuery>
                                            <span className={css(styles.tutorialPromptHash, styles.mqtutorialPromptHash6)}>#PLNDRPOINTS</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </>
                }
                {this.props.tutorial && this.state.tutStep === 7 &&
                    <>
                        <Row className={css(styles.homeContentRow)}>
                            <Col md={3} className={css(styles.leaderBox)}>
                                <br />
                                <div className={css(styles.fixedBoxContainer)}>
                                    <Leaderboard />
                                </div>
                            </Col>
                        </Row>
                        <div className={css(styles.landingOverlay)}>
                            <div className={css(styles.landingContentContainerStep3)}>
                                {/* <GameContainer tutGame={this.tutGame} /> */}
                                <Container className={css(styles.containerBoundsTutCenter)}>
                                    <img className={css(styles.logoGetStarted)} style={{ width: 300, marginBottom: -40 }} src={require("../../images/PlndrLogo.png")} />
                                    <div>
                                        <div className={css(styles.tutorialPromptGetStarted)}>Claim Your Prize</div>
                                        <div className={css(styles.tutorialPromptGetStarted)}>and</div>
                                        <div className={css(styles.tutorialPromptGetStarted)}>Get Started.</div>
                                        <Button className={css(styles.getStartedButton)} onClick={this.getStarted}>Let's Go!</Button>
                                    </div>
                                </Container>
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        signupErrors: state.loginReducer.signupErrors,
        recaptchascore: state.loginReducer.recaptchaScore,
        modalSelectAvatar: state.noPersistReducer.selectAvatar,
        modalCropImage: state.noPersistReducer.cropImage,
        duplicateUserError: state.loginReducer.signupErrorDuplicateUser,
        tutorial: state.loginReducer.tutorial
    }
}

export default connect(mapStateToProps, {
    facebookLogin,
    signUp,
    clearError,
    verifyEmail,
    validateRecaptcha,
    showSelectAvatar,
    closeSelectAvatar,
    showCropImage,
    closeCropImage,
    clearDuplicateUserError,
    endTutorial
})(SignUp)