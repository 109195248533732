// GameHistoryModal
import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from 'react-bootstrap/FormLabel';
import Button from 'react-bootstrap/Button';
import { styles } from './ModalStyles.js';
import { css } from 'aphrodite';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { updateFacebookUser, updateUserProfile } from '../../actions/LoginActions';
import { showSelectAvatar, closeSelectAvatar, showCropImage, closeCropImage } from '../../actions/MenuActions';
import Modal from 'react-bootstrap/Modal';
import AvatarSelection from './AvatarSelection.js';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { Media } from 'react-bootstrap';
import CropImage from '../Modals/CropImage.js';

class EditProfileModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.currUser,
            editing: false,
            renderCrop: null
        };

        this.handleFormChange = this.handleFormChange.bind(this);
        this.saveData = this.saveData.bind(this);
        this.selectImage = this.selectImage.bind(this);
        this.editName = this.editName.bind(this);
        this.backToPlndr = this.backToPlndr.bind(this);
    }

    onUploadImage = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ renderCrop: reader.result })
                this.props.showCropImage();
            }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    saveData() {
        if (this.props.currUser.facebookID) {
            this.props.updateFacebookUser(this.props.token, this.state.name);
        }
        else {
            var user = this.props.currUser;
            user.name = this.state.name;
            this.props.updateUserProfile(this.props.token, user);
            this.setState({ editing: false });
        }
    }

    selectImage(url) {
        this.setState({ userProfileURL: url });
    }

    editName() {
        let edit = this.state.editing;
        this.setState({
            editing: !edit
        });
        if (this.state.editing === true) {
            this.setState({
                name: this.props.user.name
            });
        }
    }


    handleFormChange(e) {
        let name = e.target.id
        let value = e.target.value
        this.setState({
            [name]: value
        })
    }

    backToPlndr() {
        document.location = "/"
    }
    render() {
        var urls = this.props.urls;

        return (
            <>
                <MediaQuery maxWidth={575}>
                    <Row style={{ marginTop: 10, marginBottom: 10 }}>
                        <Button className={css(styles.toHomeButtonMobile)} onClick={this.backToPlndr}>BACK TO PLNDR</Button>
                    </Row>
                </MediaQuery>
                <Container className={css(styles.histContainer, styles.noSelect, styles.mqprofileContainer)}>
                    <Row>
                        <Col className={css(styles.mqprofilecol1)} md={1} sm={1} xs={1}></Col>
                        <Col md={11} sm={11} xs={11}>
                            <Row><Col><div className={css(styles.gameHistoryTitle, styles.mqgameHistoryTitle, styles.mqeditProfileTitle)}>PROFILE</div></Col></Row>
                            <Row><Col><div className={css(styles.asOfDate, styles.mqgameHistoryTitle)}>PERSONAL INFORMATION</div></Col></Row>
                            <Row><Col><div> <br></br></div></Col></Row>
                            <Row className={css(styles.mqgameHistoryRow)}>
                                <MediaQuery minWidth={1300}>
                                    <Col md={5}>
                                        <div className={css(styles.dataBoxTop)}>
                                            <Col>
                                                <Row><Col className={css(styles.dataBoxTitle)}>AVATAR</Col></Row>
                                                <Row className={css(styles.dataBoxRow)}>
                                                    <Col md={6}>
                                                        <img className={css(styles.profileModalAvatar)} src={this.props.currUser.facebookID ? this.props.currUser.userProfileURL : this.props.currUser.userProfileURL} />
                                                    </Col>
                                                    <Col md={6}>
                                                        {/* <Row style={{ justifyContent: 'center', marginBottom: 10 }}>
                                                        <Button className={css(styles.profileModalButton, styles.profileModalButtonAlt)}>EDIT PHOTO</Button>
                                                    </Row> */}
                                                        <Row style={{ justifyContent: 'center' }}>
                                                            <Button onClick={this.props.showSelectAvatar} className={css(styles.profileModalButton)}>PICK AVATAR</Button>
                                                            <div style={{ textAlign: 'center', marginTop: 10, marginBottom: -10 }}><label htmlFor="file"><div className={css(styles.profileModalButton, styles.imageUploadButton)}>UPLOAD IMAGE</div></label></div>
                                                            <input style={{ display: 'none' }} type="file" id="file" accept="image/*" onChange={this.onUploadImage} />
                                                        </Row>
                                                        <Row></Row>
                                                    </Col>
                                                </Row>
                                                <div className={css(styles.dataBoxSpacer)}>
                                                    <Row><Col></Col></Row>
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </MediaQuery>
                                <MediaQuery maxWidth={1299}>
                                    <Col md={7}>
                                        <div className={css(styles.dataBoxTop)}>
                                            <Col>
                                                <Row><Col className={css(styles.dataBoxTitle)}>AVATAR</Col></Row>
                                                <Row className={css(styles.dataBoxRow)}>
                                                    <Col md={6} style={{ textAlign: 'center' }}>
                                                        <img className={css(styles.profileModalAvatar, styles.mqprofileModalAvatar)} src={this.props.currUser.facebookID ? this.props.currUser.userProfileURL : this.props.currUser.userProfileURL} />
                                                    </Col>
                                                    <Col md={6} className={css(styles.mqPickAvCol)}>
                                                        {/* <Row style={{ justifyContent: 'center', marginBottom: 10 }}>
                                                        <Button className={css(styles.profileModalButton, styles.profileModalButtonAlt)}>EDIT PHOTO</Button>
                                                    </Row> */}
                                                        <Row style={{ justifyContent: 'center' }}>
                                                            <Button onClick={this.props.showSelectAvatar} className={css(styles.profileModalButton)}>PICK AVATAR</Button>
                                                        </Row>
                                                        <Row style={{ justifyContent: 'center' }}>                                                            <div style={{ textAlign: 'center', marginTop: 10, marginBottom: -10 }}><label htmlFor="file"><div className={css(styles.profileModalButton, styles.imageUploadButton)}>UPLOAD IMAGE</div></label></div>
                                                            <input style={{ display: 'none' }} type="file" id="file" accept="image/*" onChange={this.onUploadImage} />
                                                        </Row>
                                                        <Row></Row>
                                                    </Col>
                                                </Row>
                                                <div className={css(styles.dataBoxSpacer)}>
                                                    <Row><Col></Col></Row>
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </MediaQuery>
                            </Row>
                            <Row className={css(styles.mqgameHistoryRow)}>
                                <MediaQuery minWidth={1300}>
                                    <Col md={5}>
                                        <div className={css(styles.dataBox)}>
                                            <Col>
                                                <Row>
                                                    <Col className={css(styles.dataBoxTitle)}>
                                                        NAME
                                                </Col>
                                                    <Col style={{ position: 'relative' }}>
                                                        <Button onClick={this.editName} className={css(styles.profileModalButton, styles.profileModalButtonEdit)}>{this.state.editing ? "CANCEL" : "EDIT"}</Button>
                                                    </Col>
                                                </Row>
                                                <div className={css(styles.dataBoxRow)}>
                                                    <Row><Col className={css(styles.dataBoxHeader)}>PIRATE NAME</Col></Row>
                                                    {this.state.editing ?
                                                        <Form.Control id="name" type="text" placeholder="Enter user name" onChange={this.handleFormChange} value={this.state.name} className={css(styles.profileModalFormInput)} />
                                                        :
                                                        <Row><Col className={css(styles.valueText)}>{this.props.currUser.name}</Col></Row>
                                                    }
                                                </div>
                                                <div className={css(styles.dataBoxRow)}>
                                                    {this.props.currUser.firstName && (
                                                        <>
                                                            <Row><Col className={css(styles.dataBoxHeader)}>FIRST NAME</Col></Row>
                                                            <Row><Col className={css(styles.valueText)}>{this.props.currUser.firstName}</Col></Row>
                                                        </>
                                                    )}
                                                </div>
                                                <div className={css(styles.dataBoxRow)}>
                                                    {this.props.currUser.firstName && (
                                                        <>
                                                            <Row><Col className={css(styles.dataBoxHeader)}>LAST NAME</Col></Row>
                                                            <Row><Col className={css(styles.valueText)}>{this.props.currUser.lastName}</Col></Row>
                                                        </>
                                                    )}
                                                </div>
                                                {(this.state.editing && this.state.name !== this.props.currUser.name && this.state.name !== '' && this.state.name !== ' ') && (
                                                    <Row>
                                                        <Col style={{ position: 'relative' }}>
                                                            <Button style={{ float: 'right' }} className={css(styles.profileModalButton, styles.profileModalEditButton)} onClick={this.saveData}>SAVE</Button>
                                                        </Col>
                                                    </Row>
                                                )}
                                                <div className={css(styles.dataBoxSpacer)}>
                                                    <Row><Col></Col></Row>
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </MediaQuery>
                                <MediaQuery maxWidth={1299}>
                                    <Col md={7}>
                                        <div className={css(styles.dataBox)}>
                                            <Col>
                                                <Row>
                                                    <Col className={css(styles.dataBoxTitle)}>
                                                        NAME
                                                </Col>
                                                    <Col style={{ position: 'relative' }}>
                                                        <Button onClick={this.editName} className={css(styles.profileModalButton, styles.profileModalButtonEdit)}>{this.state.editing ? "CANCEL" : "EDIT"}</Button>
                                                    </Col>
                                                </Row>
                                                <div className={css(styles.dataBoxRow)}>
                                                    <Row><Col className={css(styles.dataBoxHeader)}>PIRATE NAME</Col></Row>
                                                    {this.state.editing ?
                                                        <Form.Control id="name" type="text" placeholder="Enter user name" onChange={this.handleFormChange} value={this.state.name} className={css(styles.profileModalFormInput)} />
                                                        :
                                                        <Row><Col className={css(styles.valueText)}>{this.props.currUser.name}</Col></Row>
                                                    }
                                                </div>
                                                <div className={css(styles.dataBoxRow)}>
                                                    {this.props.currUser.firstName && (
                                                        <>
                                                            <Row><Col className={css(styles.dataBoxHeader)}>FIRST NAME</Col></Row>
                                                            <Row><Col className={css(styles.valueText)}>{this.props.currUser.firstName}</Col></Row>
                                                        </>
                                                    )}
                                                </div>
                                                <div className={css(styles.dataBoxRow)}>
                                                    {this.props.currUser.firstName && (
                                                        <>
                                                            <Row><Col className={css(styles.dataBoxHeader)}>LAST NAME</Col></Row>
                                                            <Row><Col className={css(styles.valueText)}>{this.props.currUser.lastName}</Col></Row>
                                                        </>
                                                    )}
                                                </div>
                                                {(this.state.editing && this.state.name !== this.props.currUser.name && this.state.name !== '' && this.state.name !== ' ') && (
                                                    <Row>
                                                        <Col style={{ position: 'relative' }}>
                                                            <Button style={{ float: 'right' }} className={css(styles.profileModalButton, styles.profileModalEditButton)} onClick={this.saveData}>SAVE</Button>
                                                        </Col>
                                                    </Row>
                                                )}
                                                <div className={css(styles.dataBoxSpacer)}>
                                                    <Row><Col></Col></Row>
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </MediaQuery>
                            </Row>
                            <Row className={css(styles.pushBottom)}></Row>
                        </Col>
                    </Row>
                </Container>
                {this.props.modalSelectAvatar && (
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className={css(styles.avatarModal, styles.noSelect)}
                        show={true}
                        onHide={this.props.closeSelectAvatar}>
                        <Modal.Body className={css(styles.menuModalBody)}>
                            <AvatarSelection />
                        </Modal.Body>
                    </Modal>
                )}
                {this.props.modalCropImage && (
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className={css(styles.avatarModal, styles.noSelect)}
                        show={true}
                        onHide={this.props.closeCropImage}>
                        <Modal.Body className={css(styles.menuModalBody)}>
                            <CropImage renderCrop={this.state.renderCrop} />
                        </Modal.Body>
                    </Modal>
                )}
                {/* <Link to="/"> */}
                <MediaQuery minWidth={576}>
                    <Button className={css(styles.toHomeButton, styles.mqtoHomeButtonProfile)} onClick={this.backToPlndr}>BACK TO PLNDR</Button>
                </MediaQuery>
                {/* </Link> */}
            </>

            // <div>
            //     {this.props.currUser.facebookID && (
            //         <Form.Group controlId="userName">
            //             <Label>Update your User Name:</Label>
            //             <Form.Control id="name" type="text" placeholder="Enter user name" onChange={this.handleFormChange} value={this.state.name} className={css(styles.profileModalFormInput)} />
            //         </Form.Group>
            //     )}
            //     {!this.props.currUser.facebookID && urls && urls.length > 0 && (
            //         <div>
            //             <Label>Select a Profile Picture</Label>
            //             <Form.Group controlId="profilePic">
            //                 <Form.Control id="userProfileURL" type="text" placeholder="Enter user profile URL" onChange={this.handleFormChange} value={this.state.userProfileURL} />
            //             </Form.Group>
            //             <Row>
            //                 <Col><img width="90" src={urls[0]} onClick={() => { this.selectImage(urls[0]); }} /></Col>
            //                 <Col><img width="90" src={urls[1]} onClick={() => { this.selectImage(urls[1]); }} /></Col>
            //                 <Col><img width="90" src={urls[2]} onClick={() => { this.selectImage(urls[2]); }} /></Col>
            //                 <Col><img width="90" src={urls[3]} onClick={() => { this.selectImage(urls[3]); }} /></Col>
            //                 <Col><img width="90" src={urls[4]} onClick={() => { this.selectImage(urls[4]); }} /></Col>
            //             </Row>
            //             <Row>
            //                 <Col><img width="90" src={urls[5]} onClick={() => { this.selectImage(urls[5]); }} /></Col>
            //                 <Col><img width="90" src={urls[6]} onClick={() => { this.selectImage(urls[6]); }} /></Col>
            //                 <Col><img width="90" src={urls[7]} onClick={() => { this.selectImage(urls[7]); }} /></Col>
            //                 <Col><img width="90" src={urls[8]} onClick={() => { this.selectImage(urls[8]); }} /></Col>
            //                 <Col><img width="90" src={urls[9]} onClick={() => { this.selectImage(urls[9]); }} /></Col>

            //             </Row>
            //         </div>
            //     )}
            //     <br/>
            //         <Button className={css(styles.profileModalSaveButton)} onClick={this.saveData}>SAVE PROFILE INFO</Button>
            // </div>

        )
    }

}

function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        currUser: state.loginReducer.user,
        urls: state.loginReducer.urls,
        modalSelectAvatar: state.noPersistReducer.selectAvatar,
        modalCropImage: state.noPersistReducer.cropImage,
    }
}

export default connect(mapStateToProps, {
    updateFacebookUser,
    updateUserProfile,
    showSelectAvatar,
    closeSelectAvatar,
    showCropImage,
    closeCropImage
})(EditProfileModal);
