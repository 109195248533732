

import { loginActionTypes, gameActionTypes, menuActionTypes, paymentActionTypes, payoutActionTypes, pending_function, rejected_function, fulfilled_function, serverEnvironment } from '../constants/ActionConstants';
import axios from 'axios';
import swal from 'sweetalert';

export var axiosConfig = {
    headers: { 'token': '', 'refUrl': document.location.host }
};

export function validateRecaptcha(recaptchaToken) {
    const { IS_VALID_RECAPTCHA } = loginActionTypes;
    return function (dispatch) {
        // console.log("in validateRecaptcha action", recaptchaToken);
        var payload = {
            recaptchaToken: recaptchaToken
        }
        axios
            .post(serverEnvironment.apiHost + '/login/validaterecaptcha', payload)
            .then((res) => {
                dispatch(fulfilled_function(IS_VALID_RECAPTCHA, res));
            })
            .catch(err => {
                dispatch(rejected_function(IS_VALID_RECAPTCHA, err));
                swal("Error on isvalidRecaptcha", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    }
}
/* 
 * Accepts a facebook response and logs it in the db
 * Registers the user if needed
 * */
export function facebookLogin(fbResponse) {
    const { FACEBOOK_LOGIN } = loginActionTypes;
    const { CLEAR_ALL, GAMES_REFRESHED } = gameActionTypes;

    return function (dispatch) {
        dispatch(pending_function(FACEBOOK_LOGIN));

        var fbResponsePhoto = fbResponse.picture.data.url;
        var subStr = fbResponsePhoto.match("asid=(.*)&height");
        var profilePhotoURL = fbResponsePhoto;

        var payload = {
            email: fbResponse.email,
            name: fbResponse.name,
            loginName: fbResponse.email,
            facebookID: fbResponse.userID,
            id: 0,
            userProfileURL: profilePhotoURL,
            medals: []
        };

        if (fbResponse.referralCode) {
            payload.userReferral = fbResponse.referralCode;
            console.log(fbResponse.referralCode);
        }

        axios
            .post(serverEnvironment.apiHost + '/login/facebook', payload)
            .then((res) => {
                dispatch(fulfilled_function(FACEBOOK_LOGIN, res));
                dispatch(fulfilled_function(CLEAR_ALL));
                dispatch(fulfilled_function(GAMES_REFRESHED));
            })
            .catch(err => {
                dispatch(rejected_function(FACEBOOK_LOGIN, err));
                swal("Error on FacebookLogin", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    }
}

/* 
 * Update facebook user name
 * */
export function updateFacebookUser(token, userName) {
    const { UPDATE_FACEBOOK_USER } = loginActionTypes;
    return function (dispatch) {
        dispatch(pending_function(UPDATE_FACEBOOK_USER));

        axiosConfig.headers.token = token;
        axios
            .put(serverEnvironment.apiHost + '/user/facebook/' + userName, {}, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(UPDATE_FACEBOOK_USER, res));
                // console.log(res);
            })
            .catch(err => {
                dispatch(rejected_function(UPDATE_FACEBOOK_USER, err));
                swal("Error on updateFacebookUser", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    }
}

/* 
 * Update profile pic
 * */
export function updateUserProfile(token, user) {
    const { UPDATE_PROFILE_PIC } = loginActionTypes;
    //const { UPDATE_USER } = gameActionTypes;
    return function (dispatch) {
        dispatch(pending_function(UPDATE_PROFILE_PIC));
        axiosConfig.headers.token = token;
        axios
            .put(serverEnvironment.apiHost + '/user/' + String(user.id), user, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(UPDATE_PROFILE_PIC, res.data));
                // console.log(res);
            })
            .catch(err => {
                dispatch(rejected_function(UPDATE_PROFILE_PIC, err));
                swal("Error on updateUserProfile", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    }
}

/* 
 * Verify email
 * */
export function verifyEmail(email) {
    const { VERIFY_EMAIL } = loginActionTypes;
    return function (dispatch) {
        dispatch(pending_function(VERIFY_EMAIL));

        var payload = {
            userID: 0,
            to: email
        }

        axios
            .post(serverEnvironment.apiHost + '/email/verify', payload, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(VERIFY_EMAIL, res));
                // console.log(res);
            })
            .catch(err => {
                dispatch(rejected_function(VERIFY_EMAIL, err));
                swal("Error on verifyEmail", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    }
}

/* 
 * Log email verification
 * */
export function logVerification(token) {
    const { LOG_VERIFICATION } = loginActionTypes;
    return function (dispatch) {
        dispatch(pending_function(LOG_VERIFICATION));

        var payload = {
            token: token,
            user: null
        }

        axiosConfig.headers.token = token;
        axios
            .post(serverEnvironment.apiHost + '/email/logVerification', payload, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(LOG_VERIFICATION, res));
                // console.log(res);
            })
            .catch(err => {
                dispatch(rejected_function(LOG_VERIFICATION, err));
                swal("Error on logVerification", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    }
}

export function login(userName, password) {
    const { LOGIN } = loginActionTypes;
    const { CLEAR_ALL, GAMES_REFRESHED } = gameActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(LOGIN));
        var payload =
        {
            'userName': userName,
            'password': password
        };

        axios
            .post(serverEnvironment.apiHost + '/login', payload)
            .then((res) => {
                dispatch(fulfilled_function(LOGIN, res.data));
                dispatch(fulfilled_function(CLEAR_ALL));
                dispatch(fulfilled_function(GAMES_REFRESHED));
            })
            .catch(err => {
                dispatch(rejected_function(LOGIN, err));
                if (err.response.status == 401) {
                    swal("Incorrect Login", `We didn't recognize your username or password. Please try again.`, "error")
                }
                else {
                    swal("Error on login", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
                }
            });

    }

}

export function logout(token) {
    const { LOGOUT } = loginActionTypes;
    const { CLEAR_ALL } = gameActionTypes;
    const { CLOSE_USER_MENU } = menuActionTypes;
    const { CLEAR_PAYMENT_INFO } = paymentActionTypes;
    const { CLEAR_PAYOUT_INFO } = payoutActionTypes;

    return function (dispatch) {
        dispatch(pending_function(LOGOUT));

        axiosConfig.headers.token = token;
        axios
            .post(serverEnvironment.apiHost + '/logout', {}, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(LOGOUT, res));
                dispatch(fulfilled_function(CLOSE_USER_MENU));
                dispatch(fulfilled_function(CLEAR_ALL));
                dispatch(fulfilled_function(CLEAR_PAYMENT_INFO));
                dispatch(fulfilled_function(CLEAR_PAYOUT_INFO));
            })
            .catch(err => {
                dispatch(rejected_function(LOGOUT, err));
                swal("Error on logout", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    }
}

export function signUp(signUpInfo) {
    const { SIGNUP } = loginActionTypes;
    const { DUPLICATE_USER } = loginActionTypes;
    // console.log(signUpInfo);

    return function (dispatch, getState) {
        // console.log(serverEnvironment.apiHost)
        dispatch(pending_function(SIGNUP));
        var payload =
        {
            'name': signUpInfo.text,
            'phone': signUpInfo.number,
            'email': signUpInfo.email,
            'password': signUpInfo.password,
            'userProfileURL': signUpInfo.url, 
        };

        if (signUpInfo.referralCode) {
            payload.userReferral = signUpInfo.referralCode;
        }

        console.log(payload); 
    
            axios
            .post(serverEnvironment.apiHost + '/user', payload)
            .then((res) => {
                // console.log(res)
                dispatch(fulfilled_function(SIGNUP, res.data));
            })
            .catch(err => {
                dispatch(rejected_function(SIGNUP, err));
                if (err.response.status === 409) {
                    dispatch(rejected_function(DUPLICATE_USER));
                }
                // swal("Error on signUp", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });
    }
}

export function clearDuplicateUserError() {
    const { DUPLICATE_USER } = loginActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(DUPLICATE_USER));
    }
}

export function closeLandingPopup() {
    const { CLOSE_LANDING_POPUP } = loginActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(CLOSE_LANDING_POPUP));
    }
}

export function clearError() {
    const { CLEAR_ERR } = loginActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(CLEAR_ERR));
    }
}

export function resetPassword(email) {
    const { RESET_PASSWORD } = loginActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(RESET_PASSWORD));

        var payload = {
            userName: email,
            password: ''
        };

        axios
            .post(serverEnvironment.apiHost + '/login/requestToken', payload)
            .then((res) => {
                dispatch(fulfilled_function(RESET_PASSWORD, res.data));
            })
            .catch(err => {
                dispatch(rejected_function(RESET_PASSWORD, err));
                swal("Error on resetPassword", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });

    }

}

export function changePassword(userName, password, token) {
    const { CHANGE_PASSWORD } = loginActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(CHANGE_PASSWORD));

        var payload = {
            userName: userName,
            password: password,
            newpassword: token
        }
        axios
            .post(serverEnvironment.apiHost + '/changePassword', payload)
            .then((res) => {
                dispatch(fulfilled_function(CHANGE_PASSWORD, res.data));
            })
            .catch(err => {
                dispatch(rejected_function(CHANGE_PASSWORD, err));
                swal("Error on changePassword", `This action resulted in a${err.response ? " " + err.response.status : "n"} error. Please report it to support.`, "error")
            });

    }

}


export function startTutorial() {
    const { TUTORIAL_START } = loginActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(TUTORIAL_START));
    }
}

export function endTutorial() {
    const { TUTORIAL_END } = loginActionTypes;

    return function (dispatch) {
        dispatch(fulfilled_function(TUTORIAL_END));
    }
}