/*
    ActionConstants.js
    Plndr 

    Created by Kyle Connolly on 12/30/19.
    Copyright © 2019 Plndr Mobile, inc. All rights reserved. 
*/

export var serverEnvironment = {
    // apiHost: "http://plndrapitest.us-west-2.elasticbeanstalk.com"
    // apiHost: "http://plndrapi-test-2.j9cxmb3qps.us-west-2.elasticbeanstalk.com"
    // apiHost: "http://localhost:44381",
    apiHost: process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST :"https://dev-api.plndr.link",
    adCost: 0.25,
    paypalPayoutAPIHost:(process.env.REACT_APP_STAGE === 'PROD') ? "https://api-m.paypal.com/v1" : "https://api-m.sandbox.paypal.com/v1",
    clientID: (process.env.REACT_APP_STAGE === 'PROD') ? process.env.REACT_APP_PAYPAL_CLIENT_ID : 'AXCZNp2tOqHTWnI5i0GJeI7Gja4WWeY3e8xrCa43e7cYMlv8YfkWInrpK8L7sxoWZ4zQ7Mh1hFuS8YQ7',
    clientSecret: (process.env.REACT_APP_STAGE === 'PROD') ? process.env.REACT_APP_PAYPAL_CLIENT_SECRET : 'EM6zq15rD2icSbIwgyAOCWrszOfxXXsE-fQwrRFmEY3l_6A4ulGdbpXRJ_KK78QPBGK4nnXmmjSuOGMp',
    photosHost: (process.env.REACT_APP_PHOTOS_HOST) ? process.env.REACT_APP_PHOTOS_HOST : "https://dev.plndrplay.com/photos",
    photosS3Bucket: (process.env.REACT_APP_STAGE === 'PROD') ? "plndr-asset-prod" : (process.env.REACT_APP_STAGE === 'STAGING') ? "plndr-asset-staging" : "plndr-asset-dev"
}
 
export const sleep = function (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const pending = function (status) {
    return status.concat("_PENDING");

}

export const rejected = function (status) {
    return status.concat("_REJECTED");
}

export const fulfilled = function (status) {
    return status.concat("_FULFILLED");
}

export function pending_function(type) {
    return {
        type: pending(type)
    }
}

export function rejected_function(type, err) {
    return {
        type: rejected(type),
        payload: err
    }
}

export function fulfilled_function(type, res) {
    return {
        type: fulfilled(type),
        payload: res
    }
}

export const blankGame = {
    id: 0, maxUsers: 0, entryValue: 0, prizeValue: 0, filled: false, templateID: 0, leaderboard: 0, fillStatusFiat: 0, fillStatusUsers: 0, userEntered: false, cannonsGiven: 0, players: [], id: 0, name: ""
};

export const gameActionTypes = {
    CLEAR_CURRENT_GAME: "CLEAR_CURRENT_GAME",
    ENTER_GAME_VIEW: "ENTER_GAME_VIEW",
    GET_GAMES: "GET_GAMES",
    GET_GAME_HISTORY: "GET_GAME_HISTORY",
    GET_LEADERBOARD: "GET_LEADERBOARD",
    REQUEST_SLOT: "REQUEST_SLOT",
    UNREQUEST_SLOT: "UNREQUEST_SLOT", 
    FINALIZE_SLOT: "FINALIZE_SLOT", 
    TRANSACT_VALUE: "TRANSACT_VALUE", 
    UPDATE_USER: "UPDATE_USER",
    UPDATE_USER_BALANCE: "UPDATE_USER_BALANCE",
    CLEAR_ALL: "CLEAR_ALL",
    GAMES_REFRESHED: "GAMES_REFRESHED",
    LEADERBOARD_REFRESHED: "LEADERBOARD_REFRESHED",
    ADD_USER_TO_LEADERBOARD: "ADD_USER_TO_LEADERBOARD"
};

export const interestsActionTypes = {
    SHOW_MORE_INTERESTS_TODO: "SHOW_MORE_INTERESTS_TODO",
    SHOW_MORE_INTERESTS_GENERAL: "SHOW_MORE_INTERESTS_GENERAL",
    HIDE_INTERESTS_TODO: "HIDE_INTERESTS_TODO", 
    HIDE_INTERESTS_GENERAL: "HIDE_INTERESTS_GENERAL"
};

export const menuActionTypes = {
    OPEN_USER_MENU: "OPEN_USER_MENU",
    CLOSE_USER_MENU: "CLOSE_USER_MENU", 
    GAME_ENTRY_CLOSE: "GAME_ENTRY_CLOSE", 
    GAME_ENTRY_MENU: "GAME_ENTRY_MENU",
    PLAY_AD_CONTENT: "PLAY_AD_CONTENT", 
    PLAY_ALL_CONTENT: "PLAY_ALL_CONTENT", 
    STOP_AD_CONTENT: "STOP_AD_CONTENT",
    STOP_ALL_CONTENT: "STOP_ALL_CONTENT",
    OPEN_ADD_CREDITS: "OPEN_ADD_CREDITS",
    CLOSE_ADD_CREDITS: "CLOSE_ADD_CREDITS",
    OPEN_PAYOUT: "OPEN_PAYOUT",
    CLOSE_PAYOUT: "CLOSE_PAYOUT",
    OPEN_WALLET: "OPEN_WALLET",
    CLOSE_WALLET: "CLOSE_WALLET",
    GOT_ALERT: "GOT_ALERT",
    CLEAR_ALERT: "CLEAR_ALERT",
    SHOW_EDIT_PROFILE: "SHOW_EDIT_PROFILE", 
    SHOW_GAME_HISTORY: "SHOW_GAME_HISTORY", 
    SHOW_PAYMENT_HISTORY: "SHOW_PAYMENT_HISTORY", 
    SHOW_PAYOUT_HISTORY: "SHOW_PAYOUT_HISTORY",
    SHOW_WALLET: "SHOW_WALLET", 
    CLOSE_MENU_MODALS: "CLOSE_MENU_MODALS", 
    SHOW_SELECT_AVATAR: "SHOW_SELECT_AVATAR",
    CLOSE_SELECT_AVATAR: "CLOSE_SELECT_AVATAR", 
    OPEN_SIDE_MENUS: "SHOW_SIDE_MENUS", 
    CLOSE_SIDE_MENUS: "CLOSE_SIDE_MENUS", 
    SHOW_CROP_IMAGE: "SHOW_CROP_IMAGE", 
    CLOSE_CROP_IMAGE: "CLOSE_CROP_IMAGE", 
    SHOW_REFER_CODE: "SHOW_REFER_CODE",
    CLOSE_REFER_CODE: "CLOSE_REFER_CODE"
};

export const loginActionTypes = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    FACEBOOK_LOGIN: "FACEBOOK_LOGIN",
    CLOSE_LANDING_POPUP: "CLOSE_LANDING_POPUP",
    SIGNUP: "SIGNUP",
    CLEAR_ERR: "CLEAR_ERR",
    UPDATE_FACEBOOK_USER: "UPDATE_FACEBOOK_USER",
    UPDATE_PROFILE_PIC: "UPDATE_PROFILE_PIC",
    VERIFY_EMAIL: "VERIFY_EMAIL",
    LOG_VERIFICATION: "LOG_VERIFICATION",
    RESET_PASSWORD: "RESET_PASSWORD",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    IS_VALID_RECAPTCHA: "IS_VALID_RECAPTCHA",
    DUPLICATE_USER: "DUPLICATE_USER", 
    TUTORIAL_START: "TUTORIAL_START",
    TUTORIAL_END: "TUTORIAL_END"
};

export const minigameActionTypes = {
    TOGGLE_UI: "TOGGLE_UI",
    UPDATE_CANNON_SHOTS: "UPDATE_CANNON_SHOTS",
    GET_CANNON_BALANCE:"GET_CANNON_BALANCE",
    ADD_MORE_SHOTS: "ADD_MORE_SHOTS",
    CLOSE_MORE_SHOTS: "CLOSE_MORE_SHOTS",
}
    
export const paymentActionTypes = {
    GET_TOKEN: "GET_TOKEN",
    MAKE_PAYMENT: "MAKE_PAYMENT",
    ADD_PAYPAL_PAYMENT: "ADD_PAYPAL_PAYMENT",
    GET_PAYMENT_HISTORY:"GET_PAYMENT_HISTORY",
    CLEAR_PAYMENT_INFO: "CLEAR_PAYMENT_INFO"
};

export const payoutActionTypes = {
    GET_AUTHTOKEN: "GET_AUTHTOKEN",
    CREATE_PAYOUT: "CREATE_PAYOUT",
    CANCEL_PAYOUT: "CANCEL_PAYOUT", //If needed@TODO
    ADD_USER_PAYOUT_INFO:"ADD_USER_PAYOUT_INFO",
    GET_PAYOUT_HISTORY: "GET_PAYOUT_HISTORY",
    CLEAR_PAYOUT_INFO: "CLEAR_PAYOUT_INFO"
};
export const walletActionTypes = {
    GET_CLIENT_TOKEN: "GET_CLIENT_TOKEN",
    GET_PAYMENT_METHODS: "GET_PAYMENT_METHODS",
    CREATE_PAYMETHOD: "CREATE_PAYMETHOD",
    UPDATE_PAYMETHOD: "UPDATE_PAYMETHOD",
    DELETE_PAYMETHOD: "DELETE_PAYMETHOD"
};
    
