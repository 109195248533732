import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
    closeMenuButton: {
        width: 32,
        height: 32,
        borderRadius: 50,
        backgroundColor: "#ffcc00",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    closeMenuIcon: {

    },
    menuModalBody: {
    }, 
    menuModals: {
        zIndex: "1000000"
    },
    navBarPipe: {
        color: "#FAC51C",
        fontSize: 22
    },
    navigationContainer: {
        background: "none",
        paddingBottom: 17,
        zIndex: 100001
    },
    navigationContainerHidden: {
        background: "none",
        paddingBottom: 17,
        zIndex: 100001, 
        visibility: 'hidden'
    }, 
    navProfilePhoto: {
        width: 32,
        height: 32,
        borderRadius: 50
    },
    noSelect: {
        WebkitTouchCallout: 'none', /* iOS Safari */
        WebkitUserSelect: 'none', /* Safari */
        KhtmlUserSelect: 'none', /* Konqueror HTML */
        MozUserSelect: 'none', /* Old versions of Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */
        userSelect: 'none' /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    },
    notificationCount: {
        height: 20,
        width: 20,
        // backgroundColor: "lightyellow",
        color: "rgb(250, 197, 28) !important",
        fontWeight: 700,
        fontSize: 13,
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        // paddingLeft: -5,
        marginLeft: -10,
        marginTop: -8,
        marginBottom: -9,
        borderRadius: 10,
        textAlign: "center",
        border: "1px solid rgb(250, 197, 28)"
    },
    plndrLogoNav: {
        marginLeft: "2%",
        marginTop: -18,
        marginBottom: -50
    },
    plndrLogoNavHidden: {
        marginLeft: "2%",
        marginTop: -18,
        marginBottom: -50, 
        visibility: 'hidden', 
        display: 'none'
    },
    profileMenuToggle: {
        border: "3px solid",
        borderRadius: 30,
        ":hover": {
            cursor: "pointer"
        }
    },
    navBarButton: {
        background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
        height: 32,
        border: "none",
        borderRadius: 4,
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        color: "#3A3934",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        ":focus:active": {
            boxShadow: "none"
        }
    },
    navBarButtonDisable: {
        background: "linear-gradient(270deg, #FFE500 0%, #FAC51C 100%)",
        height: 32,
        border: "none",
        borderRadius: 4,
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 15,
        letterSpacing: 0.79,
        textAlign: "center",
        color: "#3A3934",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 16px 2px rgba(0,0,0,0.16)",
        opacity: 0.4, 
        ":focus:active": {
            boxShadow: "none"
        }, 
        ":hover": {
            cursor: 'default'
        }
    }, 
    navBarEmphasis: {
        position: 'absolute', 
        width: 320, 
        height: 300, 
        backgroundColor: "#3a3934", 
        zIndex: -1, 
        marginTop: -50, 
        borderRadius: 200, 
        right: 13, 
        '@media (max-width: 699px)': {
            width: 200, 
            height: 200, 
            right: 1
        }
    },
    userBalanceNav: {
        color: "#FFC300",
        textAlign: "right"
    },
    userInfoNav: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: 15,
        letterSpacing: 0.79
    },
    userNameNav: {
        color: "#FFC400",
        wordBreak: "break-all",
        ":hover": {
            cursor: "pointer"
        }
    },
    videoButtonIcon: {
        width: 24,
        height: 24,
        ":focus:active": {
            boxShadow: "none",
            border: "none"
        }
    },
    videoButtonNav: {
        boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 4px 0px, rgba(0, 0, 0, 0.3) 0px 4px 16px 2px",
        borderRadius: 50,
        ":hover": {
            cursor: "pointer"
        },
        ":focus:active": {
            boxShadow: "none"
        }
    }
});